import React from 'react'
import { Grid, Box, Typography } from '@mui/material'
import TableView from '../../../../utilComponents/TableView'

const View = ({ client }) => {
  return (
    <Grid item xs={12} container sx={{ padding: '1rem' }} >
      <Grid item xs={12} sx={{ marginTop: '0rem' }}>
        <Typography variant='h3'>Client Details</Typography>
      </Grid>
      <Grid item xs={12} sx={{ marginTop: '1rem' }}>
        <TableView title={'email'} value={client.email} />
        <TableView title={'username'} value={client.username} />
        <TableView title={'Group'} value={client.clientGroup} />
        <TableView title={'phone number'} value={client.phoneNumber || 'no number'} />
        <TableView title={'priceList'} value={client.priceListId} />
        <TableView title={'status'} value={client.status.toString()} />
        <TableView title={'credit customer'} value={client.creditPayment.toString()} />
      </Grid>
      <Grid item xs={12} sx={{ marginTop: '1rem' }}>
        <Typography sx={{ marginTop: '1rem' }} variant='h3'>Client Address(es)</Typography>
        {client.addresses.map(address => {
          return (
            <Box key={address.id} sx={{ marginTop: '1rem' }}>
              <Box sx={{ paddingLeft: '1rem', marginTop: '1rem' }}>
                <TableView title='name' value={address.name} />
                <TableView title='address' value={address.address} />
                <TableView title='pincode' value={address.pincode} />
                <TableView title='lng_lat' value={`${address.lngLat.x}-${address.lngLat.y}`} />
              </Box>
            </Box>
          )
        })}
      </Grid>
      <Grid item xs={12} sx={{ marginTop: '1rem', marginBottom: '3rem' }}>
        <Typography sx={{ marginTop: '1rem' }} variant='h3'>GST</Typography>
        {client?.gst && client?.gst.length > 0 ?
          client?.gst.map(gst => { return <TableView title='GST' value={client?.gst.join(', ')} /> })
          :
          <Typography variant='h5' sx={{ marginTop: '2rem', paddingLeft: '1rem' }}>No associated GST</Typography>
        }
      </Grid>
    </Grid >
  )
}

export default View 