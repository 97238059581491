import React, { useEffect } from 'react';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import AsxRoutes from './routes/Asx';
import GodownRoutes from './routes/Godown'
import { getUserAction } from './reducer_action/user';
import Auth from './routes/Auth';
import Authentication from './components/Authentication_Module/Authentication';

const App = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user);

  useEffect(() => {
    !Object.keys(user).length > 0 && dispatch(getUserAction());
  }, [dispatch, user]);


  return (
    <>
      <Routes>
        <Route path='/' element={<Auth />}> </Route>
        <Route path='/auth' element={<Authentication />} />
      </Routes>
      <AsxRoutes />
      <GodownRoutes />
    </>
  );
};

export default App;