import React from "react";
import { TableRow, TableCell, Typography } from "@mui/material";

const PaymentCard = () => {
  return (
    <TableRow>
      <TableCell>124</TableCell>
      <TableCell>24/1/2022</TableCell>
      <TableCell>name / username </TableCell>
      <TableCell>Rs: 3450</TableCell>
      <TableCell>Advance payment for binding and color print outs</TableCell>
      <TableCell> Pending </TableCell>
      <TableCell>
        <Typography>Bill id : 43 </Typography>
        <Typography>
          billed @ branch id : 4sdfhASGyhsdfg256Jd$dhs3 / branch name
        </Typography>
        <Typography>branch name or</Typography>
        <Typography variant="h5">no associations </Typography>
      </TableCell>
    </TableRow>
  );
};

export default PaymentCard;
