import React from "react";
import {
  Typography,
  Box,
  Grid,
  Select,
  MenuItem,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import BillingItem from "./BillingItem";

// pass the item id and pull the
const BillingForm = () => {
  return (
    <Grid item xs={12} container sx={{}}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography align="left">Name</Typography>
              </TableCell>
              <TableCell>Size/ Option values</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Unit Price</TableCell>
              <TableCell>Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography>Copier ~75gsm</Typography>
              </TableCell>
              <TableCell>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    rowGap: ".3rem",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      columnGap: "0.5rem",
                    }}
                  >
                    <Typography align="left">Size:</Typography>
                    <Select variant="standard" value="hello">
                      <MenuItem
                        // sx={{ color: "white" }}
                        value="hello"
                        // divider={true}
                      >
                        hello
                      </MenuItem>
                    </Select>

                    {/* <EditIcon /> */}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      columnGap: ".5rem",
                    }}
                  >
                    <Typography align="left">Color:</Typography>
                    <Select variant="standard" value="hello">
                      <MenuItem sx={{ color: "white" }} value="hello">
                        hello
                      </MenuItem>
                    </Select>
                    {/* <EditIcon /> */}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      // justifyContent:'space-between',
                      columnGap: ".5rem",
                    }}
                  >
                    <Typography align="left">Option Name:</Typography>
                    <Select variant="standard" value="hello">
                      <MenuItem sx={{}} value="hello">
                        hello
                      </MenuItem>
                    </Select>
                  </Box>
                </Box>
              </TableCell>
              <TableCell sx={{ maxWidth: "30px" }}>
                <TextField fullWidth label="Quantity" value="124" />
              </TableCell>
              <TableCell sx={{ maxWidth: "30px" }}>
                <TextField fullWidth label="Unit Price" value="456" />
              </TableCell>
              <TableCell sx={{ maxWidth: "30px" }}>
                <Typography align="left">15660</Typography>
              </TableCell>
              <TableCell>
                <DeleteOutlineIcon />
              </TableCell>
            </TableRow>
            <BillingItem />
            <BillingItem />
            <BillingItem />
            <BillingItem />
            <BillingItem />
            <BillingItem />
            <BillingItem />
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

export default BillingForm;
