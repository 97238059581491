import React, { useRef, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { Grid } from '@mui/material'

// components
import GodownOrdersHeader from './GodownOrdersHeader'
import GodownOrdersBody from './GodownOrdersBody'
// service 
import { getAllDemandOrder } from '../../../../../service/demandOrder'

// dispatch actions
import { getAllProductsAction } from '../../../../../reducer_action/product'
import { getAllOptionsAction } from '../../../../../reducer_action/options'
import { getAllProductUtilDataAction } from '../../../../../reducer_action/productUtil'

const GodownOrdersSideBar = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const location = useLocation()
  const headerRef = useRef()

  const useQuery = () => new URLSearchParams(location.search);
  const query = useQuery()

  const enums = useSelector(state => state.productUtil.enums)

  const [headerHeight, setHeaderHeight] = useState(0);
  // header
  const [itemName, setItemName] = useState(query.get('itemName'))
  const [status, setStatus] = useState(query.get('status'))
  const [cognitoGroup, setCognitoGroup] = useState(query.get('cognitoGroup'))
  // body/footer
  const [currentPage, setCurrentPage] = useState(query.get('currentPage'))
  const [limit, setLimit] = useState(query.get('limit'))
  const [orderByDesc, setOrderByDesc] = useState(Boolean(query.get('orderByDesc') === 'true'))
  const [returnOrder, setReturnOrder] = useState(false)
  // demandOrders
  const [demandOrders, setDemandOrders] = useState({})

  useEffect(() => {
    dispatch(getAllProductsAction());
    dispatch(getAllOptionsAction());
    dispatch(getAllProductUtilDataAction());
  }, [dispatch]);

  useEffect(() => {
    // ? enums ensure this runs after the dispatch hooks
    enums && (async () => {
      const orderId = params.orderId ? params.orderId : null
      const data = { currentPage, limit, itemName, status, orderByDesc, cognitoGroup, orderId, returnOrder }
      const demandOrders = await getAllDemandOrder(data)

      setDemandOrders(demandOrders)
      const height = headerRef.current && headerRef?.current.offsetHeight;
      setHeaderHeight(height);
    })()
    // disable
  }, [currentPage, limit, itemName, status, orderByDesc, cognitoGroup, headerRef, enums, params, returnOrder])


  return (
    <>
      <Grid container item xs={12}>
        {enums &&
          <GodownOrdersHeader
            headerRef={headerRef}
            status={status}
            setStatus={setStatus}
            itemName={itemName}
            setItemName={setItemName}
            cognitoGroup={cognitoGroup}
            setCognitoGroup={setCognitoGroup}
          />
        }
        {demandOrders && demandOrders.count >= 0 ?
          <GodownOrdersBody
            // ? misc
            headerHeight={headerHeight}
            // ? the orders
            demandOrders={demandOrders}
            // ? page settings
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            limit={limit}
            setLimit={setLimit}
            orderByDesc={orderByDesc}
            setOrderByDesc={setOrderByDesc}
            // ? set from the header
            itemName={itemName}
            cognitoGroup={cognitoGroup}
            status={status}
            returnOrder={returnOrder}
            setReturnOrder={setReturnOrder}
          /> : 'no orders'
        }
      </Grid>
    </>
  )
}

export default GodownOrdersSideBar  