import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { Grid, Box, Select, MenuItem } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';

const GodownOrdersHeader = ({ headerRef, status, setStatus, itemName, setItemName, cognitoGroup, setCognitoGroup }) => {
  // const location = useLocation()
  // const useQuery = () => new URLSearchParams(location.search);
  // const query = useQuery()
  // const navigate = useNavigate()

  // todo .. this is pulling from state, when the sidebar should be dependent on the presence of  associated params
  // const orderId = useParams().orderId
  const util = useSelector((state) => state?.util);

  const cognitoGroups = useSelector(state => state.productUtil && state?.productUtil.cognitoGroups);
  const orderStatus = useSelector(state => state.productUtil && state?.productUtil.enums.find(x => x.name === 'order_status_type')?.value.split('|'));

  const [tempItemName, setTempItemName] = useState('');

  const handleKeyPress = (e) => {
    console.log(e.key, 'keyPress')
    if (e.key === 'Enter' || e.which === 'Enter') {
      setItemName(tempItemName)
      // todo match the address with the state variables
    }
  }

  return (
    <Grid container item xs={12} justifyContent='space-between' alignItems='flex-start' ref={headerRef} >
      <Grid item xs={12}>
        <Box sx={{ padding: '1rem 1rem' }}>
          <OutlinedInput
            id='standard-search'
            value={tempItemName}
            fullWidth
            onChange={(e) => setTempItemName(e.target.value)}
            onKeyPress={handleKeyPress}
            sx={{ margin: '0' }}
            endAdornment={
              <InputAdornment position='end'>
                <SearchIcon sx={{ color: 'black' }} />
              </InputAdornment>
            }
          />
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: util.mainSideBar ? 'space-between' : 'flex-end',
            alignItems: util.mainSideBar ? 'flex-end' : 'flex-start',
            padding: '0 1rem ',
          }}
        >
          <Select
            fullWidth
            value={status}
            sx={{
              color: 'primary.main',
              fontWeight: '800',
            }}
            onChange={(e) => setStatus(e.target.value)}
          >
            <MenuItem value={'ALL'}>all</MenuItem>
            {orderStatus.map((x) => {
              return <MenuItem key={x} value={x}>{x.toLowerCase()}</MenuItem>
            })}
          </Select>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: util.mainSideBar ? 'space-between' : 'flex-end',
            alignItems: util.mainSideBar ? 'flex-end' : 'flex-start',
            padding: '0 1rem ',
          }}
        >
          <Select
            fullWidth
            value={cognitoGroup}
            sx={{
              color: 'primary.main',
              fontWeight: '800',
            }}
            onChange={(e) => setCognitoGroup(e.target.value)}
          >

            <MenuItem value={'ALL'}>all</MenuItem>
            {cognitoGroups.filter(x => !['admin',
              // 'godown',
              'order_fullfillment'].includes(x.GroupName)).map((group) => {
                return <MenuItem key={group.GroupName} value={group.GroupName}>{group.GroupName}</MenuItem>
              })}
          </Select>
        </Box>
      </Grid>
    </Grid >
  )
}

export default GodownOrdersHeader