import React, { useState, useEffect } from 'react';
import { Auth as AuthService } from 'aws-amplify'
import Authentication from '../components/Authentication_Module/Authentication';
import CenterBox from '../components/utilComponents/CenterBox';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';


const Auth = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [currentUser, setCurrentUser] = useState({});
  const [userGroup, setUserGroup] = useState('');
  const currentModule = location.pathname.split('/')[1];


  useEffect(() => {
    (async () => {
      const session = await AuthService.currentSession();
      const group = session.getIdToken().payload['cognito:groups'][0];
      setCurrentUser(session);
      setUserGroup(group === 'asx_branch' ? 'asx' : group);
    })()
  }, [dispatch])


  return (
    <>
      {currentUser?.idToken?.payload ?
        (
          process.env.NODE_ENV === 'production' ?
            <> {currentModule === userGroup ? children : navigate(`/${userGroup}`)} </>
            : children
        ) : (
          <CenterBox> <Authentication /> </CenterBox>
        )
      }
    </>
  );
};

export default Auth;
