import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box, Button, Grid, Typography } from '@mui/material';
import EditTableView from '../../../../../../../utilComponents/EditTableView';
import { postItemRuleAction } from '../../../../../../../../reducer_action/product';

const SizeOption = ({ ruleType }) => {
  const params = useParams();
  const dispatch = useDispatch();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [sizeIds, setSizeIds] = useState([]);
  const [optionIds, setOptionIds] = useState([]);
  const [hideOptionIds, setHideOptionIds] = useState([]);

  const itemSizes = useSelector(
    (state) => state.product.find((x) => x.id === +params.item).size
  );
  const itemOption = useSelector(
    (state) => state.product.find((x) => x.id === +params.item).options
  );

  const optionSelectValues = itemOption
    .map((option) => {
      const selectValuesOptions = option.values.map((value) => {
        return {
          name: `${option.name}-${value.optionValueName}`,
          id: value.id,
        };
      });
      return selectValuesOptions;
    })
    .flat();

  const handleSubmit = () => {
    console.log({
      title,
      description,
      sizeIds,
      optionIds,
      hideOptionIds,
      ruleType,
      itemId: +params.item,
    });
    const rule = {
      ruleTitle: title,
      ruleDescription: description,
      sizeId: sizeIds,
      hideOptionValueId: hideOptionIds,
      optionValueId: optionIds,
      ruleType,
      itemId: +params.item,
    };
    dispatch(postItemRuleAction(rule));
  };
  return (
    <Grid item container xs={12}>
      <Grid item xs={12}>
        <Typography variant='h4'>SizeOption</Typography>
      </Grid>
      <Grid item xs={12}>
        <EditTableView
          title='rule name'
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          type='text'
        />
        <EditTableView
          title='rule description'
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          type='text'
        />
        <EditTableView
          title='size'
          value={sizeIds}
          onChange={(e) => setSizeIds(e.target.value)}
          type='multiSelect'
          selectValues={itemSizes.map((x) => {
            const sizes = {
              name: x.sizeTitle,
              id: x.id,
            };
            return sizes;
          })}
        />
        <EditTableView
          title='option'
          value={optionIds}
          type='multiSelect'
          selectValues={optionSelectValues}
          onChange={(e) => setOptionIds(e.target.value)}
        />
        <EditTableView
          title='hide options'
          value={hideOptionIds}
          type='multiSelect'
          selectValues={optionSelectValues}
          onChange={(e) => setHideOptionIds(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '1rem',
          }}
        >
          <Button variant='contained' onClick={handleSubmit}>
            save
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default SizeOption;
