import React, { useState } from 'react'
import { Box, Grid, Tabs, Tab, Typography } from '@mui/material'

// import { putOrderGuideline } from '../../../../../../../service/productUtil'
// import EditTableView from '../../../../../../utilComponents/EditTableView'

import EditAddOrderGuideline from './EditAddOrderGuideline'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

const OrderGuideline = () => {
  const params = useParams()

  const currentProduct = useSelector(state => state.product.find(x => x.id === +params.item))
  const category = useSelector(state => state.productUtil.category.find(x => x.id === currentProduct.categoryId))

  const [sizeTabId, setSizeTabId] = useState(currentProduct.size.length > 0 ? currentProduct.size[0].id : null)
  // const currentOrderGuideline = currentProduct.orderGuideline.length > 0 && currentProduct.orderGuideline.find(x => x.itemSizeId === sizeTabId)
  // const allOrderUnits = useSelector(state => state.productUtil.orderUnit)

  const isItABillingItem = () => category.categoryGroupId === 1

  const ItemHasNoSize = () => {
    return (
      <Box>
        <Typography>
          This item has no size. Please add size to this item to add order guideline.
        </Typography>
      </Box>
    )
  }

  const ItemHasSize = () => {
    return (
      <Grid container >
        <Grid item xs={12}>
          <Tabs value={sizeTabId}>
            {currentProduct.size.length > 0 &&
              currentProduct.size.map((size) => {
                console.log(size)
                return (
                  <Tab sx={{}} key={size.id} value={size.id} label={size.sizeTitle}
                    onClick={() => { setSizeTabId(size.id) }}
                  />
                );
              })}
          </Tabs>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: '2rem' }}>
          {sizeTabId && <EditAddOrderGuideline sizeTabId={sizeTabId} />}
        </Grid>
      </Grid>
    )
  }

  return (
    // post table section
    // nothing should show up when the item category Group is a billing Item 
    <>
      {/* {isItABillingItem() ? */}
      {/* <Typography variant='h4'>This is a billing Item and does not have a any order guidelines</Typography> */}
      {/* : */}
      <> {currentProduct.size.length > 0 ? <ItemHasSize /> : <ItemHasNoSize />} </>
      {/* } */}
    </>
  )
}

export default OrderGuideline