import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Typography, TextField, Button, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, ListItemText, Tabs, Tab, Grid, Select, MenuItem, } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { updateItemPriceRangeTableAction } from '../../../../../../reducer_action/product';

// todo the price are deleted for all sizes when one size price is modified the backend needs to have a size where clause to ensure against this.
const Price = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const product = useSelector((state) => { return state.product.find((x) => x.id === +params.item); });
  const priceList = useSelector((state) => state.productUtil.priceList);
  const [sizeTab, setSizeTab] = useState(product.size[0] || null);
  const [priceListId, setPriceListId] = useState(priceList[0].id);

  const handlePriceListChange = (e) => {
    setPriceListId(e.target.value);
  };

  const handleSubmitItemSizePrice = () => {
    console.log(sizeTab.price);
    // we can only update one pricelist at a time since we delete all the prices in the pricelist and then add new ones. So prices cannot be used with their foreign key for identification and in the bill we will use the actual prices
    const currentPriceListPrices = sizeTab.price.filter((x) => x.priceListId === priceListId);
    // console.log(currentPriceListPrices);
    dispatch(updateItemPriceRangeTableAction(currentPriceListPrices));
  };

  return (
    <>
      {sizeTab ? (
        <Grid container sx={{ marginTop: '0rem' }}>
          {/* choose size */}
          <Grid item xs={12}>
            <Tabs value={sizeTab.id} >
              {product.size.map((size) => {
                return (
                  <Tab
                    key={size.id}
                    value={size.id}
                    label={size.sizeTitle}
                    onClick={() => setSizeTab(size)}
                  />
                );
              })}
            </Tabs>
          </Grid>
          {/* choose the associated price list for the item */}
          <Grid item xs={12} sx={{ margin: '2rem 0 1rem 0' }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Select
                value={priceListId}
                onChange={handlePriceListChange}
                sx={{ minWidth: '200px' }}
              >
                {priceList.map((priceList) => {
                  return (
                    <MenuItem key={priceList.id} value={priceList.id}>
                      <ListItemText>{priceList.name}</ListItemText>
                    </MenuItem>
                  );
                })}
              </Select>
            </Box>
          </Grid>
          {/* // edit the price list */}
          <Grid item xs={12}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Start Quantity</TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sizeTab.price
                    // .filter((x) => x.priceListId === +priceListId)
                    .map((price, index, priceArray) => {
                      if (price.priceListId !== +priceListId) {
                        return null;
                      }
                      return (
                        // this table uses the index because the new rows do not have a id to use .. so we use both

                        <TableRow key={`${price.id}${index}`}>
                          <TableCell>
                            <TextField
                              value={price.startQuantity}
                              onChange={(e) => {
                                console.log({ e: e.target.value, index });
                                priceArray[index].startQuantity = +e.target.value;
                                setSizeTab({ ...sizeTab, price: priceArray });
                              }}
                            />
                          </TableCell>
                          <TableCell>
                            <TextField
                              value={price.price}
                              onChange={(e) => {
                                console.log({ e: e.target.value, index });
                                priceArray[index].price = +e.target.value;
                                setSizeTab({ ...sizeTab, price: priceArray });
                              }}
                            />
                          </TableCell>
                          <TableCell
                            onClick={() => {
                              setSizeTab({ ...sizeTab, price: priceArray.filter((x, i) => i !== index), });
                            }}
                          >
                            <DeleteIcon />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  <TableRow>
                    <TableCell />
                    <TableCell />
                    <TableCell
                      onClick={() => {
                        setSizeTab({
                          ...sizeTab,
                          price: [
                            ...sizeTab.price,
                            {
                              itemId: product.id,
                              itemSizeId: +sizeTab.id,
                              startQuantity: 0,
                              priceListId,
                              price: 0,
                            },
                          ],
                        });
                      }}
                    >
                      <AddIcon />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Grid item xs={12} sx={{ marginTop: '2rem' }}>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button onClick={handleSubmitItemSizePrice} variant='contained'> save </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Typography>
          There are no sizes and therefore no prices associated with this item
        </Typography>
      )}
    </>
  );
};

export default Price;
