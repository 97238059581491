import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import View from './pages/View'

const ClientOrderBody = ({ order }) => {
  // const action = useSelector(state => state.util.actionQuery)
  const location = useLocation()
  const useQuery = () => new URLSearchParams(location.search);
  const query = useQuery()
  const action = query.get('action');

  return (
    <>
      {action === 'view' && <View order={order} />}
    </>
  )
}

export default ClientOrderBody    