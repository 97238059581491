import React from 'react';
import { useLocation, useParams } from 'react-router-dom';

import View from './ProductsPages/View';
import BuyNow from './ProductsPages/Order';

import Grid from '@mui/material/Grid';

const Body = ({ height }) => {
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const action = query.get('action');

  return (
    <Grid
      container
      alignItems='flex-start'
      sx={{
        height: `calc(100vh - ${height}px)`,
        overflow: 'scroll',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      }}
    >
      <Grid container sx={{ padding: '1.25rem 1rem 3rem 1.5rem' }}>
        {action === 'view' && (<View />)}
        {action === 'order' && (<BuyNow />)}
      </Grid>
    </Grid>
  );
};

export default Body;
