import React, { useEffect, useState } from 'react'
import { Box, Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getOneDemandOrder, updateOrderItemStatus, updateOrderStatus } from '../../../../../../service/demandOrder'
import { addProductToShipmentCartAction } from '../../../../../../reducer_action/shipCart'
import userService from '../../../../../../service/user'
import { toast } from 'react-toastify'

const View = ({ order, setOrder }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const user = useSelector(state => state.user);
  const shipmentCart = useSelector(state => state.shipmentCart);

  const [orderAddress, setOrderAddress] = useState({})

  useEffect(() => {
    (async () => {
      try {
        const userAddresses = await userService.findUsersAddress([order.clientUsername])
        const address = userAddresses.addresses.find(address => address.id === order.clientAddressId)
        setOrderAddress(address)
      } catch (error) {
        toast.error('Error while fetching user address(es)')
      }
    })()
  }, [order.clientUsername, order.clientAddressId])

  const product = useSelector(state => state.product)
  const option = useSelector(state => state.option)
  const orderUnit = useSelector(state => state.productUtil.orderUnit)
  const trackingUnit = useSelector(state => state.productUtil.trackingUnit)

  const findProductNameFromId = (itemId) => {
    const name = product.find((product) => product.id === itemId)?.name;
    return name;
  };

  const findProductSizeName = (itemId, sizeId) => {
    const currentProduct = product.find((x) => x.id === itemId);
    const sizeName = currentProduct?.size.find((x) => x?.id === sizeId)?.sizeTitle;
    return sizeName;
  };

  const findOptionNameFromId = (optionId) => {
    const name = option.find((option) => option?.id === optionId)?.name;
    return name;
  };

  const findOptionValueFromId = (optionId, optionValueId) => {
    const currentOption = option.find((x) => x.id === optionId);
    const valueName = currentOption?.values.find(
      (v) => v.id === optionValueId
    ).optionValueName;
    return valueName;
  };

  const handleUpdateOrderItemStatus = async (orderId, orderItemId, status) => {
    try {
      await updateOrderItemStatus({ orderId, orderItemId, status })
      const order = await getOneDemandOrder({ orderId: params.orderId })
      setOrder(order)
    } catch (error) {
      toast.error('Error while updating order item status')
    }
  }

  const handleUpdateOrderStatus = async (orderId, status) => {
    try {
      await updateOrderStatus({ orderId, status })
      const order = await getOneDemandOrder({ orderId: params.orderId })
      setOrder(order)
    } catch (error) {
      toast.error('Error while updating order status')
    }
  }

  const undoShipmentCartItemUpdate = async (orderId, orderItemId, status) => {
    console.log('undoShipmentCartItemUpdate', orderId, orderItemId, status)

    try {
      await updateOrderItemStatus({ orderId, orderItemId, status })
      const order = await getOneDemandOrder({ orderId: params.orderId })
      setOrder(order)
    } catch (error) {
      toast.error('Error while updating order item status')
    }
  }

  const addShipmentCartItem = (group, email, item, order) => {
    dispatch(addProductToShipmentCartAction(group, email, item, order))
  }

  const actionButton = (orderItem, order) => {
    switch (orderItem.status) {
      case 'PENDING':
        return (
          <>
            <Button onClick={() => handleUpdateOrderItemStatus(orderItem.orderId, orderItem.id, 'ACCEPTED')} variant='contained'>
              {order.returnOrder ? 'Accept Return' : 'Accept'}
            </Button>
            <Button variant='contained' color='error' onClick={() => handleUpdateOrderItemStatus(orderItem.orderId, orderItem.id, 'REJECTED')}>
              {order.returnOrder ? 'Reject Return' : 'Reject'}
            </Button>
          </>
        )
      case 'PROCESSING':
        return <>
          {
            shipmentCart.map(x => x.id).includes(orderItem.id) ?
              <Button variant='contained' color='primary' disabled >added</Button> :
              <Button variant='contained' color='primary' onClick={() => addShipmentCartItem(user.clientGroup, user.email, orderItem, order)}>ship cart</Button>
          }
          {!shipmentCart.map(x => x.id).includes(orderItem.id) &&
            <Button variant='contained' color='error' onClick={() => undoShipmentCartItemUpdate(orderItem.orderId, orderItem.id, 'PENDING')}>undo</Button>}
        </>
      case 'CANCELLED':
        return <>
          {!shipmentCart.map(x => x.id).includes(orderItem.id) &&
            <Button variant='contained' color='error' onClick={() => undoShipmentCartItemUpdate(orderItem.orderId, orderItem.id, 'PENDING')}>undo</Button>}
        </>
      default:
        return <Button disabled> none</Button>
    }
  }

  return (
    <>
      {order ? (
        <Box sx={{ padding: '1rem', width: '100%' }}>
          <TableContainer>
            <Table sx={{}}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant='h4'>Item</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h4'>Item Info</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h4'>specification</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h4'>media / notes</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h4'>status</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h4'>action</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {order && order.orderItems && order?.orderItems.length > 0 && order?.orderItems.sort((a, b) => a.itemId - b.itemId).map((item, index) => {
                  return <TableRow key={`${item.id}-${index}-${Math.random()}`}>
                    <TableCell>
                      {findProductNameFromId(item.itemId)}
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: 'flex', flexDirection: 'column', }} >
                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', }} >
                          <Typography>Size:</Typography>
                          <Typography>
                            {findProductSizeName(item.itemId, item.itemSizeId)}
                          </Typography>
                        </Box>
                        {item.options.length > 0 && item.options.map((op, index) => {
                          return (
                            <Box key={`${op.optionId}-${Math.random()}-${index}`} sx={{ display: 'flex', justifyContent: 'flex-start', }} >
                              <Typography>
                                {findOptionNameFromId(op.itemOptionId)}:{' '}
                              </Typography>
                              <Typography>
                                {findOptionValueFromId(
                                  op.itemOptionId,
                                  op.itemOptionValueId
                                )}
                              </Typography>
                            </Box>
                          );
                        })}
                      </Box>{' '}
                    </TableCell>
                    <TableCell>
                      <Box>
                        <Typography>quantity: {item.quantity}</Typography>
                        <Typography>price: {item.price || 'n/a'}</Typography>
                        <Typography>weight: {item.weight || 'n/a'}</Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: 'flex' }} >
                        {item.mediaUrl === 'no media' ? (<Typography>{item.mediaUrl}</Typography>) :
                          <Typography align='left'>
                            <a href={item.mediaUrl} style={{ textDecoration: 'none' }} download>download media</a>
                          </Typography>
                        }
                      </Box>
                      {/* </TableCell>
                    <TableCell> */}
                      <Typography>
                        {/* no notes */}
                        {item.notes ? item.notes : 'no notes'}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {item.status}
                      </Typography>
                      <Typography noWrap={true}>
                        {orderUnit.find(x => item.itemOrderUnitId === x.id).name}
                      </Typography>
                      <Typography>
                        {orderUnit.find(x => item.itemOrderUnitId === x.id).trackingUnitQuantity * item.quantity}{' '}
                        {trackingUnit.find(y => y.id === orderUnit.find(x => item.itemOrderUnitId === x.id).trackingUnitId).name}(s)
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                        {actionButton(item, order)}
                      </Box>
                    </TableCell>
                  </TableRow>
                })}
                {order && order?.orderItems.length > 0 && order.orderItems.map(x => x.status).every(x => x === 'PENDING') && (
                  <TableRow  >
                    <TableCell colSpan={7}>
                      <Box sx={{ display: 'flex', gap: '1rem', width: '100%', justifyContent: 'flex-end' }}>
                        <Button variant='contained' color='error'
                          onClick={() => handleUpdateOrderStatus(order.id, 'REJECTED')}
                        >
                          {order.returnOrder ? 'Reject all return Items' : 'Reject all items'}
                        </Button>
                        <Button variant='contained'
                          onClick={() => handleUpdateOrderStatus(order.id, 'ACCEPTED')}
                        >
                          {order.returnOrder ? 'accept all return Items' : 'accept all items'}
                        </Button>
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{ marginTop: '4rem', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant='h3'>
              {order.returnOrder ? 'Pick up address' : 'Delivery Address'}
            </Typography>
            <Box sx={{ paddingRight: '2rem' }}>
              <Typography align='right' variant='h5'>{orderAddress.name && orderAddress.name}</Typography>
              <Typography align='right' variant='h6'>{orderAddress.address && orderAddress.address}</Typography>
              <Typography align='right' variant='h6'>{orderAddress.pincode}</Typography>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box sx={{ width: '100%', padding: '1rem' }}>
          <Typography variant='h4' align='center' sx={{ textTransform: 'uppercase' }} >
            select an order to view
          </Typography>
        </Box>
      )}
    </>

  )
}

export default View