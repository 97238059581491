import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setUtilAction } from '../../../../reducer_action/util';
import {
  Grid,
  Typography,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Box,
  Button,
  Select,
  MenuItem,
  TextField,
} from '@mui/material';
import PaymentRow from './PaymentCard';

// todo this will need an add advance payment
// todo show the advance payment details - in the main bar
// todo will also need to show all advance payments and sort them by date, used/unused, client-customer and amount

const AdvancePayment = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant='h3'>Advance Payment</Typography>
      </Grid>
      <Grid item xs={12}>
        <Select value='2' fullWidth>
          {' '}
          <MenuItem value='2' sx={{ color: 'white' }}>
            all
          </MenuItem>
          <MenuItem sx={{ color: 'white' }}>client 1</MenuItem>
          <MenuItem sx={{ color: 'white' }}>clinet name 2</MenuItem>
          <MenuItem sx={{ color: 'white' }}>client name 3</MenuItem>
          <MenuItem sx={{ color: 'white' }}>client name 5</MenuItem>
        </Select>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            onClick={() => {
              // console.log("add advance payment");
              dispatch(
                setUtilAction({ mainSideBar: false, actionQuery: 'add' })
              );
              navigate('/asx/advancePayments?action=add');
            }}
            variant='contained'
          >
            Add{' '}
          </Button>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Payment Id</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Client Name</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Note</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Associated Bill Id</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <PaymentRow />
              <PaymentRow />
              <PaymentRow />
              <PaymentRow />
              <PaymentRow />
              <PaymentRow />
              <PaymentRow />
              <PaymentRow />
              <PaymentRow />
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default AdvancePayment;
