import actionTypes from './actionTypes';
import { createReducer, createAction } from '@reduxjs/toolkit';

// create the actions
const newCartDispatch = createAction(actionTypes.NEW_CART);


export const getCartAction = (group, email) => {
  return async (dispatch) => {
    if (!group || !email) return;

    const cart = localStorage.getItem(`cart-${group}-${email}`) || JSON.stringify([]);

    const newCart = JSON.parse(cart);
    localStorage.setItem(`cart-${group}-${email}`, JSON.stringify(newCart));

    dispatch(newCartDispatch(newCart))
  };
};

export const addProductToCartAction = (group, email, cartItem) => {
  return async (dispatch) => {
    if (!group && !email && !cartItem) return;

    const cart = localStorage.getItem(`cart-${group}-${email}`) || JSON.stringify([]);

    const newCart = [...JSON.parse(cart), cartItem];

    localStorage.setItem(`cart-${group}-${email}`, JSON.stringify(newCart));

    dispatch(newCartDispatch(newCart))
  };
};

export const removeProductFromCartAction = (group, email, indexOf) => {
  return async (dispatch) => {
    if (!group && !email && indexOf) return;
    const cart = JSON.parse(localStorage.getItem(`cart-${group}-${email}`)) || [];

    if (!cart || cart.length <= 0) return;
    const newCart = cart.filter((item, index) => index !== indexOf);
    localStorage.setItem(`cart-${group}-${email}`, JSON.stringify(newCart));

    dispatch(newCartDispatch(newCart))

  };
};


export const addCartItemDescriptionAction = (group, email, itemId, description) => {
  return (dispatch) => {
    const cart = JSON.parse(localStorage.getItem(`cart-${group}-${email}`));

    const newItem = cart.find((item) => item.id === itemId);

    newItem.description = description;

    const untouched = cart.filter((item) => item.id !== itemId);

    const newCart = [...untouched, newItem];
    localStorage.setItem(`cart-${group}-${email}`, JSON.stringify(newCart));

    dispatch(newCartDispatch(newCart))
  };
};

export const addCartItemFileAction = (group, email, itemId, fileUrl) => {
  return (dispatch) => {
    const cart = JSON.parse(localStorage.getItem(`cart-${group}-${email}`));

    const newItem = cart.find((item) => item.id === itemId);

    newItem.sourceFileUrl = fileUrl;

    const untouched = cart.filter((item) => item.id !== itemId);

    const newCart = [...untouched, newItem];
    localStorage.setItem(`cart-${group}-${email}`, JSON.stringify(newCart));

    dispatch(newCartDispatch(newCart))
  };
};

export const deleteCartAction = (group, email) => {
  return (dispatch) => {
    const newCart = [];
    localStorage.setItem(`cart-${group}-${email}`, JSON.stringify(newCart));

    dispatch(newCartDispatch(newCart))
  };
};

const cartReducer = createReducer([], (builder) => {
  builder
    .addCase(newCartDispatch, (state, action) => {
      return action.payload;
    })
    .addDefaultCase(state => state)

})

export default cartReducer;

// const cartReducer = (state = [], action) => {
//   switch (action.type) {
//     case 'NEW_CART':
//       return [...action.data];
//     default:
//       return state;
//   }
// };