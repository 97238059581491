import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Button, Typography, Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import { setUtilAction } from '../../../../../reducer_action/util';
import Edit from '../Mainbar/OptionPages/Edit';

const AddOption = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <Box sx={{ padding: '1rem' }}>
      <Typography variant='h3'>AddOption</Typography>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant='contained'
          onClick={() => {
            navigate('/godown/productOptions');
            dispatch(
              setUtilAction({ mainSideBar: false, actionQuery: 'view' })
            );
          }}
        >
          close
        </Button>
      </Box>
      <Box sx={{ marginTop: '3rem', padding: '1rem' }}>
        <Edit type='post' />
      </Box>
    </Box>
  );
};

export default AddOption;
