import axios from 'axios';
import { toast } from 'react-toastify';
import userService from './user.js';

const baseUrl = process.env.REACT_APP_PROXY_BACKEND

const getToken = async () => {
  const session = await userService.getSession()
  const token = session && session.idToken
  return token.jwtToken
}


export const getPrnproOrders = async (data) => {
  const token = await getToken()
  const response = await axios({
    method: 'get',
    url: `${baseUrl}/billing?clientUsernameBiller=${data.clientUsernameBiller}`,
    headers: { Authorization: `${token}` },
  })

  return response.data

}