import React from "react";
import {
  Grid,
  Button,
  TextField,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from "@mui/material";

const AssignBill = () => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography>Assign payment to bills to change their status</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          Unassigned Amount : credit Payment id: '123', amount unassigned:'12'
        </Typography>
        <Typography>credit Payment id: '123'</Typography>
        <Typography>amount unassigned:'12'</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          Unassigned Amount : credit Payment id: '123', amount unassigned:'12'
        </Typography>
        <Typography>credit Payment id: '123'</Typography>
        <Typography>amount unassigned:'12'</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          total pending bill/ bill values : 10 bills values at 2400
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Bill Id</TableCell>
                <TableCell>client id</TableCell>
                <TableCell>total amount</TableCell>
                <TableCell>amount pending</TableCell>
                <TableCell>status</TableCell>
                <TableCell>assign amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>123</TableCell>
                <TableCell>something tharun</TableCell>
                <TableCell>2400</TableCell>
                <TableCell>200</TableCell>
                <TableCell>pending</TableCell>
                <TableCell>
                  <TextField />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>123</TableCell>
                <TableCell>something tharun</TableCell>
                <TableCell>2400</TableCell>
                <TableCell>200</TableCell>
                <TableCell>pending</TableCell>
                <TableCell>
                  <TextField />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>123</TableCell>
                <TableCell>something tharun</TableCell>
                <TableCell>2400</TableCell>
                <TableCell>200</TableCell>
                <TableCell>pending</TableCell>
                <TableCell>
                  <TextField />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Button variant="contained">Save</Button>
      </Grid>
    </Grid>
  );
};

export default AssignBill;
