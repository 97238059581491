import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom'

import View from './ProductsPages/View';
import Edit from './ProductsPages/Edit-Add';
import DesignerOptions from './ProductsPages/DesignerOptions/DesignerOptions';
import Price from './ProductsPages/Price';
import AdditionalOptions from './ProductsPages/AdditionalOptions';
import OrderGuideline from './ProductsPages/orderGuideline/OrderGuideline';
import OptionRules from './ProductsPages/optionRules/OptionRules';
import Gallery from './ProductsPages/gallery/Gallery';

import Grid from '@mui/material/Grid';
import AddOptionRule from './ProductsPages/AddOptionRule';

const Body = ({ height }) => {
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const action = query.get('action');

  return (
    <Grid
      container
      alignItems='flex-start'
      sx={{
        height: `calc(100vh - ${height}px)`,
        overflow: 'scroll',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      }}
    >
      <Grid container sx={{ padding: '1.25rem 1rem 3rem 1.5rem' }}>
        {/* this section determines who can see which page */}
        {action === 'view' && (<View />)}
        {/* {action === 'order' && (<BuyNow />)} */}
        {action === 'edit' && (<Edit type='edit' />)}
        {action === 'designer-options' && (<DesignerOptions />)}
        {action === 'price' && <Price />}
        {action === 'order-guideline' && <OrderGuideline />}
        {action === 'additional-options' && (<AdditionalOptions />)}
        {action === 'gallery' && <Gallery />}
        {action === 'option-rules' && (<OptionRules />)}
        {action === 'add-option-rule' && (<AddOptionRule />)}
      </Grid>
    </Grid>
  );
};

export default Body;
