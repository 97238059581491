import axios from 'axios';
import userService from './user.js';
import { toast } from 'react-toastify'

const baseUrl = process.env.REACT_APP_PROXY_BACKEND

const getToken = async () => {
  const session = await userService.getSession()
  const token = session && session.idToken
  return token.jwtToken
}

export const getAllProductUtilData = async () => {
  try {
    const token = await getToken()
    if (!token) return

    const response = await axios({
      method: 'get',
      url: `${baseUrl}/item/util`,
      headers: { Authorization: `${token}` },
    });

    return response.data;
  } catch (error) {
    // toast.error('there was an error in getting all product util data')
    toast.error(`there was an error in getting all product util data: ${error.response.data}`)
  }
};

export const updateCategoryTable = async (data, id) => {
  try {
    const token = await getToken()
    if (!token) return

    const response = await axios({
      method: 'put',
      url: `${baseUrl}/item/category/${id}`,
      headers: { Authorization: `${token}` },
      data: data,
    });

    return response.data;
  } catch (error) {
    toast.error(`there was an error in updating the category: ${error.response.data}`)
  }
};

export const postCategoryTable = async (data, id) => {
  try {
    const token = await getToken()
    if (!token) return

    const response = await axios({
      method: 'post',
      url: `${baseUrl}/item/category`,
      headers: { Authorization: `${token}` },
      data: data,
    });

    return response.data;
  } catch (error) {
    toast.error(`there was an error in creating the category: ${error.response.data}`)
  }
};


export const updateOrderUnit = async (orderUnitId, data) => {
  try {
    const token = await getToken()
    if (!token) return

    const response = await axios({
      method: 'put',
      url: `${baseUrl}/item/util/orderUnit/${orderUnitId}`,
      headers: { Authorization: `${token}` },
      data: data
    })

    return response.data
  } catch (error) {
    toast.error(`there was an error in updating the order unit: ${error.response.data}`)
  }
}

export const postOrderUnit = async (data) => {
  try {
    const token = await getToken()
    if (!token) return

    const response = await axios({
      method: 'post',
      url: `${baseUrl}/item/util/orderUnit`,
      headers: { Authorization: `${token}` },
      data: data
    })

    return response.data
  } catch (error) {
    toast.error(`there was an error in creating the order unit: ${error.response.data}`)
  }
}

export const putOrderGuideline = async (orderGuidelineId, data) => {
  try {
    const token = await getToken()
    if (!token) return

    const response = await axios({
      method: 'put',
      url: `${baseUrl}/item/util/orderGuideline/${orderGuidelineId}`,
      headers: { Authorization: `${token}` },
      data: data
    })

    return response.data
  } catch (error) {
    toast.error(`there was an error in updating the order unit: ${error.response.data}`)
  }
}
