import React, { useRef, useEffect, useState } from 'react'
import GodownOrdersHeader from './GodownOrdersHeader'
import GodownOrdersBody from './GodownOrdersBody'
import { Grid } from '@mui/material'
import { useParams, useLocation } from 'react-router-dom'
import { getOneDemandOrder } from '../../../../../service/demandOrder'
import { toast } from 'aws-amplify'
import Spinner from '../../../../utilComponents/Spinner'

const GodownOrdersMainbar = () => {
  const params = useParams()
  const location = useLocation()
  const headerRef = useRef()

  const [order, setOrder] = useState({})
  const [headerHeight, setHeaderHeight] = useState(0)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    (async () => {
      try {
        setLoading(true)
        const order = await getOneDemandOrder({ orderId: +params.orderId })
        setOrder(order)
      } catch (error) {
        setOrder(null)
        toast.error('there was an error getting the order')
      } finally {
        setLoading(false)
      }
    })();
  }, [params.orderId, location, headerRef])

  useEffect(() => {
    const height = headerRef.current && headerRef?.current.offsetHeight;
    setHeaderHeight(height);
  }, [order])



  return (
    <Grid container item xs={12} justifyContent='center' alignItems='center' >
      <Spinner loading={loading}>
        {order &&
          (order.id === +params.orderId &&
            <>
              <GodownOrdersHeader headerRef={headerRef} order={order} />
              <GodownOrdersBody headerHeight={headerHeight} order={order} setOrder={setOrder} />
            </>
          )
        }
      </Spinner>
    </Grid>
  )
}

export default GodownOrdersMainbar