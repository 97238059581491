import React from "react";
import { Auth } from 'aws-amplify'
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { setUtilAction } from "../../reducer_action/util";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import LogoutIcon from '@mui/icons-material/Logout';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ListItems from "./ListItems";
import {
  // adminModules,
  asxModules, godownModules
} from "../../data/navigationRoutes";
import { removeUserAction } from "../../reducer_action/user";

const Nav = () => {
  const dispatch = useDispatch();
  const util = useSelector((state) => state.util);
  const location = useLocation();
  const navigate = useNavigate();
  const modules = () => {
    switch (location.pathname.split("/")[1]) {
      // case "admin":
      //   return adminModules;
      case 'asx':
        return asxModules;
      case 'godown':
        return godownModules;
      default:
        return;
    }
  }

  const handleLogout = async () => {
    if (window.confirm('Are you sure you want to logout?')) {
      await Auth.signOut();
      dispatch(removeUserAction())
      navigate('/')
    }
  }
  return (
    <>
      <Box
        sx={{
          height: "calc(100vh - 2rem)",
          display: "flex",
          flexDirection: "column",
          width: !util.sideBar ? "260px" : "60px",
          backgroundColor: "primary.main",
          transitionProperty: "all",
          transitionDuration: ".2s",
          justifyContent: "space-between",
          scrollbarWidth: "none",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          overflow: "auto",
        }}
      >
        <List
          sx={{
            color: "white",
          }}
        >
          {modules().map((module, index) => {
            return (
              <ListItems
                key={module.title}
                text={module.title}
                toggle={util.sideBar}
                routes={module.section}
                icon={module.icon}
              />
            );
          })}
        </List>
        <Box sx={{ color: 'white', cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginBottom: '1rem' }} onClick={handleLogout}>
          <LogoutIcon />
          {!util.sideBar && <Typography sx={{ color: 'white', marginLeft: '1rem' }} > logout</Typography>}
        </Box>
      </Box>

      <Box
        sx={{
          padding: "1rem",
          display: "flex",
          justifyContent: "center",
          backgroundColor: "primary.main",
        }}
        onClick={() => {
          dispatch(
            setUtilAction({
              sideBar: !util.sideBar,
            })
          );
        }}
      >
        {util.sideBar ? (
          <ArrowForwardIcon sx={{ color: "white" }} />
        ) : (
          <ArrowBackIcon sx={{ color: "white" }} />
        )}
      </Box>
    </>
  );
};

export default Nav;
