import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllProductUtilDataAction } from '../../../../../reducer_action/productUtil'
import OrderGuidelineHeader from './OrderGuidelineHeader'
import OrderGuidelineBody from './OrderGuidelineBody'

import { Grid } from '@mui/material'


const OrderGuidelineSidebar = () => {
  const headerRef = useRef()
  const dispatch = useDispatch()

  const trackingUnit = useSelector(state => state.productUtil.trackingUnit)

  const [headerHeight, setHeaderHeight] = useState(0)
  const [trackingUnitId, setTrackingUnitId] = useState(0)

  useEffect(() => {
    dispatch(getAllProductUtilDataAction())
  }, [dispatch])

  useEffect(() => {
    const height = headerRef.current && headerRef.current.offsetHeight
    setHeaderHeight(height)
  }, [trackingUnit])

  return (
    <Grid container >
      {trackingUnit &&
        <>
          <OrderGuidelineHeader headerRef={headerRef} trackingUnitId={trackingUnitId} setTrackingUnitId={setTrackingUnitId} />
          <OrderGuidelineBody headerHeight={headerHeight} trackingUnitId={trackingUnitId} />
        </>
      }
    </Grid>
  )
}

export default OrderGuidelineSidebar