import React from 'react';
import { Grid } from '@mui/material';
import { useLocation } from 'react-router-dom';

import View from './categoryPages/View';
import Edit from './categoryPages/Edit';

const CategoryBody = ({ height }) => {
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const action = query.get('action');

  return (
    <Grid container item xs={12}
      sx={{
        height: `calc(100vh - ${height}px)`,
        overflow: 'scroll',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      }}
    >
      <Grid container item xs={12} sx={{ padding: '1rem 1rem 0 1rem' }}>
        {action === 'view' && <View />}
        {action === 'edit' && <Edit />}
      </Grid>
    </Grid>
  );
};

export default CategoryBody;
