import React from 'react';
import { useSelector } from 'react-redux';

import Grid from '@mui/material/Grid';
// import Tabs from '@mui/material/Tabs';
// import Tab from '@mui/material/Tab';
import Tabs from '../../../../utilComponents/Tabs'

import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Typography } from '@mui/material';


const Header = ({ headerRef }) => {
  const params = useParams();
  const navigate = useNavigate();

  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const action = query.get('action');

  const [product] = useSelector((state) => state.product.filter((item) => item.id === +params.item));

  const routes = ['view', 'edit', 'designer-options', 'price', 'additional-options', 'order-guideline', 'option-rules', 'gallery',]
    .map(x => {
      return {
        name: x,
        onClick: () => navigate(`?action=${x}`)
      }
    })

  return (
    <Grid container ref={headerRef} sx={{ padding: '1rem 1rem 1rem 1.5rem' }} >
      <Grid item xs={12} sx={{ marginBottom: '0.75rem' }}>
        <Typography variant='h3'>{product?.name && product.name.toUpperCase()}</Typography>
        <Tabs tabs={routes} />
      </Grid>
    </Grid>
  );
};

export default Header;

{/* <Tabs value={action} sx={{ borderBottom: '2px solid', borderColor: 'secondary.underLine' }} >
        {routes.map((t) => {
          return (
            <Tab
              key={t}
              value={t}
              onClick={() => {
                navigate(`?action=${t}`);
              }}
              sx={{ fontSize: '18px', color: 'primary.dark' }}
              label={t}
            />
          );
        })}
      </Tabs> */}