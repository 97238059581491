import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { postOrderUnit } from '../../../../../service/productUtil'
import { getAllProductUtilDataAction } from '../../../../../reducer_action/productUtil'

import { Box, Grid, Typography, Select, MenuItem, Button, Modal } from '@mui/material'
import CenterBox from '../../../../utilComponents/CenterBox'
import EditTableView from '../../../../utilComponents/EditTableView'

const OrderGuidelineHeader = ({ headerRef, trackingUnitId, setTrackingUnitId }) => {
  const dispatch = useDispatch()

  const trackingUnits = useSelector(state => state.productUtil.trackingUnit)
  const [addOrderUnitModal, setAddOrderUnitModal] = useState(false)

  // modal 
  const [name, setName] = useState('')
  const [newTrackingUnitId, setNewTrackingUnitId] = useState(trackingUnits[0].id)
  const [trackingUnitQuantity, setTrackingUnitQuantity] = useState('')
  const [description, setDescription] = useState('')

  const handlePostOrderUnit = async () => {
    await postOrderUnit({ name, newTrackingUnitId, trackingUnitQuantity, description })
    dispatch(getAllProductUtilDataAction())
    setAddOrderUnitModal(false)

    // reset the data
    setName('')
    setNewTrackingUnitId(trackingUnits[0].id)
    setTrackingUnitQuantity('')
    setDescription('')
  }

  return (
    <Grid container ref={headerRef} sx={{ padding: '1rem' }} >
      <Grid item xs={12}>
        <Typography variant='h3'>Order Guidelines </Typography>
      </Grid>

      <Grid item xs={12} sx={{ marginTop: '1rem ' }}>
        <Select fullWidth value={trackingUnitId} onChange={(e) => setTrackingUnitId(e.target.value)}>
          <MenuItem value={0}>All</MenuItem>
          {trackingUnits.map(unit => {
            return <MenuItem value={unit.id}>{unit.name}</MenuItem>
          })}
        </Select>
      </Grid>

      <Grid item xs={12} sx={{ marginTop: '1rem ' }}>
        <Button variant='contained' onClick={() => setAddOrderUnitModal(true)}>new</Button>
      </Grid>

      {/* add order Unit modal section */}
      <Modal open={addOrderUnitModal} onClose={() => setAddOrderUnitModal(false)}>
        <CenterBox>
          <Typography variant='h3' align='center'>Add New Order Unit</Typography>
          <Box sx={{ marginTop: '1rem' }}>
            <EditTableView type='text' title='Name' value={name} onChange={(e) => setName(e.target.value)} />
            <EditTableView type='text' title='description' value={description} onChange={(e) => setDescription(e.target.value)} />
            <EditTableView type='select' title='tracking Unit' value={newTrackingUnitId} selectValues={trackingUnits} onChange={(e) => setNewTrackingUnitId(e.target.value)} />
            <EditTableView type='text' title='tracking unit quantity' value={trackingUnitQuantity} onChange={(e) => setTrackingUnitQuantity(e.target.value)} />
          </Box>
          <Box sx={{ marginTop: '2rem', display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant='contained' onClick={handlePostOrderUnit} > save </Button>
          </Box>
        </CenterBox>
      </Modal>
    </Grid >
  )
}

export default OrderGuidelineHeader