import React from 'react';
import { Grid, Typography } from '@mui/material';

const TableView = ({
  title,
  value,
  block1,
  block2,
  underline = true,
  align = 'right',
}) => {
  const x = block1 || 6;
  const y = block2 || 6;

  return (
    <Grid container item alignItems='baseline'
      sx={{
        borderBottom: underline && '1px solid', borderColor: underline && 'secondary.underLine', margin: '0 1.5rem 0 0',
      }}
    >
      <Grid item xs={x} sx={{ padding: '.7rem 0' }}>
        <Typography sx={{ textTransform: 'capitalize' }} wrap='noWrap' variant='h6' > {title} </Typography>
      </Grid>
      <Grid item xs={y}>
        <Typography align={align} variant='body2'> {value} </Typography>
      </Grid>
    </Grid>
  );
};

export default TableView;
