import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { setUtilAction } from "../../../../reducer_action/util";

import {
  Button,
  Grid,
  Typography,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const { pathname } = location;
    if (
      pathname === "/asx/creditCustomers" ||
      pathname === "/asx/creditCustomers?action=add"
    ) {
      dispatch(setUtilAction({ mainSideBar: false }));
      console.log({ location });
      console.log(location.pathname.split("/"));
    }
  });

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h4" align="center">
          Credit Customers
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField label="name / GST" fullWidth />
      </Grid>
      <Grid item xs={12}>
        <Select fullWidth value="1" label="credit type">
          <MenuItem sx={{ color: "white" }}>All</MenuItem>
          <MenuItem value="1" sx={{ color: "white" }}>
            Pending Bills
          </MenuItem>
          <MenuItem sx={{ color: "white" }}>Unassigned Payments</MenuItem>
        </Select>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          onClick={() => {
            dispatch(setUtilAction({ actionQuery: "add", mainSideBar: false }));
            navigate("/asx/creditCustomers?action=add");
          }}
        >
          Add
        </Button>
      </Grid>
    </Grid>
  );
};

export default Header;
