import React, { useEffect, useState, useRef } from 'react';
import { FormControlLabel, RadioGroup, Radio, Box, Button, Grid, Typography, } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash'
import ShipmentRow from './ShipmentRow';
import { getShipmentCartAction, deleteShipmentCartAction } from '../../../../../reducer_action/shipCart';
import userService from '../../../../../service/user'
import { postShipment } from '../../../../../service/shipments';
import { toast } from 'react-toastify';

const ShipmentCart = () => {
  const shipmentDocRef = useRef()
  const dispatch = useDispatch()
  const user = useSelector(state => state.user);

  const shipmentCart = useSelector(state => state.shipmentCart);
  const shipmentUsername = shipmentCart.map(item => item.clientUsername)
  const setShipmentUsername = [...new Set(shipmentUsername)]

  // @ ordered cart is the display cart and serves only as a display function
  const [orderedCart, setOrderedCart] = useState([])
  const [returnOrderedCart, setReturnOrderedCart] = useState([])
  const [addressId, setAddressId] = useState(user.addresses[0].id)

  useEffect(() => {
    dispatch(getShipmentCartAction(user.clientGroup, user.email))
  }, [dispatch, user])

  useEffect(() => {
    try {
      (async () => {
        /* @abstract this get all the addresses for all users addresses from the from the backend **/
        const addresses = await userService.findUsersAddress(setShipmentUsername)
        const newOrderedCart = shipmentCart.map(item => {
          const newItem = { ...item } // otherwise state will be altered directly // the item is the shipmentCartItem ref and not a copy
          newItem.clientAddress = addresses.addresses.find(address => address.id === item.clientAddressId)
          return newItem
        })

        const cartGrouped = _.groupBy(newOrderedCart, 'returnOrder')

        const normalOrder = cartGrouped.false
        const normalOrderGrouped = _.groupBy(normalOrder, 'clientAddressId')
        const sortedNormalOrderGrouped = _.orderBy(normalOrderGrouped, ['clientUsername', 'orderId'], ['asc', 'asc'])
        setOrderedCart(sortedNormalOrderGrouped)

        const returnOrder = cartGrouped.true
        const returnOrderGrouped = _.groupBy(returnOrder, 'clientAddressId')
        const sortedReturnOrderGrouped = _.orderBy(returnOrderGrouped, ['clientUsername', 'orderId'], ['asc', 'asc'])
        setReturnOrderedCart(sortedReturnOrderGrouped)

      })()
    } catch (error) {
      toast.error('there was an error in getting shipment details')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipmentCart])

  const handleShipmentSubmit = async () => {
    if (!window.confirm('are you sure you want to ship these items')) return
    console.log('ahler', addressId)
    await postShipment(shipmentCart, addressId)


    dispatch(deleteShipmentCartAction(user.clientGroup, user.email))
  }

  return (
    <>
      {orderedCart && returnOrderedCart &&
        // <>
        <Grid container sx={{ overflow: 'auto', maxHeight: '100vh', padding: '1rem 1rem 3rem 1rem' }} >
          <Grid item xs={12}>
            <Typography variant='h2'>shipment Cart</Typography>
          </Grid>
          <Grid item xs={12} container >
            {shipmentCart.length > 0 ?
              (<>
                <Grid container item={12} gap={2} ref={shipmentDocRef}>
                  {orderedCart && orderedCart.length > 0 && orderedCart.map((row, index) => {
                    return <ShipmentRow row={row} index={index} key={`${row[0].clientUsername}-${row[0].clientAddressId}`} />
                  })}
                  {returnOrderedCart && returnOrderedCart.length > 0 && returnOrderedCart.map((row, index) => {
                    return <ShipmentRow row={row} index={index} key={`${row[0].clientUsername}-${row[0].clientAddressId}`} />
                  })}
                </Grid>
              </>)
              :
              (<Box sx={{ marginTop: '2rem' }}>
                <Typography variant='h4'>There are no items in the cart</Typography>
              </Box>)
            }
          </Grid>

          {/* select Address (from) for the shipment section */}
          {shipmentCart.length > 0 &&
            <>
              <Grid item xs={12} sx={{ marginTop: '3rem' }}>
                <Typography variant='h4'>Select Address</Typography>
                <RadioGroup
                  value={addressId}
                  onChange={(e) => setAddressId(e.target.value)}
                >
                  {
                    user.addresses.length > 0 ?
                      user.addresses.map((address, index) => {
                        return <FormControlLabel key={address.id} value={address.id} control={<Radio />} label={`${address.name}: ${address.address}`} />
                      }) :
                      <Typography>No address</Typography>
                  }
                </RadioGroup>
              </Grid>
              <Grid item xs={12} sx={{ marginTop: '2rem' }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button variant='contained' onClick={handleShipmentSubmit}> ship item</Button>
                </Box>
              </Grid>
            </>
          }
        </Grid >
      }
    </>
  )
};

export default ShipmentCart;
