import React, { useRef, useState, useEffect } from 'react'
import { Grid, Box, Typography, Select, MenuItem, Switch, Pagination, } from '@mui/material'
import { useSelector } from 'react-redux'
import ClientCard from './ClientCard'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const ClientBody = ({ clients, headerHeight, limit, setLimit, clientEmail, cognitoGroup, currentPage, setCurrentPage, orderByDesc, setOrderByDesc }) => {
  const footerRef = useRef()
  const [footerHeight, setFooterHeight] = useState(0)
  const mainSideBar = useSelector(state => state.util.mainSideBar)
  const clientList = clients.users

  const totalUsers = clients.count
  console.log({ clientList, totalUsers, clients })
  const totalPages = Math.ceil(+totalUsers / +limit);

  useEffect(() => {
    const height = footerRef.current.offsetHeight;
    // console.log('godown clients', height, headerHeight)
    setFooterHeight(height);

  }, [footerHeight])

  return (
    <Grid container >
      <Grid item xs={12}>
        <Box sx={{ padding: '0 1rem', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '100%' }} >
          <Box
            sx={{ height: `calc(100vh - ${headerHeight}px  - ${footerHeight}px)`, overflow: 'scroll', scrollbarWidth: 'none', '&::-webkit-scrollbar': { display: 'none', }, }}
          >
            {clientList && clientList.length > 0 && clientList.map((client) => {
              return <ClientCard
                key={client.username}
                client={client}
                limit={limit}
                cognitoGroup={cognitoGroup}
                clientEmail={clientEmail}
              />
            })}
            {/* this is the list users sections */}
          </Box>
          <Box
            ref={footerRef}
            sx={{ padding: '0.5rem', display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }} >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {!mainSideBar && <Typography sx={{ marginRight: '0.5rem' }}>limit:</Typography>}
              <Select variant='standard'
                value={limit}
                onChange={(e) => {
                  setLimit(e.target.value)
                  setCurrentPage(1)
                }}
              >
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
              </Select>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Switch checked={orderByDesc} onChange={(e) => { setOrderByDesc(e.target.checked) }} />
              {orderByDesc ?
                <Typography>Desc</Typography>
                :
                <Typography>Asc</Typography>
              }
            </Box>
            <Box>
              {!mainSideBar ?
                <Pagination
                  size='small'
                  count={totalPages}
                  variant='outlined'
                  shape='rounded'
                  onChange={(e, value) => setCurrentPage(value)}
                />
                : (
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Box
                      onClick={() => {
                        if (currentPage > 1) {
                          setCurrentPage(+currentPage - 1)
                        } else {
                          setCurrentPage(1)
                        }
                      }}
                      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}
                    >
                      <ArrowBackIosIcon />
                    </Box>
                    <Box sx={{ border: '1px solid black', borderRadius: '10px', padding: '0rem 0.4rem' }}>
                      <Typography>{currentPage}</Typography>
                    </Box>
                    <Box
                      onClick={() => {
                        if (currentPage < totalPages) {
                          setCurrentPage(+currentPage + 1)
                        }
                      }}
                      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                      <ArrowForwardIosIcon />
                    </Box>
                  </Box>
                )
              }
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid >
  )
}

export default ClientBody