import React, { useRef, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Pagination, Switch, Select, MenuItem, Box, Grid, Typography } from '@mui/material'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AsxShipmentSidebarCard from './AsxShipmentSidebarCard'


const AsxShipmentSidebarBody = ({ shipments, limit, setLimit, headerHeight, currentPage, setCurrentPage, orderByDesc, setOrderByDesc }) => {
  const footerRef = useRef()
  const mainSideBar = useSelector(state => state.util.mainSideBar)

  const [footerHeight, setFooterHeight] = useState(0)
  const totalPages = Math.ceil(shipments.count / limit);

  useEffect(() => {
    const height = footerRef.current.offsetHeight;
    setFooterHeight(height);
  }, [footerRef])

  return (
    <Grid item xs={12}>
      <Box sx={{ padding: '0 1rem', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} >
        <Box sx={{ height: `calc(100vh - ${headerHeight}px - ${footerHeight}px)`, overflow: 'scroll', scrollbarWidth: 'none', '&::-webkit-scrollbar': { display: 'none', }, }} >
          {shipments && shipments.shipments.length > 0 && shipments.shipments.map((shipment, index) => {
            return <AsxShipmentSidebarCard key={shipment.id} shipment={shipment} limit={limit} currentPage={currentPage} orderByDesc={orderByDesc} />
          })}
        </Box>
        <Box ref={footerRef}
          sx={{ padding: '0.5rem', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {!mainSideBar && <Typography sx={{ marginRight: '0.5rem' }}>limit:</Typography>}
            <Select variant='standard' value={limit} onChange={(e) => {
              setLimit(e.target.value)
              setCurrentPage(1)
            }}
            >
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
            </Select>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Switch checked={orderByDesc} onChange={(e) => {
              console.log(e.target.checked)
              setOrderByDesc(e.target.checked)
            }} />
            {orderByDesc ? <Typography>Desc</Typography> : <Typography>Asc</Typography>}
          </Box>
          <Box>
            {!mainSideBar ?
              <Pagination
                size='small'
                count={totalPages}
                variant='outlined'
                shape='rounded'
                onChange={(e, value) => setCurrentPage(value)}
              />
              : (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Box
                    onClick={() => {
                      if (currentPage > 1) {
                        setCurrentPage(+currentPage - 1)
                      } else {
                        setCurrentPage(1)
                      }
                    }}
                    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}
                  >
                    <ArrowBackIosIcon />
                  </Box>
                  <Box sx={{ border: '1px solid black', borderRadius: '10px', padding: '0rem 0.4rem' }}>
                    <Typography>{currentPage}</Typography>
                  </Box>
                  <Box
                    onClick={() => {
                      if (currentPage < totalPages) {
                        setCurrentPage(+currentPage + 1)
                      }
                    }}
                    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <ArrowForwardIosIcon />
                  </Box>
                </Box>
              )
            }
          </Box>
        </Box>
      </Box>
    </Grid>
  )
}

export default AsxShipmentSidebarBody