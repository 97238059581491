import React, { useState, useRef, useEffect } from 'react';
import CategoryBody from './CategoryBody';
import CategoryHeader from './CategoryHeader';

const Category = () => {
  const [height, setHeight] = useState();
  const headerHeight = useRef();

  useEffect(() => {
    const hei = headerHeight.current.offsetHeight;
    setHeight(hei);
  }, []);

  return (
    <>
      <CategoryHeader headerRef={headerHeight} />
      <CategoryBody height={height} />
    </>
  );
};

export default Category;
