import React from "react";
import { Box, Grid, Typography, Divider } from "@mui/material";
import CustomerCard from "./CustomerCard";

const Body = () => {
  return (
    <Grid
      container
      sx={{
        overflow: "scroll",
        height: "calc(100vh - 199px)",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
          display: "none",
        },
        padding: "0 1rem",
      }}
    >
      <CustomerCard />
      <CustomerCard />
      <CustomerCard />
      <CustomerCard />
      <CustomerCard />
      <CustomerCard />
      <CustomerCard />
      <CustomerCard />
      <CustomerCard />
      <CustomerCard />
      <CustomerCard />
    </Grid>
  );
};

export default Body;
