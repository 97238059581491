import React from 'react'
import View from './pages/View'
import { useLocation } from 'react-router-dom';
import { Grid } from '@mui/material';

const AsxShipmentBody = ({ headerHeight, shipment, setShipment }) => {
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery()
  const action = query.get('action');
  console.log({ headerHeight })

  return (
    <Grid item container
      sx={{
        height: 'calc(100vh - ' + headerHeight + 'px)',
        overflow: 'scroll',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
          display: 'none',
        }
      }}
    >
      <Grid container item xs={12}>
        {action === 'view' &&
          <View shipment={shipment} setShipment={setShipment} />
        }
      </Grid>
    </Grid >
  )
}

export default AsxShipmentBody