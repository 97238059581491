import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import userService from '../../../../service/user'

import { getAllProductUtilDataAction } from '../../../../reducer_action/productUtil'
import { getAllProductsAction } from '../../../../reducer_action/product'
import { getAllOptionsAction } from '../../../../reducer_action/options'

import ClientHeader from './ClientHeader'
import ClientBody from './ClientBody'


const ClientSidebar = () => {
  const headerRef = useRef();
  const dispatch = useDispatch();

  // header height
  const [headerHeight, setHeaderHeight] = useState(0)
  const [cognitoGroup, setCognitoGroup] = useState('ALL')
  const [clientEmail, setClientEmail] = useState('')
  // body footer
  const [limit, setLimit] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [orderByDesc, setOrderByDesc] = useState(false)
  const [clients, setClients] = useState({})


  const cognitoGroups = useSelector(state => state.productUtil.cognitoGroups)

  useEffect(() => {
    const currentHeaderHeight = headerRef.current && headerRef?.current.offsetHeight
    setHeaderHeight(currentHeaderHeight)
  }, [headerRef, cognitoGroups])

  useEffect(() => {
    (async () => {
      const clients = await userService.getAllUsers({ limit, clientEmail, cognitoGroup, currentPage, orderByDesc })
      setClients(clients)
    })()
  }, [limit, clientEmail, cognitoGroup, currentPage, orderByDesc])

  useEffect(() => {
    dispatch(getAllProductUtilDataAction())
    dispatch(getAllProductsAction());
    dispatch(getAllOptionsAction());
  }, [dispatch])


  return (
    <>
      {cognitoGroups && cognitoGroups.length > 0 &&
        <ClientHeader
          headerRef={headerRef}
          cognitoGroup={cognitoGroup}
          setCognitoGroup={setCognitoGroup}
          clientEmail={clientEmail}
          setClientEmail={setClientEmail}
        />
      }
      {clients &&
        <ClientBody
          headerHeight={headerHeight}
          limit={limit}
          setLimit={setLimit}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          clientEmail={clientEmail}
          cognitoGroup={cognitoGroup}
          orderByDesc={orderByDesc}
          setOrderByDesc={setOrderByDesc}
          clients={clients}
        />
      }
    </>
  )
}

export default ClientSidebar