import React from "react";
import {
  Button,
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
} from "@mui/material";

const AddCreditPayment = () => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography>Add credit payment for Customer1</Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField label="amount" />
      </Grid>
      <Grid item xs={12}>
        <TextField label="tds amount" />
      </Grid>
      <Grid item xs={12}>
        <Select value="w">
          <MenuItem value="w">Payment Type</MenuItem>
        </Select>
      </Grid>
      <Grid item xs={12}>
        <input type="file" />
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained">save</Button>
      </Grid>
    </Grid>
  );
};

export default AddCreditPayment;
