import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Typography, Grid, } from '@mui/material';

import AddSize from './AddSize'
import EditSize from './EditSize';

const DesignerOptions = () => {
  const params = useParams();
  const product = useSelector((state) => state.product.find((x) => x.id === +params.item));

  const [sizeTab, setSizeTab] = useState({ ...product.size[0] });
  const [addSizeTab, setAddSizeTab] = useState(false);

  const SizeSection = () => {
    return <>
      {product ? (
        <>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', marginTop: '0rem' }}>
            {product.size.length > 0 &&
              product.size.map((size) => {
                return (
                  <Box key={size.id} value={size.id} label={size.sizeTitle}
                    sx={{
                      borderBottom: sizeTab.id === size.id ? '1px solid black' : null,
                      cursor: 'pointer', padding: '0.3rem 0.7rem', marginBottom: '0.5rem',
                    }}
                    onClick={() => {
                      setSizeTab({ ...size })
                      setAddSizeTab(false)
                    }}
                  >
                    <Typography variant='h6' sx={{ textTransform: 'capitalize', letterSpacing: '1px' }} >{size.sizeTitle}</Typography>
                  </Box>
                )
              })
            }
            <Box value={'addSize'} label={'add Size'}
              sx={{ cursor: 'pointer', padding: '0.3rem 0.7rem', marginBottom: '0.5rem', }}
              onClick={() => {
                setAddSizeTab(true)
              }}
            >
              <Typography variant='h6' sx={{ textTransform: 'capitalize', letterSpacing: '1px' }} >Add size</Typography>
            </Box>
            {/* show the page */}
            {sizeTab.id && < EditSize sizeTab={sizeTab} setSizeTab={setSizeTab} product={product} />}
          </Box>
        </>
      ) : (
        <Grid item xs={12}>
          <Typography variant='h5'>No size available for this item</Typography>
        </Grid>
      )}

    </>
  }


  return (
    <Grid container >
      <Grid item xs={12}>
        {addSizeTab ?
          <AddSize addSizeTab={addSizeTab} setAddSizeTab={setAddSizeTab} /> :
          sizeTab && <SizeSection />}
      </Grid>
    </Grid>
  );
};

export default DesignerOptions;
