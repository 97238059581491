import React from 'react';
import Grid from '@mui/material/Grid';

import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// import { setUtilAction } from '../../../../../reducer_action/util';
import Tabs from '../../../../utilComponents/Tabs'

import { Typography, } from '@mui/material';

const ProductsOptionsHeader = ({ headerRef }) => {
  const params = useParams();
  const navigate = useNavigate();

  const option = useSelector((state) =>
    state.option.find((op) => op.id === +params.option)
  );

  const routes = ['view', 'edit', 'optionValues', 'price', 'gallery']
    .map(tab => {
      return {
        name: tab,
        onClick: () => navigate(`?action=${tab}`)
      }
    })

  return (
    <Grid container ref={headerRef} sx={{ padding: '1rem', }} >
      <Grid item xs={12}>
        <Typography variant='h3' sx={{ textTransform: 'capitalize' }}>
          {option.name}
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ padding: '1rem 0' }}>
        <Tabs tabs={routes} />
      </Grid>
    </Grid>
  );
};

export default ProductsOptionsHeader;
