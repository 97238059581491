import React from 'react'
import { Box, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Typography } from '@mui/material'
import { useSelector, } from 'react-redux'
// import { useParams } from 'react-router-dom'

const View = ({ order }) => {
  // const params = useParams();
  // const order = useSelector(state => state.demandOrder?.orders.find(order => order.id === +params.orderId))

  const product = useSelector(state => state.product)
  const option = useSelector(state => state.option)

  const findProductNameFromId = (itemId) => {
    const name = product.find((product) => product.id === itemId)?.name;
    return name || '-';
  };

  const findProductSizeName = (itemId, sizeId) => {
    // console.log({ itemId, sizeId });
    const currentProduct = product.find((x) => x.id === itemId);
    const sizeName = currentProduct?.size.find((x) => x?.id === sizeId)?.sizeTitle;
    return sizeName;
  };

  const findOptionNameFromId = (optionId) => {
    const name = option.find((option) => option?.id === optionId)?.name;
    return name;
  };

  const findOptionValueFromId = (optionId, optionValueId) => {
    const currentOption = option.find((x) => x.id === optionId);
    const valueName = currentOption?.values.find(
      (v) => v.id === optionValueId
    ).optionValueName;
    return valueName;
  };
  return (
    <>
      {order ? (
        <Box sx={{ padding: '1rem', width: '100%' }}>
          <TableContainer>
            <Table sx={{}}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant='h4'>Item</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h4'>Item Info</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h4'>specification</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h4'>media</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h4'>notes</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h4'>status</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {order?.orderItems.map((item, index) => {

                  return <TableRow key={`${item.id}-${index}-${Math.random()}`}>
                    <TableCell>
                      {findProductNameFromId(item.itemId)}
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                          }}
                        >
                          <Typography>Size:</Typography>
                          <Typography>
                            {findProductSizeName(item.itemId, item.itemSizeId)}
                          </Typography>
                        </Box>
                        {item.options.length > 0 && item.options.map((op, index) => {
                          return (
                            <Box
                              key={`${op.optionId}-${Math.random()}-${index}`}
                              sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                              }}
                            >
                              <Typography>
                                {findOptionNameFromId(op.itemOptionId)}:{' '}
                              </Typography>
                              <Typography>
                                {findOptionValueFromId(
                                  op.itemOptionId,
                                  op.itemOptionValueId
                                )}
                              </Typography>
                            </Box>
                          );
                        })}
                      </Box>{' '}
                    </TableCell>
                    <TableCell>
                      <Box>
                        <Typography>quantity: {item.quantity}</Typography>
                        <Typography>price: {item.price || 'n/a'}</Typography>
                        <Typography>weight: {item.weight || 'n/a'}</Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        {item.mediaUrl === 'no media' ? (<Typography>{item.mediaUrl}</Typography>) :
                          <Typography align='center'>
                            <a href={item.mediaUrl} style={{ textDecoration: 'none' }} download>download media</a>
                          </Typography>
                          // null
                        }
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {/* no notes */}
                        {item.notes ? item.notes : 'no notes'}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {item.status}
                      </Typography>
                    </TableCell>
                  </TableRow>
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

      ) :
        <Box sx={{ width: '100%', marginTop: '4rem' }}>
          <Typography variant='h4' align='center'> select an order to view </Typography>
        </Box>
      }
    </>
  )
}

export default View