import axios from 'axios';
import { toast } from 'react-toastify';
import userService from './user.js';

const baseUrl = process.env.REACT_APP_PROXY_BACKEND

const getToken = async () => {
  const session = await userService.getSession()
  const token = session && session.idToken
  return token.jwtToken
}

// items section
export const getAllProducts = async () => {
  try {
    const token = await getToken()
    if (!token) return

    const response = await axios({
      method: 'get',
      url: `${baseUrl}/item`,
      headers: { Authorization: `${token}` },
    });
    return response.data;
  } catch (error) {
    toast.error(`there was an error in getting all product ${error.response.data}`)
  }
};

export const getOneProduct = async (id) => {
  try {
    const token = await getToken()
    if (!token) return

    const response = await axios({
      method: 'get',
      url: `${baseUrl}/item/${id}`,
      headers: { Authorization: `${token}` },
    });
    return response.data;
  } catch (error) {
    toast.error(`there was an error in getting one product ${error.response.data}`)
  }
};


export const updateItem = async (item, id) => {
  try {
    const token = await getToken()
    if (!token) return

    const response = await axios({
      method: 'put',
      url: `${baseUrl}/item/${id}`,
      headers: { Authorization: `${token}` },
      data: item,
    });
    return response.data;
  } catch (error) {
    toast.error(`there was an error in updating the product: ${error.response.data}`)
  }
};

export const postItemTable = async (data) => {
  try {
    const token = await getToken()
    if (!token) return

    const response = await axios({
      method: 'post',
      url: `${baseUrl}/item`,
      headers: { Authorization: `${token}` },
      data: data,
    });

    return response.data;
  } catch (error) {
    toast.error(`there was an error in creating the product: ${error.response.data}`)
  }
};

// item size section
export const updateItemSizeTable = async (size, sizeId) => {
  try {
    const token = await getToken()
    if (!token) return
    const response = await axios({
      method: 'put',
      url: `${baseUrl}/item/size/${sizeId}`,
      headers: { Authorization: `${token}` },
      data: { size },
    });
    toast.success('the item size was successfully updated')
    return response.data;
  } catch (error) {
    toast.error(`there was an error in updating the item size: ${error.response.data}`)
  }
};

export const postItemSize = async (sizeData) => {
  try {
    const token = await getToken()
    if (!token) return
    const response = await axios({
      method: 'post',
      url: `${baseUrl}/item/size`,
      headers: { Authorization: `${token}` },
      data: { sizeData }
    });
    return response.data;
  } catch (error) {
    toast.error(`there was an error in creating the item size: ${error.response.data}`)
  }
}

// item price section
export const updateItemPriceRangeTable = async (priceRange) => {
  try {
    const token = await getToken()
    if (!token) return

    const response = await axios({
      method: 'put',
      url: `${baseUrl}/item/priceRange`,
      headers: { Authorization: `${token}` },
      data: priceRange,
    });
    return response.data;
  } catch (error) {
    toast.error(`there was an error in updating the item size price: ${error.response.data}`)
  }
};


// options section
export const getAllOptions = async () => {
  try {
    const token = await getToken()
    if (!token) return

    const response = await axios({
      method: 'get',
      url: `${baseUrl}/item/options`,
      headers: { Authorization: `${token}` },
    });
    return response.data;
  } catch (error) {
    // console.log();
    toast.error('there was an error in getting all the options')
  }
};

export const postItemOptionTable = async (data) => {
  try {
    const token = await getToken()
    if (!token) return

    const response = await axios({
      method: 'post',
      url: `${baseUrl}/item/options`,
      headers: { Authorization: `${token}` },
      data: data,
    });

    return response.data;
  } catch (error) {
    // console.log(error);
    toast.error('there was an error in creating the option')
  }
};

export const updateItemOptionTable = async (id, data) => {
  try {
    const token = await getToken()
    if (!token) return
    const response = await axios({
      method: 'put',
      url: `${baseUrl}/item/options/${id}`,
      headers: { Authorization: `${token}` },
      data: data,
    });
    return response.data;
  } catch (error) {
    // console.log(error)
    toast.error(`there was an error in updating the option: ${error.response.data}`)
  }
};


// option value section
export const updateItemOptionValueTable = async (id, data) => {
  try {
    const token = await getToken()
    if (!token) return

    const response = await axios({
      method: 'put',
      url: `${baseUrl}/item/optionValues/${id}`,
      headers: { Authorization: `${token}` },
      data: data,
    });

    return response.data;
  } catch (error) {
    // console.log(error);
    toast.error('there was an error in updating the  option value')
  }
};

export const postItemOptionValueTable = async (data) => {
  try {
    const token = await getToken()
    if (!token) return

    const response = await axios({
      method: 'post',
      url: `${baseUrl}/item/optionValues`,
      headers: { Authorization: `${token}` },
      data: data,
    });

    return response.data;
  } catch (error) {
    toast.error('there was an error in creating the item option value')
  }
};

// option price section
export const putItemOptionPriceTable = async (data, priceListId, optionValueId) => {
  try {
    const token = await getToken()
    if (!token) return

    const response = await axios({
      method: 'put',
      url: `${baseUrl}/item/optionPrice?priceListId=${priceListId}&optionValueId=${optionValueId}`,
      headers: { Authorization: `${token}` },
      data: data,
    });

    return response.data;
  } catch (error) {
    toast.error('there was an error in updating the item option price')
  }
};


// item map itemOption section
export const updateItemMapOptionValue = async ({ bool, itemId, itemOptionValueId, itemOptionId }) => {
  try {
    const token = await getToken()
    if (!token) return

    const response = await axios({
      method: 'put',
      url: `${baseUrl}/item/itemMapOptions`,
      headers: { Authorization: `${token}` },
      data: { bool, itemId, itemOptionId, itemOptionValueId },
    });
    return response.data;
  } catch (error) {
    toast.error(`there was an error in updating the item mapped option value: ${error.response.data}`)
  }
};


// item rule section
export const postItemRule = async (data) => {
  try {
    const token = await getToken()
    if (!token) return

    const tableUrl = () => {
      switch (data.ruleType) {
        case 'SIZE_QUANTITY':
          return 'sizeQuantity';
        case 'SIZE_OPTION':
          return 'sizeOption';
        case 'OPTION':
          return 'option';
        case 'SIZE_QUANTITY_OPTION':
          return 'sizeQuantityOption';
        case 'OPTION_SIZE_HIDE':
          return 'optionSizeHide';
        default:
          return 'null';
      }
    };
    const ruleTable = tableUrl();

    const response = await axios({
      method: 'post',
      url: `${baseUrl}/item/itemRule/${ruleTable}`,
      headers: { Authorization: `${token}` },
      data: data,
    });

    return response.data;
  } catch (error) {
    toast.error(`there was an error in creating the item rule: ${error.response.data}`)
    throw new Error(error.response.data)
  }
};

export const deleteItemRule = async (ruleId, ruleType) => {
  try {
    const token = await getToken()
    if (!token) return

    const response = await axios({
      method: 'delete',
      url: `${baseUrl}/item/itemRule/${ruleId}?ruleType=${ruleType}`,
      headers: { Authorization: `${token}` },
    });

    return response.data;
  } catch (error) {
    toast.error(`there was an error in deleting the item rule: ${error.response.data}`)
  }
}

// item media section
export const postMedia = async (type, data) => {
  try {
    const token = await getToken()
    if (!token) return

    const response = await axios({
      method: 'post',
      url: `${baseUrl}/item/itemMedia?type=${type}`,
      headers: { Authorization: `${token}` },
      data: data,
    });

    return response.data;
  } catch (error) {
    toast.error(`there was an error in creating the item media: ${error.response.data}`)
  }
};

export const deleteMedia = async (type, data) => {
  try {
    const token = await getToken()
    if (!token) return

    const response = await axios({
      method: 'delete',
      url: `${baseUrl}/item/itemMedia?type=${type}`,
      headers: { Authorization: `${token}` },
      data: data,
    });

    return response.data;
  } catch (error) {
    toast.error(`there was an error in deleting the item media: ${error.response.data}`)
  }
};


