import React from 'react';
import { Grid, Typography } from '@mui/material';

const View = () => {
  return (
    <Grid container>
      <Grid sx={{ borderRight: '1px solid green' }} item xs={3}>
        <Typography>Name: Name of contact person</Typography>
        <Typography>Contact: email and phone</Typography>
        <Typography>GST: hf93jf938fk</Typography>
        <Typography>
          address: $a kurinju flats mfggh gorf sastri nagar chennai 41
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography>Pending Balance : Rs. 65000</Typography>
        <Typography>Unassigned Amount: Rs. 3000</Typography>
      </Grid>
      <Grid item xs={12}></Grid>
    </Grid>
  );
};

export default View;
