const types = {
  SET_USER: 'SET_USER',
  REMOVE_USER: 'REMOVE_USER',
  SET_UTIL: 'SET_UTIL',
  // clients section: it still uses the user Service
  GET_ALL_CLIENTS: 'GET_ALL_CLIENTS',
  // product section
  GET_ALL_PRODUCTS: 'GET_ALL_PRODUCTS',
  // category section
  GET_ALL_CATEGORIES: 'GET_ALL_CATEGORIES',
  // options section
  GET_ALL_OPTIONS: 'GET_ALL_OPTIONS',
  // updateItemTable
  UPDATE_ITEM_TABLE: 'UPDATE_ITEM_TABLE',
  NEW_CART: 'NEW_CART',
  // 
  POST_DEMAND_ORDER: 'POST_DEMAND_ORDER',
  GET_ALL_DEMAND_ORDER: 'GET_ALL_DEMAND_ORDER',
  // 
  NEW_SHIPMENT_CART: 'NEW_SHIPMENT_CART',
  NEW_GODOWN_TO_CLIENT_ORDER_CART: 'NEW_GODOWN_TO_CLIENT_ORDER_CART',

};


export default types;
