import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import PropTypes from 'prop-types';

const NewCenterBox = styled(Box)(() => {
  return {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#FFFFFF',
    padding: '3rem',
    borderRadius: '10px',
  };
});

const CenterBox = ({ children }) => {
  return <NewCenterBox>{children}</NewCenterBox>;
};

CenterBox.propTypes = {
  children: PropTypes.node,
};

export default CenterBox;
