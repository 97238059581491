import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import EditTableView from '../../utilComponents/EditTableView'
import TableView from '../../utilComponents/TableView'
import { useSelector, useDispatch } from 'react-redux'
import { Box, Grid, Button, Typography } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { editUserAction, inactivateUserAddressAction } from '../../../reducer_action/user'

const User = () => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.user)
  const navigate = useNavigate()

  const [editName, setEditName] = useState(user.name ? true : false)
  const [name, setName] = useState(user.name || '')

  const addAddress = () => {
    navigate('/godown/user/add-address')
  }

  const verifyPhone = () => {
    navigate('/godown/user/verifyPhone')
  }

  const changePassword = () => {
    navigate('/godown/user/changePassword')
  }

  const handleEditAddress = (addressId) => { navigate(`/godown/user/edit-address/${addressId}`) }

  const handleDeleteAddress = (addressId) => {
    const data = { addressId, active: false }
    if (window.confirm('Are you sure you want to delete this address?')) {
      dispatch(inactivateUserAddressAction(data))
    }
  }

  const handleUpdateName = () => {
    dispatch(editUserAction({ name }))
    setEditName(true)
  }

  return (
    <Grid container sx={{ padding: '1rem' }}>
      <Grid item xs={12} sx={{ marginBottom: '3rem' }}>
        <Typography variant='h3'>User Information</Typography>
      </Grid>
      <Grid item xs={12}>
        <TableView type='text' title='email' value={user.email} />
        <Box sx={{ display: 'flex' }}>
          {editName ?
            <>
              <TableView type='text' title='name' value={user.name} />
              <Box onClick={() => setEditName(false)} sx={{ display: 'flex', alignItems: 'center', marginRight: '0rem' }}>
                <EditIcon />
              </Box>
            </>
            :
            <>
              <EditTableView type='text' title='name' value={name} compareValue={user.name} onChange={(e) => setName(e.target.value)} />
              <Button sx={{ height: '80%', margin: 'auto 1rem' }} variant='contained' onClick={handleUpdateName}>update name</Button>
            </>
          }
        </Box>

        {user.phone ?
          <Box sx={{ display: 'flex' }}>
            <TableView type='text' title='phone number' value={user?.phone || 'no phone number'} />
            <Box onClick={verifyPhone} sx={{ display: 'flex', alignItems: 'center', marginRight: '0rem' }}>
              <EditIcon />
            </Box>
          </Box>
          // <TableView type='text' title='phone' value={user?.phone || 'no phone number'} />
          :
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '1rem 0', borderBottom: '1px solid', borderColor: 'secondary.underLine', }}>
            <Typography variant='h6' sx={{ textTransform: 'capitalize' }}>Phone number</Typography>
            <Button variant="contained" onClick={verifyPhone}>add and verify phone number</Button>
          </Box>
        }

        <TableView type='text' title='group' value={user.clientGroup} />

        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '1rem 0', borderBottom: '1px solid', borderColor: 'secondary.underLine', }}>
          <Typography variant='h6' sx={{ textTransform: 'capitalize' }}>change password</Typography>
          <Button variant="contained" onClick={changePassword}>Change Password</Button>
        </Box>
      </Grid>

      <Grid item xs={12} sx={{ marginTop: '2rem' }}>
        <Typography variant='h3'>Addresses</Typography>
        <Box sx={{ padding: '0 0rem 0 2rem' }}>
          {user.addresses.map((address, index) => {
            return <Box key={address.id} sx={{ marginTop: index === 0 ? '0rem' : '1rem' }}>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', }}>
                <Box sx={{ border: '1px solid black', padding: '.5rem', borderRadius: '10px' }} onClick={() => handleEditAddress(address.id)} >
                  <EditIcon />
                </Box>
                <Box onClick={() => handleDeleteAddress(address.id)} sx={{ marginLeft: '1rem', border: '1px solid black', padding: '.5rem', borderRadius: '10px' }} >
                  <DeleteIcon />
                </Box>
              </Box>
              <Box>
                <TableView type='text' title='name' value={address.name} />
                <TableView type='text' title='address' value={address.address} />
                <TableView type='text' title='pincode' value={address.pincode} />
                <TableView type='text' title='coordinates' value={`${address.lngLat.x}/${address.lngLat.y}`} />
              </Box>
            </Box>
          })}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '2rem' }}>
          <Button variant="contained" onClick={addAddress}>Add new Address</Button>
        </Box>
      </Grid>
    </Grid>
  )
}

export default User