import React, { useRef, useEffect, useState } from 'react'
import GodownShipmentHeader from './GodownShipmentHeader'
import GodownShipmentBody from './GodownShipmentBody'
import { Grid } from '@mui/material'
import { useLocation, useParams } from 'react-router-dom'
import { getOneShipment } from '../../../../../../service/shipments'

const GodownShipmentMainbar = () => {
  const params = useParams()
  const location = useLocation()
  const headerRef = useRef()

  const [shipment, setShipment] = useState({})
  const [headerHeight, setHeaderHeight] = useState(0)

  useEffect(() => {
    (async () => {
      const getShipment = await getOneShipment({ shipmentId: +params.shipmentId })
      console.log({ getShipment })
      setShipment(getShipment)
    })()
  }, [params.shipmentId, location, headerRef])

  useEffect(() => {
    const height = headerRef.current && headerRef?.current.offsetHeight;
    setHeaderHeight(height);
  }, [shipment])

  return (
    <Grid container item xs={12}>
      {shipment && shipment.id === +params.shipmentId &&
        <>
          <GodownShipmentHeader headerRef={headerRef} shipment={shipment} />
          <GodownShipmentBody headerHeight={headerHeight} shipment={shipment} setShipment={setShipment} />
        </>
      }
    </Grid >
  )
}

export default GodownShipmentMainbar