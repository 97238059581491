import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { LinearProgress, Modal, Button, TextField, Select, MenuItem, Box, Grid, Typography, } from '@mui/material';
import FileMultipleInput from '../../../../../utilComponents/FileMultipleInput';
import { addProductToCartAction } from '../../../../../../reducer_action/cart';
import { getPresignedUrl, uploadToAwsS3 } from '../../../../../../service/s3';
import { getAllDemandOrder } from '../../../../../../service/demandOrder';
import { toast } from 'react-toastify';
import CenterBox from '../../../../../utilComponents/CenterBox'
import { groupBy } from 'lodash';

// todo import and execute rule set for showing option 
// * which function is used is based on the rule type enum
// * validation before the item is added to the cart

const Order = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const currentCart = useSelector((state) => state.cart)
  const userGroup = useSelector((state) => state.user.clientGroup)
  const userEmail = useSelector((state) => state.user.email)
  const username = useSelector((state) => state.user.username)

  const currentItem = useSelector((state) => state.product.find((item) => item.id === +params.item));

  const trackingUnits = useSelector(state => state.productUtil.trackingUnit)
  const orderUnits = useSelector(state => state.productUtil.orderUnit)

  const categoryGroup = useSelector((state) => state.productUtil.categoryGroup);
  const category = useSelector((state) => state.productUtil.category);

  // component util
  const [loading, setLoading] = useState(false)
  const [loadingProgress, setLoadingProgress] = useState(0)
  const [openModal, setOpenModal] = useState(false)


  const findCategory = (categoryId) => {
    const currentCategory = category.find((x) => categoryId === x.id);
    return currentCategory;
  };

  const isItBillingItem = () => {
    const cat = findCategory(currentItem.categoryId);
    const billingItems = categoryGroup.find((y) => y.id === 1);
    const isBillingItem = cat.categoryGroupId === billingItems.id;
    return isBillingItem;
  };

  const allItemImage = () => {
    const itemMedia = currentItem?.media;
    const sizeMedia = currentItem?.size.map((size) => size.media).flat();
    const optionMedia = currentItem?.options
      .map((option) => { return option.values.map(value => value.media).flat() })
      .flat()
    return [...itemMedia, ...sizeMedia, ...optionMedia];
  };

  const initOrderDetails = () => {
    const optionInitValues = currentItem?.options.map((option) => {
      return {
        optionId: option.id,
        // this needs to be the default value of the option
        optionValueId: option.values.length > 0 ? option.values[0].id : [],
      };
    });

    const initialOrderDetails = {
      itemSizeId: currentItem.size.length > 0 ? currentItem?.size[0].id : null,
      options: optionInitValues,
    };

    // console.log('initial order details', initialOrderDetails)
    return initialOrderDetails;
  };

  const [allImages, setAllImages] = useState(allItemImage());
  const [currentImage, setCurrentImage] = useState(allImages[0] || {});
  const [orderDetails, setOrderDetails] = useState(initOrderDetails());
  const [quantity, setQuantity] = useState(1);

  const [notes, setNotes] = useState('');
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');

  const currentGuideline = (sizeId) => {
    if (!sizeId) {
      return currentItem.orderGuideline.find(guideline => guideline.itemId === currentItem.id);
    } else {
      return currentItem.orderGuideline.find((guideline) => guideline.itemSizeId === orderDetails.itemSizeId)
    }
  }

  const currentOrderUnit = (orderUnitId) => {
    return orderUnits.find(orderUnit => orderUnit.id === orderUnitId)
  }

  const currentTrackingUnit = (trackingUnitId) => {
    return trackingUnits.find(trackingUnit => trackingUnit.id === trackingUnitId)
  }

  useEffect(() => {
    setOrderDetails(initOrderDetails());
    setAllImages(allItemImage());
    setCurrentImage(allItemImage().length > 0 ? allItemImage()[0] : {});
    setQuantity(1)
    setNotes('')
    // eslint-disable-next-line
  }, [currentItem]);


  const handleAddToCart = async () => {
    try {
      //  rule validation section
      setLoading(true)
      // check if the item is already in the cart
      if (currentCart.map(x => x.itemSizeId).includes(orderDetails.itemSizeId)) {
        // find all items with the same itemSizeId
        const currentCartItem = currentCart.filter(x => x.itemSizeId === orderDetails.itemSizeId)
        // check if they have options
        if (orderDetails.options.length > 0) {
          // convert option values into a string for all itemSizes
          const mappedCurrentCartOptions = currentCartItem.map(item => item.options.map(x => `${x.optionId}.${x.optionValueId}`).join('-')).flat()
          const mappedOrderDetailsOptions = orderDetails.options.map(x => `${x.optionId}.${x.optionValueId}`).join('-')
          if (mappedCurrentCartOptions.includes(mappedOrderDetailsOptions)) {
            setLoading(false)
            return alert('item already in cart, please update option')
          }
        } else {
          alert('item already in cart')
          setLoading(false)
          return
        }
      }


      // is the item a pending/processing item  
      const pendingItems = await getAllDemandOrder({ status: 'PENDING', itemName: currentItem.name, username: username, limit: 10, currentPage: 1, itemId: currentItem.id, itemSizeId: orderDetails.itemSizeId });

      if (pendingItems.count > 0) {
        if (!window.confirm(`This item in this size is already in your PENDING ORDER. Do you want to add another one?`)) {
          setLoading(false)
          return
        }
      }

      // upload media 
      let mediaUrl = 'no media'
      if (file && fileName) {
        // upload the file
        setOpenModal(true)
        const uploadUrl = await getPresignedUrl(fileName);
        const resultOfUpload = await uploadToAwsS3(uploadUrl, file, setLoadingProgress);
        if (resultOfUpload.ok) {
          mediaUrl = resultOfUpload.url.split('?')[0];
        } else {
          toast.error('Error uploading media')
          setQuantity(1)
          setNotes('')
          setLoading(false)
          setOpenModal(false)
          return
        }
        setOpenModal(false)
        setFile(null)
        setFileName(null)
      }

      const orderUnitId = currentGuideline(orderDetails.itemSizeId)?.orderUnitId;

      const cartItem = {
        itemId: currentItem.id,
        ...orderDetails,
        itemOrderUnitId: orderUnitId,
        quantity,
        mediaUrl,
        notes
      };
      dispatch(addProductToCartAction(userGroup, userEmail, cartItem));
      setQuantity(1)
      setNotes('')
      setLoading(false)
      toast.success(`${currentItem.name} added to cart`)
    } catch (error) {
      toast.error('Error adding item to cart')
      setQuantity(1)
      setNotes('')
      setLoading(false)
      setOpenModal(false)
      setFile(null)
      setFileName(null)
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      {isItBillingItem() ? (
        <Typography>
          Billing item can not be ordered. Only e-commerce and inventory items
          can be ordered.
        </Typography>
      ) : (
        <Grid container columnSpacing={4}>
          {allImages.length > 0 ? (
            <Grid item xs={6} sx={{
              height: '100%',
              // paddingRight: '2rem'
            }}>
              <Box
                sx={{
                  maxWidth: '450px',
                  maxHeight: '450px',
                  borderRadius: '10px',
                  border: '1px solid black',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '1rem',
                }}
              >
                <img
                  src={currentImage?.mediaUrl}
                  alt={currentImage?.mediaTitle}
                  style={{
                    maxHeight: '400px',
                    maxWidth: '100%',
                    borderRadius: '10px',
                    objectFit: 'cover',
                  }}
                />
              </Box>
              <Box sx={{ display: 'flex', width: '100%', flexWrap: 'wrap', paddingLeft: '1rem' }} >
                {allImages.length > 0 &&
                  allImages.map((image) => {
                    if (!image) return null;
                    return (
                      <React.Fragment
                        key={`${image?.id}-${image?.mediaTitle}`}
                      >
                        {image ? (
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'flex-start',
                              alignItems: 'center',
                              paddingTop: '1rem'
                            }}
                            key={`${image.id}-${image.mediaTitle}`}
                            onClick={() => setCurrentImage(image)}
                          >
                            <img
                              style={{
                                width: '33%',
                                height: 'auto',
                                borderRadius: '10px',
                                objectFit: 'cover',
                              }}
                              src={image.mediaUrl}
                              alt={image.mediaTitle}
                            />
                          </Box>
                        ) : null}
                      </React.Fragment>
                    );
                  })}
              </Box>
            </Grid>
          ) : null}
          <Grid item xs={allImages.length > 0 ? 6 : 12}>
            {/* size section */}
            {currentItem.size.filter(s => s.status).length > 0 ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <Typography variant='h4'>Choose a size</Typography>
                <Select
                  value={orderDetails.itemSizeId}
                  onChange={(e) => {
                    setOrderDetails({
                      ...orderDetails,
                      itemSizeId: e.target.value,
                    });
                  }}
                >
                  {currentItem?.size.length > 0 &&
                    currentItem?.size.map((size) => {
                      if (!size.status) return null

                      // implement optionSizeHide rules
                      const optionRulesGrouped = groupBy(currentItem?.optionRules, 'ruleType')
                      const optionOptionSizeHideRules = optionRulesGrouped?.OPTION_SIZE_HIDE
                      const optionSizeHideRuleValues = []
                      optionOptionSizeHideRules?.forEach(rule => { rule.values.forEach(ruleValue => { optionSizeHideRuleValues.push(ruleValue) }) })

                      const isItOptionSizeHideDisabled = optionSizeHideRuleValues?.find(ruleValue => {
                        return orderDetails?.options && orderDetails.options.find(selection => selection.optionValueId === ruleValue.itemOptionValueId) && ruleValue.hideSizeId === size.id
                      })

                      return (
                        <MenuItem
                          disabled={typeof isItOptionSizeHideDisabled === 'object'}
                          key={size.id}
                          value={size.id}
                        >
                          {size.sizeTitle}
                        </MenuItem>
                      );
                    })}
                </Select>
              </Box>
            ) : null}

            {/* place order option here  */}
            <Box>
              {currentItem?.options.length > 0 &&
                currentItem.options.map((op, index) => {
                  const setDefault = () => {
                    const value = orderDetails?.options.find((x) => {
                      const result = x.optionId === op.id;
                      return result;
                    });
                    return !value ? null : +value?.optionValueId;
                  };

                  console.log(setDefault(), 'set default')
                  return (
                    <Box key={`${op.id}-${op.optionTitle}-${index}`} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '2rem', }} >
                      <Typography variant='h4'>{op.name.toUpperCase()}</Typography>
                      <Select
                        value={setDefault()}
                        onChange={(e) => {
                          const optionValue = {
                            optionId: op.id,
                            optionValueId: e.target.value,
                          };

                          const newOrderDetailsOptions = () => {
                            if (orderDetails?.options.length <= 0) {
                              return [optionValue];
                            } else if (orderDetails?.options.map((x) => x.optionId).includes(op.id)) {
                              //replace option value with current option value
                              return orderDetails?.options.map((x) => {
                                return x.optionId === op.id ? optionValue : x;
                              });
                            } else {
                              return [...orderDetails?.options, optionValue];
                            }
                          };
                          console.log({ optionValue, h: newOrderDetailsOptions() });
                          setOrderDetails({
                            ...orderDetails,
                            options: newOrderDetailsOptions(),
                          });
                        }}
                      >
                        {op.values.length > 0 &&
                          [...op.values]
                            .sort((x, y) => x.sort - y.sort)
                            .map((value) => {
                              console.log(value, 'value')
                              console.log(currentItem.optionRules, 'currentItem.optionRules')
                              const optionRulesGrouped = groupBy(currentItem.optionRules, 'ruleType')

                              // size_quantity rule implementation
                              const sizeQuantityRules = optionRulesGrouped?.SIZE_QUANTITY
                              const ruleValues = []
                              sizeQuantityRules?.forEach(rule => { rule.values.forEach(ruleValue => { ruleValues.push(ruleValue) }) })
                              const isItSizeQuantityDisabled = ruleValues.find(ruleValue => {
                                if (ruleValue.allQuantity) {
                                  return ruleValue.itemSizeId === orderDetails.itemSizeId && ruleValue.hideOptionValueId === value.id
                                } else if (ruleValue.evalType === 'EQUAL_TO') {
                                  return ruleValue.itemSizeId === orderDetails.itemSizeId && ruleValue.hideOptionValueId === value.id && orderDetails.quantity === ruleValue.startQuantity
                                } else if (ruleValue.evalType === 'GREATER_THAN_OR_EQUAL_TO') {
                                  return ruleValue.itemSizeId === orderDetails.itemSizeId && ruleValue.hideOptionValueId === value.id && orderDetails.quantity > ruleValue.startQuantity
                                } else if (ruleValue.evalType === 'LESS_THAN_OR_EQUAL_TO') {
                                  return ruleValue.itemSizeId === orderDetails.itemSizeId && ruleValue.hideOptionValueId === value.id && orderDetails.quantity < ruleValue.startQuantity
                                } else if (ruleValue.evalType === 'NOT_EQUAL_TO') {
                                  return ruleValue.itemSizeId === orderDetails.itemSizeId && ruleValue.hideOptionValueId === value.id && orderDetails.quantity !== ruleValue.startQuantity
                                } else if (ruleValue.evalType === 'BETWEEN') {
                                  return ruleValue.itemSizeId === orderDetails.itemSizeId && ruleValue.hideOptionValueId === value.id && orderDetails.quantity >= ruleValue.startQuantity && orderDetails.quantity <= ruleValue.endQuantity
                                }
                                return false
                              })

                              // size_option rule implementation
                              const sizeOptionRules = optionRulesGrouped?.SIZE_OPTION
                              const sizeOptionRuleValues = []
                              sizeOptionRules?.forEach(rule => { rule.values.forEach(ruleValue => { sizeOptionRuleValues.push(ruleValue) }) })
                              const isItSizeOptionDisabled = sizeOptionRuleValues?.find(ruleValue => {
                                return ruleValue.itemSizeId === orderDetails.itemSizeId &&
                                  ruleValue.itemOptionValueId === orderDetails.options.find(x => x.optionValueId === ruleValue.itemOptionValueId)?.optionValueId &&
                                  ruleValue.hideOptionValueId === value.id
                              })

                              // option rule implementation
                              const optionOptionRules = optionRulesGrouped?.OPTION
                              const optionRuleValues = []
                              optionOptionRules?.forEach(rule => { rule.values.forEach(ruleValue => { optionRuleValues.push(ruleValue) }) })
                              const isItOptionDisabled = optionRuleValues?.find(ruleValue => orderDetails?.options && orderDetails.options.find(selection => selection.optionValueId === ruleValue.itemOptionValueId) && ruleValue.hideOptionValueId === value.id)


                              // size_quantity_option rule implementation
                              const sizeQuantityOptionRules = optionRulesGrouped?.SIZE_QUANTITY_OPTION
                              const sizeQuantityOptionRuleValues = []
                              sizeQuantityOptionRules?.forEach(rule => { rule.values.forEach(ruleValue => { sizeQuantityOptionRuleValues.push(ruleValue) }) })
                              const isItSizeQuantityOptionDisabled = sizeQuantityOptionRuleValues?.find(ruleValue => {
                                if (ruleValue.allQuantity) {
                                  return ruleValue.itemSizeId === orderDetails.itemSizeId &&
                                    ruleValue.itemOptionValueId === orderDetails.options.find(x => x.optionValueId === ruleValue.itemOptionValueId)?.optionValueId &&
                                    ruleValue.hideOptionValueId === value.id
                                } else if (ruleValue.evalType === 'EQUAL_TO') {
                                  return ruleValue.itemSizeId === orderDetails.itemSizeId &&
                                    ruleValue.itemOptionValueId === orderDetails.options.find(x => x.optionValueId === ruleValue.itemOptionValueId)?.optionValueId &&
                                    ruleValue.hideOptionValueId === value.id &&
                                    orderDetails.quantity === ruleValue.startQuantity
                                } else if (ruleValue === 'GREATER_THAN_OR_EQUAL_TO') {
                                  return ruleValue.itemSizeId === orderDetails.itemSizeId &&
                                    ruleValue.itemOptionValueId === orderDetails.options.find(x => x.optionValueId === ruleValue.itemOptionValueId)?.optionValueId &&
                                    ruleValue.hideOptionValueId === value.id &&
                                    orderDetails.quantity >= ruleValue.startQuantity
                                } else if (ruleValue === 'LESS_THAN_OR_EQUAL_TO') {
                                  return ruleValue.itemSizeId === orderDetails.itemSizeId &&
                                    ruleValue.itemOptionValueId === orderDetails.options.find(x => x.optionValueId === ruleValue.itemOptionValueId)?.optionValueId &&
                                    ruleValue.hideOptionValueId === value.id &&
                                    orderDetails.quantity <= ruleValue.startQuantity
                                } else if (ruleValue === 'NOT_EQUAL_TO') {
                                  return ruleValue.itemSizeId === orderDetails.itemSizeId &&
                                    ruleValue.itemOptionValueId === orderDetails.options.find(x => x.optionValueId === ruleValue.itemOptionValueId)?.optionValueId &&
                                    ruleValue.hideOptionValueId === value.id &&
                                    orderDetails.quantity !== ruleValue.startQuantity
                                } else if (ruleValue === 'BETWEEN') {
                                  return ruleValue.itemSizeId === orderDetails.itemSizeId &&
                                    ruleValue.itemOptionValueId === orderDetails.options.find(x => x.optionValueId === ruleValue.itemOptionValueId)?.optionValueId &&
                                    ruleValue.hideOptionValueId === value.id &&
                                    orderDetails.quantity >= ruleValue.startQuantity &&
                                    orderDetails.quantity <= ruleValue.endQuantity
                                }
                                return false
                              })

                              return (
                                <MenuItem
                                  disabled={
                                    typeof isItSizeQuantityDisabled === 'object' ||
                                    typeof isItSizeOptionDisabled === 'object' ||
                                    typeof isItOptionDisabled === 'object' ||
                                    typeof isItSizeQuantityOptionDisabled === 'object'
                                  }
                                  key={value.id}
                                  value={value.id}
                                >
                                  {value.optionValueName}
                                </MenuItem>
                              );
                            })}
                      </Select>
                    </Box>
                  )
                })}
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '2rem', }} >
              <Typography variant='h4'>Quantity</Typography>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <TextField
                  value={quantity}
                  onChange={(e) => {
                    setQuantity(+e.target.value);
                    setOrderDetails({
                      ...orderDetails,
                      quantity: +e.target.value,
                    });
                  }}
                  align='center'
                  sx={{ width: '100px' }}
                />
              </Box>
            </Box>
            {currentItem.uploadCenter && (
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '2rem', }} >
                <Typography variant='h4'>Upload File</Typography>
                <FileMultipleInput file={file} setFile={setFile} setFileName={setFileName} fileName={fileName} />
              </Box>
            )}

            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '2rem', }} >
              <Typography variant='h4'>Notes</Typography>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', }} >
                <TextField
                  multiline
                  value={notes}
                  onChange={(e) => {
                    setNotes(e.target.value);
                    setOrderDetails({
                      ...orderDetails,
                      notes: e.target.value,
                    });
                  }}
                  align='center'
                  sx={{ width: '100px' }}
                />
              </Box>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '2rem' }}>
              <Typography variant='h4' >Order Info</Typography>
              {orderDetails && (() => {
                const orderUnitId = currentGuideline(orderDetails.itemSizeId)?.orderUnitId
                const currentOrUnit = currentOrderUnit(orderUnitId)
                const currentTrUnit = currentTrackingUnit(currentOrUnit?.trackingUnitId)
                // console.log(orderUnitId, currentOrUnit, currentTrUnit)
                return (
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography align='right' variant='body1'>
                      {/* {currentOrUnit?.name}: */}
                      {currentOrUnit?.trackingUnitQuantity} {currentTrUnit?.name} per quantity
                    </Typography>
                    <Typography align='right'>Total {currentTrUnit?.name}(s): {quantity * currentOrUnit?.trackingUnitQuantity} </Typography>
                  </Box>
                )
              })()}
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', marginTop: '4rem', }} >
              <Button disabled={loading} onClick={handleAddToCart} variant='contained'>
                add to cart
              </Button>
            </Box>
          </Grid>
        </Grid>
      )
      }
      <Modal open={openModal} onClose={() => console.log('modal close')}>
        <>
          <CenterBox >
            <Box sx={{ display: ' flex', justifyContent: 'flex-end' }}>
              <Button variant='contained' onClick={() => setOpenModal(false)}>back</Button>
            </Box>
            <Typography variant='h2' align='center' sx={{ marginBottom: '1rem' }}>Uploading File</Typography>
            <Box sx={{ display: 'flex', align: 'center', columnGap: '1rem' }}>
              <Typography sx={{ paddingTop: '0.5rem' }}>{loadingProgress}%</Typography>
              <Box sx={{ padding: '1rem 1rem 1rem 0.5rem', width: '50vw', }}>
                <LinearProgress color='primary' />
              </Box>
            </Box>
          </CenterBox>
        </>
      </Modal>
    </Box >
  );
};

export default Order;
