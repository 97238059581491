import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setUtilAction } from '../../../../../reducer_action/util';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import OutlinedInput from '@mui/material/OutlinedInput';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
// import ListItemText from '@mui/material/ListItemText';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';

const Header = ({ headerRef, categoryGroupId, setCategoryGroupId, categoryGroupSelectValues, itemName, setItemName }) => {
  const util = useSelector((state) => state.util);
  const dispatch = useDispatch()
  const navigate = useNavigate();

  return (
    <Grid container justifyContent='space-between' alignItems='flex-start' ref={headerRef} >
      <Grid item xs={12}>
        <Box sx={{ padding: '1rem 1rem' }}>
          <OutlinedInput
            id='standard-search'
            value={itemName}
            fullWidth
            endAdornment={
              <InputAdornment position='end'>
                <SearchIcon sx={{ color: 'black' }} />
              </InputAdornment>
            }
            sx={{ margin: '0' }}
            onChange={(e) => setItemName(e.target.value)}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', justifyContent: util.mainSideBar ? 'space-between' : 'flex-end', alignItems: util.mainSideBar ? 'flex-end' : 'flex-start', padding: '0 1rem ', }} >
          <Select
            fullWidth
            value={categoryGroupId}
            sx={{ color: 'primary.main', fontWeight: '800', }}
            onChange={(e) => setCategoryGroupId(e.target.value)}
          >
            {categoryGroupSelectValues.length > 0 &&
              categoryGroupSelectValues.sort(group => group.id).map((group) => {
                return <MenuItem value={group.id} key={group.id}>{group.name}</MenuItem>;
              })}
          </Select>
        </Box>
      </Grid>
      <Grid item xs={12} sx={{ margin: '1rem 0 0 1rem' }}>
        <Button
          onClick={() => {
            dispatch(setUtilAction({ mainSideBar: false }));
            navigate('/godown/products?action=add');
          }}
          variant='contained'
        >
          New
        </Button>
      </Grid>
    </Grid>
  );
};

export default Header;
