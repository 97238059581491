import React from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setUtilAction } from '../../../../../reducer_action/util';
import { Box, Grid, Typography } from '@mui/material';

const Card = ({ product }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const category = useSelector(state => state.productUtil.category)

  const mainSideBar = useSelector((state) => state.util.mainSideBar);

  const defaultPage = 'view'

  const findCategoryNameFromId = (categoryId) => {
    const currentCategory = category.find(cat => cat.id === categoryId)
    return currentCategory.name
  }

  return (
    <>
      {category && category.length > 0 &&
        <Grid container
          sx={{ padding: '1rem 1rem', cursor: 'pointer', justifyContent: 'space-between', alignItems: 'center', backgroundColor: 'background.cardBackground', margin: '1rem 0', }}
          onClick={() => {
            dispatch(setUtilAction({ mainSideBar: true, }));
            navigate(`/godown/products/${product.id}?action=${defaultPage}`);
          }}
        >
          <Grid item xs={mainSideBar ? 9 : 3}>
            <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '3px', maxWidth: '60%', minWidth: '10%', }} >
              <Typography noWrap={true} variant='h6' sx={{ textTransform: 'uppercase' }} >
                {product.name}
              </Typography>
              <Typography variant='caption'>{findCategoryNameFromId(product.categoryId)}</Typography>
            </Box>
          </Grid>

          {/* the extra information */}
          {!mainSideBar && (
            <Grid item xs={6}>
              <Box>
                <Box sx={{ height: '100%', display: 'flex', alignItems: 'baseline' }} >
                  <Typography variant='h6' sx={{ paddingRight: '.5rem' }}>
                    Size:{' '}
                  </Typography>
                  {product.size.length > 0
                    ? product.size.map((size, index, array) => {
                      const length = array.length;
                      if (array.length === 0) {
                        return <Typography>this item has no size</Typography>;
                      } else {
                        return (
                          <Typography variant='body1' sx={{ paddingRight: '0.5rem' }} key={size.id} >
                            {length - 1 !== index
                              ? size.sizeTitle.toUpperCase().concat(', ')
                              : size.sizeTitle.toUpperCase()}
                          </Typography>
                        );
                      }
                    })
                    : 'none'}
                </Box>

                <Box sx={{ height: '100%', display: 'flex', alignItems: 'baseline' }} >
                  <Typography variant='h6' sx={{ paddingRight: '0.5rem' }}>
                    Options:
                  </Typography>
                  {product.options.length > 0
                    ? product.options.map((option, index, array) => {
                      if (array.length === 0) {
                        return <Typography>this iem has no options</Typography>;
                      } else {
                        return (
                          <Typography
                            variant='body1'
                            sx={{ paddingRight: '0.5rem' }}
                            key={option.id}
                          >
                            {option.name}:{' '}
                            {option.values.map((value, index, array) => {
                              if (array.length - 1 === index) {
                                return value.optionValueName;
                              } else {
                                return value.optionValueName.concat(', ');
                              }
                            })}
                          </Typography>
                        );
                      }
                    })
                    : 'none'}
                </Box>
              </Box>
            </Grid>
          )}
          <Grid item xs={3}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', }} >
              <Box sx={{ backgroundColor: 'background.activeCardBackground', padding: '0.4rem 0.6rem', maxWidth: 'max-content', }} >
                <Typography>active</Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      }
    </>
  );
};

export default Card;
