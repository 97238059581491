import React from 'react'
import { useLocation } from 'react-router-dom'
import View from './Pages/View'
import { Grid } from '@mui/material'

const GodownOrdersBody = ({ order, setOrder, headerHeight }) => {
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery()
  const action = query.get('action');
  console.log({ headerHeight })

  return (
    <Grid item container
      sx={{
        height: 'calc(100vh - ' + headerHeight + 'px)',
        overflow: 'scroll',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
          display: 'none',
        }
      }}
    >
      <Grid container item xs={12}>
        {action === 'view' && <View order={order} setOrder={setOrder} />}
      </Grid>
    </Grid >
  )
}

export default GodownOrdersBody