
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';

import { Box, Grid, Button } from '@mui/material';
import EditTableView from '../../../../../utilComponents/EditTableView';
import { postCategoryAction, updateCategoryTableAction, } from '../../../../../../reducer_action/productUtil';
import { toast } from 'react-toastify';

// todo add a image post button here
const Add = ({ type }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate()

  // const action = useSelector((state) => state.util.actionQuery);

  // const category = useSelector((state) => {
  // if (action !== 'add') {
  // return state.productUtil.category.find((x) => x.id === +params.category);
  // } else {
  // return null;
  // }
  // });

  const categoryGroupSelectValues = useSelector((state) =>
    state.productUtil.categoryGroup.map((group) => {
      return { name: group.name, id: group.id };
    })
  );

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [categoryGroupId, setCategoryGroupId] = useState(categoryGroupSelectValues[0].id);

  const handleCategoryUpdateClick = () => {
    // console.log({ name, description, categoryGroupId });
    // if (type === 'edit') {
    // dispatch(updateCategoryTableAction({ name, description, categoryGroupId }, category.id));
    // toast.success('category has been updated')
    // navigate('?action=view');
    // } else if (type === 'post') {
    dispatch(postCategoryAction({ name, description, categoryGroupId }));
    toast.success('category has been created')
    navigate('/godown/productCategories')
    // }
  };

  return (
    <Grid container item xs={12} alignContent='flex-start'>
      <Grid item xs={12}>
        <EditTableView
          title='name'
          value={name}
          type='text'
          onChange={(e) => setName(e.target.value)}
        />
        <EditTableView
          title='description'
          value={description}
          type='multiline-text'
          onChange={(e) => setDescription(e.target.value)}
        />
        <EditTableView
          title='categoryGroup'
          type='select'
          value={categoryGroupId}
          selectValues={categoryGroupSelectValues}
          onChange={(e) => setCategoryGroupId(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} sx={{ marginTop: '1rem' }}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={handleCategoryUpdateClick} variant='contained'> Save </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Add;
