import React from 'react'
import { useSelector } from 'react-redux'
import { Grid, Box, Button, Table, TableContainer, TableRow, TableCell, TableHead, TableBody, Typography } from '@mui/material'

const View = ({ shipment }) => {
  const product = useSelector(state => state.product)
  const option = useSelector(state => state.option)
  const orderUnit = useSelector(state => state.productUtil.orderUnit)
  const trackingUnit = useSelector(state => state.productUtil.trackingUnit)

  const findProductNameFromId = (itemId) => {
    const name = product.find((product) => product.id === itemId)?.name;
    return name;
  };

  const findProductSizeName = (itemId, sizeId) => {
    const currentProduct = product.find((x) => x.id === itemId);
    const sizeName = currentProduct?.size.find((x) => x?.id === sizeId)?.sizeTitle;
    return sizeName;
  };

  const findOptionNameFromId = (optionId) => {
    const name = option.find((option) => option?.id === optionId)?.name;
    return name;
  };

  const findOptionValueFromId = (optionId, optionValueId) => {
    const currentOption = option.find((x) => x.id === optionId);
    const valueName = currentOption?.values.find(
      (v) => v.id === optionValueId
    ).optionValueName;
    return valueName;
  };

  return (
    <Grid container sx={{ padding: '0 1rem', width: '100' }}>
      <Grid item xs={12}>
        <TableContainer >
          <Table >
            <TableHead>
              <TableRow>
                <TableCell> <Typography variant='h4'>Item</Typography> </TableCell>
                <TableCell> <Typography variant='h4'>Item Info</Typography> </TableCell>
                <TableCell> <Typography variant='h4'>specification</Typography> </TableCell>
                <TableCell> <Typography variant='h4'>Media / Notes</Typography> </TableCell>
                <TableCell> <Typography variant='h4'>status</Typography> </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {shipment && shipment.shipmentItems && shipment?.shipmentItems.length > 0 && shipment?.shipmentItems.map((item, index) => {
                return <TableRow key={`${item.id}-${index}-${Math.random()}`}>
                  <TableCell>
                    {findProductNameFromId(item.itemId)}
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex', flexDirection: 'column', }} >
                      <Box sx={{ display: 'flex', justifyContent: 'flex-start', }} >
                        <Typography>Size:</Typography>
                        <Typography>
                          {findProductSizeName(item.itemId, item.itemSizeId)}
                        </Typography>
                      </Box>
                      {item.options.length > 0 && item.options.map((op, index) => {
                        return (
                          <Box key={`${op.optionId}-${Math.random()}-${index}`} sx={{ display: 'flex', justifyContent: 'flex-start', }} >
                            <Typography> {findOptionNameFromId(op.itemOptionId)}:{' '} </Typography>
                            <Typography> {findOptionValueFromId(op.itemOptionId, op.itemOptionValueId)} </Typography>
                          </Box>
                        );
                      })}
                    </Box>{' '}
                  </TableCell>
                  <TableCell>
                    <Box>
                      <Typography>quantity: {item.quantity}</Typography>
                      <Typography>price: {item.price || 'n/a'}</Typography>
                      <Typography>weight: {item.weight || 'n/a'}</Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex', }} >
                      {item.mediaUrl === 'no media'
                        ? (<Typography>{item.mediaUrl}</Typography>)
                        :
                        <Typography align='center'> <a href={item.mediaUrl} style={{ textDecoration: 'none' }} download>download media</a>
                        </Typography>
                      }
                    </Box>
                    <Typography>
                      {item.notes ? item.notes : 'no notes'}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {item.status}
                    </Typography>
                    <Typography>
                      {orderUnit.find(x => item.itemOrderUnitId === x.id).name}
                    </Typography>
                    <Typography>
                      {orderUnit.find(x => item.itemOrderUnitId === x.id).trackingUnitQuantity * item.quantity}{' '}
                      {trackingUnit.find(y => y.id === orderUnit.find(x => item.itemOrderUnitId === x.id).trackingUnitId).name}s
                    </Typography>
                  </TableCell>
                  <TableCell>
                  </TableCell>
                </TableRow>
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12} sx={{ marginTop: '1rem' }}>
        {shipment.mediaUrl &&
          <Button variant='contained'>
            <a download href={shipment.mediaUrl} style={{ textDecoration: 'none', color: 'white' }}>Download DC</a>
          </Button>
        }
      </Grid>
    </Grid>
  )
}

export default View