import React, { useState } from 'react'
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Grid, Box, Select, MenuItem } from '@mui/material'
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';


const AsxShipmentSidebarHeader = ({ headerRef, setItemName, status, setStatus }) => {
  const shipmentId = useParams().shipmentId

  const orderStatus = useSelector(state => state.productUtil && state?.productUtil.enums.find(x => x.name === 'order_status_type')?.value.split('|'));

  const [tempItemName, setTempItemName] = useState('');

  const handleKeyPress = (e) => {
    console.log(e.key, 'keyPress')
    if (e.key === 'Enter' || e.which === 'Enter') {
      setItemName(tempItemName)
      // todo match the address with the state variables
      // console.log(query, { query }, query.map((x, i, array) => array[0] + '=' + array[1]))
      // navigate(`?itemName=${tempItemName}`)
    }
  }
  return (
    <Grid container item xs={12} justifyContent='space-between' alignItems='flex-start' ref={headerRef} >
      <Grid item xs={12}>
        <Box sx={{ padding: '1rem 1rem' }}>
          <OutlinedInput id='standard-search' value={tempItemName} fullWidth onChange={(e) => setTempItemName(e.target.value)} onKeyPress={handleKeyPress} sx={{ margin: '0' }}
            endAdornment={
              <InputAdornment position='end'>
                <SearchIcon sx={{ color: 'black' }} />
              </InputAdornment>
            }
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', justifyContent: !shipmentId ? 'space-between' : 'flex-end', alignItems: !shipmentId ? 'flex-end' : 'flex-start', padding: '0 1rem ', }} >
          <Select fullWidth value={status} sx={{ color: 'primary.main', fontWeight: '800', }} onChange={(e) => setStatus(e.target.value)} >
            <MenuItem value={'ALL'}>all</MenuItem>
            {orderStatus.filter(x => !['PENDING', 'PROCESSING', 'CANCELLED', 'CANCELLATION_REQUEST'].includes(x)).map((x) => {
              return <MenuItem key={x} value={x}>{x.toLowerCase()}</MenuItem>
            })}
          </Select>
        </Box>
      </Grid>
    </Grid >
  )
}

export default AsxShipmentSidebarHeader