import React, { } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { TableRow, TableCell, Typography, Box } from '@mui/material'
import { DeleteOutline } from '@mui/icons-material'
import { removeProductFromShipmentCartAction } from '../../../../../reducer_action/shipCart'

const ShipmentItemRow = ({ item, index }) => {
  const dispatch = useDispatch()

  const user = useSelector(state => state.user)
  const product = useSelector(state => state.product)
  const option = useSelector(state => state.option)

  const findProductNameFromId = (itemId) => {
    const name = product.find((product) => product.id === itemId)?.name;
    return name;
  };

  const findProductSizeName = (itemId, sizeId) => {
    const currentProduct = product.find((x) => x.id === itemId);
    const sizeName = currentProduct?.size?.find((x) => x.id === sizeId)?.sizeTitle;
    return sizeName;
  };

  const findOptionNameFromId = (optionId) => {
    const name = option.find((option) => option.id === optionId)?.name;
    return name;
  };

  const findOptionValueFromId = (optionId, optionValueId) => {
    const currentOption = option.find((x) => x.id === optionId);
    const valueName = currentOption?.values.find(
      (v) => v.id === optionValueId
    ).optionValueName;
    return valueName;
  };

  return (
    <TableRow key={`${item.itemId}-${Math.random()}-${index}`} sx={{ width: '100%' }} >
      <TableCell>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem', }} >
          <Typography>{item.orderId}</Typography>
        </Box>
      </TableCell>
      <TableCell>
        <Box>
          <Typography variant='h6'> {findProductNameFromId(item.itemId)} </Typography>
          <Typography>
            {item.returnOrder ? <Typography sx={{ color: 'red' }}>RETURN ORDER ITEM</Typography> : null}
          </Typography>
        </Box>
      </TableCell>
      <TableCell >
        <Box>
          <Box sx={{ paddingLeft: '0rem' }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', }} >
              <Typography>Size: </Typography>
              <Typography > {findProductSizeName(item.itemId, item.itemSizeId) || 'null'} </Typography>
            </Box>
            {item.options.map((op, index) => {
              return (
                <Box key={`${op.optionId}-${Math.random()}-${index}`} sx={{ display: 'flex', justifyContent: 'flex-start', }} >
                  <Typography>{findOptionNameFromId(op.itemOptionId)}:{' '}</Typography>
                  <Typography> {findOptionValueFromId(op.itemOptionId, op.itemOptionValueId)} </Typography>
                </Box>
              );
            })}
          </Box>
        </Box>
      </TableCell>

      <TableCell>
        <Box>
          <Typography align='left'>quantity: {item.quantity}</Typography>
          <Typography align='left'>price: {item.price || 'n/a'}</Typography>
          <Typography align='left'>weight: {item.weight || 'n/a'}</Typography>
        </Box>
      </TableCell>

      <TableCell >
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', }} >
          {item.mediaUrl === 'no media' ? (<Typography>{item.mediaUrl}</Typography>) :
            <Typography align='center'>
              <a href={item.mediaUrl} style={{ textDecoration: 'none' }} download>download media</a>
            </Typography>
          }
        </Box>
      </TableCell>
      <TableCell
      >
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', cursor: 'pointer', width: '100%' }}
          // item id is the orderItemId since is directly from the demand_order_item table // group , email, itemId
          onClick={() => dispatch(removeProductFromShipmentCartAction(user.clientGroup, user.email, item.id))}
        >
          <DeleteOutline />
        </Box>
      </TableCell>
    </TableRow >
  )
}

export default ShipmentItemRow