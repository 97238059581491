import types from './actionTypes';
import { createReducer, createAction } from '@reduxjs/toolkit';

const setUtilDispatch = createAction(types.SET_UTIL);

export const setUtilAction = (layout) => {
  return (dispatch) => {
    dispatch(setUtilDispatch(layout))
  };
};

const utilReducer = createReducer({ sideBar: true, mainSideBar: true, mainBar: false, }, (builder) => {
  builder
    .addCase(setUtilDispatch, (state, action) => {
      return ({ ...state, ...action.payload, })
    })
    .addDefaultCase((state) => state)
})

export default utilReducer;

// check - is it in the bar's maximums width. Then its true.
// const utilReducer = ( //   state = { //     sideBar: true, //     mainSideBar: true, //     mainBar: false, //     // actionQuery: 'view', // action query should be sourced from the query parameters //   }, //   action
// ) => {
//   switch (action.type) {
//     case types.SET_UTIL:
//       return {
//         ...state,
//         ...action.data,
//       };
//     default:
//       return state;
//   }
// };