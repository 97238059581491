import React from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import { Typography } from '@mui/material'

import Auth from './Auth';
// import Authentication from '../components/Authentication_Module/Authentication';
import Dash from '../components/Layout_Module/Dash';
import MainSideBar from '../components/Layout_Module/MainSideBar';
import MainBar from '../components/Layout_Module/MainBar';

import ProductsMainBar from '../components/Products_Module/Products/godown/Mainbar/Products';
import ProductsSidebar from '../components/Products_Module/Products/godown/Sidebar/ProductsSidebar';
import AddProduct from '../components/Products_Module/Products/godown/Sidebar/AddProduct';

import AddOption from '../components/Products_Module/Options/godown/Sidebar/AddOption';
import OptionsSidebar from '../components/Products_Module/Options/godown/Sidebar/OptionsSidebar';
import OptionsMainbar from '../components/Products_Module/Options/godown/Mainbar/OptionsMainbar';

import GodownOrdersSideBar from '../components/Demand_Order_Module/orders/godown/Sidebar/GodownOrdersSideBar';
import GodownOrdersMainbar from '../components/Demand_Order_Module/orders/godown/MainBar/GodownOrdersMainbar';


/// shipments section
import ShipmentCart from '../components/Demand_Order_Module/shipments/godown/Cart/ShipmentCart';
import GodownShipmentSidebar from '../components/Demand_Order_Module/shipments/godown/Shipments/Sidebar/GodownShipmentSidebar';
// import CreateShipment from '../components/Demand_Order_Module/shipments/CreateShipment';
import GodownShipmentMainbar from '../components/Demand_Order_Module/shipments/godown/Shipments/Mainbar/GodownShipmentMainbar';
// print section => shipment module 
import DeliveryChallan from '../components/Demand_Order_Module/shipments/godown/Shipments/Mainbar/printPages/DeliveryChallan';

// client section
import ClientSidebar from '../components/Clients_Module/godown/Sidebar/ClientSidebar';
import ClientMainbar from '../components/Clients_Module/godown/Mainbar/ClientMainbar';
import OrderGuidelineSidebar from '../components/Products_Module/Order_Guideline/godown/Sidebar/OrderGuidelineSidebar';
import OrderGuidelineMainbar from '../components/Products_Module/Order_Guideline/godown/Mainbar/OrderGuidelineMainbar';


// user module 
import User from '../components/User_Module/godown/User'
import ChangePassword from '../components/User_Module/godown/ChangePassword';
import AddAddress from '../components/User_Module/godown/AddAddress';
import EditAddress from '../components/User_Module/godown/EditAddress';
import VerifyPhoneAtt from '../components/User_Module/godown/VerifyPhoneAtt';


// category
import CategorySideBar from '../components/Products_Module/Categories/godown/Sidebar/Category';
import Category from '../components/Products_Module/Categories/godown/Mainbar/Category';
import AddCategory from '../components/Products_Module/Categories/godown/Sidebar/AddCategory';

const Godown = () => {
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const action = query.get('action');

  return (
    <Routes>
      <Route
        path="godown"
        element={
          <Auth>
            <Dash />
          </Auth>
        }
      >
        <Route index
          element={
            <Auth>
              <Typography variant='h2' align='center' sx={{ marginTop: '3rem' }}>Godown Module</Typography>
            </Auth>
          }
        />
        <Route
          path='/godown/products'
          element={
            <Auth>
              <MainSideBar>
                {action === 'add' ? <AddProduct /> : <ProductsSidebar />}
              </MainSideBar>
            </Auth>
          }
        >
          <Route
            path='/godown/products/:item'
            element={
              <Auth>
                <MainBar>
                  <ProductsMainBar />
                </MainBar>
              </Auth>
            }
          />
        </Route>

        {/* the product categories section */}
        <Route
          path='/godown/productCategories'
          element={
            <Auth>
              <MainSideBar>
                {action === 'add' ? (
                  <AddCategory />
                ) : (
                  <> <CategorySideBar /> </>
                )}
              </MainSideBar>
            </Auth>
          }
        >
          <Route
            path='/godown/productCategories/:category'
            element={
              <Auth>
                <MainBar>
                  <Category />
                </MainBar>
              </Auth>
            }
          />
        </Route>


        {/* product options section */}

        <Route
          path='/godown/productOptions'
          element={
            <Auth>
              <MainSideBar>
                {action === 'add' ? (
                  <AddOption />
                ) : (
                  <OptionsSidebar />
                )}
              </MainSideBar>
            </Auth>
          }
        >
          <Route
            path='/godown/productOptions/:option'
            element={
              <Auth>
                <MainBar>
                  <OptionsMainbar />
                </MainBar>
              </Auth>
            }
          />
        </Route>

        {/* order guidelines section */}

        <Route
          path='/godown/orderUnit'
          element={
            <Auth>
              <MainSideBar>
                <OrderGuidelineSidebar />
              </MainSideBar>
            </Auth>
          }
        >
          <Route
            path='/godown/orderUnit/:orderUnitId'
            element={
              <Auth>
                <MainBar>
                  <OrderGuidelineMainbar />
                </MainBar>
              </Auth>
            }>
          </Route>
        </Route>

        {/* Orders and shipment section */}

        <Route
          path='/godown/orders'
          element={
            <Auth>
              <MainSideBar>
                <GodownOrdersSideBar />
              </MainSideBar>
            </Auth>
          }
        >
          <Route
            path='/godown/orders/:orderId'
            element={
              <Auth>
                <MainBar>
                  <GodownOrdersMainbar />
                </MainBar>
              </Auth>
            }
          />
        </Route>

        <Route
          path='/godown/shipmentCart'
          element={
            <Auth>
              <MainSideBar>
                <ShipmentCart />
              </MainSideBar>
            </Auth>
          }
        />

        <Route
          path='/godown/shipments'
          element={
            <Auth>
              <MainSideBar>
                <GodownShipmentSidebar />
              </MainSideBar>
            </Auth>
          }
        >

          <Route
            path='/godown/shipments/:shipmentId'
            element={
              <Auth>
                <MainBar>
                  <GodownShipmentMainbar />
                </MainBar>
              </Auth>
            }
          />

        </Route>

        {/* to delete this route */}
        {/* <Route path='/godown/createShipment' element={ <Auth> <MainBar> <CreateShipment /> </MainBar> </Auth> } /> */}

        <Route
          path='/godown/returnShipment'
          element={
            <Auth>
              <MainBar>
                godown shipment
              </MainBar>
            </Auth>
          }
        />
        <Route
          path='/godown/inventoryAdjustment'
          element={
            <Auth>
              <MainBar>
                godown shipment
              </MainBar>
            </Auth>
          }
        />
        <Route
          path='/godown/currentInventory'
          element={
            <Auth>
              <MainBar>
                godown shipment
              </MainBar>
            </Auth>
          }
        />
        <Route
          path='/godown/clients'
          element={
            <Auth>
              <MainSideBar>
                <ClientSidebar />
              </MainSideBar>
            </Auth>
          }>
          <Route
            path='/godown/clients/:clientId'
            element={
              <Auth>
                <MainBar>
                  <ClientMainbar />
                </MainBar>
              </Auth>
            }
          />
        </Route>

        {/* printing section */}
        <Route path='/godown/print/shipment/:shipmentId' element={
          <Auth>
            <DeliveryChallan />
          </Auth>
        } />
        <Route path='/godown/user' element={<Auth> <User /> </Auth>} />
        <Route path='/godown/user/add-address' element={<Auth><AddAddress /></Auth>} />
        <Route path='/godown/user/edit-address/:addressId' element={<Auth><EditAddress /></Auth>} />
        <Route path='/godown/user/verifyPhone' element={<Auth><VerifyPhoneAtt /></Auth>} />
        <Route path='/godown/user/changePassword' element={<Auth><ChangePassword /></Auth>} />
      </Route>
    </Routes >
  )
}

export default Godown 