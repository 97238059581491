import React, { useEffect, useState, useRef } from 'react';
import ProductOptionsBody from './ProductOptionsBody';
import ProductOptionsHeader from './ProductOptionsHeader';

const Options = () => {
  const [height, setHeight] = useState();
  const headerHeight = useRef();

  useEffect(() => {
    const hei = headerHeight.current.offsetHeight;
    setHeight(hei);
  }, []);

  return (
    <>
      <ProductOptionsHeader headerRef={headerHeight} />
      <ProductOptionsBody height={height} />
    </>
  );
};

export default Options;
