import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getPresignedUrl, uploadToAwsS3 } from '../../../../../../service/s3';
import GalleryCard from '../../../../Products/godown/Mainbar/ProductsPages/gallery/GalleryCard';
import EditTableView from '../../../../../utilComponents/EditTableView';
import FileInput from '../../../../../utilComponents/FileInput';
import { postMediaAction } from '../../../../../../reducer_action/product';

import {
  Box,
  Button,
  Grid,
  Typography,
  Select,
  MenuItem,
  Modal,
} from '@mui/material';
import CenterBox from '../../../../../utilComponents/CenterBox';
import { toast } from 'react-toastify';

const Gallery = () => {
  const params = useParams();
  const dispatch = useDispatch();

  const currentOption = useSelector((state) =>
    state.option.find((x) => x.id === +params.option)
  );
  const mediaTypes = useSelector((state) =>
    state.productUtil.enums.find((x) => x.name === 'mediatype')
  ).value.split('|');
  const [optionValueId, setOptionValueId] = useState(
    currentOption.values[0].id
  );
  const [optionModal, setOptionModal] = useState(false);

  /// media post
  const [mediaTitle, setMediaTitle] = useState();
  const [mediaType, setMediaType] = useState(mediaTypes[0]);
  const [fileName, setFileName] = useState('');
  const [file, setFile] = useState(null);

  const resetState = () => {
    setOptionValueId(optionValueId);

    setMediaTitle('');
    setMediaType(mediaTypes[0]);
    setFileName('');
    setFile(null);
  };

  const handlePostMedia = async () => {
    // console.log('add media');
    try {

      const uploadUrl = await getPresignedUrl(fileName);

      const resultOfUpload = await uploadToAwsS3(uploadUrl, file);
      if (resultOfUpload.ok) {
        const mediaUrl = resultOfUpload.url.split('?')[0];
        dispatch(
          postMediaAction('optionMedia', {
            itemOptionValueId: optionValueId,
            mediaType,
            mediaTitle,
            mediaUrl,
          })
        );
      } else {
        toast.error('upload failed');
      }
      resetState();
      setOptionModal(false);
    } catch (error) {
      toast.error('Error in uploading media');
      resetState();
      setOptionModal(false);
    }
  };

  return (
    <Grid container item xs={12}>
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Select
            value={optionValueId}
            onChange={(e) => {
              setOptionValueId(e.target.value);
              // setOptionValue(findOptionValue(e.target.value));
            }}
          >
            {currentOption.values.map((x) => {
              return <MenuItem value={x.id}>{x.optionValueName}</MenuItem>;
            })}
          </Select>
          <Button variant='contained' onClick={() => setOptionModal(true)}>
            add
          </Button>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='h4'> Option Media</Typography>
      </Grid>
      <Grid item xs={12}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            flexWrap: 'wrap',
            gap: '1rem',
          }}
        >
          {currentOption.values
            .find((x) => x.id === optionValueId)
            .media.map((x) => {
              return (
                <GalleryCard
                  title={`${x.mediaTitle}-${x.mediaType.toLowerCase()}`}
                  img={x.mediaUrl}
                  type='optionMedia'
                  id={x.id}
                  mediaType={x.mediaType}
                />
              );
            })}
        </Box>
      </Grid>
      <Modal open={optionModal}>
        <Box>
          <CenterBox>
            <Grid container>
              <Grid item xs={12}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    variant='contained'
                    onClick={() => {
                      setOptionModal(false);
                      resetState();
                    }}
                  >
                    close
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12} sx={{ marginTop: '2rem' }}>
                <Typography align='center' variant='h4'>
                  Add Option Media
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ marginTop: '2rem' }}>
              <EditTableView
                title='media title'
                type='text'
                value={mediaTitle}
                onChange={(e) => setMediaTitle(e.target.value)}
              />
              <EditTableView
                title='media type'
                type='select'
                selectValues={mediaTypes}
                value={mediaType}
                onChange={(e) => setMediaType(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sx={{ marginTop: '1rem' }}>
              <FileInput
                file={file}
                setFile={setFile}
                fileName={fileName}
                setFileName={setFileName}
              />
            </Grid>
            <Grid item xs={12} sx={{ marginTop: '1rem' }}>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button variant='contained' onClick={handlePostMedia}>
                  save
                </Button>
              </Box>
            </Grid>
          </CenterBox>
        </Box>
      </Modal>
    </Grid>
  );
};

export default Gallery;
