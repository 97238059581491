import React from 'react'
import { Box, Typography } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { underscoreSplitter } from '../../utilFunctions'

const Tabs = ({ tabs }) => {
  const useQuery = () => new URLSearchParams(useLocation().search)
  const query = useQuery()
  const action = query.get('action')

  return (
    <>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', marginTop: '1rem' }}>
        {tabs.map(tab => {
          return (
            <Box
              key={`${tab.name}-${Math.random()}`}
              onClick={tab.onClick}
              sx={{
                borderBottom: action === tab.name ? '1px solid black' : null,
                cursor: 'pointer',
                padding: '0.3rem 0.7rem',
                marginBottom: '0.5rem',
              }}
            >
              <Typography variant='h6' sx={{ textTransform: 'capitalize', letterSpacing: '1px' }} >{underscoreSplitter(tab.name)}</Typography>
            </Box>
          )
        })}

      </Box>
    </>
  )
}

export default Tabs