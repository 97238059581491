import React, { useState } from 'react'
import EditTableView from '../../../../../../utilComponents/EditTableView'
import { Grid, Box, Button } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { updateItemSizeTableAction } from '../../../../../../../reducer_action/product'

const EditSize = ({ sizeTab: size, product }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const rangeLookup = useSelector((state) => state.productUtil.enums.find((x) => x.name === 'range_lookup')).value.split('|');

  const [sizeTab, setSizeTab] = useState({ ...size })
  const handleSubmit = () => {
    dispatch(updateItemSizeTableAction(product.id, sizeTab, sizeTab.id));
    navigate('?action=view')
  };

  return (
    <>
      <Grid container item xs={12}>
        <EditTableView
          title='size title'
          value={sizeTab.sizeTitle}
          onChange={(e) => setSizeTab({ ...sizeTab, sizeTitle: e.target.value })}
          type='text'
          compareValue={sizeTab.sizeTitle}
        />
        <EditTableView
          title='item width'
          value={sizeTab.itemWidth}
          onChange={(e) => setSizeTab({ ...sizeTab, itemWidth: +e.target.value })}
          type='text'
          compareValue={sizeTab.itemWidth}
        />
        <EditTableView
          title='item length'
          value={sizeTab.itemLength}
          onChange={(e) => setSizeTab({ ...sizeTab, itemLength: +e.target.value })}
          type='text'
          compareValue={sizeTab.itemLength}
        />
        <EditTableView
          title='increment quantity'
          value={sizeTab.incrementQuantity}
          onChange={(e) => setSizeTab({ ...sizeTab, incrementQuantity: +e.target.value })}
          type='text'
          compareValue={sizeTab.incrementQuantity}
        />
        <EditTableView
          title='portrait'
          value={sizeTab.portrait}
          onChange={(e) =>
            setSizeTab({ ...sizeTab, portrait: e.target.checked })
          }
          type='switch'
          compareValue={sizeTab.portrait}
        />
        <EditTableView
          title='weight'
          value={sizeTab.weight}
          onChange={(e) =>
            setSizeTab({ ...sizeTab, weight: e.target.value })
          }
          type='text'
          compareValue={sizeTab.weight}
        />
        <EditTableView
          title='setup cost'
          value={sizeTab.setupCost}
          onChange={(e) =>
            setSizeTab({ ...sizeTab, setupCost: +e.target.value })
          }
          type='text'
          compareValue={sizeTab.setupCost}
        />
        <EditTableView
          title='jobs per sheet'
          value={sizeTab.jobsPerSheet}
          onChange={(e) => setSizeTab({ ...sizeTab, jobsPerSheet: +e.target.value })}
          type='text'
          compareValue={sizeTab.jobsPerSheet}
        />
        <EditTableView
          title='custom_size'
          value={sizeTab.customSize}
          onChange={(e) => setSizeTab({ ...sizeTab, customSize: e.target.checked })}
          type='switch'
          compareValue={sizeTab.customSize}
        />
        <EditTableView
          title='price range lookup'
          value={sizeTab.priceRangeLookup}
          onChange={(e) => setSizeTab({ ...sizeTab, priceRangeLookup: e.target.value })}
          type='select'
          selectValues={rangeLookup}
          compareValue={sizeTab.priceRangeLookup}
        />
        <EditTableView
          title='sort'
          value={sizeTab.sort}
          onChange={(e) => setSizeTab({ ...sizeTab, sort: e.target.value })}
          type='text'
          compareValue={sizeTab.sort}
        />
        <EditTableView
          title='default value'
          value={sizeTab.defaultValue}
          onChange={(e) => setSizeTab({ ...sizeTab, defaultValue: e.target.checked })}
          type='switch'
          compareValue={sizeTab.defaultValue}
        />
        <EditTableView
          title='status'
          value={sizeTab.status}
          onChange={(e) => setSizeTab({ ...sizeTab, status: e.target.checked })}
          type='switch'
          compareValue={sizeTab.status}
        />
      </Grid>
      <Grid item xs={12} sx={{ marginTop: '1.5rem' }}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant='contained' onClick={handleSubmit}>save</Button>
        </Box>
      </Grid>
    </>
  )
}

export default EditSize         