import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import CenterBox from '../../../../../../utilComponents/CenterBox';
import {
  Select,
  MenuItem,
  Box,
  Button,
  Modal,
  Typography,
} from '@mui/material';
import EditTableView from '../../../../../../utilComponents/EditTableView';
import FileInput from '../../../../../../utilComponents/FileInput';
import GalleryCard from './GalleryCard';
import { getPresignedUrl, uploadToAwsS3 } from '../../../../../../../service/s3';
import { postMediaAction } from '../../../../../../../reducer_action/product';
import { toast } from 'react-toastify';

const Gallery = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const [selectMedia, setSelectMedia] = useState('itemMedia');

  const [itemMediaModal, setItemMediaModal] = useState(false);
  const [sizeModal, setSizeModal] = useState(false);

  const itemSize = useSelector(
    (state) => state.product.find((product) => product.id === +params.item).size
  );

  const itemSizeNames = itemSize.map((item) => {
    return {
      name: item.sizeTitle,
      id: item.id,
    };
  });

  const itemMedia = useSelector((state) => {
    const item = state.product.find((product) => product.id === +params.item);
    return { name: item.name, media: item.media };
  });

  const itemSizeMedia = useSelector((state) => {
    const itemSizes = state.product.find(
      (product) => product.id === +params.item
    ).size;

    const sizeMedia = itemSizes.map((size) => {
      const media = size.media.map((media) => {
        return {
          name: size.sizeTitle,
          media,
        };
      });
      return media;
    });
    return sizeMedia;
  }).flat();

  const mediaTypes = useSelector((state) => state.productUtil.enums)
    .find((x) => x.name === 'mediatype')
    .value.split('|');

  // form state
  const [sizeId, setSizeId] = useState(itemSizeNames[0]?.id || null);

  const [mediaTitle, setMediaTitle] = useState('');
  const [mediaType, setMediaType] = useState(mediaTypes[0]);
  const [fileName, setFileName] = useState('');
  const [file, setFile] = useState(null);

  const stateReset = () => {
    setSizeId(itemSizeNames[0]?.id);
    setMediaTitle('');
    setMediaType(mediaTypes[0]);
    setFile(null);
    setFileName('');
  };

  const handleAddMedia = async (type) => {
    try {

      const uploadUrl = await getPresignedUrl(fileName);
      const resultOfUpload = await uploadToAwsS3(uploadUrl, file);

      if (resultOfUpload.ok) {
        // dispatch the action here
        const mediaUrl = resultOfUpload.url.split('?')[0];
        dispatch(
          postMediaAction(
            type,
            {
              mediaType,
              mediaTitle,
              mediaUrl,
              sizeId,
              itemId: +params.item,
            },
            +params.item
          )
        );
      } else {
        toast.error('Error in uploading file');
      }
      stateReset();
      setItemMediaModal(false);
      setSizeModal(false);
    } catch (error) {
      toast.error('error in uploading media');
      stateReset();
      setItemMediaModal(false);
      setSizeModal(false);
    }
  };

  const handleAddMediaClick = () => {
    stateReset();
    if (selectMedia === 'itemMedia') {
      setItemMediaModal(true);
    } else if (selectMedia === 'sizeMedia' && itemSize.length > 0) {
      setSizeModal(true);
    }
  };

  return (
    <Grid container>
      {/* <Grid item xs={12}>
        <Typography variant='h4'>Item Media</Typography>
      </Grid> */}
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Select
            value={selectMedia}
            onChange={(e) => setSelectMedia(e.target.value)}
          >
            <MenuItem value='itemMedia'>item media</MenuItem>
            <MenuItem value='sizeMedia'>size media</MenuItem>
          </Select>
          <Button variant='contained' onClick={handleAddMediaClick}>
            add
          </Button>
        </Box>
      </Grid>
      <Grid item xs={12}>
        {/* <GalleryCard media={itemSizeMedia} /> */}
        <Box
          sx={{
            borderBottom: '1px solid ',
            paddingBottom: '0.5rem',
            borderColor: 'secondary.underLine',
            marginBottom: '1rem',
          }}
        >
          <Typography variant='h4'>Item media</Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }}>
          {itemMedia?.media.map((media) => {
            return (
              <GalleryCard
                key={media.id}
                img={media.mediaUrl}
                title={`${media.mediaTitle}--${media.mediaType.toLowerCase()}`}
                id={media.id}
                type='itemMedia'
              />
            );
          })}
        </Box>
        {itemSize.length > 0 && (
          <Grid item xs={12} sx={{ marginTop: '2rem' }}>
            {/* <GalleryCard media={itemSizeMedia} /> */}
            <Box
              sx={{
                borderBottom: '1px solid ',
                paddingBottom: '0.5rem',
                borderColor: 'secondary.underLine',
                marginBottom: '1rem',
              }}
            >
              <Typography variant='h4'>Item size media</Typography>
            </Box>
            <Box sx={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }}>
              {itemSizeMedia.map((media) => {
                return (
                  <GalleryCard
                    key={media.media.id}
                    img={media.media.mediaUrl}
                    title={`${media.name
                      }--${media.media.mediaType.toLowerCase()}--${media.media.mediaTitle
                      }`}
                    id={media.media.id}
                    type='sizeMedia'
                  />
                );
              })}
            </Box>
          </Grid>
        )}
      </Grid>
      <Modal
        open={sizeModal}
        onClose={() => {
          setSizeModal(false);
          stateReset();
        }}
      >
        <React.Fragment>
          <CenterBox>
            <Typography variant='h4' align='center'> Add Size Media </Typography>
            <EditTableView
              title='select size'
              type='select'
              selectValues={itemSizeNames}
              value={sizeId}
              onChange={(e) => setSizeId(e.target.value)}
            />
            <EditTableView
              title='media title'
              type='text'
              value={mediaTitle}
              onChange={(e) => setMediaTitle(e.target.value)}
            />
            <EditTableView
              title='media type'
              type='select'
              selectValues={mediaTypes}
              value={mediaType}
              onChange={(e) => setMediaType(e.target.value)}
            />
            <Box sx={{ marginTop: '1rem' }}>
              <FileInput
                file={file}
                setFile={setFile}
                fileName={fileName}
                setFileName={setFileName}
              />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '1rem', }} >
              <Button
                onClick={() => handleAddMedia('sizeMedia')}
                variant='contained'
              >
                save
              </Button>
            </Box>
          </CenterBox>
        </React.Fragment>
      </Modal>
      <Modal
        open={itemMediaModal}
        onClose={() => {
          setItemMediaModal(false);
          stateReset();
        }}
      >
        <React.Fragment>
          <CenterBox>
            <Typography variant='h4' align='center'>
              Add Item Media
            </Typography>
            <EditTableView
              title='media title'
              type='text'
              value={mediaTitle}
              onChange={(e) => setMediaTitle(e.target.value)}
            />
            <EditTableView
              title='media type'
              type='select'
              selectValues={mediaTypes}
              value={mediaType}
              onChange={(e) => setMediaType(e.target.value)}
            />
            <Box sx={{ marginTop: '1rem' }}>
              <FileInput
                file={file}
                setFile={setFile}
                fileName={fileName}
                setFileName={setFileName}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '1rem',
              }}
            >
              <Button
                onClick={() => handleAddMedia('itemMedia')}
                variant='contained'
              >
                save
              </Button>
            </Box>
          </CenterBox>
        </React.Fragment>
      </Modal>
      {/* the table showing all the item and size media of the item  */}
    </Grid>
  );
};

export default Gallery;
