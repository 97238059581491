import React from "react";
import Grid from "@mui/material/Grid";

const MainBar = ({ children }) => {
  return (
    <Grid container sx={{ maxHeight: '100vh', overflow: 'scroll' }} >
      {children}
    </Grid>
  );
};

export default MainBar;
