import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Switch, Typography, Select, MenuItem, Box, Pagination } from '@mui/material'
import ClientOrderCard from './ClientOrderCard'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const ClientOrderBody = ({ demandOrder, status, itemName, headerHeight, currentPage, setCurrentPage, limit, setLimit, orderByDesc, setOrderByDesc }) => {
  const totalPages = Math.ceil(demandOrder.count / limit);
  const footerRef = useRef()

  const [footerHeight, setFooterHeight] = useState(0)

  const mainSideBar = useSelector(state => state.util.mainSideBar)
  useEffect(() => {
    const height = footerRef.current.offsetHeight;
    setFooterHeight(height);
  }, [])


  return (
    <Box sx={{ padding: '0 1rem', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '100%' }} >
      <Box
        sx={{
          height: `calc(100vh - ${headerHeight}px - ${footerHeight}px)`,
          overflow: 'scroll',
          scrollbarWidth: 'none',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          width: '100%'
        }}
      >
        {(demandOrder && demandOrder.orders.length > 0) ?
          (
            demandOrder.orders.map((order) => {
              return <ClientOrderCard key={order.id} order={order} currentPage={currentPage} limit={limit} orderByDesc={orderByDesc} status={status} itemName={itemName} />
            })
          ) : (
            <Typography sx={{ marginTop: '2rem' }} variant='h3' align='center'>No orders</Typography>
          )}
      </Box>
      <Box ref={footerRef} sx={{ padding: '0.5rem', display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }} >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {!mainSideBar && <Typography sx={{ marginRight: '0.5rem' }}>limit:</Typography>}
          <Select variant='standard' value={limit} onChange={(e) => {
            setLimit(e.target.value)
            setCurrentPage(1)
          }}>
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={25}>25</MenuItem>
          </Select>

        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Switch checked={orderByDesc} onChange={(e) => setOrderByDesc(e.target.checked)} />
          {orderByDesc ? <Typography>Desc</Typography> : <Typography>Asc</Typography>}
        </Box>
        <Box>
          {!mainSideBar ?
            <Pagination
              size='small'
              count={totalPages}
              variant='outlined'
              shape='rounded'
              onChange={(e, value) => setCurrentPage(value)}
            />
            : (
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Box
                  onClick={() => {
                    if (currentPage > 1) {
                      setCurrentPage(+currentPage - 1)
                    } else {
                      setCurrentPage(1)
                    }
                  }}
                  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                  <ArrowBackIosIcon />
                </Box>
                <Box sx={{ border: '1px solid black', borderRadius: '10px', padding: '0rem 0.4rem' }}>
                  <Typography>{currentPage}</Typography>
                </Box>
                <Box
                  onClick={() => {
                    if (currentPage < totalPages) {
                      setCurrentPage(+currentPage + 1)
                    }
                  }}
                  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                  <ArrowForwardIosIcon />
                </Box>
              </Box>
            )
          }
        </Box>
      </Box>
    </Box>
  )
}

export default ClientOrderBody