import React, { useState } from 'react'
import { FormControlLabel, Switch, Box, Typography, TextField, Select, MenuItem, Pagination } from '@mui/material'
import { useSelector } from 'react-redux'

const OrdersFooter = ({ setItemName, orderByDesc, setOrderByDesc, setCurrentPage, clientDemandOrders, status, setStatus, limit, setLimit, currentPage }) => {

  const [tempItemName, setTempItemName] = useState('')
  const orderStatusList = useSelector(state => state.productUtil.enums.find(x => x.name === 'order_status_type').value.split('|'))

  return (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', }} >
      <Typography>Total: {clientDemandOrders.count}</Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <FormControlLabel control={<Switch checked={orderByDesc} onChange={(e) => setOrderByDesc(e.target.checked)} />
        } label='Desc' />
      </Box>
      <TextField value={tempItemName} placeholder='item name'
        onChange={(e) => setTempItemName(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            setItemName(tempItemName)
          }
        }}
      />
      <Select value={status} onChange={(e) => { setStatus(e.target.value) }} >
        <MenuItem value='ALL'>ALL</MenuItem>
        {orderStatusList.map(stat => {
          return <MenuItem key={stat} value={stat}>{stat}</MenuItem>
        })}
      </Select>
      <Select
        value={limit}
        onChange={(e) => { setLimit(e.target.value) }}
      >
        <MenuItem value={1}>1</MenuItem>
        <MenuItem value={5}>5</MenuItem>
        <MenuItem value={10}>10</MenuItem>
        <MenuItem value={25}>25</MenuItem>
      </Select>
      {Math.ceil(+clientDemandOrders.count / limit) > 1 ?
        <Pagination
          page={currentPage}
          size='small'
          count={Math.ceil(+clientDemandOrders.count / limit)}
          variant='outlined'
          onChange={(e, value) => setCurrentPage(value)}
        />
        :
        <Typography>No pages</Typography>
      }
    </Box>
  )
}

export default OrdersFooter