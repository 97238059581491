import React, { useEffect, useRef, useState } from 'react'
import { Grid, Box, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import OrderGuideLineCard from './OrderGuidelineCard'

const OrderGuidelineBody = ({ headerHeight, trackingUnitId }) => {
  const footerRef = useRef()

  const orderUnit = useSelector(state => state.productUtil.orderUnit)

  const [footerHeight, setFooterHeight] = useState(0)

  useEffect(() => {
    const height = footerRef.current.offsetHeight
    setFooterHeight(height)
  }, [])

  console.log({ headerHeight, footerHeight })

  return (
    <Grid container item xs={12} >
      {/* body section */}
      <Grid item xs={12}
        sx={{
          height: `calc(100vh - ${headerHeight}px - ${footerHeight}px)`,
          overflow: 'scroll',
          scrollbarWidth: 'none',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        {orderUnit.length > 0 && orderUnit.filter(x => trackingUnitId === 0 ? true : x.trackingUnitId === trackingUnitId).sort((a, b) => a.id - b.id).map(oUnit => {
          return (
            <Grid container sx={{ padding: '0rem 1rem 1rem 1rem' }}>
              <OrderGuideLineCard orderUnit={oUnit} />
            </Grid>
          )
        })}
      </Grid>
      {/* footer section */}
      <Grid item xs={12} ref={footerRef}></Grid>
    </Grid>
  )
}

export default OrderGuidelineBody