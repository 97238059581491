import React from 'react'
import { Divider, TableCell, Table, TableContainer, TableRow, TableHead, TableBody, Box, Grid, Typography } from '@mui/material'
import ShipmentItemRow from './ShipmentItemRow'

// a row contains a unique username and their address
const ShipmentRow = ({ row, index }) => {
  const clientEmail = row[0]?.clientEmail
  const addressName = row[0]?.clientAddress?.name
  const address = row[0]?.clientAddress?.address
  const pincode = row[0]?.clientAddress?.pincode

  return (
    <Grid className='pageBreak' container sx={{ padding: '1.5rem', border: '1px solid black', borderRadius: '20px' }}>
      <Grid item xs={12}>
        <Typography variant='h4'>
          {clientEmail}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', columnGap: '3rem' }}>
          <Box sx={{ maxWidth: '15rem' }}>
            <Typography variant='body1'> {address} </Typography>
          </Box>
          <Box>
            <Typography variant='h6'> {addressName}</Typography>
            <Typography variant='h6'>pincode: {pincode}</Typography>
          </Box>
        </Box>
      </Grid>
      <Divider />
      <Grid item xs={12} sx={{ marginTop: '2rem' }}>
        <TableContainer className='' sx={{ width: '100%' }}>
          <Table sx={{ width: '100%' }}>
            <TableHead sx={{ width: '100%' }}>
              <TableRow className=''>
                <TableCell><Typography>Order Id</Typography></TableCell>
                <TableCell><Typography>Item</Typography></TableCell>
                <TableCell sx={{ minWidth: '10rem' }} className='width10'><Typography>Specs</Typography></TableCell>
                <TableCell><Typography>Add.info</Typography></TableCell>
                <TableCell className='noPrint border'><Typography>media</Typography></TableCell>
                <TableCell className='noPrint'><Typography>Action</Typography></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {row.length > 0 && row.map((item, index) => {
                return <ShipmentItemRow item={item} index={index} key={`${item.id}-${item.quantity}`} />
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  )
}

export default ShipmentRow