import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Grid, Box, Typography, } from '@mui/material'
import { setUtilAction } from '../../../../../reducer_action/util';

const ClientOrderCard = ({ order, currentPage, limit, orderByDesc, status, itemName }) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()

  const product = useSelector(state => state.product)

  const findProductNameFromId = (itemId) => {
    const name = product.find((product) => product.id === itemId)?.name;
    return name;
  };

  const mainSideBar = useSelector(state => state.util.mainSideBar)

  const module = location.pathname.split('/')[1]
  const findDefaultPage = (module) => {
    switch (module) {
      case 'asx':
        return 'view';
      case 'admin':
        return 'view';
      case 'godown':
        return 'view';
      default:
        return;
    }
  };
  const defaultPage = findDefaultPage(module);

  return (
    <Grid
      container
      sx={{ padding: '1rem 1rem', cursor: 'pointer', justifyContent: 'space-between', alignItems: 'center', backgroundColor: 'background.cardBackground', margin: '1rem 0', width: '100%' }}
      onClick={() => {
        dispatch(
          setUtilAction({
            mainSideBar: true,
            // actionQuery: defaultPage
          })
        );
        navigate(`/${module}/orders/${order.id}?action=${defaultPage}&currentPage=${currentPage}&limit=${limit}&orderByDesc=${orderByDesc}&status=${status}&itemName=${itemName}`)
      }}
    >
      <Grid item xs={mainSideBar ? 9 : 3}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '3px',
            maxWidth: '60%',
            minWidth: '10%',
          }}
        >
          <Typography
            noWrap={true}
            variant='h6'
            sx={{ textTransform: 'capitalize' }}
          >
            {order.clientEmail}
          </Typography>

          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Typography variant='caption'>{order.orderDate.toLocaleString().slice(0, 10)}</Typography>
            <Typography variant='caption'>Order Id: {order.id}</Typography>
            {order.returnOrder && <Typography sx={{ color: 'red' }} variant='caption'>RETURN ORDER</Typography>}
          </Box>
        </Box>
      </Grid>
      {/* the extra information */}
      {!mainSideBar && (
        <Grid item xs={6}>
          <Box sx={{ display: 'flex' }}>
            <Box>
              <Typography variant='h6' sx={{ paddingRight: '.5rem' }}>Items:</Typography>
              <Typography align='left'>
                {order.demandOrderItem.map((item) => `${findProductNameFromId(item.itemId)}-(${item.status.toLowerCase()})`).join(', ')}
              </Typography>
            </Box>
          </Box>
        </Grid>
      )}
      <Grid item xs={3}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Box
            sx={{
              backgroundColor: 'background.activeCardBackground',
              padding: '0.4rem 0.6rem',
              maxWidth: 'max-content',
            }}
          >
            <Typography>{order.orderType}</Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}

export default ClientOrderCard  