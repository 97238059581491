import axios from 'axios'
import userService from './user';
import { toast } from 'react-toastify'

const baseUrl = process.env.REACT_APP_PROXY_BACKEND

const getToken = async () => {
  try {
    const session = await userService.getSession()
    const token = session && session.idToken
    return token.jwtToken
  } catch (error) {
    toast.error('there was an error in getting token')
  }
}

export const postDemandOrder = async (demandOrder, addressId, clientData, returnOrder, fulfillerUsername) => {
  try {
    const token = await getToken()
    if (!token) return

    const response = await axios({
      method: 'post',
      url: `${baseUrl}/demandOrder`,
      headers: {
        Authorization: `${token}`,
      },
      data: { demandOrder, clientData, addressId, returnOrder, fulfillerUsername }
    })
    return response.data
  } catch (error) {
    toast.error(`there was an error posting the demand order, ${error?.response.data}`)
  }
}


export const getAllDemandOrder = async (data) => {
  try {
    const token = await getToken()
    if (!token) return

    const response = await axios({
      method: 'get',
      url: `${baseUrl}/demandOrder?status=${data.status}&currentPage=${data.currentPage}&limit=${data.limit}&itemName=${data.itemName}&orderByDesc=${data.orderByDesc}&cognitoGroup=${data.cognitoGroup}&orderId=${data.orderId}&username=${data.username}&itemId=${data.itemId}&itemSizeId=${data.itemSizeId}&returnOrder=${data.returnOrder}`,
      headers: {
        Authorization: `${token}`,
      },
    })
    return response.data
  } catch (error) {
    // console.log(error)
    toast.error(`there was an error getting the demand orders, ${error?.response.data}`)
  }
}
export const getOneDemandOrder = async (data) => {
  try {
    const token = await getToken()
    if (!token) return

    const response = await axios({
      method: 'get',
      url: `${baseUrl}/demandOrder/${data.orderId}`,
      headers: {
        Authorization: `${token}`,
      },
    })
    return response.data
  } catch (error) {
    toast.error(`there was an error getting a demand order, ${error?.response.data}`)
  }
}

export const updateOrderItemStatus = async (data) => {
  try {
    const token = await getToken()
    if (!token) return

    const response = await axios({
      method: 'put',
      url: `${baseUrl}/demandOrder/${data.orderId}/${data.orderItemId}?status=${data.status}`,
      headers: {
        Authorization: `${token}`,
      },
      data: data
    })
    return response.data

  } catch (error) {
    console.log(error)
    toast.error(`there was an error in updating the order item status, ${error?.response.data}`)
  }
}

export const updateOrderStatus = async (data) => {
  try {
    const token = await getToken()
    if (!token) return

    const response = await axios({
      method: 'put',
      url: `${baseUrl}/demandOrder/${data.orderId}?status=${data.status}`,
      headers: {
        Authorization: `${token}`,
      },
      data: data
    })

    return response.data
  } catch (error) {
    toast.error(`there was an error in updating the order status, ${error?.response.data}`)
  }
}
