import React, { useState, useEffect, useRef } from 'react'
import AsxShipmentSidebarBody from './AsxShipmentSidebarBody'
import AsxShipmentSidebarHeader from './AsxShipmentSidebarHeader'
import { Grid } from '@mui/material'

import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { getAllProductsAction } from '../../../../../reducer_action/product'
import { getAllOptionsAction } from '../../../../../reducer_action/options'
import { getAllProductUtilDataAction } from '../../../../../reducer_action/productUtil'

import { getAllShipments } from '../../../../../service/shipments'

const AsxShipmentSidebar = () => {
  const headerRef = useRef();
  const dispatch = useDispatch()
  const location = useLocation()

  const useQuery = () => new URLSearchParams(location.search)
  const query = useQuery()
  const enums = useSelector(state => state.productUtil.enums)
  const user = useSelector(state => state.user)

  const [headerHeight, setHeaderHeight] = useState(0);
  // header
  const [itemName, setItemName] = useState(query.get('itemName'))
  const [status, setStatus] = useState(query.get('status'))
  // body/footer
  const [currentPage, setCurrentPage] = useState(query.get('currentPage'))
  const [limit, setLimit] = useState(query.get('limit'))
  const [orderByDesc, setOrderByDesc] = useState(Boolean(query.get('orderByDesc') === 'true'))
  // demandOrders
  const [shipments, setShipments] = useState({})

  useEffect(() => {
    dispatch(getAllProductsAction());
    dispatch(getAllOptionsAction());
    dispatch(getAllProductUtilDataAction());
  }, [dispatch]);

  useEffect(() => {
    enums && (async () => {
      const data = { currentPage, limit, itemName, status, orderByDesc, username: user.username }
      const getShipments = await getAllShipments(data)
      console.log(getShipments, 'getShipments')
      setShipments(getShipments)
    })()
  }, [currentPage, enums, limit, itemName, status, orderByDesc, user.username])

  useEffect(() => {
    const headerHeight = headerRef.current && headerRef?.current.offsetHeight;
    setHeaderHeight(headerHeight);
  }, [shipments])

  return (
    <Grid container item xs={12}>
      <Grid item xs={12}>
        {enums &&
          <AsxShipmentSidebarHeader
            headerRef={headerRef}
            itemName={itemName}
            setItemName={setItemName}
            status={status}
            setStatus={setStatus}
          />
        }
      </Grid>
      <Grid item xs={12}>
        {headerHeight && shipments?.shipments && shipments?.shipments.length > 0 &&
          <AsxShipmentSidebarBody
            headerHeight={headerHeight}
            // status
            status={status}
            itemName={itemName}
            // footer
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            limit={limit}
            setLimit={setLimit}
            orderByDesc={orderByDesc}
            setOrderByDesc={setOrderByDesc}
            // shipments
            shipments={shipments}
          />
        }
      </Grid>
    </Grid>
  )
}

export default AsxShipmentSidebar