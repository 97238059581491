import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Box, OutlinedInput, InputAdornment, MenuItem, Select, Grid } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'

const ClientOrderHeader = ({ headerRef, setStatus, status, setItemName, itemName }) => {
  const [tempItemName, setTempItemName] = useState('')

  const orderStatus = useSelector(state => state.productUtil.enums.find(x => x.name === 'order_status_type')?.value.split('|'))

  useEffect(() => {
    const searchTimer = setTimeout(() => {
      setItemName(tempItemName)
    }, 1000)
    return () => clearTimeout(searchTimer)
  }, [tempItemName, setItemName])

  return (
    <Grid ref={headerRef} container item xs={12} sx={{ padding: '1rem' }}>
      <Grid item xs={12}>
        {/* this search is for items */}
        <Box>
          <OutlinedInput
            id='standard-search'
            placeholder={'Search by item name'}
            value={tempItemName}
            onChange={(e) => setTempItemName(e.target.value)}
            fullWidth
            endAdornment={
              <InputAdornment
                sx={{ cursor: 'pointer' }}
                position='end'
                onClick={() => { setItemName(tempItemName) }}
              >
                <SearchIcon sx={{ color: 'black' }} />
              </InputAdornment>
            }
            sx={{ margin: '0' }}
          />
        </Box>
      </Grid>
      {/* todo : this select is at the order level and needs to make the status logic at the server side  */}
      <Grid item xs={12} sx={{ marginTop: '1rem' }} >
        <Select value={status} sx={{ width: '100%' }} onChange={(e) => setStatus(e.target.value)} >
          <MenuItem value={'ALL'}>all</MenuItem>
          {orderStatus.map(x => <MenuItem value={x}>{x.toLowerCase()}</MenuItem>)}
        </Select>
      </Grid>
    </Grid>
  )
}

export default ClientOrderHeader  