import { Auth } from 'aws-amplify';
import userService from '../service/user.js';
import actionTypes from './actionTypes.js';
import { toast } from 'react-toastify';
import { createReducer, createAction } from '@reduxjs/toolkit';

const setUserDispatch = createAction(actionTypes.SET_USER);
const removeUserDispatch = createAction(actionTypes.REMOVE_USER);

export const getUserAction = () => {
  return async (dispatch) => {
    try {
      const session = await Auth.currentSession();
      const dbUser = await userService.getOneUser(session.idToken.payload.sub)

      if (!session) return null;

      const newUser = { ...dbUser }
      dispatch(setUserDispatch(newUser))
    } catch (error) {
      toast.error('error in getting the current user')
    }
  };
};

export const postUserAddressAction = (data) => {
  return async (dispatch) => {
    try {
      const session = await Auth.currentSession();
      const username = session.idToken.payload.sub
      const newAddress = await userService.postUserAddress(username, data);
      const dbUser = await userService.getOneUser(username)
      console.log(dbUser, session, newAddress)

      if (!session) return null;

      const newUser = { ...dbUser }
      dispatch(setUserDispatch(newUser))
    } catch (error) {
      console.log(error);
    }
  };
}

export const editUserAddressAction = (data) => {
  return async (dispatch) => {
    const session = await Auth.currentSession();
    const username = session.idToken.payload.sub
    await userService.editUserAddress(username, data);

    // get the user again
    const dbUser = await userService.getOneUser(username)

    if (!session) return null;

    const newUser = { ...dbUser }

    dispatch(setUserDispatch(newUser))
  }
}

export const inactivateUserAddressAction = (data) => {
  return async (dispatch) => {
    const session = await Auth.currentSession();
    const username = session.idToken.payload.sub
    await userService.inactivateUserAddress(username, data);

    // get the user again
    const dbUser = await userService.getOneUser(username)

    if (!session) return null;

    const newUser = { ...dbUser }
    dispatch(setUserDispatch(newUser))
  }
}


export const editUserAction = (data) => {
  return async (dispatch) => {
    const session = await Auth.currentSession();
    const username = session.idToken.payload.sub

    await userService.updateUser(username, data)
    const dbUser = await userService.getOneUser(username)
    console.log({ dbUser, session })

    if (!session) return null;

    const newUser = { ...dbUser }
    dispatch(setUserDispatch(newUser))
  }
}

export const removeUserAction = () => {
  return async (dispatch) => {
    try {
      await Auth.signOut();
      const noUser = {}
      dispatch(setUserDispatch(noUser))
    } catch (error) {
      console.log(error);
    }
  };
};

const userReducer = createReducer({}, (builder) => {
  builder
    .addCase(setUserDispatch, (state, action) => {
      return action.payload
    })
    .addCase(removeUserDispatch, (state, action) => {
      return action.payload
    })
    .addDefaultCase(state => state)
})

export default userReducer;

// const userReducer = (state = {}, action) => {
//   switch (action.type) {
//     case actionTypes.SET_USER:
//       return { ...action.data, };
//     case actionTypes.REMOVE_USER:
//       return { ...action.data, };
//     default:
//       return state;
//   }
// };