import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setUtilAction } from '../../../../../reducer_action/util'
import { Grid, Box, Typography } from '@mui/material'

const OrderGuidelineCard = ({ orderUnit }) => {

  const product = useSelector(state => state.product)
  const mainSideBar = useSelector(state => state.util.mainSideBar)
  const dispatch = useDispatch()
  const navigate = useNavigate()


  const trackingUnits = useSelector(state => state.productUtil.trackingUnit)
  const findTrackingUnitName = (trackingUnitId) => {
    const name = trackingUnits.find(x => x.id === trackingUnitId)?.name
    return name
  }


  const findItemsAndSize = (orderUnitId) => {
    const items = product.filter(item => {
      const currentArray = item.orderGuideline.filter(x => x.orderUnitId === orderUnitId)
      return currentArray.length > 0
    })
    return items.map(item => {
      const st = `${item.name}(`
      const orderUnitSizes = item.orderGuideline.filter(x => x.orderUnitId === orderUnitId).map(x => x.itemSizeId)
      console.log(item.name)
      const sizes = item.size.filter(size => orderUnitSizes.includes(size.id)).map((x) => `${x.sizeTitle}`)
      const neSt = `${st.concat(sizes)})`
      return neSt
    }).join(',   ')
  }


  return (
    <Grid container item xs={12} sx={{ padding: '1rem 1rem', cursor: 'pointer', justifyContent: 'space-between', alignItems: 'center', backgroundColor: 'background.cardBackground', margin: '0', width: '100%' }}
      onClick={() => {
        dispatch(
          setUtilAction({
            mainSideBar: true,
          })
        );
        navigate(`/godown/orderUnit/${orderUnit.id}?action=view`);
      }}
    >
      <Grid item xs={mainSideBar ? 9 : 3}>
        <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '3px', maxWidth: '60%', minWidth: '10%', }} >
          <Typography noWrap={true} variant='h6' sx={{ textTransform: 'capitalize' }} >{orderUnit.name} </Typography>

          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Typography variant='caption'>{findTrackingUnitName(orderUnit.trackingUnitId)} </Typography>
          </Box>
        </Box>
      </Grid>
      {/* the extra information */}
      {!mainSideBar && (
        <Grid item xs={6}>
          <Box sx={{ display: 'flex' }}>
            <Box>
              <Typography>
                {findItemsAndSize(orderUnit.id)}

              </Typography>
            </Box>
          </Box>
        </Grid>
      )}
      <Grid item xs={3}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', flexDirection: 'column', gap: '1rem' }} >
          <Box sx={{ backgroundColor: 'background.activeCardBackground', padding: '0.4rem 0.6rem', maxWidth: 'max-content', }} >
            <Typography>{orderUnit.status ? 'active' : 'inactive'}</Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}

export default OrderGuidelineCard