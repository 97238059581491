import React from 'react';
import { Grid, Box, Typography } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setUtilAction } from '../../../../../reducer_action/util';

const ProductOptionsCard = ({ option }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const mainSideBar = useSelector((state) => state.util.mainSideBar);

  return (
    <Grid
      container
      item
      xs={12}
      sx={{
        padding: '1rem 1rem 1rem 1rem',
        cursor: 'pointer',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: 'background.cardBackground',
        marginTop: '0.08rem',
      }}
      onClick={() => {
        dispatch(setUtilAction({ mainSideBar: true, actionQuery: 'view' }));
        navigate(`/godown/productOptions/${option.id}?action=view`);
      }}
    >
      <Grid item xs={mainSideBar ? 9 : 3}>
        <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '3px', maxWidth: '60%', minWidth: '10%', }} >
          <Typography noWrap={true} variant='h6' sx={{ textTransform: 'capitalize' }} >
            {option.name}
          </Typography>
          <Typography noWrap='true' variant='caption' sx={{ textTransform: 'capitalize' }} >
            Values: {option.values.length}
          </Typography>
        </Box>
      </Grid>
      {/* the extra information */}
      {!mainSideBar && (
        <Grid item xs={6}>
          <Typography variant='caption' sx={{}}>
            {option.values.map((value, index, array) => {
              const length = array.length;
              if (length - 1 === index) {
                return value.optionValueName.concat(' ');
              } else {
                return value.optionValueName.concat(', ');
              }
            })}
          </Typography>
        </Grid>
      )}
      <Grid item xs={3}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Box
            sx={{
              backgroundColor: 'background.activeCardBackground',
              padding: '0.4rem 0.6rem',
              maxWidth: 'max-content',
            }}
          >
            <Typography>{option.status ? 'active' : 'inactive'}</Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ProductOptionsCard;
