import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, Box, Typography, Grid } from '@mui/material';
// import { underscoreSplitter } from '../../../../../../../utilFunctions';

import EditTableView from '../../../../../../../../components/utilComponents/EditTableView';
import { postItemRuleAction } from '../../../../../../../../reducer_action/product';

const Option = ({ ruleType }) => {
  const params = useParams();
  const dispatch = useDispatch();
  const productOption = useSelector((state) => {
    return state.product.find((x) => x.id === +params.item).options;
  });

  const [ruleTitle, setRuleTitle] = useState('');
  const [ruleDescription, setRuleDescription] = useState('');
  const [optionIds, setOptionIds] = useState([]);
  const [hideOptionIds, setHideOptionIds] = useState([]);

  const optionSelectValues = productOption
    .map((option) => {
      const selectValuesOptions = option.values.map((value) => {
        return {
          name: `${option.name}-${value.optionValueName}`,
          id: value.id,
        };
      });
      return selectValuesOptions;
    })
    .flat();

  const handleSubmit = () => {
    console.log({
      ruleTitle,
      ruleDescription,
      optionIds,
      hideOptionIds,
      ruleType,
      itemId: +params.item,
    });

    dispatch(
      postItemRuleAction({
        ruleTitle,
        ruleDescription,
        optionIds,
        hideOptionIds,
        ruleType,
        itemId: +params.item,
      })
    );
  };

  return (
    <React.Fragment>
      <Grid container item xs={12}>
        <Grid item xs={12}>
          <Typography variant='h4'>Option hides option</Typography>
        </Grid>
        <Grid item xs={12}>
          <EditTableView
            title='rule name'
            value={ruleTitle}
            onChange={(e) => setRuleTitle(e.target.value)}
            type='text'
          />
          <EditTableView
            title='rule description'
            value={ruleDescription}
            onChange={(e) => setRuleDescription(e.target.value)}
            type='text'
          />
          <EditTableView
            title='option'
            type='multiSelect'
            value={optionIds}
            onChange={(e) => setOptionIds(e.target.value)}
            selectValues={optionSelectValues}
          />
          <EditTableView
            title='hide option'
            type='multiSelect'
            value={hideOptionIds}
            onChange={(e) => setHideOptionIds(e.target.value)}
            selectValues={optionSelectValues}
          />
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '1rem',
            }}
          >
            <Button variant='contained' onClick={handleSubmit}>
              save
            </Button>
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Option;
