import {
  getAllOptions,
  postItemOptionTable,
  updateItemOptionTable,
  getAllProducts,
  updateItemOptionValueTable,
  postItemOptionValueTable,
  putItemOptionPriceTable,
} from '../service/product';
import { getAllProductUtilData } from '../service/productUtil';
import actionTypes from '../reducer_action/actionTypes';
import { toast } from 'react-toastify';
import { createReducer, createAction } from '@reduxjs/toolkit';
import { getAllProductsDispatch } from './product';
import { getAllProductUtilDispatch } from './productUtil';


export const getAllOptionsDispatch = createAction(actionTypes.GET_ALL_OPTIONS);

export const getAllOptionsAction = () => {

  return async (dispatch) => {
    try {
      const option = await getAllOptions();
      // dispatch({ type: actionTypes.GET_ALL_OPTIONS, data: option, });
      dispatch(getAllOptionsDispatch(option));
    } catch (error) { }
  };
};

export const postItemOptionTableAction = (data) => {
  return async (dispatch) => {
    try {
      await postItemOptionTable(data);

      const products = await getAllProducts();
      const productUtil = await getAllProductUtilData();
      const option = await getAllOptions();
      // dispatch({ type: actionTypes.GET_ALL_PRODUCTS, data: products, });
      // dispatch({ type: actionTypes.GET_ALL_CATEGORIES, data: productUtil, });
      // dispatch({ type: actionTypes.GET_ALL_OPTIONS, data: option, });
      dispatch(getAllProductsDispatch(products))
      dispatch(getAllProductUtilDispatch(productUtil));
      dispatch(getAllOptionsDispatch(option));
    } catch (error) {
      console.log(error);
    }
  };
};

export const updateItemOptionTableAction = (id, data) => {
  return async (dispatch) => {
    try {
      await updateItemOptionTable(id, data);

      const products = await getAllProducts();
      const productUtil = await getAllProductUtilData();
      const option = await getAllOptions();
      // dispatch({ type: actionTypes.GET_ALL_PRODUCTS, data: products, });
      // dispatch({ type: actionTypes.GET_ALL_CATEGORIES, data: productUtil, });
      // dispatch({ type: actionTypes.GET_ALL_OPTIONS, data: option, });
      dispatch(getAllProductsDispatch(products))
      dispatch(getAllProductUtilDispatch(productUtil));
      dispatch(getAllOptionsDispatch(option));
    } catch (error) {
      toast.error(`there was an error updating the option value: ${error.response.data}`);
    }
  };
};

export const updateItemOptionValueTableAction = (id, data) => {
  return async (dispatch) => {
    try {
      await updateItemOptionValueTable(id, data);
      const products = await getAllProducts();
      const productUtil = await getAllProductUtilData();
      const option = await getAllOptions();
      // dispatch({ type: actionTypes.GET_ALL_PRODUCTS, data: products, });
      // dispatch({ type: actionTypes.GET_ALL_CATEGORIES, data: productUtil, });
      // dispatch({ type: actionTypes.GET_ALL_OPTIONS, data: option, });
      dispatch(getAllProductsDispatch(products))
      dispatch(getAllProductUtilDispatch(productUtil));
      dispatch(getAllOptionsDispatch(option));
    } catch (error) {
      toast.error(`there was an error updating the option value: ${error.response.data}`);
    }
  };
};

export const postItemOptionValueTableAction = (data) => {
  return async (dispatch) => {
    // console.log(data);
    try {
      await postItemOptionValueTable(data);

      const products = await getAllProducts();
      const productUtil = await getAllProductUtilData();
      const option = await getAllOptions();
      // dispatch({ type: actionTypes.GET_ALL_PRODUCTS, data: products, });
      // dispatch({ type: actionTypes.GET_ALL_CATEGORIES, data: productUtil, });
      // dispatch({ type: actionTypes.GET_ALL_OPTIONS, data: option, });
      dispatch(getAllProductsDispatch(products))
      dispatch(getAllProductUtilDispatch(productUtil));
      dispatch(getAllOptionsDispatch(option));
    } catch (error) {
      // console.log(error);
      toast.error(`there was an error creating the option value: ${error.response.data}`);
    }
  };
};

export const putItemOptionPriceTableAction = (data, priceListId, optionValueId) => {
  return async (dispatch) => {
    try {
      await putItemOptionPriceTable(data, priceListId, optionValueId);

      const products = await getAllProducts();
      const productUtil = await getAllProductUtilData();
      const option = await getAllOptions();
      // dispatch({ type: actionTypes.GET_ALL_PRODUCTS, data: products, });
      // dispatch({ type: actionTypes.GET_ALL_CATEGORIES, data: productUtil, });
      // dispatch({ type: actionTypes.GET_ALL_OPTIONS, data: option, });
      dispatch(getAllProductsDispatch(products))
      dispatch(getAllProductUtilDispatch(productUtil));
      dispatch(getAllOptionsDispatch(option));

    } catch (error) {
      // console.log(error);
      toast.error(`there was an error updating the option value price: ${error.response.data}`);
    }
  };
};

const optionsReducer = createReducer([], (builder) => {
  builder
    .addCase(getAllOptionsDispatch, (state, action) => {
      return action.payload
    })
    .addDefaultCase(state => state)
})

// const optionsReducer = (state = [], action) => {
//   switch (action.type) {
//     case actionTypes.GET_ALL_OPTIONS:
//       return [...action.data];
//     default:
//       return state;
//   }
// };

export default optionsReducer;