import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { Grid, Box, Typography, } from '@mui/material'
import { setUtilAction } from '../../../../reducer_action/util'

const ClientCard = ({ client, limit, clientEmail, cognitoGroup, }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const mainSideBar = useSelector((state) => state.util.mainSideBar)

  const defaultPage = 'View'

  return (
    <Grid container
      sx={{
        padding: '1rem 1rem',
        cursor: 'pointer',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: 'background.cardBackground',
        margin: '1rem 0',
        width: '100%'
      }}
      onClick={() => {
        dispatch(
          setUtilAction({ mainSideBar: true, actionQuery: defaultPage })
        );
        navigate(`/godown/clients/${client.username}?action=${defaultPage}&limit=${limit}&clientEmail=${clientEmail}`);
      }}
    >
      <Grid item xs={mainSideBar ? 9 : 3}>
        <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '3px', maxWidth: '60%', minWidth: '10%', }} >
          <Typography noWrap={true} variant='h6' >
            {client.email}
          </Typography>
        </Box>
      </Grid>
      {/* the extra information */}
      {!mainSideBar && (
        <Grid item xs={6}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box>
              <Typography variant='h6' sx={{ paddingRight: '.5rem' }}>Addresses:</Typography>
              {client.addresses.length > 0 ? <>
                {client.addresses.map((address, index) => {
                  return (<Typography key={address.id}>{address.name}: {address.address}</Typography>)
                })}
              </> : 'no addresses'}
            </Box>
            <Box sx={{ display: 'flex' }}>
              <Typography variant='h6' sx={{ paddingRight: '.5rem' }}>GST:</Typography>
              <Typography align='left'>
                {client?.gst && client?.gst.length > 0 ? <>
                  {client?.gst.map((gst, index) => {
                    return (<Typography key={gst.gst_id}>{gst.gst_id}</Typography>)
                  })}
                </> : 'no associated GST'}
              </Typography>
            </Box>
          </Box>
        </Grid>
      )}
      <Grid item xs={3}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', }} >
          <Box sx={{ backgroundColor: 'background.activeCardBackground', padding: '0.4rem 0.6rem', maxWidth: 'max-content', }} >
            <Typography>{client.clientGroup}</Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}

export default ClientCard