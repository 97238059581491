import React from "react";
import { Grid, Box, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setUtilAction } from "../../../../reducer_action/util";

const CustomerCard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <Grid
      item
      xs={12}
      sx={{ padding: "1rem", borderBottom: "1px solid black" }}
      onClick={() => {
        navigate("/asx/creditCustomers/nameOfCustomer?action=view");
        dispatch(setUtilAction({ mainSideBar: true, actionQuery: "view" }));
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h6" align="left">
          Customer name
        </Typography>
      </Box>
      <Typography>Pending payment : 1000 rs</Typography>
      <Typography>GSTNumber1234 </Typography>
    </Grid>
  );
};

export default CustomerCard;
