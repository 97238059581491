import React from "react";
import {
  Grid,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
const Transactions = () => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography>Transactions</Typography>
      </Grid>
      <Grid item xs={12} sx={{ padding: "1rem" }}>
        <Accordion>
          <AccordionSummary sx={{ color: "white" }}>
            Pending bills
          </AccordionSummary>
          <AccordionDetails sx={{ color: "white" }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>bill id</TableCell>
                    <TableCell>amount</TableCell>
                    <TableCell>date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow></TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item xs={12} sx={{ padding: "1rem" }}>
        <Accordion>
          <AccordionSummary sx={{ color: "white" }}>All bills</AccordionSummary>
          <AccordionDetails sx={{ color: "white" }}>
            <Accordion>
              <AccordionSummary sx={{ color: "white" }}>
                Bill id : date and the amount
              </AccordionSummary>
              <AccordionDetails sx={{ color: "white" }}>
                table with the item id and option details
              </AccordionDetails>
            </Accordion>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
};

export default Transactions;
