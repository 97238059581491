import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setUtilAction } from '../../../../../reducer_action/util';
import Add from '../Mainbar/ProductsPages/Edit-Add';
import { Box, Button, Typography } from '@mui/material';

const AddProduct = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingRight: '1rem', }} >
        <Typography variant='h4' sx={{ textTransform: 'capitalize', margin: '1rem', display: 'block', }} >
          Add a products
        </Typography>
        <Button
          onClick={() => {
            // dispatch(setUtilAction({ mainSideBar: false }));
            navigate('/godown/products');
          }}
          color='primary'
          variant='contained'
        >
          close
        </Button>
      </Box>
      <Box sx={{ padding: '1rem', overflow: 'scroll', maxHeight: '90vh' }}>
        <Add type='post' />
      </Box>
    </>
  );
};

export default AddProduct;
