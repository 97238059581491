import React from 'react';
import { useSelector } from 'react-redux';
import Tabs from '../../../../utilComponents/Tabs'
import Grid from '@mui/material/Grid';
import { useParams, useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';

const Header = ({ headerRef }) => {
  const params = useParams();
  const navigate = useNavigate();

  const [product] = useSelector((state) => state.product.filter((item) => item.id === +params.item));

  const routes = ['order', 'view']
    .map(tab => {
      return {
        name: tab,
        onClick: () => navigate(`?action=${tab}`)
      }
    })

  return (
    <Grid container ref={headerRef} sx={{ padding: '1rem 1rem 1rem 1.5rem' }} >
      <Grid item xs={12} sx={{ marginBottom: '0.75rem' }}>
        <Typography variant='h3' noWrap={true}>{product.name.toUpperCase()}</Typography>
      </Grid>
      <Tabs tabs={routes} />
    </Grid>
  );
};

export default Header;
