import React, { useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import OrderGuidelineBody from './OrderGuidelineBody'
import OrderGuidelineHeader from './OrderGuidelineHeader'

import { Grid } from '@mui/material'

const OrderGuidelineMainbar = () => {
  const headerRef = useRef()
  const params = useParams()

  const orderUnit = useSelector((state) => state.productUtil.orderUnit.find((unit) => unit.id === +params.orderUnitId))

  const [headerHeight, setHeaderHeight] = useState(0)

  useEffect(() => {
    const height = headerRef.current && headerRef?.current.offsetHeight;
    setHeaderHeight(height);
  }, [])

  return (
    <Grid container>
      {orderUnit &&
        <>
          <OrderGuidelineHeader headerRef={headerRef} orderUnit={orderUnit} />
          <OrderGuidelineBody headerHeight={headerHeight} orderUnit={orderUnit} />
        </>
      }
    </Grid>
  )
}

export default OrderGuidelineMainbar