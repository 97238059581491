import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { Button, Grid } from '@mui/material';
import EditTableView from '../../../../../../components/utilComponents/EditTableView';

import { getAllProductUtilDataAction } from '../../../../../../reducer_action/productUtil';
import { updateItemAction, postItemTableAction, } from '../../../../../../reducer_action/product';
// import { setUtilAction } from '../../../../../../reducer_action/util';


const Edit = ({ type }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const handleSubmit = async () => {
    const itemObject = { name, status, predefinedItem, categoryId, associatedCategoryId, taxRateId, priceType, weightCalcType, applyLinearFormula, sheetCalculation, uploadCenter, uploadCenterDescription, meetADesigner, hsnCode, shortDescription, longDescription, };

    if (type === 'post') {
      dispatch(postItemTableAction(itemObject));
      navigate('/godown');
      // dispatch(setUtilAction({ actionQuery: 'view' }));
    } else if (type === 'edit') {
      dispatch(updateItemAction(itemObject, +params.item));
      navigate(`/godown/products/${+params.item}?action=view`);
    }
    return
  };

  useEffect(() => {
    dispatch(getAllProductUtilDataAction());
  }, [dispatch]);

  const item = useSelector((state) => state.product.filter((x) => x.id === +params.item)[0]);
  const category = useSelector((state) => state.productUtil.category);
  const taxRate = useSelector((state) => state.productUtil.taxRate);
  const enums = useSelector((state) => state.productUtil.enums);

  // all the edit state variables
  const [name, setName] = useState(item?.name || '');
  const [status, setStatus] = useState(item?.status || '');
  const [predefinedItem, setPredefinedItem] = useState(item?.predefinedItem || false);
  const [categoryId, setCategoryId] = useState(item?.categoryId || category[0].id);
  const [associatedCategoryId, setAssociatedCategoryId] = useState(item?.associatedCategoryId || category[0].id);
  const [taxRateId, setTaxRateId] = useState(item?.taxRateId || taxRate[0].id);

  const [priceType, setPriceType] = useState(
    item?.priceType ||
    enums.find((x) => x.name === 'pricetype').value.split('|')[0]
  );
  const [weightCalcType, setWeightCalcType] = useState(
    item?.weightCalcType ||
    enums.find((x) => x.name === 'weight_calc_type').value.split('|')[0]
  );

  const [applyLinearFormula, setApplyLinearFormula] = useState(item?.applyLinearFormula || false);
  const [sheetCalculation, setSheetCalculation] = useState(item?.sheetCalculation || false);
  const [uploadCenter, setUploadCenter] = useState(item?.uploadCenter || false);
  const [uploadCenterDescription, setUploadCenterDescription] = useState(item?.uploadCenterDescription || '');
  const [meetADesigner, setMeetADesigner] = useState(item?.meetADesigner || false);
  const [hsnCode, setHsnCode] = useState(item?.hsnCode || '');
  const [shortDescription, setShortDescription] = useState(item?.shortDescription || '');
  const [longDescription, setLongDescription] = useState(item?.longDescription || '');

  return (
    <Grid container sx={{ padding: '1rem 0rem' }}>
      <Grid container item xs={12} alignItems='center'>
        <EditTableView
          title={'name'}
          value={name}
          onChange={(e) => setName(e.target.value)}
          compareValue={item?.name}
        />
        <EditTableView
          title={'status'}
          value={status}
          onChange={(e) => setStatus(e.target.checked)}
          type={'switch'}
          compareValue={item?.status}
        />
        <EditTableView
          title={'Predefined Item'}
          value={predefinedItem}
          onChange={(e) => setPredefinedItem(e.target.checked)}
          type={'switch'}
          compareValue={item?.predefinedItem}
        />
        <EditTableView
          title={'category '}
          value={categoryId}
          onChange={(e) => setCategoryId(+e.target.value)}
          type='select'
          selectValues={category}
          compareValue={item?.categoryId}
        />
        <EditTableView
          title={'associated Category'}
          value={associatedCategoryId}
          onChange={(e) => setAssociatedCategoryId(+e.target.value)}
          type='select'
          selectValues={category}
          compareValue={item?.associatedCategoryId}
        />
        <EditTableView
          title={'Tax rate'}
          value={taxRateId}
          onChange={(e) => setTaxRateId(e.target.value)}
          type='select'
          selectValues={taxRate}
          compareValue={item?.taxRateId}
        />
        <EditTableView
          title='price type'
          value={priceType}
          onChange={(e) => setPriceType(e.target.value)}
          type='select'
          selectValues={enums
            .find((x) => x.name === 'pricetype')
            .value.split('|')}
          compareValue={item?.priceType}
        />
        <EditTableView
          title='weight calc type'
          value={weightCalcType}
          onChange={(e) => setWeightCalcType(e.target.value)}
          type='select'
          selectValues={enums
            .find((x) => x.name === 'weight_calc_type')
            .value.split('|')}
          compareValue={item?.weightCalcType}
        />
        <EditTableView
          title='apply linear formula'
          value={applyLinearFormula}
          onChange={(e) => setApplyLinearFormula(e.target.checked)}
          type='switch'
          compareValue={item?.applyLinearFormula}
        />
        <EditTableView
          title='sheet calculation'
          compareValue={item?.sheetCalculation}
          value={sheetCalculation}
          onChange={(e) => setSheetCalculation(e.target.checked)}
          type='switch'
        />
        <EditTableView
          title='upload center'
          value={uploadCenter}
          onChange={(e) => setUploadCenter(e.target.checked)}
          type='switch'
          compareValue={item?.uploadCenter}
        />
        <EditTableView
          title='upload center description'
          value={uploadCenterDescription}
          onChange={(e) => setUploadCenterDescription(e.target.value)}
          type='text'
          compareValue={item?.uploadCenterDescription}
        />
        <EditTableView
          title='meet a designer'
          value={meetADesigner}
          onChange={(e) => setMeetADesigner(e.target.checked)}
          type='switch'
          compareValue={item?.meetADesigner}
        />
        <EditTableView
          title='hsn code'
          value={hsnCode}
          onChange={(e) => setHsnCode(e.target.value)}
          compareValue={item?.hsnCode}
          type='text'
        />{' '}
        <EditTableView
          title='short Description'
          value={shortDescription}
          onChange={(e) => setShortDescription(e.target.value)}
          type='multiline-text'
          compareValue={item?.shortDescription}
        />
        <EditTableView
          title='long Description'
          value={longDescription}
          onChange={(e) => setLongDescription(e.target.value)}
          type='multiline-text'
          compareValue={item?.longDescription}
        />
      </Grid>
      <Grid item xs={12} sx={{ paddingBottom: '2rem' }}>
        <Button
          sx={{ margin: '3rem auto 2rem', display: 'block' }}
          variant='contained'
          onClick={handleSubmit}
        >
          Save
        </Button>
      </Grid>
    </Grid>
  );
};

export default Edit;
