import React, { useState, useEffect } from 'react'
import { getPrnproOrders } from '../../../../service/prnpro'
import { Grid } from '@mui/material'

const AsxPrnproBody = ({ prnOrders }) => {


  return (
    <>
      {prnOrders && prnOrders.length > 0 &&
        <div>
          {prnOrders.map((bill, index) => {
            return (
              <Grid container item xs={12} key={bill.id}>
                {bill.id}
              </Grid>
            )
          })}
        </div>
      }
    </>
  )
}

export default AsxPrnproBody    