import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import userService from '../../../service/user'

import CenterBox from '../../utilComponents/CenterBox'
import { Typography, Box, TextField, Grid, Button } from '@mui/material'
import { getUserAction } from '../../../reducer_action/user'
import { toast } from 'react-toastify'

const VerifyPhoneAtt = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const user = useSelector(state => state.user)
  const [stage, setState] = useState(1)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [phoneVerificationCode, setPhoneVerificationCode] = useState('')

  const postAndVerifyPhoneNumber = async () => {
    if (typeof +phoneNumber !== 'number') return toast.error('phone number must be a number')
    if (phoneNumber.length !== 10) return toast.error('phone number must be 10 digits. Do not include 0 or country codes')

    try {
      // send data to the backend and update aws phone attribute
      await userService.updateUser(user.username, { phone: phoneNumber })
      dispatch(getUserAction())
      navigate('/godown/user')

      // todo after we move the sms out of the sandbox section
      // await userService.verifyCurrentUserPhoneAttribute();
      // setState(2)
    } catch (error) {
      toast.error('error: postAndVerifyPhoneNumber, ' + error.message)
    }
  }

  const submitPhoneVerificationCode = async () => {
    try {

      const username = user.username
      await userService.submitPhoneVerificationCode(phoneVerificationCode, { phoneNumber, username }
      );
      dispatch(getUserAction())
      navigate('/godown/user')
    } catch (error) {
      toast.error('error: submitPhoneVerificationCode, ' + error.message)
    }
  }

  return (
    <Box>
      <CenterBox>
        <Grid container sx={{ padding: '2rem', border: '2px solid black', borderRadius: '10px' }}>
          <Grid item xs={12}>
            <Typography align='center' variant='h3'>Verify Mobile phone number</Typography>
          </Grid>
          {stage === 1 && (
            // add and verify phone number and address
            <>
              <Grid item xs={12} sx={{ marginTop: '2rem', }}>
                <TextField fullWidth variant='outlined' label='phone number' value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sx={{ marginTop: '2rem' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Button variant='contained' onClick={() => navigate('/godown/user')}>back</Button>
                  <Button color='primary' variant='contained' onClick={postAndVerifyPhoneNumber} >update</Button>
                </Box>
              </Grid>
            </>
          )}
          {stage === 2 && ( // verify phone number here
            <>
              <Grid item xs={12} sx={{ marginTop: '2rem' }}>
                <TextField
                  fullWidth
                  variant='outlined'
                  label='enter verification code'
                  value={phoneVerificationCode}
                  onChange={(e) => setPhoneVerificationCode(e.target.value)} />
              </Grid>
              <Grid item xs={12} sx={{ marginTop: '2rem' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Button variant='contained' onClick={() => navigate('/godown/user')}>back</Button>
                  <Button variant='contained' onClick={submitPhoneVerificationCode}> submit OTP</Button>
                </Box>
              </Grid>
            </>
          )}
        </Grid>
      </CenterBox>
    </Box>
  )
}

export default VerifyPhoneAtt