import React from 'react'
import { Box, Grid, InputAdornment, MenuItem, OutlinedInput, Select } from '@mui/material'


const AsxPrnproHeader = ({ headerRef }) => {
  return (
    <Grid container item xs={12} justifyContent='space-between' alignItems='flex-start' ref={headerRef} >
      <Grid item xs={12}>
        search by orderId
      </Grid>
    </Grid >
  )
}

export default AsxPrnproHeader