import React from 'react';
import { Button, Box, Grid, TextField, Typography } from '@mui/material';
import BillingForm from './BillingForm';

// billing there needs to be confirmation modal for printing all the item and then sending them to the
const Billing = () => {
  return (
    <Grid container sx={{}}>
      <Grid item xs={12} sx={{ padding: '1rem 2rem' }}>
        <Typography variant='h3' align='center'>
          Billing (Branch Name)
        </Typography>
      </Grid>
      <Grid
        xs={8}
        item
        container
        spacing={2}
        sx={{
          padding: '1rem 2rem 1rem',
          borderRight: '1px solid blue',
          height: 'calc(100vh - 74px)',
          overflow: 'scroll',
          scrollbarWidth: 'none',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
        alignContent='flex-start'
      >
        <Grid item xs={6}>
          <TextField fullWidth label='client email / id' />
        </Grid>
        <Grid item xs={6}>
          <Box sx={{ display: 'flex' }}>
            <TextField fullWidth label='gst number' />
            <Button sx={{ paddingLeft: '1rem' }}>verify</Button>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <TextField fullWidth label='item or bundle id' />
        </Grid>
        <Grid item xs={6}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              height: '100%',
            }}
          >
            <Typography variant='h4' align='center'>
              TOTAL : Rs. 15660
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <BillingForm />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button variant='contained'>
              verify the payment to client and remaining amount
            </Button>
            <TextField label='adv payment ref' />
            {/* check the client details and post if the id has already been used and  subtract it from the final bill */}
            <TextField label='adv payment amount' />
            <Button
              onClick={() => {
                if (window.confirm('print the following items')) {
                  console.log('printing the bill');
                }
              }}
              variant='contained'
            >
              print
            </Button>
          </Box>
        </Grid>
      </Grid>

      {/* the second half of the column structure */}
      <Grid
        item
        container
        xs={4}
        fullWidth
        spacing={2}
        sx={{ padding: '1rem 2rem 1rem', alignContent: 'flex-start' }}
      >
        <Grid xs={12}>
          <Typography variant='h5'> BILL / GST Information</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h5'>company info</Typography>
          <Typography>1. company name </Typography>
          <Typography>
            2. address: 'askdfj;ljk' asdkfj;lkj;kljsdf;ljk;aslkjdfj
          </Typography>
          <Typography>associated customers to this gst information</Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant='h5'>Item Size</Typography>
          <Typography>1. ~A4</Typography>
          <Typography>2. ~A3</Typography>
          <Typography>3. ~A2</Typography>
          <Typography>4. ~A1</Typography>
          <Typography>5. ~A0</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h5'>Option: id value</Typography>
          <Typography>1. option value </Typography>
          <Typography>2. option value (default value) </Typography>
          <Typography>3. option value</Typography>
          <Typography>4. option value</Typography>
          <Typography>5. option value</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h5'>Option: id value</Typography>
          <Typography>1. another option value </Typography>
          <Typography>2. another option value (default value) </Typography>
          <Typography>3. another option value</Typography>
          <Typography>4. another option value</Typography>
          <Typography>5. another option value</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h5'>Option: id value</Typography>
          <Typography>1. another option value </Typography>
          <Typography>2. another option value (default value) </Typography>
          <Typography>3. another option value</Typography>
          <Typography>4. another option value</Typography>
          <Typography>5. another option value</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Billing;
