import React from 'react'
import { Grid, Typography } from '@mui/material'
import { dashSplitter } from '../../../../../utilFunctions'
import { useNavigate } from 'react-router-dom';
import Tabs from '../../../../utilComponents/Tabs'


const OrderGuidelineHeader = ({ headerRef, orderUnit }) => {

  const navigate = useNavigate()
  const routes = ['view']
    .map(tab => {
      return {
        name: tab,
        onClick: () => navigate(`?action=${tab}`)
      }
    })

  return (
    <Grid container item ref={headerRef} xs={12} sx={{ padding: '1rem 1rem 1rem 1rem', }} >
      <Grid item xs={12} sx={{ marginBottom: '0.75rem' }}>
        <Typography variant='h3' sx={{ textTransform: 'capitalize' }}>
          {dashSplitter(orderUnit.name)}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Tabs tabs={routes} />
      </Grid>
    </Grid>
  )
}

export default OrderGuidelineHeader