import {
  updateItem,
  getAllProducts,
  getOneProduct,
  updateItemSizeTable,
  postItemSize,
  updateItemPriceRangeTable,
  updateItemMapOptionValue,
  postItemRule,
  deleteItemRule,
  postMedia,
  deleteMedia,
  postItemTable,
  getAllOptions,
} from '../service/product';
import actionTypes from '../reducer_action/actionTypes';
import { toast } from 'react-toastify';
import { createReducer, createAction } from '@reduxjs/toolkit';
import { getAllOptionsDispatch } from './options';


export const getAllProductsDispatch = createAction(actionTypes.GET_ALL_PRODUCTS);
export const updateItemTableDispatch = createAction(actionTypes.UPDATE_ITEM_TABLE);

export const getAllProductsAction = () => {
  return async (dispatch) => {
    try {
      const products = await getAllProducts();
      // dispatch({ type: actionTypes.GET_ALL_PRODUCTS, data: products, });
      dispatch(getAllProductsDispatch(products));
    } catch (error) {
      console.log(error);
    }
  };
};

export const getOneProductAction = (itemId) => {
  return async (dispatch) => {
    try {
      const newItem = await getOneProduct(itemId);
      // dispatch({ type: actionTypes.UPDATE_ITEM_TABLE, data: newItem, });
      dispatch(updateItemTableDispatch(newItem));
    } catch (error) {
      console.log(error);
    }
  };

}

export const updateItemAction = (item, id) => {
  return async (dispatch) => {
    try {
      await updateItem(item, id);
      // console.log(data);
      const newItem = await getOneProduct(id);
      // console.log({ updatedItem: newItem });
      // dispatch({ type: actionTypes.UPDATE_ITEM_TABLE, data: newItem, });
      dispatch(updateItemTableDispatch(newItem));
    } catch (error) {
      console.log(error);
    }
  };
};

export const postItemTableAction = (item) => {
  return async (dispatch) => {
    try {
      const data = await postItemTable(item);
      // console.log(data);
      const newItem = await getOneProduct(data.id);
      // console.log({ updatedItem: newItem });
      // dispatch({ type: actionTypes.UPDATE_ITEM_TABLE, data: newItem, });
      dispatch(updateItemTableDispatch(newItem));
    } catch (error) {
      console.log(error);
    }
  };
};

export const updateItemSizeTableAction = (itemId, size, sizeId) => {
  return async (dispatch) => {
    try {
      await updateItemSizeTable(size, sizeId);
      const newItem = await getOneProduct(itemId);
      // dispatch({ type: actionTypes.UPDATE_ITEM_TABLE, data: newItem, });
      dispatch(updateItemTableDispatch(newItem));
    } catch (error) {
      console.log(error);
    }
  };
};

export const postItemSizeAction = (sizeData) => {
  return async (dispatch) => {
    try {
      // console.log({ sizeData })
      await postItemSize(sizeData);
      const newItem = await getOneProduct(sizeData.itemId);
      // dispatch({ type: actionTypes.UPDATE_ITEM_TABLE, data: newItem, });
      dispatch(updateItemTableDispatch(newItem));
    } catch (error) {
      console.log(error)
    }
  }
}

export const updateItemMapOptionValueAction = ({ bool, itemId, itemOptionId, itemOptionValueId }) => {
  return async (dispatch) => {
    try {
      await updateItemMapOptionValue({ bool, itemId, itemOptionId, itemOptionValueId, });
      const newItem = await getOneProduct(itemId);
      // dispatch({ type: actionTypes.UPDATE_ITEM_TABLE, data: newItem, });
      dispatch(updateItemTableDispatch(newItem));
    } catch (error) {
      toast.error(`there was an error mapping item to options: ${error.message}`);
    }
  };
};

export const updateItemPriceRangeTableAction = (priceRange) => {
  return async (dispatch) => {
    try {
      const newItemPrice = await updateItemPriceRangeTable(priceRange);
      // console.log({ newItemPrice });
      const newItem = await getOneProduct(newItemPrice[0].itemId);
      // dispatch({ type: actionTypes.UPDATE_ITEM_TABLE, data: newItem, });
      dispatch(updateItemTableDispatch(newItem));
    } catch (error) {
      console.log(error);
    }
  };
};

export const postItemRuleAction = (data) => {
  return async (dispatch) => {
    try {

      await postItemRule(data);
      const newItem = await getOneProduct(data.itemId);

      // dispatch({ type: actionTypes.UPDATE_ITEM_TABLE, data: newItem, });
      dispatch(updateItemTableDispatch(newItem));

      toast.success(`Rule: ${data.ruleTitle} has been created successfully!`)
    } catch (error) {
      toast.error(`there was an error posting the rule: ${error.message}`);
    }
  };
};

export const deleteItemRuleAction = (itemId, ruleId, ruleType) => {
  return async (dispatch) => {
    try {
      // console.log({ itemId, ruleId }, 'delete item Rule action');
      await deleteItemRule(ruleId, ruleType);
      const newItem = await getOneProduct(itemId);
      // console.log(newItem)
      // dispatch({ type: actionTypes.UPDATE_ITEM_TABLE, data: newItem, });
      dispatch(updateItemTableDispatch(newItem));
    } catch (error) {
      console.log(error);
    }
  };
}

export const postMediaAction = (type, data, itemId) => {
  return async (dispatch) => {
    try {
      await postMedia(type, data);
      // console.log({ newMedia });
      if (type === 'itemMedia' || type === 'sizeMedia') {
        const newItem = await getOneProduct(itemId);
        // dispatch({ type: actionTypes.UPDATE_ITEM_TABLE, data: newItem, });
        dispatch(updateItemTableDispatch(newItem));
      } else if (type === 'optionMedia') {
        const allOptions = await getAllOptions();
        // dispatch({ type: actionTypes.GET_ALL_OPTIONS, data: allOptions, });
        dispatch(getAllOptionsDispatch(allOptions));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const deleteMediaAction = (type, id, itemId) => {
  return async (dispatch) => {
    try {
      await deleteMedia(type, { id });
      const newItem = await getOneProduct(itemId);
      // dispatch({ type: actionTypes.UPDATE_ITEM_TABLE, data: newItem, });
      dispatch(updateItemTableDispatch(newItem));
    } catch (error) {
      console.log(error);
    }
  };
};

const productReducer = createReducer([], (builder) => {
  builder
    .addCase(actionTypes.GET_ALL_PRODUCTS, (state, action) => {
      return action.payload
    })
    .addCase(actionTypes.UPDATE_ITEM_TABLE, (state, action) => {
      return state.map((item) => item.id === action.payload.id ? action.payload : item);
    })
    .addDefaultCase(state => state)
})

// const productReducer = (state = [], action) => {
//   switch (action.type) {
//     case actionTypes.GET_ALL_PRODUCTS:
//       return [...action.data];
//     case actionTypes.UPDATE_ITEM_TABLE:
//       return state.map((item) => item.id === action.data.id ? action.data : item);
//     default:
//       return state;
//   }
// };

export default productReducer;