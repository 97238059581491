import {
  getAllProductUtilData,
  updateCategoryTable,
  postCategoryTable,
} from '../service/productUtil';
import actionTypes from './actionTypes';
import { createReducer, createAction } from '@reduxjs/toolkit'

export const getAllProductUtilDispatch = createAction(actionTypes.GET_ALL_CATEGORIES);

export const getAllProductUtilDataAction = () => {
  return async (dispatch) => {
    const productUtil = await getAllProductUtilData();
    // console.log({ categories });
    // dispatch({ type: actionTypes.GET_ALL_CATEGORIES, data: productUtil, });
    dispatch(getAllProductUtilDispatch(productUtil))
  };
};

export const updateCategoryTableAction = (data, id) => {
  return async (dispatch) => {
    await updateCategoryTable(data, id);
    // console.log(newData);
    const productUtil = await getAllProductUtilData();
    // console.log({ categories });
    // dispatch({ type: actionTypes.GET_ALL_CATEGORIES, data: productUtil, });
    dispatch(getAllProductUtilDispatch(productUtil))
  };
};

export const postCategoryAction = (data, id) => {
  return async (dispatch) => {
    await postCategoryTable(data, id);
    // console.log(newData);
    const productUtil = await getAllProductUtilData();
    // dispatch({ type: actionTypes.GET_ALL_CATEGORIES, data: productUtil, });
    dispatch(getAllProductUtilDispatch(productUtil))
  };
};

const productUtilReducer = createReducer({}, (builder) => {
  builder
    .addCase(getAllProductUtilDispatch, (state, action) => {
      return action.payload
    })
    .addDefaultCase(state => state)
})


export default productUtilReducer;

// const productUtilReducer = (state = {}, action) => {
//   switch (action.type) {
//     case actionTypes.GET_ALL_CATEGORIES:
//       return { ...action.data }
//     default:
//       return state;
//   }
// };