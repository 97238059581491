import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useLocation } from 'react-router-dom'

// import { getClientDemandOrderAction } from '../../../../../reducer_action/demandOrder'
import { getAllOptionsAction } from '../../../../../reducer_action/options'
import { getAllProductUtilDataAction } from '../../../../../reducer_action/productUtil'
import { getAllProductsAction } from '../../../../../reducer_action/product'

import { getAllDemandOrder } from '../../../../../service/demandOrder'

import ClientOrderBody from './ClientOrderBody'
import ClientOrderHeader from './ClientOrderHeader'

import { Grid } from '@mui/material'

const ClientOrderSidebar = () => {
  const headerRef = useRef()
  const params = useParams()
  const location = useLocation()

  const useQuery = () => new URLSearchParams(location.search);
  const query = useQuery()

  const dispatch = useDispatch()
  const enums = useSelector(state => state.productUtil.enums)
  const username = useSelector(state => state.user.username)

  const [headerHeight, setHeaderHeight] = useState(0)

  // header
  const [itemName, setItemName] = useState(query.get('itemName'))
  const [status, setStatus] = useState(query.get('status'))

  // footer
  const [currentPage, setCurrentPage] = useState(query.get('currentPage') || 1)
  const [limit, setLimit] = useState(query.get('limit') || 10)
  const [orderByDesc, setOrderByDesc] = useState(Boolean(query.get('orderByDesc') === 'true'))

  // demand order
  const [demandOrder, setDemandOrder] = useState({})
  // need to update with date .. to and from 

  useEffect(() => {
    dispatch(getAllProductsAction())
    // dispatch(getClientDemandOrderAction())
    dispatch(getAllOptionsAction())
    dispatch(getAllProductUtilDataAction())
  }, [dispatch])

  useEffect(() => {
    enums && (async () => {
      const orderId = params.orderId ? params.orderId : null
      const data = { currentPage, limit, itemName, status, orderByDesc, orderId, username }
      const demandOrders = await getAllDemandOrder(data)
      setDemandOrder(demandOrders)
      // navigate(`/asx/orders/${demandOrders.orders[0].id}`)

      const height = headerRef.current && headerRef.current.offsetHeight;
      setHeaderHeight(height)
    })()
  }, [dispatch, currentPage, status, limit, itemName, orderByDesc, enums, params, username])

  return (
    <>
      <Grid container item xs={12}>
        {enums &&
          <ClientOrderHeader
            headerRef={headerRef}
            status={status}
            setStatus={setStatus}
            itemName={itemName}
            setItemName={setItemName}
          />
        }
        {demandOrder && demandOrder.count >= 0 &&
          <ClientOrderBody
            headerHeight={headerHeight}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            limit={limit}
            setLimit={setLimit}
            demandOrder={demandOrder}
            orderByDesc={orderByDesc}
            setOrderByDesc={setOrderByDesc}
            status={status}
            itemName={itemName}
          />
        }
      </Grid>
    </>
  )
}

export default ClientOrderSidebar