import React, { useState, useRef, useEffect } from 'react'
import { Grid } from '@mui/material'

import AsxPrnproHeader from './AsxPrnproHeader'
import AsxPrnproBody from './AsxPrnproBody'

import { getPrnproOrders } from '../../../../service/prnpro'


const AsxPrnproSidebar = () => {
  const headerRef = useRef();

  const [headerHeight, setHeaderHeight] = useState(0);
  const [prnOrders, setPrnOrders] = useState([])

  useEffect(() => {
    const headerHeight = headerRef.current && headerRef?.current.offsetHeight;
    setHeaderHeight(headerHeight);
  }, [])



  useEffect(() => {
    (async () => {
      // todo : this clientUsernameBiller needs to be their own 
      const data = await getPrnproOrders({ clientUsernameBiller: '3252671a-cd2f-41bc-b87b-e1f4039eef81' })
      console.log({ data })
      setPrnOrders(data.bills)
    })()
  }, [])
  // todo search by order Id or customer email

  return (
    <Grid container item xs={12}>
      <Grid item xs={12}>
        <AsxPrnproHeader headerRef={headerRef} />
      </Grid>
      <Grid item xs={12}>
        <AsxPrnproBody headerHeight={headerHeight} />
      </Grid>
    </Grid>
  )
}

export default AsxPrnproSidebar