import axios from 'axios'
import userService from './user';
import { toast } from 'react-toastify'

const baseUrl = process.env.REACT_APP_PROXY_BACKEND

const getToken = async () => {
  const session = await userService.getSession()
  const token = session && session.idToken
  return token.jwtToken
}

export const postShipment = async (shipmentCart, addressId) => {
  try {
    const token = await getToken()
    if (!token) return

    const response = await axios({
      method: 'post',
      url: `${baseUrl}/shipment`,
      headers: { Authorization: `${token}` },
      data: { shipmentCart, senderAddressId: addressId },
      // todo do not send the user name as part of the data set
    })

    return response.data
  } catch (error) {
    toast.error(`there was an error posting the shipment: ${error.response.data}`)
  }
}

export const getAllShipments = async (data) => {
  try {
    const token = await getToken()
    if (!token) return

    const response = await axios({
      method: 'get',
      url: `${baseUrl}/shipment?currentPage=${data.currentPage}&limit=${data.limit}&itemName=${data.itemName}&status=${data.status}&orderByDesc=${data.orderByDesc}&username=${data.username}`,
      headers: { Authorization: `${token}` },
    })

    return response.data
  } catch (error) {
    toast.error('there was an error getting the shipments')
  }
}

export const getOneShipment = async (data) => {
  try {
    const token = await getToken()
    if (!token) return

    const response = await axios({
      method: 'get',
      url: `${baseUrl}/shipment/${data.shipmentId}`,
      headers: { Authorization: `${token}` },
    })

    return response.data
  } catch (error) {
    toast.error('there was an error getting a shipment')
  }
}

export const updateShipment = async (data) => {
  try {
    const token = await getToken()
    if (!token) return

    const response = await axios({
      method: 'put',
      url: `${baseUrl}/shipment/${data.shipmentId}?status=${data.status}&mediaUrl=${data.mediaUrl}`,
      headers: { Authorization: `${token}` },
    })

    return response.data
  } catch (error) {
    toast.error('there was an error updating a shipment')
  }
}