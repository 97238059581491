import React from "react";
import { Grid } from "@mui/material";

import { useSelector } from "react-redux";

import View from "./Pages/View";
import Transactions from "./Pages/Transactions";
import AssignBill from "./Pages/AssignBill";
import AddCreditPayment from "./Pages/AddCreditPayment";

const Body = () => {
  const action = useSelector((state) => state.util.actionQuery);
  return (
    <Grid container>
      <Grid item container xs={12}>
        {action === "view" && <View />}
        {action === "transactions" && <Transactions />}
        {action === "Assign-Bill" && <AssignBill />}
        {action === "add-credit-payment" && <AddCreditPayment />}
      </Grid>
    </Grid>
  );
};

export default Body;
