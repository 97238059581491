import React from "react";
import {
  Button,
  Select,
  MenuItem,
  Grid,
  TextField,
  Typography,
} from "@mui/material";

const Expense = () => {
  const date = new Date();
  console.log({ date: date.getUTCDate() });
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h4" align="center">
          Expense
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ padding: "1rem" }}>
        <Typography variant="h6">
          Date: {date.getUTCDate()}/{date.getUTCMonth()}/{date.getUTCFullYear()}
        </Typography>
      </Grid>
      <Grid xs={12} item sx={{ padding: "1rem" }}>
        <Select fullWidth value="1" label="expense type">
          <MenuItem value="1" sx={{ color: "white" }}>
            Expense Type
          </MenuItem>
        </Select>
      </Grid>
      <Grid item xs={12} sx={{ padding: "1rem" }}>
        <TextField fullWidth label="name" />
      </Grid>
      <Grid item xs={12} sx={{ padding: "1rem" }}>
        <TextField fullWidth label="amount" />
      </Grid>
      <Grid item xs={12} sx={{ padding: "1rem" }}>
        <TextField multiline fullWidth label="Description" />
      </Grid>
      <Grid item xs={12} sx={{ padding: "1rem" }}>
        <Button variant="contained">Save</Button>
      </Grid>
    </Grid>
  );
};

export default Expense;
