import React from 'react'
import { Grid, Box, Typography, } from '@mui/material'
import { useSelector } from 'react-redux'

const ClientShipmentCard = ({ shipment }) => {
  const product = useSelector(state => state.product)

  const summaryStatus = () => {
    const shipStatus = shipment.shipmentItems.map(item => item.status)

    if (shipStatus.some(status => status === 'PENDING')) {
      return 'Pending'
    } else if (shipStatus.every(status => status === 'COMPLETED')) {
      return 'Completed'
    } else if (shipStatus.every(status => status === 'SHIPPED')) {
      return 'Shipped'
    } else {
      return 'unknown'
    }
  }

  const findProductNameFromId = (itemId) => {
    const name = product.find((product) => product.id === itemId)?.name;
    return name;
  };

  return (
    <Grid
      container
      sx={{
        padding: '1rem 1rem',
        cursor: 'pointer',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: 'background.cardBackground',
        margin: '1rem 0',
        width: '100%'
      }}
    >
      <Grid item xs={3}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '3px',
            maxWidth: '60%',
            minWidth: '10%',
          }}
        >
          <Typography
            noWrap={true}
            variant='h6'
            sx={{ textTransform: 'capitalize' }}
          >
            {shipment.shippedTo.email}
          </Typography>

          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Typography variant='caption'>{shipment.shipDate.toLocaleString().slice(0, 10)}</Typography>
            <Typography variant='caption'>shipment Id: {shipment.id}</Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box sx={{ display: 'flex' }}>
          <Box>
            <Typography variant='h6' sx={{ paddingRight: '.5rem' }}>Items:</Typography>
            <Typography align='left'>
              {shipment.shipmentItems.map((item) => `${findProductNameFromId(item.itemId)}-(${item.status.toLowerCase()})`).join(', ')}
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={3}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            flexDirection: 'column',
            gap: '1rem'
          }}
        >
          <Box
            sx={{
              backgroundColor: 'background.activeCardBackground',
              padding: '0.4rem 0.6rem',
              maxWidth: 'max-content',
            }}
          >
            <Typography>{summaryStatus().toUpperCase()}</Typography>
          </Box>
          <Box
            sx={{
              backgroundColor: 'background.activeCardBackground',
              padding: '0.4rem 0.6rem',
              maxWidth: 'max-content',
            }}
          >
            <Typography>
              {shipment.shippedTo.clientGroup.toUpperCase()}
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}

export default ClientShipmentCard