import actionTypes from './actionTypes';
import { createReducer, createAction } from '@reduxjs/toolkit';

const newGodownClientCartDispatch = createAction(actionTypes.NEW_GODOWN_TO_CLIENT_ORDER_CART);

const getGodownCartName = ({ godownEmail, godownUsername, clientUsername, clientEmail }) => {
  const cartName = `cart-${godownEmail}-${godownUsername}-${clientUsername}-${clientEmail}`
  return cartName
}


export const getGodownClientCartAction = ({ godownEmail, godownUsername, clientUsername, clientEmail }) => {
  return async (dispatch) => {
    if (!godownEmail || !godownUsername || !clientUsername || !clientEmail) return;
    const cartName = getGodownCartName({ godownEmail, godownUsername, clientUsername, clientEmail })
    const cart = localStorage.getItem(cartName) || JSON.stringify([]);

    const newCart = JSON.parse(cart);
    localStorage.setItem(cartName, JSON.stringify(newCart));

    dispatch(newGodownClientCartDispatch(newCart))
  };
};

export const addProductToGodownClientCartAction = ({ godownEmail, godownUsername, clientUsername, clientEmail }, cartItem) => {
  return async (dispatch) => {
    if (!godownEmail || !godownUsername || !clientUsername || !clientEmail) return;

    const cartName = getGodownCartName({ godownEmail, godownUsername, clientUsername, clientEmail })
    const cart = localStorage.getItem(cartName) || JSON.stringify([]);

    const newCart = [...JSON.parse(cart), cartItem];

    localStorage.setItem(cartName, JSON.stringify(newCart));

    dispatch(newGodownClientCartDispatch(newCart))
  };
};

export const removeProductFromGodownClientCartAction = ({ godownEmail, godownUsername, clientUsername, clientEmail }, indexOf) => {
  return async (dispatch) => {
    if (!godownEmail || !godownUsername || !clientUsername || !clientEmail) return;
    const cartName = getGodownCartName({ godownEmail, godownUsername, clientUsername, clientEmail })

    const cart = JSON.parse(localStorage.getItem(cartName)) || [];
    if (!cart || cart.length <= 0) return;

    const newCart = cart.filter((item, index) => index !== indexOf);

    localStorage.setItem(cartName, JSON.stringify(newCart));

    // dispatch({ type: actionTypes.NEW_GODOWN_TO_CLIENT_ORDER_CART, data: newCart, });
    dispatch(newGodownClientCartDispatch(newCart))
  };
};


export const deleteGodownClientCartAction = ({ godownEmail, godownUsername, clientUsername, clientEmail }) => {
  return (dispatch) => {
    const cartName = getGodownCartName({ godownEmail, godownUsername, clientUsername, clientEmail })
    const newCart = [];
    localStorage.setItem(cartName, JSON.stringify(newCart));

    dispatch(newGodownClientCartDispatch(newCart))
  };
};


const godownClientCartReducer = createReducer([], (builder) => {
  builder
    .addCase(newGodownClientCartDispatch, (state, action) => {
      return action.payload;
    })
    .addDefaultCase((state) => state)
})


export default godownClientCartReducer;

// const godownClientCartReducer = (state = [], action) => {
//   switch (action.type) {
//     case 'NEW_GODOWN_TO_CLIENT_ORDER_CART':
//       return [...action.data];
//     default:
//       return state;
//   }
// };