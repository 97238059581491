import React, { useRef, useState, useEffect } from 'react'
import { Grid, Box, Typography, Select, MenuItem } from '@mui/material'

import { getAllShipments } from '../../../../../../service/shipments';
import ClientShipmentCard from './ClientShipmentCard';
import ClientShipmentFooter from './ClientShipmentFooter';

const Shipments = ({ headerHeight, client }) => {
  const footerRef = useRef()

  const [footerHeight, setFooterHeight] = useState(0)
  const [status, setStatus] = useState('ALL')
  const [itemName, setItemName] = useState('')
  const [limit, setLimit] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [orderByDesc, setOrderByDesc] = useState(true)
  const [shipments, setShipments] = useState({})

  // const totalPages = Math.ceil(+shipments.count / +limit)
  useEffect(() => {
    const height = footerRef.current.offsetHeight;
    setFooterHeight(height);
  }, [footerRef])

  useEffect(() => {
    (async () => {
      const shipmentData = { status, currentPage, limit, itemName, orderByDesc, username: client.username }
      const clientShipments = await getAllShipments(shipmentData)
      console.log({ clientShipments })
      setShipments(clientShipments)
    })()
  }, [status, currentPage, limit, itemName, orderByDesc, client.username])

  return (
    <Grid item xs={12}>
      <Box sx={{ padding: '0 1rem', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} >
        <Box sx={{ height: `calc(100vh - ${headerHeight}px - ${footerHeight}px)`, overflow: 'scroll', scrollbarWidth: 'none', '&::-webkit-scrollbar': { display: 'none', } }}>
          {shipments.shipments && shipments?.shipments.length > 0 && shipments.shipments.map((shipment, index) => {
            return <ClientShipmentCard key={shipment.id} shipment={shipment} />
          })}
        </Box>
        <Box ref={footerRef} sx={{ padding: '0.5rem' }}>
          <ClientShipmentFooter shipments={shipments} status={status} setStatus={setStatus} limit={limit} setLimit={setLimit} orderByDesc={orderByDesc} setOrderByDesc={setOrderByDesc} itemName={itemName} setItemName={setItemName} currentPage={currentPage} setCurrentPage={setCurrentPage} />
        </Box>
      </Box>
    </Grid >
  )
}

export default Shipments
