import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setUtilAction } from '../../../../../reducer_action/util';
import { Grid, Typography, Box } from '@mui/material';

const CategoryCard = ({ category }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const mainSideBar = useSelector((state) => state.util.mainSideBar);
  const categoryGroups = useSelector(
    (state) => state.productUtil.categoryGroup
  );


  return (
    <Grid
      container
      item xs={12}
      sx={{
        padding: '1rem 1rem 1rem 1rem',
        marginTop: '1rem',
        cursor: 'pointer',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: 'background.cardBackground',
        maxHeight: '250px'
        // margin: '1rem 0',
      }}
      onClick={() => {
        dispatch(setUtilAction({ mainSideBar: true, actionQuery: 'view' }));
        navigate(`/godown/productCategories/${category.id}?action=view`);
      }}
    >
      <Grid item xs={mainSideBar ? 9 : 3}>
        <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '3px', maxWidth: '60%', minWidth: '10%', }} >
          <Typography noWrap={true} variant='h6' sx={{ textTransform: 'capitalize' }} >
            {category.name}
          </Typography>
          <Typography noWrap='true' variant='caption' sx={{ textTransform: 'capitalize' }} >
            {
              categoryGroups.find(
                (group) => group.id === category.categoryGroupId
              ).name
            }
          </Typography>
        </Box>
      </Grid>
      {/* the extra information */}
      {!mainSideBar && (
        <Grid item xs={6}>
          <Typography variant='caption' sx={{}}>
            {category.description}
          </Typography>
        </Grid>
      )}
      <Grid item xs={3}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Box
            sx={{
              backgroundColor: 'background.activeCardBackground',
              padding: '0.4rem 0.6rem',
              maxWidth: 'max-content',
            }}
          >
            <Typography>active</Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default CategoryCard;
