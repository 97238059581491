import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import TableView from '../../../../../utilComponents/TableView';

import {
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Typography,
} from '@mui/material';

const View = () => {
  const params = useParams();
  const category = useSelector((state) =>
    state.productUtil.category.find((x) => x.id === +params.category)
  );
  const products = useSelector((state) => state.product);

  const categoryProducts = products.filter(
    (item) => item.categoryId === category.id
  );
  return (
    <Grid container item xs={12} alignContent='flex-start'>
      <Grid
        container
        sx={{ paddingBottom: '1rem', paddingLeft: '0rem' }}
        item
        xs={12}
        alignContent='flex-start'
      >
        <TableView title='Name' value={category.name} />
        <TableView title={'total Items'} value={categoryProducts.length} />
        <TableView title='Description' value={category.description} />
      </Grid>

      <Grid item xs={12} sx={{ marginTop: '3rem' }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {['name', 'description', 'status'].map((x) => {
                  return (
                    <TableCell key={x}>
                      <Typography
                        variant='h6'
                        sx={{ textTransform: 'capitalize' }}
                      >
                        {x}
                      </Typography>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {categoryProducts.map((product) => {
                return (
                  <TableRow>
                    <TableCell>{product.name}</TableCell>
                    <TableCell>{product.shortDescription}</TableCell>
                    <TableCell>{product.status.toString()}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default View;
