import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Switch, Box, Button, Typography, Grid, TableContainer, Table, TableRow, TableCell, TableHead, TableBody, RadioGroup, Radio,
  FormControlLabel,
} from '@mui/material';
import { postDemandOrder } from '../../../../service/demandOrder';
import ItemRow from './ItemRow'
import { deleteCartAction } from '../../../../reducer_action/cart';
import { toast } from 'react-toastify';
// todo -> call all auth calls from the auth state and not redux global state
// import { Auth } from 'aws-amplify'

const Cart = () => {
  // todo add Notes to the place order upload. It is done on the asx side
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const cart = useSelector((state) => state.cart);
  const user = useSelector(state => state.user)

  const userInfo = useSelector(state => {
    const group = state?.user?.clientGroup
    const email = state?.user?.email
    const userName = state?.user?.username

    const addresses = state?.user.addresses.length > 0 && state?.user?.addresses
    return { group, email, userName, addresses }
  })

  const [addressId, setAddressId] = useState(userInfo?.addresses[0]?.id || null)
  const [returnOrder, setReturnOrder] = useState(false)

  const handlePlaceOrder = async () => {
    try {
      if (!window.confirm('are you sure you want to place this order?')) return

      if (!addressId) {
        if (!userInfo?.addresses.length > 0) {
          toast.error('please add an delivery address to proceed')
          navigate('/asx/user')
          return
        } else {
          toast.error('please select an delivery address to proceed')
          return
        }
      }

      const clientData = { username: user.username, email: user.email }
      const fulfillerUsername = '09ab14b5-7a2c-4eb7-99e9-4cac1bfd2c66' // default godown account is asx srini @ triplicane

      await postDemandOrder(cart, addressId, clientData, returnOrder, fulfillerUsername)
      dispatch(deleteCartAction(userInfo.group, userInfo.email))
    } catch (error) {
      toast.error('there was an error placing the order: ' + error.response.data)
    }
  };

  return (
    <Grid container item xs={12} sx={{ padding: '1rem 1rem 3rem 1rem' }}>
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant='h2'>{returnOrder ? 'Return Order' : 'Cart'}</Typography>
          <Switch checked={returnOrder} onChange={() => setReturnOrder(!returnOrder)} />
        </Box>
      </Grid>

      <Grid item xs={12} sx={{ marginTop: '3rem' }}>
        {cart.length > 0 ?
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography align='left' variant='h4'>
                      Item
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography align='center' variant='h4'>
                      Specifications
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography align='center' variant='h4'>
                      Quantity
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography align='center' variant='h4'>
                      Media/Notes
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography align='center' variant='h4'> Action </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cart.map((item, index) => {
                  return (<ItemRow item={item} index={index} key={`${item.itemId}-${Math.random()}-${index}`} />);
                })}
              </TableBody>
            </Table>
          </TableContainer>
          : (
            <Box sx={{ marginTop: '2rem' }}>
              <Typography variant='h4'>There are no items in the cart</Typography>
            </Box>
          )}
      </Grid>
      {cart.length > 0 && (<>
        <Grid item xs={12} sx={{ marginTop: '3rem' }}>
          <Typography variant='h4'>Select Address</Typography>
          <RadioGroup
            value={addressId}
            onChange={(e) => setAddressId(e.target.value)}
          >
            {userInfo.addresses.length > 0 ? userInfo.addresses.map((address, index) => {
              return <FormControlLabel value={address.id} control={<Radio />} label={`${address.name}:  ${address.address}`} />
            }) : <Typography>No address: Please add an address to proceed</Typography>}
          </RadioGroup>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '3rem',
            }}
          >
            <Button onClick={handlePlaceOrder} variant='contained'>
              Place order
            </Button>
          </Box>
        </Grid>
      </>
      )}
    </Grid>
  );
};

export default Cart;
