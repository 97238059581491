import React, { useEffect, useState, useRef } from 'react';
import ProductsBody from './ProductsBody';
import ProductsHeader from './ProductsHeader';

const Products = () => {
  const [height, setHeight] = useState();
  const headerHeight = useRef();

  useEffect(() => {
    const hei = headerHeight.current.offsetHeight;
    setHeight(hei);
  }, []);
  return (
    <>
      <ProductsHeader headerRef={headerHeight} />
      <ProductsBody height={height} />
    </>
  );
};

export default Products;
