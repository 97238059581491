import actionTypes from './actionTypes';
import { createReducer, createAction } from '@reduxjs/toolkit';

// import { toast } from 'react-toastify';

// create all the actions
const newShipmentCartDispatch = createAction(actionTypes.NEW_SHIPMENT_CART);

export const getShipmentCartAction = (group, email) => {
  return async (dispatch) => {
    if (!group || !email) return;
    const shipmentCart = localStorage.getItem(`shipment-cart-${group}-${email}`) || JSON.stringify([]);
    const newCart = JSON.parse(shipmentCart);
    localStorage.setItem(`shipment-cart-${group}-${email}`, JSON.stringify(newCart));

    dispatch(newShipmentCartDispatch(newCart))
  };
};

export const addProductToShipmentCartAction = (group, email, cartItem, order) => {
  return async (dispatch) => {
    if (!group && !email && !cartItem) return;
    const shipmentCart = localStorage.getItem(`shipment-cart-${group}-${email}`) || JSON.stringify([]);

    const newItem = { ...order, ...cartItem }
    delete newItem.orderItems
    const newCart = [...JSON.parse(shipmentCart), newItem]

    // check for adding item in the cart is in the add to cart function
    localStorage.setItem(`shipment-cart-${group}-${email}`, JSON.stringify(newCart));

    dispatch(newShipmentCartDispatch(newCart))
  };
};

export const removeProductFromShipmentCartAction = (group, email, cartItemId) => {
  return async (dispatch) => {
    if (!group && !email && cartItemId) return;

    const shipmentCart =
      JSON.parse(localStorage.getItem(`shipment-cart-${group}-${email}`)) || [];


    if (!shipmentCart || shipmentCart.length <= 0) return;

    const newCart = shipmentCart.filter((item) => {
      return item.id !== cartItemId
    });

    localStorage.setItem(`shipment-cart-${group}-${email}`, JSON.stringify(newCart));

    dispatch(newShipmentCartDispatch(newCart))
  };
};


export const addShipmentCartItemDescriptionAction = (group, email, itemId, description) => {
  return (dispatch) => {
    const shipmentCart = JSON.parse(localStorage.getItem(`shipment-cart-${group}-${email}`));

    const newItem = shipmentCart.find((item) => item.id === itemId);

    newItem.description = description;

    const untouched = shipmentCart.filter((item) => item.id !== itemId);

    const newCart = [...untouched, newItem];
    localStorage.setItem(`shipment-cart-${group}-${email}`, JSON.stringify(newCart));

    dispatch(newShipmentCartDispatch(newCart))
  };
};

export const addShipmentCartItemFileAction = (group, email, itemId, fileUrl) => {
  return (dispatch) => {
    const shipmentCart = JSON.parse(localStorage.getItem(`shipment-cart-${group}-${email}`));

    const newItem = shipmentCart.find((item) => item.id === itemId);

    newItem.sourceFileUrl = fileUrl;

    const untouched = shipmentCart.filter((item) => item.id !== itemId);

    const newCart = [...untouched, newItem];
    localStorage.setItem(`shipment-cart-${group}-${email}`, JSON.stringify(newCart));

    dispatch(newShipmentCartDispatch(newCart))
  };
};

export const deleteShipmentCartAction = (group, email) => {
  return (dispatch) => {
    const newCart = [];
    localStorage.setItem(`shipment-cart-${group}-${email}`, JSON.stringify(newCart));

    dispatch(newShipmentCartDispatch(newCart))
  };
};


const shipmentCartReducer = createReducer([], (builder) => {
  builder
    .addCase(newShipmentCartDispatch, (state, action) => {
      return action.payload
    })
    .addDefaultCase((state) => state);
});


export default shipmentCartReducer;

// const shipmentCartReducer = (state = [], action) => {
//   switch (action.type) {
//     case actionTypes.NEW_SHIPMENT_CART:
//       return [...action.data];
//     default:
//       return state;
//   }
// };