import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Box } from '@mui/material';
import { getAllProductUtilDataAction } from '../../../../../reducer_action/productUtil';
import CategoryCard from './CategoryCard';

const CategoryBody = ({ height, categoryGroup }) => {
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.productUtil.category);

  useEffect(() => {
    dispatch(getAllProductUtilDataAction());
  }, [dispatch]);

  console.log({ categoryGroup })

  return (
    <Grid
      container
      item
      xs={12}
      alignContent='flex-start'
      sx={{
        overflow: 'scroll',
        height: `calc(100vh - ${height}px)`,
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        padding: '1rem 1rem 1rem 1rem',
      }}
    >
      {categories &&
        categories.length > 0 &&
        categories
          .filter(x => categoryGroup === 'all' ? true : x.categoryGroupId === categoryGroup)
          .map((x) => {
            return (
              <Grid container item xs={12} >
                <CategoryCard item={x} category={x} />
              </Grid>
            );
          })}
    </Grid>
  );
};

export default CategoryBody;
