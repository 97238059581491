import React, { useState } from 'react';
import { dashSplitter, underscoreSplitter } from '../../utilFunctions';
import { Box, Typography, Grid, TextField, Switch, Select, MenuItem, ListItemText, } from '@mui/material';

const EditTableView = ({
  title,
  value,
  onChange,
  type = 'text',
  selectValues = ['asdfk', 'asdfh'],
  compareValue = null,
}) => {
  return (
    <Grid container item xs={12} justifyContent='space-between' alignItems='center' sx={{ borderBottom: '1px solid', borderColor: 'secondary.underLine', padding: '0.5rem 0rem', }} >
      <Grid item xs={6} sx={{}}>
        <Typography
          sx={{
            fontWeight: '600',
            textTransform: 'capitalize',
            fontSize: '18px',
            color: value === compareValue ? 'primary.dark' : 'red',
          }}
        >
          {title}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          {type === 'text' && <TextField value={value} onChange={onChange} />}
          {type === 'multiline-text' && (<TextField multiline value={value} fullWidth onChange={onChange} />)}
          {type === 'switch' && (<Switch onChange={onChange} checked={Boolean(value)} />)}
          {type === 'select' &&
            (<Select variant='standard'
              autoWidth={true}
              value={value}
              onChange={onChange}
              sx={{
                color: 'primary.main',
                fontWeight: '800',
              }}
            >
              <MenuItem value={'undefined'}>
                <ListItemText>undefined</ListItemText>
              </MenuItem>
              {selectValues.map((x) => {
                return (
                  <MenuItem
                    key={x.id || x}
                    value={x.id || x}
                    sx={{ color: 'white' }}
                  >
                    <ListItemText>
                      {x.name || x.rate || dashSplitter(underscoreSplitter(x))}
                    </ListItemText>
                  </MenuItem>
                );
              })}
            </Select>)}
          {type === 'multiSelect' && (
            <Select
              variant='standard'
              multiple
              autoWidth={true}
              value={value}
              onChange={onChange}
              sx={{
                color: 'primary.main',
                fontWeight: '800',
                minWidth: '290px'
              }}
            >
              <MenuItem value={'unassigned'}>
                <ListItemText>undefined</ListItemText>
              </MenuItem>
              {selectValues.map((x) => {
                return (
                  <MenuItem
                    key={x.id || x}
                    value={x.id || x}
                    sx={{ color: 'white' }}
                  >
                    <ListItemText primary={x?.name || x?.rate || dashSplitter(underscoreSplitter(x))} />
                  </MenuItem>
                );
              })}
            </Select>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default EditTableView;
