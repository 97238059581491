import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Select, MenuItem, InputAdornment, Grid, Box, OutlinedInput } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import { useSelector } from 'react-redux';

const ClientHeader = ({ headerRef, cognitoGroup, setCognitoGroup, clientEmail, setClientEmail }) => {
  const [tempClientEmail, setTempClientEmail] = useState('')
  const util = useSelector(state => state?.util)
  const cognitoGroups = useSelector(state => state?.productUtil.cognitoGroups)

  const handleKeyPress = (e) => {
    console.log(e.key, 'keyPress')
    if (e.key === 'Enter' || e.which === 'Enter') {
      setClientEmail(tempClientEmail)
    }
  }

  return (
    <Grid container ref={headerRef} justifyContent='space-between' alignItems='flex-start' >
      <Grid item xs={12}>
        <Box sx={{ padding: '1rem 1rem' }}>
          <OutlinedInput id='standard-search' value={tempClientEmail} fullWidth onChange={(e) => setTempClientEmail(e.target.value)} onKeyPress={handleKeyPress} sx={{ margin: '0' }}
            endAdornment={
              <InputAdornment position='end'>
                <SearchIcon sx={{ color: 'black' }} />
              </InputAdornment>
            }
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: util.mainSideBar ? 'space-between' : 'flex-end',
            alignItems: util.mainSideBar ? 'flex-end' : 'flex-start',
            padding: '0 1rem ',
          }}
        >
          <Select
            fullWidth
            value={cognitoGroup}
            sx={{
              color: 'primary.main',
              fontWeight: '800',
            }}
            onChange={(e) => setCognitoGroup(e.target.value)}
          >
            <MenuItem value={'ALL'}>all</MenuItem>
            {cognitoGroups.filter(x => !['admin',
              // 'godown',
              'order_fullfillment'].includes(x.GroupName)).map((group) => {
                return <MenuItem key={group.GroupName} value={group.GroupName}>{group.GroupName}</MenuItem>
              })}
          </Select>
        </Box>
      </Grid>
    </Grid>
  )
}

export default ClientHeader