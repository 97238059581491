import React from 'react';
import { Typography, Box } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const FileInput = ({ file, setFile, fileName, setFileName }) => {
  return (
    <>
      <label>
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', padding: '2rem 5rem', border: '1px solid #B6C7D2', background: '#B6C7D233', }} >
          <input
            type='file'
            style={{ display: 'none' }}
            onChange={async (e) => {
              // console.log(e.target.files);
              // console.log(e.target.files[0].name, file);
              setFileName(e.target.files[0].name);
              setFile(e.target.files[0]);
            }}
          />
          <CloudUploadIcon
            sx={{
              width: '100px',
              fill: 'primary.main',
              display: 'block',
              margin: '0 auto 1rem',
            }}
          />
          <Typography align='center' variant='body1'>
            {fileName ? fileName : 'Upload file'}
          </Typography>{' '}
        </Box>
      </label>
    </>
  );
};

export default FileInput;
