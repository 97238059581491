import React from 'react'
import { Grid, } from '@mui/material'
import { useLocation } from 'react-router-dom'
import View from './pages/View'
import Orders from './pages/orders/Orders'
import CreateOrder from './pages/CreateOrder/CreateOrder'
import Cart from './pages/cart/Cart'
import Shipments from './pages/shipment/Shipments'

const ClientBody = ({ client, headerHeight }) => {
  const location = useLocation()
  const useQuery = () => new URLSearchParams(location.search);
  const query = useQuery()
  const action = query.get('action');

  return (
    <Grid item xs={12} container
      sx={{
        padding: ' 0 1rem',
        height: 'calc(100vh - ' + headerHeight + 'px)',
        overflow: 'scroll',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      }}
    >
      {action === 'View' && <View client={client} />}
      {action === 'Orders' && <Orders client={client} headerHeight={headerHeight} />}
      {/* to do this header height needs to have the 1 rem padding added to it */}
      {action === 'Create_Order' && <CreateOrder headerHeight={headerHeight} client={client} />}
      {action === 'Cart' && <Cart headerHeight={headerHeight} client={client} />}
      {action === 'Shipments' && <Shipments headerHeight={headerHeight} client={client} />}
    </Grid >
  )
}

export default ClientBody 