import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useParams, useNavigate } from 'react-router-dom';
import { updateItemOptionValueTableAction, postItemOptionValueTableAction, } from '../../../../../../reducer_action/options';
import EditTableView from '../../../../../utilComponents/EditTableView';
import CenterBox from '../../../../../utilComponents/CenterBox';
import { Grid, Switch, TextField, Typography, Table, TableHead, TableBody, TableRow, TableContainer, TableCell, Box, Button, Modal, } from '@mui/material';

const OptionValues = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const option = useSelector((state) =>
    state.option.find((op) => op.id === +params.option)
  );

  const [optionValues, setOptionValues] = useState(option.values);

  const [addOptionValueModal, setAddOptionValueModal] = useState(false);

  // add option state
  const [optionValueName, setOptionValueName] = useState('');
  const [description, setDescription] = useState('');
  const [setupCost, setSetupCost] = useState(0);
  const [weight, setWeight] = useState(0);
  const [status, setStatus] = useState(true);
  const [sort, setSort] = useState(0);

  const replaceIndexOfOptionValues = (index, newValue, array) => {
    const newOptionValue = array.map((x, n) => (n === index ? newValue : x));
    setOptionValues(newOptionValue);
  };

  const handleUpdateValue = (id, data) => {
    dispatch(updateItemOptionValueTableAction(id, data));
  };

  const handleAddOptionValue = () => {
    dispatch(postItemOptionValueTableAction({ optionId: params.option, optionValueName, description, setupCost, weight, status, sort, }));
    setAddOptionValueModal(false);
    navigate('?action=view');
  };

  return (
    <Grid item xs={12} container sx={{ marginTop: '0rem' }}>
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', }} >
          <Button variant='contained' onClick={() => setAddOptionValueModal(true)} >Add value</Button>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {['Option name', 'Description', 'Specs', 'Status', 'Action',].map((x) => {
                  return (
                    <TableCell key={x}>
                      <Typography variant='h6'>{x}</Typography>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {option && optionValues.map((value, index, array) => {
                return (
                  <TableRow key={value.id}>
                    <TableCell>
                      <TextField
                        value={value.optionValueName}
                        onChange={(e) => {
                          const newValue = {
                            ...value,
                            optionValueName: e.target.value,
                          };
                          replaceIndexOfOptionValues(index, newValue, array);
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        multiline
                        value={value.description}
                        onChange={(e) => {
                          const newValue = {
                            ...value,
                            description: e.target.value,
                          };
                          replaceIndexOfOptionValues(index, newValue, array);
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                        <TextField
                          value={value.setupCost}
                          onChange={(e) => {
                            const newValue = {
                              ...value,
                              setupCost: e.target.value,
                            };
                            replaceIndexOfOptionValues(index, newValue, array);
                          }}
                          label='setup cost'
                        />
                        <TextField
                          value={value.weight}
                          onChange={(e) => {
                            const newValue = {
                              ...value,
                              weight: e.target.value,
                            };
                            replaceIndexOfOptionValues(index, newValue, array);
                          }}
                          label='weight'
                        />
                        <TextField
                          value={value.sort}
                          onChange={(e) => {
                            const newValue = {
                              ...value,
                              sort: e.target.value,
                            };
                            replaceIndexOfOptionValues(index, newValue, array);
                          }}
                          label='sort'
                        />
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Switch
                        checked={value.status}
                        onChange={(e) => {
                          const newValue = {
                            ...value,
                            status: e.target.checked,
                          };
                          replaceIndexOfOptionValues(index, newValue, array);
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <Button
                        variant='contained'
                        onClick={() => handleUpdateValue(value.id, value)}
                      >
                        save
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Modal
        open={addOptionValueModal}
        onClose={() => console.log('modal closed')}
      >
        <Box>
          <CenterBox>
            <Grid container>
              <Grid item xs={12}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    variant='contained'
                    onClick={() => setAddOptionValueModal(false)}
                  >
                    close
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12} sx={{ marginBottom: '2rem' }}>
                <Typography align='center' variant='h4'>
                  Add option Value
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <EditTableView
                  title='option value name'
                  type='text'
                  value={optionValueName}
                  onChange={(e) => setOptionValueName(e.target.value)}
                />
                <EditTableView
                  title='description'
                  type='text'
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
                <EditTableView
                  title='setup cost'
                  type='text'
                  value={setupCost}
                  onChange={(e) => setSetupCost(+e.target.value)}
                />
                <EditTableView
                  title='weight'
                  type='text'
                  value={weight}
                  onChange={(e) => setWeight(+e.target.value)}
                />
                <EditTableView
                  title='status'
                  type='switch'
                  value={status}
                  onChange={(e) => setStatus(e.target.checked)}
                />
                <EditTableView
                  title='sort'
                  type='text'
                  value={sort}
                  onChange={(e) => setSort(+e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ marginTop: '2rem' }}>
                  <Button variant='contained' onClick={handleAddOptionValue}>
                    save
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </CenterBox>
        </Box>
      </Modal>
    </Grid>
  );
};

export default OptionValues;
