import React from "react";
import { TextField, Grid, Typography, Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setUtilAction } from "../../../../reducer_action/util";

const AddSalaryAdvance = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h4" align="center">
          Salary Advance Request - date
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          onClick={() => {
            navigate("/asx/salaryAdvance");
            dispatch(setUtilAction({ actionQuery: null }));
          }}
        >
          close
        </Button>
      </Grid>
      <Grid item xs={12}>
        <TextField label="amount" />
      </Grid>
      <Grid item xs={12}>
        <TextField label="employee name and aadhaar number" />
      </Grid>
      <Grid item xs={12}>
        <Typography>Branch Name</Typography>
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained">save</Button>
      </Grid>
    </Grid>
  );
};

export default AddSalaryAdvance;
