import React from 'react'
import { useLocation } from 'react-router-dom'
import View from './pages/View'

const OrderGuidelineBody = ({ orderUnit }) => {

  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const action = query.get('action');

  return (
    <>
      {orderUnit && action === 'view' && <View orderUnit={orderUnit} />}
    </>
  )
}

export default OrderGuidelineBody