import React from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import { setUtilAction } from '../../../../../reducer_action/util';

import { Box, Grid, Typography } from '@mui/material';

const Card = ({ product }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const mainSideBar = useSelector((state) => state.util.mainSideBar);

  const defaultPage = 'order'

  const category = useSelector(state => state.productUtil.category.find(x => x.id === product.categoryId))

  return (
    <Grid
      container
      sx={{
        padding: '1rem 1rem',
        cursor: 'pointer',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: 'background.cardBackground',
        margin: '1rem 0',
      }}
      onClick={() => {
        dispatch(setUtilAction({ mainSideBar: true }));
        navigate(`/asx/products/${product.id}?action=${defaultPage}`);
      }}
    >
      <Grid item xs={mainSideBar ? 9 : 3}>
        <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '3px', maxWidth: '60%', minWidth: '10%', }} >
          <Typography noWrap={true} variant='h6' sx={{ textTransform: 'capitalize' }} >
            {product.name}
          </Typography>
          <Typography variant='caption'>{category.name}</Typography>
        </Box>
      </Grid>
      {/* the extra information */}
      {!mainSideBar && (
        <Grid item xs={6}>
          <Box>
            <Box
              sx={{ height: '100%', display: 'flex', alignItems: 'baseline' }}
            >
              <Typography variant='h6' sx={{ paddingRight: '.5rem' }}>
                Size:{' '}
              </Typography>
              {product.size.length > 0
                ? product.size.map((size, index, array) => {
                  const length = array.length;
                  if (array.length === 0) {
                    // console.log("array");
                    return <Typography>this item has no size</Typography>;
                  } else {
                    // size.id === 4 && console.log("design", array);
                    // console.log("array", array);
                    return (
                      <Typography
                        variant='body1'
                        sx={{ paddingRight: '0.5rem' }}
                        key={size.id}
                      >
                        {length - 1 !== index
                          ? size.sizeTitle.toUpperCase().concat(', ')
                          : size.sizeTitle.toUpperCase()}
                      </Typography>
                    );
                  }
                })
                : 'none'}
            </Box>

            <Box
              sx={{ height: '100%', display: 'flex', alignItems: 'baseline' }}
            >
              <Typography variant='h6' sx={{ paddingRight: '0.5rem' }}>
                Options:
              </Typography>
              {product.options.length > 0
                ? product.options.map((option, index, array) => {
                  if (array.length === 0) {
                    return <Typography>this iem has no options</Typography>;
                  } else {
                    return (
                      <Typography
                        variant='body1'
                        sx={{ paddingRight: '0.5rem' }}
                        key={option.id}
                      >
                        {option.name}:{' '}
                        {option.values.map((value, index, array) => {
                          if (array.length - 1 === index) {
                            return value.optionValueName;
                          } else {
                            return value.optionValueName.concat(', ');
                          }
                        })}
                      </Typography>
                    );
                  }
                })
                : 'none'}
            </Box>
          </Box>
        </Grid>
      )}
      <Grid item xs={3}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', }} >
          <Box sx={{ backgroundColor: 'background.activeCardBackground', padding: '0.4rem 0.6rem', maxWidth: 'max-content', }} >
            <Typography>{product.status ? 'active' : 'inactive'}</Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Card;
