import React, { useState } from 'react'

import { Grid, Box, Button } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { putOrderGuideline } from '../../../../../../../service/productUtil'
import { getOneProductAction } from '../../../../../../../reducer_action/product'

import EditTableView from '../../../../../../utilComponents/EditTableView'

const EditAddOrderGuideline = ({ sizeTabId }) => {
  const dispatch = useDispatch()
  const params = useParams()
  const currentProduct = useSelector(state => state.product.find(x => x.id === +params.item))

  const allOrderUnits = useSelector(state => state.productUtil.orderUnit)

  const currentOrderGuideline = currentProduct.orderGuideline.length > 0 && currentProduct.orderGuideline.find(x => x.itemSizeId === sizeTabId)



  const [orderGuideline, setOrderGuideline] = useState(currentOrderGuideline)

  console.log({ sizeTabId, currentOrderGuideline, orderGuideline }, currentOrderGuideline?.itemSizeId)

  const handleOrderGuidelineUpdate = async () => {
    if (!currentOrderGuideline.id) return
    const updatedOrderGuideline = await putOrderGuideline(currentOrderGuideline.id, orderGuideline)
    console.log({ updatedOrderGuideline })
    const itemId = updatedOrderGuideline.itemId
    dispatch(getOneProductAction(itemId))
    console.log({ orderGuideline }, 'handleOrderGuidelineUpdate')
  }

  return (
    <>
      <Grid item xs={12} sx={{ marginTop: '2rem' }}>
        <EditTableView
          title='Order Unit'
          type='select'
          selectValues={allOrderUnits}
          value={orderGuideline.orderUnitId}
          compareValue={currentOrderGuideline.orderUnitId}
          onChange={(e) => setOrderGuideline({ ...orderGuideline, orderUnitId: + e.target.value })}
        />

        <EditTableView title='order min' type='text' value={orderGuideline.minOrderQuantity} onChange={(e) => setOrderGuideline({ ...orderGuideline, minOrderQuantity: + e.target.value })} compareValue={currentOrderGuideline.minOrderQuantity}
        />

        <EditTableView title='order max ' type='text' value={orderGuideline.maxOrderQuantity} onChange={(e) => setOrderGuideline({ ...orderGuideline, maxOrderQuantity: +e.target.value })}
          compareValue={currentOrderGuideline.maxOrderQuantity}
        />

        <EditTableView title='order recommended' type='text' value={orderGuideline.recommendedQuantity} onChange={(e) => setOrderGuideline({ ...orderGuideline, recommendedQuantity: +e.target.value })}
          compareValue={currentOrderGuideline.recommendedQuantity}
        />
      </Grid>

      <Grid item xs={12} sx={{ marginTop: '2rem' }}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant='contained' onClick={handleOrderGuidelineUpdate}>save</Button>
        </Box>
      </Grid>
    </>
  )
}

export default EditAddOrderGuideline