import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Grid, Button, Box } from '@mui/material'

import { updateOrderUnit } from '../../../../../../service/productUtil'
import { getAllProductUtilDataAction } from '../../../../../../reducer_action/productUtil'

import TableView from '../../../../../utilComponents/TableView'
import EditTableView from '../../../../../utilComponents/EditTableView'

const View = ({ orderUnit }) => {
  const dispatch = useDispatch()

  const trackingUnits = useSelector(state => state.productUtil.trackingUnit)

  const trackingUnitName = (trackingUnitId) => {
    const trackingUnit = trackingUnits.find(trackingUnit => trackingUnit.id === trackingUnitId)
    return trackingUnit.name
  }

  const handleUpdateOrderUnitStatus = async () => {
    await updateOrderUnit(orderUnit.id, { status: !orderUnit.status })
    // refetch the util data again
    dispatch(getAllProductUtilDataAction())
  }

  return (
    <Grid container item xs={12} alignContent='flex-start'>
      {orderUnit.id &&
        <Grid container sx={{ padding: '0rem 1rem' }} item xs={12} alignContent='flex-start' >
          <Grid item xs={12}>
            <TableView title='Name' value={orderUnit.name} />
            <TableView title='Description' value={orderUnit.description} />
            <TableView title='Tracking Quantity' value={orderUnit.trackingUnitQuantity} />
            <TableView title='Tracking orderUnit' value={trackingUnitName(orderUnit.trackingUnitId)} />
            <TableView title='status' value={orderUnit.status ? 'active' : 'inactive'} />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ marginTop: '2rem', display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant='contained' onClick={handleUpdateOrderUnitStatus}>{orderUnit.status ? 'inactivate' : 'activate'}</Button>
            </Box>
          </Grid>
        </Grid>
      }
    </Grid>
  )
}

export default View