import React, { useState } from 'react';

import userService from '../../service/user.js';
import { Auth } from 'aws-amplify';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { toast } from 'react-toastify';

const SignUp = ({ setTab }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [otp, setOtp] = useState('');

  const [stage, setStage] = useState(1);

  const handleSignUp = async () => {
    if (!email.includes('@')) return
    try {
      await userService.signUp(email, password);
      setStage(2);
    } catch (error) {
      if (error.name === "UsernameExistsException" && error.message === 'An account with the given email already exists.') {
        if (window.confirm('An account with the given email already exists. Would like us to resend an OTP for account verification? Click yes if you are unverified.')) {
          await Auth.resendSignUp(email);
          setStage(2)
          return
        }
        toast.error('An account with the given email already exists.');
        toast.error('please sign in');
        setTab('signIn')
        return
      }
      toast.error(`${error.message}`)
    }
  };

  const handleConfirmSignUp = async () => {
    try {
      await userService.confirmSignUp(email, otp, password);
      setEmail('');
      setOtp('');
      setStage(1);
      setTab('signIn');
      setPassword('');
      setConfirmPassword('');
      setTab('signIn')
    } catch (error) {
      toast.error(`${error.message}`)
      setOtp('')
    }
  };

  return (
    <Grid
      container
      justifyContent='center'
      alignItems='flex-start'
      rowSpacing={3}
      sx={{
        padding: '1rem',
      }}
    >
      {stage === 1 && (
        // we sign up with email and password
        <>
          <Grid item xs={12}>
            <TextField
              fullWidth
              type='email'
              variant='outlined'
              label='email'
              value={email}
              required
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              variant='outlined'
              label='password'
              required
              value={password}
              type='password'
              onChange={(e) => setPassword(e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              variant='outlined'
              label='confirm password'
              value={confirmPassword}
              type='password'
              required
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </Grid>

          <Grid item>
            <Button color='primary' variant='contained' onClick={handleSignUp}>
              Sign up
            </Button>
          </Grid>
        </>
      )}
      {stage === 2 && (
        // we confirm sign up with otp
        <>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant='outlined'
              label='enter verification code'
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
            />
          </Grid>

          <Grid item>
            <Button
              color='primary'
              variant='contained'
              onClick={handleConfirmSignUp}
            >
              verify
            </Button>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default SignUp;

// {/* {stage === 3 && (
//         // add and verify phone number and address
//         <>
//           <Grid item xs={12}>
//             <TextField
//               fullWidth
//               variant='outlined'
//               label='phone number'
//               value={phoneNumber}
//               onChange={(e) => setPhoneNumber(e.target.value)}
//             />
//           </Grid>
//           <Grid item>
//             <Button
//               color='primary'
//               variant='contained'
//               onClick={postAndVerifyPhoneNumber}
//             >
//               verify
//             </Button>
//           </Grid>
//         </>
//       )}
//       {stage === 4 && ( // verify phone number here
//         <>
//           <Grid item xs={12}>
//             <TextField
//               fullWidth
//               variant='outlined'
//               label='verifucation code'
//               value={phoneVerificationCode}
//               onChange={(e) => setPhoneVerificationCode(e.target.value)} />
//           </Grid>
//         </>
//       )} */}