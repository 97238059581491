import React from "react";
import {
  Grid,
  Typography,
  Select,
  MenuItem,
  TextField,
  Button,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setUtilAction } from "../../../../reducer_action/util";

const AddAdvancePayment = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h4" align="center">
          Advance Payment
        </Typography>
        <Typography variant="h4" align="center">
          <Button
            onClick={() => {
              dispatch(
                setUtilAction({ mainSideBar: false, actionQuery: null })
              );
              navigate("/asx/advancePayments");
            }}
          >
            close
          </Button>
        </Typography>
        <Typography variant="h5" align="center">
          Date: 24/1/2022
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ padding: "1rem" }}>
        <Select fullWidth value="1" label="choose client">
          <MenuItem sx={{ color: "white" }} value="1">
            Client name/ username
          </MenuItem>
        </Select>
      </Grid>
      <Grid item xs={12} sx={{ padding: "1rem" }}>
        <TextField fullWidth label="amount" />
      </Grid>
      <Grid item xs={12} sx={{ padding: "1rem" }}>
        <TextField fullWidth multiline label="description" />
      </Grid>
      <Grid item xs={12} sx={{ padding: "1rem" }}>
        <Button variant="contained">save</Button>
      </Grid>
    </Grid>
  );
};

export default AddAdvancePayment;
