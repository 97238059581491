import React from 'react';
import { Button, Box, Typography } from '@mui/material';
import { underscoreSplitter } from '../../../../../../../../utilFunctions';
import { deleteItemRuleAction } from '../../../../../../../../reducer_action/product';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom'

const SizeQuantityOptionCard = ({ rule, optionValueName, hideOptionValueName, sizeNames, }) => {
  const dispatch = useDispatch()
  const params = useParams()
  const handleRuleDelete = (ruleId) => {
    console.log('delete rule', ruleId);
    dispatch(deleteItemRuleAction(+params.item, ruleId, rule.ruleType))
  }
  return (
    <React.Fragment key={rule.id}>
      <Box
        sx={{
          display: 'flex',
          padding: '0.5rem ',
          borderWidth: '1px',
          borderColor: 'secondary.underLine',
          borderStyle: 'solid',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          marginTop: '1rem',
        }}
      >
        <Box sx={{}}>
          <Typography variant='h4'>Title</Typography>
          <Typography variant='body1'>{rule.title}</Typography>
        </Box>
        <Box>
          <Typography variant='h4'>Description</Typography>
          <Typography variant='body1'>{rule.description}</Typography>
        </Box>

        <Box sx={{}}>
          <Typography variant='h4'>Sizes</Typography>
          {/* <Typography variant='body1'> */}
          {sizeNames.map((size, index) => {
            return (
              <Box key={size} sx={{ paddingLeft: '1rem' }}>
                <Typography variant='body1'>
                  {index + 1}: {size}
                </Typography>
              </Box>
            );
          })}
        </Box>

        <Box>
          <Typography variant='h4'>Options</Typography>
          {optionValueName.map((optionString, index) => {
            return (
              <Box key={optionString} sx={{ paddingLeft: '1rem' }}>
                <Typography key={optionString} variant='body1'>
                  {optionString ? `${index + 1}.` : null} {optionString}
                </Typography>
              </Box>
            );
          })}
        </Box>

        <Box>
          <Typography variant='h4'>Hide options:</Typography>
          {hideOptionValueName.map((optionString, index) => {
            return (
              <Box key={optionString} sx={{ paddingLeft: '1rem' }}>
                <Typography key={optionString} variant='body1'>
                  {optionString ? `${index + 1}.` : null} {optionString}
                </Typography>
              </Box>
            );
          })}
        </Box>
        <Box>
          <Typography variant='h4'>Quantity</Typography>
          <Box sx={{ paddingLeft: '1rem' }}>
            {rule.values[0].allQuantity ? (
              <Typography>All quantity</Typography>
            ) : (
              <React.Fragment>
                <Typography variant='body1'>
                  condition: {underscoreSplitter(rule.values[0].evalType)}
                </Typography>
                <Typography>at: {rule.values[0].startQuantity}</Typography>
                {rule.values[0].evalType === 'BETWEEN' && (
                  <Typography>to: {rule.values[0].endQuantity}</Typography>
                )}
              </React.Fragment>
            )}
          </Box>
        </Box>
        <Box>
          <Button variant='contained' onClick={() => handleRuleDelete(rule.id)}> delete </Button>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default SizeQuantityOptionCard;
