import React from "react";
// import * as dotenv from 'dotenv'
import { ToastContainer } from 'react-toastify'
import "react-toastify/dist/ReactToastify.css";

import { createRoot } from "react-dom/client"
import { ThemeProvider } from "@mui/material/styles";

import { BrowserRouter } from "react-router-dom";

import { Amplify } from "aws-amplify";
import awsConfig from "./awsConfig.js";

import { Provider } from "react-redux";
import store from "./store.js";

import theme from "./theme.js";
import App from "./App";

Amplify.configure(awsConfig);

console.log({ process: process.env });

createRoot(document.getElementById("root")).render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <React.StrictMode>
        <BrowserRouter>
          <App />
          <ToastContainer />
        </BrowserRouter>
      </React.StrictMode>
    </ThemeProvider>
  </Provider>
)
