import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setUtilAction } from "../../../../reducer_action/util";
import RequestCard from "./RequestCard";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  Typography,
  Button,
} from "@mui/material";

const ListSalaryAdvance = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography>Salary advance from branchName</Typography>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          onClick={() => {
            navigate("/asx/salaryAdvance?action=add");
            dispatch(setUtilAction({ actionQuery: "add" }));
          }}
        >
          add salary advance
        </Button>
      </Grid>
      <Grid item xs={12}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Request Id</TableCell>
                <TableCell>Branch name</TableCell>
                <TableCell>Employee Id</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>1</TableCell>
                <TableCell>Teynampet</TableCell>
                <TableCell>aadhaar: '2349-2366-4462-2346'</TableCell>
                <TableCell>12440</TableCell>
                <TableCell>
                  salary advance to meet unforeseen expenses of medical expenses
                </TableCell>
                <TableCell>pending/ accepted / rejected</TableCell>
              </TableRow>
              <RequestCard />
              <RequestCard />
              <RequestCard />
              <RequestCard />
              <RequestCard />
              <RequestCard />
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default ListSalaryAdvance;
