import React from 'react'
import { useNavigate } from 'react-router-dom';
import { Grid, Typography, } from '@mui/material'
import Tabs from '../../../../utilComponents/Tabs'

const GodownOrdersHeader = ({ headerRef, order }) => {
  const navigate = useNavigate();
  const routes = ['view'].map(tab => {
    return {
      name: tab,
      onClick: () => navigate(`?action=${tab}`)
    }
  })


  return (
    <Grid ref={headerRef} container item xs={12} sx={{ padding: '1rem 1rem 1rem 1.5rem' }} >
      <Grid container item xs={12} sx={{ marginBottom: '0rem' }}>
        <Grid item xs={4}>
          <Typography variant="h3"> {order.clientEmail && order.clientEmail.split('@')[0].toUpperCase()} </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography align='center' variant="h3">Order Id: {order.id && order?.id}</Typography>
          {order.returnOrder && <Typography sx={{ color: 'red' }} align='center' variant='h3'>Return Order</Typography>}
        </Grid>
        <Grid item xs={4}>
          <Typography align='right' variant="h3">{order.orderDate && order?.orderDate.slice(0, 10)}</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} >
        <Tabs tabs={routes} />
      </Grid>
    </Grid>
  )
}

export default GodownOrdersHeader