import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Auth, } from 'aws-amplify'
import CenterBox from '../../utilComponents/CenterBox'
import { Grid, Button, Typography } from '@mui/material'
import EditTableView from '../../utilComponents/EditTableView'
import { toast } from 'react-toastify'

const ChangePassword = () => {
  const navigate = useNavigate()
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')

  const handleChangePassword = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser()
      if (newPassword.length < 8) return toast.error('password must be at least 8 characters')
      if (!newPassword === confirmNewPassword) return toast.error('passwords do not match')

      await Auth.changePassword(user, oldPassword, newPassword)
    } catch (error) {
      toast.error('Error: change password:' + error.message)
    }
  }

  return (
    <>
      <CenterBox>
        <Grid container sx={{ border: '2px solid grey', padding: '2rem', borderRadius: '10px' }} justifyContent='center' >
          <Grid item xs={12} sx={{ marginBottom: '1rem' }}>
            <Typography align='center' variant='h3'>Change Password</Typography>
          </Grid>
          <Grid item xs={12}>
            <EditTableView title='old password' value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} />
            <EditTableView title='new password' value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
            <EditTableView title='confirm New Password' value={confirmNewPassword} onChange={(e) => setConfirmNewPassword(e.target.value)} />
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '2rem' }}>
            <Button variant='contained' sx={{ margin: '1rem 0' }} onClick={() => navigate('/asx/user')}>Back</Button>
            <Button variant='contained' sx={{ margin: '1rem 0' }} onClick={handleChangePassword}>Change Password</Button>
          </Grid>
        </Grid>
      </CenterBox>
    </>
  )
}

export default ChangePassword