import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#051F30', // the dark blue
      light: "#051F3010", // the lighter version 10 percent opacity
      dark: '#374B59', // the matt blue
    },
    secondary: {
      main: '#301605',
      underLine: '#374B5940',
    },
    background: {
      cardBackground: '#E8EDF0', // non active card background
      activeCardBackground: '#DAE2E8', // active card default
      paper: '#ffffff', // white
      default: '#f8fcff', // this is also used for the main bar //white with a blue hue
      sidebar: '#f2f8fc',
    },

    text: {
      primary: '#051F30',
      secondary: '#364B58',
      white: '#ffffff',
    },
    warning: {
      main: '#ffc300',
    },
    info: {
      main: '#1063ff',
    },
    success: {
      main: '#00c74a',
    },
    divider: 'rgba(5, 31, 48, 0.1)',
    error: {
      main: '#d21000',
    },
  },
  typography: {
    fontFamily: 'Poppins',
    color: '#051F30',
    h1: {
      fontSize: 48,
      fontWeight: 600,
      lineHeight: 1.5,
      color: '#051F30',
    },
    h2: {
      fontSize: 36,
      fontWeight: 600,
      lineHeight: 1.5,
      color: '#051F30',
    },
    h3: {
      fontSize: 24,
      fontWeight: 600,
      lineHeight: 1.5,
      color: '#051F30',
    },
    h4: {
      fontSize: 20,
      fontWeight: 600,
      lineHeight: 1.5,
      color: '#051F30',
    },
    h5: {
      fontSize: 18,
      fontWeight: 600,
      letterSpacing: 1.5,
      color: '#051F30',
    },
    h6: {
      // used for bold lettering in the document setting
      // inline usuage
      fontSize: 16,
      fontWeight: 600,
      lineHeight: 1.5,
      color: '#051F30',
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: 1.5,
      letterSpacing: -0.02,
      color: '#051F30',
    },
    subtitle2: {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: 1.5,
      color: '#364B58',
    },
    body1: {
      fontSize: 16,
      lineHeight: 1.5,
      // color: "#364B58",
      color: '#051F30',
    },
    body2: {
      fontSize: 16,
      lineHeight: 1.5,
      color: '#364B58',
    },
    button: {
      fontWeight: 600,
      fontSize: 16,
      lineHeight: 1.5,
      textTransform: 'capitalize',
      color: '#051F30',
    },
    caption: {
      fontSize: 15,
      lineHeight: 1.5,
      color: '#505A61',
    },
    overline: {
      fontSize: 14,
      lineHeight: 1.5,
      color: '#051F30',
    },
  },
  spacing: 8,
  shape: {
    borderRadius: 4,
  },
  direction: 'rtl',
});

export default theme;
