import React from "react";
import { Button, Grid, Typography, TextField } from "@mui/material";

const AddBankDeposit = () => {
  return (
    <Grid container sx={{ padding: "1rem" }}>
      <Grid item xs={12}>
        <Typography variant="h4" align="center">
          Bank Deposit
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h4" align="left">
          12.12.2020
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h4">client name</Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField fullWidth label="amount" />
      </Grid>
      <Grid item xs={12}>
        <input type="file" />
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained">upload the image url</Button>
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained">save</Button>
      </Grid>
    </Grid>
  );
};

export default AddBankDeposit;
