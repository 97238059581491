import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Typography, Box, TableRow, TableCell, } from '@mui/material'

import { DeleteOutline } from '@mui/icons-material';
import { removeProductFromCartAction } from '../../../../reducer_action/cart'

const ItemRow = ({ item, index }) => {
  const dispatch = useDispatch()
  const option = useSelector((state) => state.option);
  const product = useSelector((state) => state.product);
  const userInfo = useSelector(state => {
    const group = state?.user?.clientGroup
    const email = state?.user.email
    const userName = state?.user?.username
    const addresses = state?.user.addresses.length > 0 && state?.user?.addresses
    return { group, email, userName, addresses }
  })

  const findProductNameFromId = (itemId) => {
    const name = product.find((product) => product.id === itemId).name;
    return name;
  };

  const findProductSizeName = (itemId, sizeId) => {
    console.log({ itemId, sizeId });
    const currentProduct = product.find((x) => x.id === itemId);

    // some products do not have a size and operate entirely on options
    if (!currentProduct.size.length > 0) return null
    const sizeName = currentProduct?.size?.find((x) => x.id === sizeId).sizeTitle;
    return sizeName;
  };

  const findOptionNameFromId = (optionId) => {
    const name = option.find((option) => option.id === optionId).name;
    return name;
  };

  const findOptionValueFromId = (optionId, optionValueId) => {
    const currentOption = option.find((x) => x.id === optionId);
    const valueName = currentOption.values.find((v) => v.id === optionValueId).optionValueName;
    return valueName;
  };

  const deleteCartItem = (group, email) => {
    dispatch(removeProductFromCartAction(group, email, index))
  }

  return (
    <TableRow key={`${item.itemId}-${Math.random()}-${index}`}>
      <TableCell>
        <Typography> {findProductNameFromId(item.itemId)} </Typography>
      </TableCell>
      <TableCell>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem', }} >
          <Box sx={{ display: 'flex', justifyContent: 'center', }} >
            <Typography>Size:</Typography>
            <Typography> {findProductSizeName(item.itemId, item.itemSizeId) || 'none'} </Typography>
          </Box>
          {item.options.map((op, index) => {
            return (
              <Box key={`${op.optionId}-${Math.random()}-${index}`} sx={{ display: 'flex', justifyContent: 'center', }} >
                <Typography sx={{ marginRight: '5px' }}>{findOptionNameFromId(op.optionId)}:</Typography>
                <Typography>{findOptionValueFromId(op.optionId, op.optionValueId)}</Typography>
              </Box>
            );
          })}
        </Box>{' '}
      </TableCell>
      <TableCell>
        <Box>
          <Typography align='center'>{item.quantity}</Typography>
        </Box>
      </TableCell>
      <TableCell>
        <Box sx={{ display: 'flex', flexDirection: 'column', }} >
          {item.mediaUrl === 'no media' ?
            <Typography align='center'>{item.mediaUrl}</Typography>
            :
            <Typography align='center'>
              <a href={item.mediaUrl} style={{ textDecoration: 'none' }} download>download media</a>
            </Typography>
          }
          <Typography align='center'>Notes: {item.notes}</Typography>
        </Box>
      </TableCell>
      <TableCell>
        <Box sx={{ display: 'flex', justifyContent: 'center', }}
          onClick={() => deleteCartItem(userInfo.group, userInfo.email)}
        >
          <DeleteOutline />
        </Box>
      </TableCell>
    </TableRow>
  )
}

export default ItemRow