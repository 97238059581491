import React from "react";
import { TableRow, TableCell, Typography, Box, TextField } from "@mui/material";

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";

const BillingItem = () => {
  return (
    <TableRow>
      <TableCell>
        <Typography>b/w print</Typography>
      </TableCell>
      <TableCell>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography align="left">Size: ~A4</Typography>
          <EditIcon />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}></Box>
      </TableCell>
      <TableCell sx={{ maxWidth: "30px" }}>
        <TextField fullWidth label="Quantity" value="124" />
      </TableCell>
      <TableCell sx={{ maxWidth: "30px" }}>
        <TextField fullWidth label="Unit Price" value="456" />
      </TableCell>
      <TableCell sx={{ maxWidth: "30px" }}>
        <Typography align="left">15660</Typography>
      </TableCell>
      <TableCell>
        <DeleteOutlineIcon />
      </TableCell>
    </TableRow>
  );
};

export default BillingItem;
