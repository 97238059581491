import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, Typography } from '@mui/material'
import { useNavigate, useLocation, } from 'react-router-dom';
import { setUtilAction } from '../../../../../../reducer_action/util';

const GodownShipmentCard = ({ shipment, currentPage, limit, status, itemName, orderByDesc }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const location = useLocation();
  const module = location.pathname.split('/')[1];
  const product = useSelector(state => state.product)

  const mainSideBar = useSelector((state) => state.util.mainSideBar);

  const summaryStatus = () => {
    const shipStatus = shipment.shipmentItems.map(item => item.status)

    if (shipStatus.some(status => status === 'PENDING')) {
      return 'Pending'
    } else if (shipStatus.every(status => status === 'COMPLETED')) {
      return 'Completed'
    } else if (shipStatus.every(status => status === 'SHIPPED')) {
      return 'Shipped'
    } else {
      return 'unknown'
    }
  }

  const findProductNameFromId = (itemId) => {
    const name = product.find((product) => product.id === itemId)?.name;
    return name;
  };

  const findDefaultPage = (module) => {
    switch (module) {
      case 'asx':
        return 'order';
      case 'admin':
        return 'view';
      case 'godown':
        return 'view';
      default:
        return;
    }
  };

  const defaultPage = findDefaultPage(module);

  return (
    <Grid container
      sx={{ padding: '1rem 1rem', cursor: 'pointer', justifyContent: 'space-between', alignItems: 'center', backgroundColor: 'background.cardBackground', margin: '1rem 0', width: '100%' }}
      onClick={() => {
        dispatch(
          setUtilAction({
            mainSideBar: true,
            // actionQuery: defaultPage
          })
        );
        navigate(`/${module}/shipments/${shipment.id}?action=${defaultPage}&currentPage=${currentPage}&limit=${limit}&orderByDesc=${orderByDesc}&status=${status}&itemName=${itemName}`);
      }}
    >
      <Grid item xs={mainSideBar ? 9 : 3}>
        <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '3px', maxWidth: '60%', minWidth: '10%', }} >
          <Typography noWrap={true} variant='h6' sx={{ textTransform: 'capitalize' }} >
            {shipment.shippedTo.email}
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Typography variant='caption'>{shipment.shipDate.toLocaleString().slice(0, 10)}</Typography>
            <Typography variant='caption'>Shipment Id: {shipment.id}</Typography>
          </Box>
        </Box>
      </Grid>
      {/* the extra information */}
      {!mainSideBar && (
        <Grid item xs={6}>
          <Box sx={{ display: 'flex' }}>
            <Box>
              <Typography variant='h6' sx={{ paddingRight: '.5rem' }}>Items:</Typography>
              <Typography align='left'>
                {shipment.shipmentItems.map((item) => `${findProductNameFromId(item.itemId)}-(${item.status.toLowerCase()})`).join(', ')}
              </Typography>
            </Box>
          </Box>
        </Grid>
      )}
      <Grid item xs={3}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            flexDirection: 'column',
            gap: '1rem'
          }}
        >
          <Box
            sx={{
              backgroundColor: 'background.activeCardBackground',
              padding: '0.4rem 0.6rem',
              maxWidth: 'max-content',
            }}
          >
            <Typography>{summaryStatus().toUpperCase()}</Typography>
          </Box>
          <Box
            sx={{
              backgroundColor: 'background.activeCardBackground',
              padding: '0.4rem 0.6rem',
              maxWidth: 'max-content',
            }}
          >
            <Typography>
              {shipment.shippedTo.clientGroup.toUpperCase()}
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}

export default GodownShipmentCard