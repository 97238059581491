import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Grid, Typography, } from '@mui/material'
import Tabs from '../../../utilComponents/Tabs'

const ClientHeader = ({ headerRef, client }) => {

  const navigate = useNavigate()
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery()
  const limit = query.get('limit')
  const cognitoGroup = query.get('cognitoGroup')
  const clientEmail = query.get('clientEmail')

  // const godownTabRoute = ['View', 'Orders', 'Create_Order', 'Cart', 'Shipments', 'Current Inventory', 'Inv_Adjustments']
  const godownTabRoute = ['View', 'Orders', 'Create_Order', 'Cart', 'Shipments']

  const tabs = godownTabRoute.map(route => {
    return {
      name: route,
      onClick: () => {
        navigate(`?action=${route}&limit=${limit}&cognitoGroup=${cognitoGroup}&clientEmail=${clientEmail}`);
      }
    }
  })

  return (
    <>
      {client &&
        <Grid ref={headerRef} container item xs={12} sx={{ padding: '1rem' }}>
          <Grid item xs={12} sx={{ marginBottom: '0.75rem' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="h3">
                {client.email}
              </Typography>
              <Box>
                <Typography variant="h6">{client.Username}</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} >
            <Tabs tabs={tabs} />
          </Grid>
        </Grid>
      }
    </>
  )
}

export default ClientHeader