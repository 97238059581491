export const dashSplitter = (x) => {
  return x.split('-').map((x, i) => {
    if (i === 0) {
      return x.toLowerCase();
    } else if (i > 0) {
      return ` ${x}`.toLowerCase();
    } else {
      return x
    }
  });
};

export const underscoreSplitter = (x) => {
  if (!x) return '';
  const xPlus = x.split('_').map((x, i, array) => {
    if (i === array.length - 1) {
      return x.concat('').toLowerCase();
    } else {
      return x.concat(' ').toLowerCase();
    }
  });
  return xPlus.toString().replaceAll(',', ' ');
};


export const sizeQuantityRule = (size, quantity) => {
  console.log('size quantity rule')
}