import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import TableView from '../../../../../utilComponents/TableView';

import {
  Grid,
  Typography,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
} from '@mui/material';

const View = () => {
  const params = useParams();
  const option = useSelector((state) =>
    state.option.find((op) => op.id === +params.option)
  );
  const items = useSelector((state) => state.product);

  const itemsIds = [...new Set(option.items.map((x) => x.itemId))];

  const optionItems = items.filter((x) => itemsIds.includes(x.id));
  const itemNames = optionItems.map((x) => ' ' + x.name);
  const priceLists = useSelector((state) => state.productUtil.priceList);

  const nameOfPriceListId = (id) => {
    const name = priceLists.find((x) => x.id === id).name;
    return name;
  };

  return (
    <Grid container sx={{ padding: '0rem 1rem' }}>
      <Grid container item xs={12}>
        <Grid item xs={12} columnSpacing={2}>
          <TableView title='name' value={option.name} />
          <TableView title='required' value={option.required.toString()} />
          <TableView
            title='render type'
            value={option.renderType.toLowerCase()}
          />
          <TableView
            title={'Price Range Lookup'}
            value={option.priceRangeLookup?.toLowerCase()}
          />
          <TableView
            title={'weight range lookup'}
            value={option.weightRangeLookup?.toLowerCase() || 'not applicable'}
          />
          <TableView
            title={'apply linear formula'}
            value={option.applyLinearFormula.toString()}
          />
          <TableView title={'items'} value={itemNames.toString()} />
          {/* todo ::: add teh item that this option is related to here */}
        </Grid>
      </Grid>

      <Grid container item xs={12} sx={{ marginTop: '2rem' }}>
        <Grid
          item
          xs={12}
          sx={{
            padding: '0.5rem 0',
            borderBottom: '1px solid',
            borderColor: 'secondary.underLine',
          }}
        >
          <Typography variant='h3'>Option Values</Typography>
        </Grid>
        <Grid item xs={12}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {[
                    'Name',
                    'Description',
                    'setup cost',
                    'weight',
                    'status',
                    'sort',
                    // 'price range',
                    // 'price unit',
                  ].map((x) => {
                    return (
                      <TableCell key={x}>
                        <Typography variant='h6'>{x}</Typography>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {option.values.map((value) => {
                  return (
                    <TableRow>
                      <TableCell>
                        <Typography variant='body1'>
                          {value.optionValueName}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant='body1'>
                          {value.description}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography align='center' variant='body1'>
                          {value.setupCost}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography align='center' variant='body1'>
                          {value.weight}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography align='center' variant='body1'>
                          {value.status.toString()}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography align='center' variant='body1'>
                          {value.sort}
                        </Typography>
                      </TableCell>
                      {/* <TableCell>
                        <Typography align='center' variant='body1'>
                          {value.priceUnit || 'not applicable'}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography align='center' variant='body1'>
                          {value.priceRangeLookup || 'not applicable'}
                        </Typography>
                      </TableCell> */}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid spacing={2} container item xs={12} sx={{ padding: '1rem' }}>
          <Grid
            item
            xs={12}
            sx={{
              borderBottom: '1px solid',
              borderColor: 'secondary.underLine',
              paddingBottom: '1rem',
            }}
          >
            <Typography variant='h4' sx={{ marginTop: '2rem' }}>
              Prices:{' '}
              {option?.priceRangeLookup
                ? option.priceRangeLookup.toLowerCase()
                : 'no price range'}
              {',  '}
              {option?.priceUnit ? ` (${option.priceUnit})` : 'no units'}
            </Typography>
          </Grid>
          {/* todo :: use a table format to show all the option pricing for each value in use the sort algo */}
          {/*item option , start quantity , price and pricelist  */}
          <Grid item xs={12}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography variant='h6'>Option value name</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant='h6'>start range value</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant='h6'>pricelist</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant='h6'>price</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {option.values.map((value) => {
                    return value.prices.map((price) => {
                      return (
                        <TableRow key={price.id}>
                          <TableCell>{value.optionValueName}</TableCell>
                          <TableCell>{price.startRangeValue}</TableCell>
                          <TableCell>
                            {nameOfPriceListId(price.priceListId)}
                          </TableCell>
                          <TableCell>{price.price}</TableCell>
                        </TableRow>
                      );
                    });
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default View;
