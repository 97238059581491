import React from "react";
import { Grid, TableRow, TableCell } from "@mui/material";

const RequestCard = () => {
  return (
    <TableRow>
      <TableCell>1</TableCell>
      <TableCell>Teynampet</TableCell>
      <TableCell>aadhaar: '2349-2366-4462-2346'</TableCell>
      <TableCell>12440</TableCell>
      <TableCell>
        salary advance to meet unforeseen expenses of medical expenses
      </TableCell>
      <TableCell>pending/ accepted / rejected</TableCell>
    </TableRow>
  );
};

export default RequestCard;
