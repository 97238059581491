import React, { useState } from 'react'
import { Box, Grid, Typography, Button } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import EditTableView from '../../utilComponents/EditTableView'
import { editUserAddressAction } from '../../../reducer_action/user'

const EditAddress = () => {
  const params = useParams()
  console.log({ params })

  const address = useSelector(state => state.user.addresses.find(x => x.id === +params.addressId))

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [name, setName] = useState(address.name)
  const [newAddress, setNewAddress] = useState(address.address)
  const [pincode, setPincode] = useState(address.pincode)
  const [latitude, setLatitude] = useState(address.lngLat.y)
  const [longitude, setLongitude] = useState(address.lngLat.x)

  const handleEditAddress = () => {
    console.log('post address', name, newAddress, pincode, latitude, longitude)
    dispatch(editUserAddressAction({ addressId: address.id, name, address: newAddress, pincode, latitude, longitude }))
    navigate('/asx/user')
  }

  return (
    <Grid container item xs={12} spacing={3} sx={{ padding: '1rem' }}>
      <Grid item xs={12} sx={{ marginTop: '0rem' }}>
        <Typography variant="h2">Edit Address</Typography>
      </Grid>
      <Grid item xs={12}>
        <EditTableView title='name' type='text' value={name} onChange={(e) => setName(e.target.value)} />
        <EditTableView title='address' type='multiline-text' value={newAddress} onChange={(e) => setNewAddress(e.target.value)} />
        <EditTableView title='pincode' type='text' value={pincode} onChange={(e) => setPincode(e.target.value)} />
        <EditTableView title='latitude' type='text' value={latitude} onChange={(e) => setLatitude(e.target.value)} />
        <EditTableView title='longitude' type='text' value={longitude} onChange={(e) => setLongitude(e.target.value)} />
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '2rem' }}>
          <Button variant='contained' onClick={() => navigate('/asx/user')}>back</Button>
          <Button variant='contained' onClick={handleEditAddress}> save this address</Button>
        </Box>
      </Grid>
    </Grid>

  )
}

export default EditAddress