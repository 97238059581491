import React, { useState } from 'react';
import { Grid, Box, Button, Typography } from '@mui/material';
import EditTableView from '../../../../../../../utilComponents/EditTableView';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { postItemRuleAction } from '../../../../../../../../reducer_action/product';


const OptionSizeHide = ({ ruleType }) => {
  const dispatch = useDispatch();
  const location = useLocation()
  const navigate = useNavigate()
  const params = useParams();
  const itemSize = useSelector(
    (state) => state.product.find((x) => x.id === +params.item).size
  );
  const itemOption = useSelector(
    (state) => state.product.find((x) => x.id === +params.item).options
  );

  const optionSelectValues = itemOption
    .map((option) => {
      const selectValuesOptions = option.values.map((value) => {
        return {
          name: `${option.name}-${value.optionValueName}`,
          id: value.id,
        };
      });
      return selectValuesOptions;
    })
    .flat();

  const [ruleTitle, setRuleTitle] = useState('');
  const [ruleDescription, setRuleDescription] = useState('');

  const [sizeId, setSizeId] = useState([]);
  const [optionIds, setOptionIds] = useState([]);

  const handleSubmit = () => {
    const rule = { itemId: +params.item, ruleTitle, ruleDescription, sizeIds: sizeId, optionIds, ruleType, };
    dispatch(postItemRuleAction(rule));
    navigate('?action=view')
  };

  return (
    <Grid container item xs={12} sx={{ marginLeft: '1rem' }}>
      <Grid item xs={12}>
        <Typography variant='h4'>Option hides size</Typography>
      </Grid>
      <Grid item xs={12}>
        <EditTableView
          type='text'
          title='rule name'
          value={ruleTitle}
          onChange={(e) => setRuleTitle(e.target.value)}
        />

        <EditTableView
          type='text'
          title='rule description'
          value={ruleDescription}
          onChange={(e) => setRuleDescription(e.target.value)}
        />

        <EditTableView
          title='option'
          value={optionIds}
          type='multiSelect'
          selectValues={optionSelectValues}
          onChange={(e) => setOptionIds(e.target.value)}
        />
        <EditTableView
          title='select sizes'
          type='multiSelect'
          value={sizeId}
          onChange={(e) => setSizeId(e.target.value)}
          selectValues={itemSize.map((x) => {
            const mappedSize = {
              name: x.sizeTitle,
              id: x.id,
            };
            return mappedSize;
          })}
        />
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem', }} >
          <Button variant='contained' onClick={handleSubmit}>save</Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default OptionSizeHide;
