import React from 'react';
import { Typography, Box } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import { downloadZip } from 'client-zip';

const FileMultipleInput = ({ file, setFile, fileName, setFileName }) => {
  // const [fileName, setFileName] = useState('');
  return (
    <>
      <label>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            padding: '1rem 2rem',
            border: '1px solid #B6C7D2',
            background: '#B6C7D233',
          }}
        >
          <input
            type='file'
            style={{ display: 'none' }}
            onChange={async (e) => {
              // // new testing === this now works
              const files = e.target.files;
              const content = await downloadZip(files).blob();

              let fileNewName = '';

              for (file of files) {
                fileNewName += file.name.slice(0, 10) + '_';
              }
              const newFileName = `${fileNewName}_orderFile.zip`;
              const unifiedFile = new File([content], newFileName, {
                type: 'application/zip',
              });
              // console.log(content, files, 'files and content', unifiedFile);
              setFileName(newFileName);
              setFile(unifiedFile);
            }}
            multiple
          />
          <CloudUploadIcon
            sx={{
              width: '100px',
              fill: 'primary.main',
              display: 'block',
              margin: '0 auto 1rem',
            }}
          />
          <Typography align='center' variant='body1'>
            {fileName ? `${fileName.slice(33, 48)}.....` : 'Upload file'}
          </Typography>{' '}
        </Box>
      </label>
    </>
  );
};

export default FileMultipleInput;
