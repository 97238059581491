import React, { useState, useEffect, useRef } from 'react'
import { Button, Grid, Typography, Box, TableBody, TableHead, Table, TableContainer, TableCell, TableRow } from '@mui/material'
import { useParams, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

// state management
import { getOneShipment } from '../../../../../../../service/shipments'
import { getAllOptionsAction } from '../../../../../../../reducer_action/options'
import { getAllProductUtilDataAction } from '../../../../../../../reducer_action/productUtil'
import { getAllProductsAction } from '../../../../../../../reducer_action/product'

// print 
import { useReactToPrint } from 'react-to-print'
import asxLogo from '../../../../../../../data/imgs/asxLogo.svg'

const DC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { shipmentId } = useParams()
  const printShipmentRef = useRef()

  const user = useSelector(state => state.user)
  const product = useSelector(state => state.product)
  const option = useSelector(state => state.option)
  const trackingUnits = useSelector(state => state.productUtil.trackingUnit)
  const orderUnits = useSelector(state => state.productUtil.orderUnit)

  const [shipment, setShipment] = useState({})

  useEffect(() => {
    dispatch(getAllProductsAction());
    dispatch(getAllOptionsAction());
    dispatch(getAllProductUtilDataAction());
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      const shipments = await getOneShipment({ shipmentId })
      setShipment(shipments)
    })()
  }, [shipmentId])

  const handlePrint = useReactToPrint({
    content: () => printShipmentRef.current
  })

  const findProductNameFromId = (itemId) => {
    const name = product.find((product) => product.id === itemId)?.name;
    return name;
  };

  const findProductSizeName = (itemId, sizeId) => {
    const currentProduct = product.find((x) => x.id === itemId);
    const sizeName = currentProduct?.size.find((x) => x?.id === sizeId)?.sizeTitle;
    return sizeName;
  };

  const findOptionNameFromId = (optionId) => {
    const name = option.find((option) => option?.id === optionId)?.name;
    return name;
  };

  const findOptionValueFromId = (optionId, optionValueId) => {
    const currentOption = option.find((x) => x.id === optionId);
    const valueName = currentOption?.values.find(
      (v) => v.id === optionValueId
    ).optionValueName;
    return valueName;
  };



  const findOrderUnit = (orderUnitId, quantity) => {
    const orderUnit = orderUnits.find((x) => x.id === orderUnitId);
    const trackingUnitName = trackingUnits.find((x) => x.id === orderUnit.trackingUnitId)?.name;
    const trackingUnitQuantity = orderUnit.trackingUnitQuantity;

    return `Total: ${quantity * trackingUnitQuantity} ${trackingUnitName}`
  }

  console.log({ shipment })

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem', padding: '1rem' }}>
        <Button variant='contained' onClick={() => navigate(-1)}>Back</Button>
        <Button variant='contained' onClick={handlePrint}>print</Button>
      </Box>
      {
        // the print section
        product && option && shipment && shipment.shippedTo && orderUnits && trackingUnits && user && user.addresses &&
        <Box sx={{ padding: '1rem' }}>
          <Grid ref={printShipmentRef} container item xs={12} sx={{ '@page': { size: 'A4', margin: '2cm', } }} >
            <Grid item xs={12} sx={{}}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: '100%' }}>
                  <img src={asxLogo} alt='logo' style={{ width: '200px', height: '200px', marginRight: '-3rem', marginLeft: '-4rem', fill: '#F58634' }} />
                  <Box sx={{}}>
                    <Typography variant='h2'>Delivery Challan</Typography>
                    <Typography variant='h3' align='right'>{shipment?.shipDate && new Date(shipment.shipDate).toLocaleDateString()}</Typography>
                  </Box>
                </Box>
                <Box sx={{ paddingTop: '0rem', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                  <Typography variant='h3' align='right'>id: {shipment?.id}</Typography>
                  <Typography variant='h5' align='right'> Goods received by: </Typography>
                  <Typography sx={{ marginTop: '2rem' }}> ____________________________ </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6} sx={{ marginTop: '1rem', paddingRight: '1rem' }}>
              <Box sx={{ marginTop: '1rem', display: 'flex', justifyContent: 'flex-start', }}>
                <Box>
                  <Typography variant='h4'>From:</Typography>
                  <Typography align='right' variant='h6'>{shipment.senderUsername?.email}</Typography>
                  <Typography align='right' variant='h6'> {shipment.senderAddressId.name} </Typography>
                  <Typography align='right' variant='body1'> {shipment.senderAddressId.address} </Typography>
                  <Typography align='right' variant='body1'> {shipment.senderAddressId.pincode} </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6} sx={{ marginTop: '1rem' }}>
              <Box sx={{ marginTop: '1rem', display: 'flex', justifyContent: 'flex-end', }}>
                <Box>
                  <Typography variant='h4' align='left'>To:</Typography>
                  <Typography align='right' variant='h6'>{shipment?.shippedTo?.email}</Typography>
                  <Typography align='right' variant='h6'> {shipment?.shippedTo?.addresses.find(x => x.id === shipment.clientAddressId)?.name} </Typography>
                  <Typography align='right' variant='body1'> {shipment?.shippedTo?.addresses.find(x => x.id === shipment.clientAddressId)?.address} </Typography>
                  <Typography align='right' variant='body1'>{shipment?.shippedTo.addresses.find(x => x.id === shipment.clientAddressId)?.pincode}</Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sx={{ marginTop: '2rem' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant='h4'> Total Items: {shipment?.shipmentItems?.length} </Typography>
                <Typography variant='h4'> Total Quantity: {shipment?.shipmentItems?.reduce((acc, item) => acc + item.quantity, 0)}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sx={{ marginTop: '2rem' }}>
              <TableContainer >
                <Table sx={{}}>
                  <TableHead>
                    <TableRow>
                      <TableCell> <Typography variant='h6'>No.</Typography> </TableCell>
                      <TableCell> <Typography variant='h4'>Item name</Typography> </TableCell>
                      <TableCell> <Typography variant='h4'>Item Info</Typography> </TableCell>
                      {/* <TableCell> <Typography variant='h4'>Specs</Typography> </TableCell> */}
                      <TableCell> <Typography variant='h4'>Status</Typography> </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {shipment && shipment.shipmentItems && shipment.shipmentItems.map((item, index) => {
                      return <TableRow key={`${item.id}-${index}-${Math.random()}`}>
                        <TableCell>
                          <Typography variant='h6'>{index + 1}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography> {findProductNameFromId(item.itemId)} </Typography>
                          <Box sx={{ display: 'flex', justifyContent: 'flex-start', }} >
                            <Typography variant='subtitle2'>Size:</Typography>
                            <Typography variant='subtitle2'> {findProductSizeName(item.itemId, item.itemSizeId)} </Typography>
                          </Box>
                        </TableCell>
                        {/* <TableCell>
                          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }} >
                            <Box sx={{ display: 'flex', justifyContent: 'flex-start', }} >
                              <Typography>Size:</Typography>
                              <Typography> {findProductSizeName(item.itemId, item.itemSizeId)} </Typography>
                            </Box>
                            {item.options.length > 0 ?
                              item.options.map((op, index) => {
                                return (
                                  <Box key={`${op.optionId}-${Math.random()}-${index}`} sx={{ display: 'flex', justifyContent: 'flex-start', }} >
                                    <Typography> {findOptionNameFromId(op.itemOptionId)}:{' '} </Typography>
                                    <Typography> {findOptionValueFromId(op.itemOptionId, op.itemOptionValueId)} </Typography>
                                  </Box>
                                );
                              })
                              :
                              <Typography>No option</Typography>
                            }
                          </Box>
                        </TableCell> */}
                        <TableCell>
                          <Box
                          // sx={{ display: 'flex' }}
                          >
                            {/* <Typography>Quantity:  {item.quantity}</Typography> <Typography>Price: {item.price || ' 0'}</Typography> <Typography>Weight: {item.weight || ' 0'}</ Typography> */}
                            <Typography variant='body1'>

                              {item.options.length > 0 ?
                                item.options.map((op, index) => {
                                  // return (
                                  //   <Box key={`${op.optionId}-${Math.random()}-${index}`} sx={{ display: 'flex', justifyContent: 'flex-start', }} >
                                  //     <Typography> {findOptionNameFromId(op.itemOptionId)}:{' '} </Typography>
                                  //     <Typography> {findOptionValueFromId(op.itemOptionId, op.itemOptionValueId)} </Typography>
                                  //   </Box>

                                  // );
                                  return `${findOptionNameFromId(op.itemOptionId)}::${findOptionValueFromId(op.itemOptionId, op.itemOptionValueId)}`
                                }).join(' , ')
                                : null
                                // <Typography>No option</Typography>
                              }
                            </Typography>
                            <Typography variant='subtitle1'> {[`Quantity:  ${item.quantity}`, `Price: ${item.price || ' 0'}`, `Weight: ${item.weight || ' 0'}`].join(' , ')} </Typography>
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Typography>{item.status}</Typography>
                          {/* ............................ */}
                          <Box>
                            <Typography variant='body1'>{findOrderUnit(item.itemOrderUnitId, item.quantity)} </Typography>
                          </Box>
                        </TableCell>
                      </TableRow>
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Box>
      }
    </>
  )
}

export default DC