import React from "react";
import { useSelector, useDispatch } from "react-redux";

import Header from "./Header";
import Nav from "./Nav";

import Drawer from "@mui/material/Drawer";

const Sidebar = () => {
  const util = useSelector((state) => state.util);
  return (
    <Drawer
      variant="permanent"
      sx={{ backgroundColor: "primary.main", background: "primary.main" }}
    >
      <Header toggle={util.sideBar} />
      <Nav toggle={util.sideBar} />
    </Drawer>
  );
};

export default Sidebar;
