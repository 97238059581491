import React, { useRef, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ClientHeader from './ClientHeader'
import ClientBody from './ClientBody'
import { Grid } from '@mui/material'
import { useParams } from 'react-router-dom'
import userService from '../../../../service/user'
import { getGodownClientCartAction } from '../../../../reducer_action/godownClientCart'

const ClientMainbar = () => {
  const headerRef = useRef()
  const dispatch = useDispatch()

  const params = useParams()
  const clientId = params.clientId

  const [headerHeight, setHeaderHeight] = useState(0)
  const [client, setClient] = useState({})
  const user = useSelector(state => state.user)

  useEffect(() => {
    const height = headerRef.current && headerRef?.current.offsetHeight;
    setHeaderHeight(height);
  }, [client])

  useEffect(() => {
    (async () => {
      // getting a different user from the database
      const currentClient = await userService.getOneUser(clientId)
      setClient(currentClient)
      const userData = {
        godownUsername: user.username,
        godownEmail: user.email,
        clientUsername: currentClient.username,
        clientEmail: currentClient.email
      }
      dispatch(getGodownClientCartAction(userData))
    })()
  }, [clientId, user.email, user.group, dispatch, user.username])



  return (
    <Grid container>
      {client.username === clientId &&
        <>
          <ClientHeader headerRef={headerRef} client={client} />
          <ClientBody headerHeight={headerHeight} client={client} />
        </>
      }
    </Grid>
  )
}

export default ClientMainbar