import React from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import Card from './ProductsCard';

const Body = ({ headerHeight, categoryGroupId, itemName }) => {
  const products = useSelector((state) => state.product);
  const category = useSelector((state) => state.productUtil.category);

  const findCategoryGroup = (categoryId) => {
    const fountCategory = category.find((item) => item.id === categoryId);
    return fountCategory;
  };

  console.log(categoryGroupId);
  return (
    <Box
      sx={{
        overflow: 'scroll',
        height: `calc(100vh - ${headerHeight}px)`,
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        padding: '0 1rem',
      }}
    >
      {products.length > 0 &&
        [...products]
          .sort((a, b) => a.id - b.id)
          .filter(x => x.name.toLowerCase().includes(itemName.toLowerCase()))
          .filter((x) => {
            if (categoryGroupId !== 0) {
              return (
                findCategoryGroup(x.categoryId).categoryGroupId ===
                categoryGroupId
              );
            } else {
              return true;
            }
          })
          .map((x) => {
            return (
              <Box key={x.id}>
                <Card product={x} />
              </Box>
            );
          })}
    </Box>
  );
};

export default Body;
