import React from 'react';
import { useSelector } from 'react-redux';

import View from './OptionPages/View';
import Edit from './OptionPages/Edit';
import Gallery from './OptionPages/Gallery';
import Box from '@mui/material/Box';
import Price from './OptionPages/Price';
import OptionValues from './OptionPages/OptionValues';
import { useLocation } from 'react-router-dom';

const ProductOptionsBody = ({ height }) => {
  const useQuery = () => new URLSearchParams(useLocation().search)
  const query = useQuery()
  const action = query.get('action')
  // const util = useSelector((state) => state.util);
  console.log(height);
  return (
    <Box
      sx={{
        height: `calc(100vh - ${height}px)`,
        overflow: 'scroll',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        padding: '1rem 0.5rem 2rem',
        width: '100%',
      }}
    >
      {action === 'view' && <View />}
      {action === 'edit' && <Edit type='put' />}
      {action === 'gallery' && <Gallery />}
      {action === 'price' && <Price />}
      {action === 'optionValues' && <OptionValues />}
    </Box>
  );
};

export default ProductOptionsBody;
