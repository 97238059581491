import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Grid, Tabs, Tab, Typography } from '@mui/material';
import EditTableView from '../../../../../utilComponents/EditTableView';
import { updateItemMapOptionValueAction } from '../../../../../../reducer_action/product';
import { toast } from 'react-toastify';

// add or remove a particular option when we click the switch button. On delete we will delete all the associated values and on add we will open the accordion and select the others and then we will make a post request to the backend.
// todo add an image of the option value if its available.

const AdditionalOptions = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const product = useSelector((state) => state.product.find((x) => x.id === +params.item));
  const allOptions = useSelector((state) => state.option);
  const [currentOption, setCurrentOption] = useState(allOptions[0] || null);

  const handleOnChange = (e, itemId, itemOptionId, itemOptionValueId) => {
    dispatch(updateItemMapOptionValueAction({ bool: e.target.checked, itemId, itemOptionId, itemOptionValueId }));
    toast.success('option value updated');
  };

  // make it immediate and then either add or delete it on the server with each change.
  const isMappedOptionValue = (optionId, optionValueId) => {
    const option = product.options.filter((option) => option.id === optionId);

    if (option.length <= 0) return false;

    const values = option[0].values.filter((value) => value.id === optionValueId);

    if (values.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      {currentOption ? (
        <Grid container>
          <Grid item xs={12} container sx={{ marginTop: '0rem', marginBottom: '1rem' }}>
            <Tabs value={currentOption.id}>
              {allOptions.map((option) => {
                return (
                  <Tab
                    key={option.id}
                    value={option.id}
                    label={option.name}
                    onClick={() => {
                      setCurrentOption(option);
                    }}
                  />
                );
              })}
            </Tabs>
          </Grid>
          <Grid item xs={12}>
            {currentOption.values.map((value) => {
              return (
                <React.Fragment key={value.id}>
                  <EditTableView
                    type='switch'
                    title={value.optionValueName}
                    value={isMappedOptionValue(value.optionId, value.id)}
                    compareValue={isMappedOptionValue(value.optionId, value.id)}
                    onChange={(e) => {
                      handleOnChange(e, +params.item, value.optionId, value.id);
                    }}
                  />
                </React.Fragment>
              );
            })}
          </Grid>
        </Grid>
      ) : (
        <Typography>There are no options assigned to the is item</Typography>
      )}
    </>
  );
};

export default AdditionalOptions;
