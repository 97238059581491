import React from "react";
import fullLogo from "../../data/imgs/fullLogo.png";
import logo from "../../data/imgs/logo.png";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Header = ({ toggle }) => {
  const navigate = useNavigate();
  return (
    <Box sx={{ cursor: "pointer", backgroundColor: "primary.main" }}
      onClick={() => {
        navigate("/");
      }}
    >
      <img
        src={toggle ? logo : fullLogo}
        alt="logo"
        style={{
          width: toggle ? "30px" : "130px",
          margin: "1rem auto 3rem",
          display: "block",
        }}
      />
    </Box>
  );
};

export default Header;
