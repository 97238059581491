import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Select, MenuItem, Button } from '@mui/material';
import { setUtilAction } from '../../../../../reducer_action/util';

const CategoryHeader = ({ headerRef, categoryGroup, setCategoryGroup }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const categoryGroups = useSelector(state => state.productUtil.categoryGroup);

  return (
    <Grid container item xs={12} ref={headerRef}>
      <Grid item xs={12} sx={{ padding: '1rem 1rem' }}>
        <Select fullWidth value={categoryGroup} onChange={(e) => {
          setCategoryGroup(e.target.value)
          // console.log(categoryGroup)
        }}>
          <MenuItem value={'all'}>all</MenuItem>
          {categoryGroups.map((group) => {
            return <MenuItem value={group.id}>{group.name}</MenuItem>
          })}
        </Select>
      </Grid>
      <Grid item xs={12} sx={{ padding: '0rem 1rem' }}>
        <Button
          variant='contained'
          onClick={() => {
            navigate('?action=add');
            dispatch(setUtilAction({ actionQuery: 'add', mainSideBar: false }));
          }}
        >
          Add
        </Button>
      </Grid>
    </Grid>
  );
};

export default CategoryHeader;
