import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { Button, TextField, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Grid, Box, Select, MenuItem, Typography, } from '@mui/material';
import { putItemOptionPriceTableAction } from '../../../../../../reducer_action/options';
import { toast } from 'react-toastify';

const Price = () => {
  const params = useParams();
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const option = useSelector(state => state.option)

  const currentOption = useSelector((state) => state.option.find((x) => x.id === +params.option));

  const priceListSelectValues = useSelector((state) => state.productUtil.priceList).map((x) => {
    return { name: x.name, id: x.id };
  });

  const [priceListId, setPriceListId] = useState(priceListSelectValues[0].id);
  const [optionValue, setOptionValue] = useState(currentOption.values[0]);
  const [optionValueId, setOptionValueId] = useState(currentOption.values[0].id);

  const handlePriceUpdate = () => {
    const priceListOptionValues = optionValue.prices.filter((price) => price.priceListId === priceListId).filter(price => price.itemOptionValueId === optionValueId);

    if (priceListOptionValues.length <= 0) return;
    dispatch(putItemOptionPriceTableAction(priceListOptionValues, priceListId, optionValueId));
    toast.success('option price has been updated')
    navigate(`?action=view`)
  };

  const findOptionValue = (optionValueId) => {
    return currentOption.values.find((x) => x.id === optionValueId);
  };

  return (
    <>
      {option &&
        <Grid container item xs={12}>
          {/* option pages */}
          <Grid item xs={12}>
            <Typography variant='h4'>
              Range Lookup: {currentOption.priceRangeLookup}
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Select
                value={optionValueId}
                onChange={(e) => {
                  setOptionValue(findOptionValue(e.target.value));
                  setOptionValueId(e.target.value);
                }}
              >
                {currentOption.values.map((value) => {
                  return (
                    <MenuItem key={value.id} value={value.id}>
                      {value.optionValueName}
                    </MenuItem>
                  );
                })}
              </Select>
              <Select
                value={priceListId}
                onChange={(e) => setPriceListId(e.target.value)}
              >
                {priceListSelectValues.map((x) => {
                  return (
                    <MenuItem key={x.id} value={x.id}>
                      {x.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {[
                      `start @ ${currentOption.priceRangeLookup}`,
                      'price',
                      'action',
                    ].map((x) => {
                      return (
                        <TableCell key={x}>
                          <Typography variant='h6'>{x}</Typography>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {optionValue.prices // .find((x) => x.id === optionValueId)
                    .filter((price) => price.priceListId === priceListId)
                    .map((value, index, array) => {
                      return (
                        <TableRow key={`${value.id}-${index}`}>
                          <TableCell>
                            <TextField
                              value={value.startRangeValue}
                              onChange={(e) => {
                                array[index].startRangeValue = +e.target.value;
                                setOptionValue({ ...optionValue, prices: array });
                              }}
                            />
                          </TableCell>
                          <TableCell>
                            <TextField
                              value={value.price}
                              onChange={(e) => {
                                array[index].price = +e.target.value;
                                setOptionValue({ ...optionValue, prices: array });
                              }}
                            />
                          </TableCell>
                          <TableCell
                            onClick={() => {
                              setOptionValue({
                                ...optionValue,
                                prices: array.filter((x, i) => i !== index),
                              });
                            }}
                          >
                            <DeleteIcon />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  <TableRow>
                    <TableCell />
                    <TableCell />
                    <TableCell>
                      <AddIcon
                        onClick={() => {
                          console.log('hello');
                          setOptionValue({
                            ...optionValue,
                            prices: [
                              ...optionValue.prices,
                              {
                                startRangeValue: 0,
                                price: 0,
                                itemOptionValueId: optionValue.id,
                                priceListId,
                              },
                            ],
                          });
                        }}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                marginTop: '2rem',
                justifyContent: 'flex-end',
              }}
            >
              <Button variant='contained' onClick={handlePriceUpdate}>
                save
              </Button>
            </Box>
          </Grid>
        </Grid>
      }
    </>
  );
};

export default Price;
