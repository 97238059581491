import React, { useRef, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ProductsHeader from './ProductsHeader';
import ProductsBody from './ProductsBody';
import { getAllProductsAction } from '../../../../../reducer_action/product';
import { getAllOptionsAction } from '../../../../../reducer_action/options';
import { getAllProductUtilDataAction } from '../../../../../reducer_action/productUtil';
import { getCartAction } from '../../../../../reducer_action/cart';
import { getUserAction } from '../../../../../reducer_action/user';

const ProductsSidebar = () => {
  const dispatch = useDispatch();

  const headerRef = useRef();
  const userInfo = useSelector((state) => {
    const group = state?.user?.clientGroup
    const email = state?.user?.email;
    return { group, email };
  });

  const category = useSelector((state) => state.productUtil.category);

  // todo this useEffect will make needless get requests to  the backend. needs to be separated into two useEffects.
  useEffect(() => {
    dispatch(getUserAction())
    const height = headerRef.current.offsetHeight;
    setHeaderHeight(height);
    dispatch(getAllProductsAction());
    dispatch(getAllOptionsAction());
    dispatch(getAllProductUtilDataAction());
    dispatch(getCartAction(userInfo?.group, userInfo?.email));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const categoryGroupSelectValues = useSelector((state) => {
    const values = state?.productUtil?.categoryGroup;
    return values || [];
  });

  const categoryGroupSelectOptions = [
    {
      id: 0,
      name: 'all',
      description: 'hlsdf',
      imageUrl: null,
    },
    ...categoryGroupSelectValues,
  ];

  const [headerHeight, setHeaderHeight] = useState(0);
  const [itemName, setItemName] = useState('');
  const [categoryGroupId, setCategoryGroupId] = useState(categoryGroupSelectOptions[0].id);

  return (
    <>
      <ProductsHeader
        headerRef={headerRef}
        itemName={itemName}
        setItemName={setItemName}
        categoryGroupId={categoryGroupId}
        setCategoryGroupId={setCategoryGroupId}
        categoryGroupSelectValues={categoryGroupSelectOptions || []}
      />
      {category && headerRef.current && category.length > 0 &&
        <ProductsBody
          headerHeight={headerHeight}
          categoryGroupId={categoryGroupId}
          itemName={itemName}
        />
      }
    </>
  );
};

export default ProductsSidebar;
