import React from 'react';
import { Grid, Typography, } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { dashSplitter } from '../../../../../utilFunctions';
import Tabs from '../../../../utilComponents/Tabs'

const CategoryHeader = ({ headerRef }) => {
  const params = useParams();
  const navigate = useNavigate();
  const category = useSelector((state) =>
    state.productUtil.category.find((cat) => cat.id === +params.category)
  );

  const routes = ['view', 'edit']
    .map(tab => {
      return {
        name: tab,
        onClick: () => navigate(`?action=${tab}`)
      }
    })

  return (
    <Grid container item ref={headerRef} xs={12} sx={{ padding: '1rem 1rem 1rem 1rem', }} >
      <Grid item xs={12} sx={{ marginBottom: '0.75rem' }}>
        <Typography variant='h3' sx={{ textTransform: 'capitalize' }}>
          {dashSplitter(category.name)}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Tabs tabs={routes} />
      </Grid>
    </Grid>
  );
};

export default CategoryHeader;
