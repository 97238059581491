import React from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Box, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import { deleteMediaAction } from '../../../../../../../reducer_action/product';

const GalleryCard = ({ img, title, id, type, mediaType }) => {
  const dispatch = useDispatch();

  const params = useParams();
  const handleMediaDelete = () => {
    console.log('delete');
    console.log(img, title, id);
    const itemId = params.item;
    dispatch(deleteMediaAction(type, id, itemId));
  };
  return (
    <Box>
      <Box>
        <img
          src={img}
          alt='media'
          style={{
            height: '300px',
            width: '300px',
            borderRadius: '10px',
            objectFit: 'cover',
          }}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '0.2rem 0.5rem',
          }}
        >
          <Typography variant='h4'>{title}</Typography>
          <DeleteIcon
            sx={{ color: 'primary.main', cursor: 'pointer' }}
            onClick={handleMediaDelete}
          />
        </Box>
        {/* add a delete button */}
      </Box>
    </Box>
  );
};

export default GalleryCard;
