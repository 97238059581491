import React from 'react';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import InventoryIcon from '@mui/icons-material/Inventory';
import PersonIcon from '@mui/icons-material/Person';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

// export const adminModules = [
//   {
//     title: 'Products',
//     icon: <ViewInArIcon sx={{ color: 'white' }} />,
//     section: [
//       { name: 'Products', url: '/admin/products' },
//       { name: 'Options', url: '/admin/productOptions' },
//       { name: 'Categories', url: '/admin/productCategories' },
//     ],
//   },
//   {
//     title: 'Billing',
//     icon: <CurrencyRupeeIcon sx={{ color: 'white' }} />,
//     section: [
//       { name: 'Billing', url: '/admin/billing' },
//       { name: 'Bill Bundles', url: '/admin/billBundles' },
//     ],
//   },
//   {
//     title: 'Clients',
//     icon: <PersonIcon sx={{ color: 'white' }} />,
//     section: [{ name: 'Customers', url: '/admin/customers' }],
//   },
//   {
//     title: 'Inventory',
//     icon: <InventoryIcon sx={{ color: 'white' }} />,
//     section: [
//       { name: 'Items', url: '/admin/products' },
//       { name: 'Cart', url: '/admin/cart' },
//       { name: 'Orders', url: '/admin/orders' },
//       { name: 'Shipments', url: '/admin/shipments' },
//     ],
//   },
// ];

export const asxModules = [
  {
    title: 'Products',
    icon: <ViewInArIcon sx={{ color: 'white' }} />,
    section: [
      { name: 'Products', url: '/asx/products' },
      { name: 'Cart', url: '/asx/cart' },
      { name: 'Orders', url: '/asx/orders?currentPage=1&limit=25&orderByDesc=true&status=ALL&cognitoGroup=ALL&itemName=' },
      { name: 'Shipments', url: '/asx/shipments?currentPage=1&limit=25&orderByDesc=true&status=ALL&itemName=' },
      // { name: 'Shipment returns', url: '/asx/returnOrders' },
    ],
  },
  {
    title: 'User',
    icon: <PersonIcon sx={{ color: 'white' }} />,
    section: [
      { name: 'User', url: '/asx/user' },
    ]
  },
  {
    title: 'Prnpro',
    icon: <PeopleAltIcon sx={{ color: 'white' }} />,
    section: [
      { name: 'User', url: '/asx/prnpro' },
    ]
  },
  // todo undo when we are ready for the shipment section
  // {
  //   title: 'Billing',
  //   icon: <CurrencyRupeeIcon sx={{ color: 'white' }} />,
  //   section: [
  //     { name: 'Billing', url: '/asx/billing' },
  //     { name: 'Expenses', url: '/asx/expenses' },

  //     { name: 'Credit Customers', url: '/asx/creditCustomers' },
  //     { name: 'Adv Payments', url: '/asx/advancePayments' },
  //     { name: 'Bank Deposits', url: '/asx/bankDeposits' },

  //     { name: 'Salary Advance', url: '/asx/salaryAdvance' },
  //     { name: 'Current Inventory', url: '/asx/currentInventory' },
  //     { name: 'Inventory Adjustment', url: '/asx/inventoryAdjustment' },
  //   ],
  // },
];

export const godownModules = [
  {
    title: 'Products',
    icon: <ViewInArIcon sx={{ color: 'white' }} />,
    section: [
      { name: 'Products', url: '/godown/products' },
      { name: 'Categories', url: '/godown/productCategories' },
      { name: 'Options', url: '/godown/productOptions' },
      { name: 'Order Units', url: '/godown/orderUnit' },
    ]
  },

  {
    title: 'Orders',
    icon: <InventoryIcon sx={{ color: 'white' }} />,
    section: [
      { name: 'Orders', url: '/godown/orders?currentPage=1&limit=25&orderByDesc=true&status=ALL&cognitoGroup=ALL&returnOrder=true&itemName=' },
      { name: 'Shipment Cart', url: '/godown/shipmentCart' },
      { name: 'Shipments', url: '/godown/shipments?currentPage=1&limit=25&orderByDesc=true&status=ALL&itemName=' },
      { name: 'Inventory Adjustment', url: '/godown/inventoryAdjustment' },
      // this is for the difference between shipping and billing
      // show the current inventory of any shop
      { name: 'Current Inventory', url: '/godown/currentInventory' },
      // { name: 'Create Shipment', url: '/godown/createShipment' },
      // { name: 'Return Shipment', url: '/godown/returnShipment' },
    ]
  },

  {

    title: 'Clients',
    icon: <PeopleAltIcon sx={{ color: 'white' }} />,
    section: [
      { name: 'Clients', url: '/godown/clients' },
      // todo these can all  be tabs
      // { name: 'Orders', url: '/godown/Orders' },
      // { name: 'Shipments', url: '/godown/Shipments' },
      // { name: 'Return Shipments', url: '/godown/returnShipments' },
      // { name: 'Current Inventory Shipments', url: '/godown/currentInventory' },


    ]
  },
  {
    title: 'User',
    icon: <PersonIcon sx={{ color: 'white' }} />,
    section: [
      { name: 'User', url: '/godown/user' },
    ]

  },
]