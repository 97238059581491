import React, { useRef, useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import ClientOrderBody from './ClientOrderBody'
import ClientOrderHeader from './ClientOrderHeader'
import { useParams, useLocation } from 'react-router-dom'
import { getOneDemandOrder } from '../../../../../service/demandOrder'

const ClientOrderMainbar = () => {
  const params = useParams()
  const location = useLocation()
  const headerRef = useRef()

  const [headerHeight, setHeaderHeight] = useState(0)
  const [order, setOrder] = useState({})


  useEffect(() => {
    (async () => {
      const order = await getOneDemandOrder({ orderId: +params.orderId })
      setOrder(order)
      const height = headerRef.current && headerRef?.current.offsetHeight;
      setHeaderHeight(height);
    })()
  }, [params.orderId, location, headerRef])

  return (
    <Grid container item xs={12}>
      {order.id === +params.orderId &&
        <>
          <ClientOrderHeader headerRef={headerRef} order={order} />
          <ClientOrderBody headerHeight={headerHeight} order={order} setOrder={setOrder} />
        </>
      }
    </Grid>
  )
}

export default ClientOrderMainbar