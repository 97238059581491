import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Typography, Grid, Box, Button } from '@mui/material'
import EditTableView from '../../../../../../utilComponents/EditTableView'
import { postItemSizeAction } from '../../../../../../../reducer_action/product'
import { useParams } from 'react-router-dom'


const AddSize = ({ setAddSizeTab, addSizeTab }) => {
  const navigate = useNavigate()
  const params = useParams()
  const itemId = +params.item
  const dispatch = useDispatch()
  const rangeLookup = useSelector((state) => state.productUtil.enums.find((x) => x.name === 'range_lookup')).value.split('|');
  const orderUnitLookup = useSelector((state) => state.productUtil.orderUnit)

  const [sizeTitle, setSizeTitle] = useState('')
  const [sizeWidth, setSizeWidth] = useState(0)
  const [sizeLength, setSizeLength] = useState(0)
  const [incrementQuantity, setIncrementQuantity] = useState(0)
  const [portrait, setPortrait] = useState(true)
  const [weight, setWeight] = useState(0)
  const [setupCost, setSetupCost] = useState(0)
  const [jobsPerSheet, setJobsPerSheet] = useState(0)
  const [customSize, setCustomSize] = useState(false)
  const [priceRangeLookup, setPriceRangeLookup] = useState(rangeLookup[0])
  const [sort, setSort] = useState(0)
  const [defaultValue, setDefaultValue] = useState(0)

  // the orderGuideline for the size also needs to be set 
  const [orderUnitId, setOrderUnitId] = useState(orderUnitLookup[0].id)
  const [minOrderQuantity, setMinOrderQuantity] = useState(0)
  const [maxOrderQuantity, setMaxOrderQuantity] = useState(0)
  const [recommendedQuantity, setRecommendedQuantity] = useState(0)


  const handlePostSize = () => {
    const data = {
      itemId, sizeTitle, sizeWidth, sizeLength, incrementQuantity, portrait, weight, setupCost, jobsPerSheet, customSize, priceRangeLookup, sort, defaultValue,
      orderUnitId, minOrderQuantity, maxOrderQuantity, recommendedQuantity
    }
    dispatch(postItemSizeAction(data))
    setAddSizeTab(false)
    navigate('?action=view')
  }

  return (
    <Grid container >
      <Grid item xs={12}>
        <Typography align='center' variant='h3'>Add size</Typography>
      </Grid>
      <Grid item xs={12} sx={{ marginBottom: '1rem' }}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant='contained' onClick={() => setAddSizeTab(!addSizeTab)}>Back</Button>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Grid container item xs={12}>
          <EditTableView
            title='size title'
            value={sizeTitle}
            onChange={(e) => setSizeTitle(e.target.value)}
            type='text'
          />
          <EditTableView
            title='item width'
            value={sizeWidth}
            onChange={(e) => setSizeWidth(+e.target.value)}
            type='text'
          />
          <EditTableView
            title='item length'
            value={sizeLength}
            onChange={(e) => setSizeLength(+e.target.value)}
            type='text'
          />
          <EditTableView
            title='increment quantity'
            value={incrementQuantity}
            onChange={(e) => setIncrementQuantity(+e.target.value)}
            type='text'
          />
          <EditTableView
            title='portrait'
            value={portrait}
            onChange={(e) => setPortrait(e.target.checked)}
            type='switch'
          />
          <EditTableView
            title='weight'
            value={weight}
            onChange={(e) => setWeight(+e.target.value)}
            type='text'
          />
          <EditTableView
            title='setup cost'
            value={setupCost}
            onChange={(e) =>
              setSetupCost(+e.target.value)
            }
            type='text'
          />
          <EditTableView
            title='jobs per sheet'
            value={jobsPerSheet}
            onChange={(e) =>
              setJobsPerSheet(+e.target.value)
            }
            type='text'
          />
          <EditTableView
            title='custom_size'
            value={customSize}
            onChange={(e) => setCustomSize(e.target.checked)}
            type='switch'
          />
          <EditTableView
            title='price range lookup'
            value={priceRangeLookup}
            onChange={(e) => setPriceRangeLookup(e.target.value)}
            type='select'
            selectValues={rangeLookup}
          />
          <EditTableView
            title='sort'
            value={sort}
            onChange={(e) => setSort(+e.target.value)}
            type='text'
          />
          <EditTableView
            title='default value'
            value={defaultValue}
            onChange={(e) => setDefaultValue(e.target.checked)}
            type='switch'
          />
          <Box sx={{ margin: '2rem 0 1rem 0' }}>
            <Typography align='center' variant='h3'>Order Unit definition</Typography>
          </Box>
          <EditTableView
            title='order unit'
            value={orderUnitId}
            onChange={(e) => setOrderUnitId(+e.target.value)}
            type='select'
            selectValues={orderUnitLookup}
          />
          <EditTableView
            title='min order quantity'
            value={minOrderQuantity}
            onChange={(e) => setMinOrderQuantity(+e.target.value)}
            type='text'
          />
          <EditTableView
            title='max order quantity'
            value={maxOrderQuantity}
            onChange={(e) => setMaxOrderQuantity(+e.target.value)}
            type='text'
          />
          <EditTableView
            title='min order quantity'
            value={recommendedQuantity}
            onChange={(e) => setRecommendedQuantity(+e.target.value)}
            type='text'
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
          <Button variant='contained' onClick={handlePostSize}>add new Size</Button>
        </Box>
      </Grid>
    </Grid>
  )
}

export default AddSize