import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { Grid, Box, Typography, } from '@mui/material'
// import { setUtilAction } from '../../../../../reducer_action/util'
import Tabs from '../../../../utilComponents/Tabs'

const ClientOrderHeader = ({ order }) => {
  const navigate = useNavigate()
  const routes = ['view'].map(tab => {
    return {
      name: tab,
      onClick: () => navigate(`?action=${tab}`)
    }
  })

  const timeConversion = (date) => {
    const dateObj = new Date(date)
    const hours = dateObj.getHours() + 5
    const minutes = dateObj.getMinutes() + 30

    dateObj.setHours(hours)
    dateObj.setMinutes(minutes)

    return dateObj
  }


  return (
    <>
      {order && order.orderItems.length > 0 && (
        <Grid item xs={12} container sx={{ padding: '1rem' }}>
          <Grid item xs={12} sx={{ marginBottom: '0.75rem' }} container>
            <Grid item xs={4}>
              <Typography align='left' variant="h3">{order?.clientEmail.split('@')[0]}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography align='center' variant='h3'>Order Id: {order?.id}</Typography>
              {order.returnOrder && <Typography sx={{ color: 'red' }} align='center' variant='h3'>Return Order</Typography>}
            </Grid>
            <Grid item xs={4}>
              <Typography align='right' variant='h3'>{timeConversion(order?.orderDate).toLocaleString(undefined, { timeZone: 'Asia/Kolkata' }).toUpperCase().split(',')[0]}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Tabs tabs={routes} />
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default ClientOrderHeader        