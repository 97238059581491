import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { dashSplitter, underscoreSplitter } from '../../../../../../utilFunctions';

import { Typography, Grid, Box, TableHead, Table, TableBody, TableCell, TableContainer, TableRow, } from '@mui/material';
import TableView from '../../../../../utilComponents/TableView';

const View = () => {
  const { item } = useParams();

  const product = useSelector((state) => state.product.find((x) => x.id === +item));
  const currentProductCategory = useSelector((state) => state.productUtil.category && product && state.productUtil.category.find((x) => x.id === product.categoryId)?.name);
  const currentAssociatedProductCategory = useSelector((state) => state.productUtil.category && product && state.productUtil.category.find((x) => x.id === product.associatedCategoryId)?.name);

  const currentProductCategoryGroup = useSelector((state) => {
    const categoryGroupId = state.productUtil.category && state.productUtil.category.find((x) => x.name === currentProductCategory)?.categoryGroupId;
    const groupName = state.productUtil.category && state.productUtil.categoryGroup.find((x) => x.id === categoryGroupId)?.name;
    return groupName;
  });

  const currentTaxRate = useSelector((state) => {
    return state.productUtil.taxRate && product && state.productUtil.taxRate.find((x) => x.id === product.taxRateId).rate
  });

  const priceList = useSelector((state) => {
    return state.productUtil.priceList && product && state.productUtil.priceList;
  });

  return (
    <>
      {product && priceList && currentTaxRate && (
        <Grid container item xs={12} sx={{ paddingLeft: '0rem' }}>
          <Grid item container xs={6} alignContent='flex-start' sx={{ paddingLeft: '1rem' }} >
            <Grid container item xs={12} columnSpacing={2}>
              <TableView title={'name'} value={product.name} />
              <TableView title={'status'} value={product.status.toString()} />
              <TableView title={'predefined item'} value={product.predefinedItem.toString()} />
            </Grid>

            <Grid container item xs={12} columnSpacing={2}>
              <TableView title={'Category'} value={currentProductCategory} />
              <TableView title={'Category Group'} value={currentProductCategoryGroup} />
              <TableView title={'Associated Category '} value={currentAssociatedProductCategory || 'none'} />
              <TableView title={'Tax rate '} value={`${currentTaxRate}%`} />
            </Grid>
          </Grid>

          <Grid item container xs={6} alignContent='flex-start' sx={{}}>
            <Grid item container xs={12} columnSpacing={2}>
              <TableView title={'Price Type '} value={underscoreSplitter(product.priceType)} />
              <TableView title={'Sheet Calculation'} value={product.sheetCalculation.toString()} />
              <TableView title={'Weight Type'} value={dashSplitter(product.weightCalcType)} />
              <TableView title={'apply linear formula'} value={product.applyLinearFormula.toString()} />
            </Grid>

            <Grid item container columnSpacing={2} xs={12}>
              <TableView title={'meet a designer'} value={product.meetADesigner.toString(0)} />
              <TableView title={'upload center'} value={product.uploadCenter.toString()} />
              <TableView title={'UC Description'} value={product.uploadCenterDescription || 'none'}
              />
            </Grid>
          </Grid>

          <Grid item container xs={12} sx={{ marginTop: '2rem' }} rowSpacing='11'>
            <Grid item xs={12} sx={{ padding: '0.5rem 0', borderBottom: '1px solid', borderColor: 'secondary.underLine', }} >
              <Typography variant='h3'>Description</Typography>
            </Grid>
            <TableView title={'short description'} value={product.shortDescription} block1={3} block2={9} align='left' />
            <TableView title={'long Description'} value={product.longDescription} block1={3} block2={9} align='left' />
          </Grid>

          <Grid container item xs={12} sx={{ marginTop: '3rem' }}>
            <Grid item xs={12}>
              <Typography variant='h3' sx={{ borderBottom: '1px solid ', borderColor: 'secondary.underLine', }} > Configuration </Typography>
            </Grid>

            <Grid item container xs={12} sx={{ marginTop: '1rem' }}>
              <Grid item container xs={12}>
                {product.pages.length > 0 ? (
                  product.pages.map((page) => {
                    return (
                      <TableView key={page.id} title={'page title'} value={page.pageTitle} block1={2} block2={10} />
                    );
                  })
                ) : (
                  <Typography variant='body1'>
                    This item does not have any pages
                  </Typography>
                )}
              </Grid>
            </Grid>

            <Grid item container xs={12} sx={{ marginTop: '2rem' }}>
              <Grid item xs={12} sx={{ marginTop: '1rem', borderBottom: '1px solid ', borderColor: 'secondary.underLine', }} >
                <Typography variant='h3'>Item Sizes, Price and Weights</Typography>
              </Grid>
              <Grid item container xs={12} sx={{ marginTop: '.0rem' }}>
                {product.size.length > 0 ? (
                  <TableContainer>
                    <Table>
                      <TableBody>
                        {product.size.map((size) => {
                          return (
                            <TableRow key={size.id}>
                              <TableCell sx={{}}>
                                <Typography variant='h4' noWrap={true}> {size.sizeTitle} </Typography>
                              </TableCell>
                              <TableCell>
                                <TableView title='width' value={size.itemWidth} underline={false} />
                                <TableView underline={false} title='length' value={size.itemLength} />
                                <TableView underline={false} title='custom size' value={size.customSize.toString()} />
                                <TableView underline={false} title='weight' value={size.weight || 'none'} />
                                <TableView underline={false} title='setup cost' value={size.setupCost} />
                                <TableView underline={false} title='Jobs per sheet' value={size.jobsPerSheet || 'not applicable'} />
                              </TableCell>
                              <TableCell >
                                <TableView underline={false} title='price (range lookup)' value={size.priceRangeLookup.toLowerCase()} />
                                <TableView underline={false} title='Increment quantity' value={size.incrementQuantity} />
                                <TableView underline={false} title='Sort value' value={size.sort} />
                                <TableView underline={false} title='default' value={size.defaultValue.toString()} />
                                <TableView underline={false} title='Portrait' value={size.portrait.toString()} />
                                <TableView underline={false} title='status' value={size.status.toString()} />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <Typography>this item does not have any sizes</Typography>
                )}
              </Grid>
            </Grid>

            <Grid item container xs={12} sx={{ marginTop: '2rem' }}>
              <Grid
                item
                xs={12}
                sx={{
                  borderBottom: '1px solid ',
                  borderColor: 'secondary.underLine',
                  marginTop: '1rem',
                }}
              >
                <Typography variant='h3' sx={{}}>
                  Item Options
                </Typography>
              </Grid>
              <Grid item container xs={12} sx={{ marginTop: '1rem' }}>
                {product.options.length > 0 ? (
                  <>
                    {product.options.map((option) => {
                      let text = '';
                      option.values.map((value, index, array) => {
                        const length = array.length;
                        if (length - 1 !== index) {
                          text = text
                            .concat(value.optionValueName)
                            .concat(
                              ` (${option?.values[index]?.price[0]?.priceListId}/${option?.values[index]?.price[0]?.price})`
                            )

                            .concat(', ');
                        } else {
                          text = text
                            .concat(value.optionValueName)
                            .concat(` (${option?.values[index]?.price[0]?.price})`);
                        }
                        return <Box key={value.id} />;
                      });
                      return (
                        <TableView
                          key={option.id}
                          title={option.name}
                          value={text}
                        />
                      );
                    })}
                  </>
                ) : (
                  <Typography>This item has no assigned options</Typography>
                )}
              </Grid>
            </Grid>
            <Grid item container xs={12} sx={{ marginTop: '2rem' }}>
              <Grid
                item
                xs={12}
                sx={{
                  borderBottom: '1px solid',
                  borderColor: 'secondary.underLine',
                  marginTop: '1rem',
                }}
              >
                <Typography variant='h3' sx={{}}>
                  Option Rules
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ marginTop: '2rem' }}>
                {product.optionRules.length > 0 ? (
                  <>
                    {product.optionRules.map(rule => {
                      return <TableView key={rule.id} title={rule.title} value={`${rule.ruleType}----${rule.description}`} />
                    })}
                  </>
                ) : (
                  <Typography variant='body1'>
                    This item has no option rules
                  </Typography>
                )}
              </Grid>
            </Grid>

            <Grid item container xs={12} sx={{ marginTop: '2rem' }}>
              <Grid
                item
                xs={12}
                sx={{
                  borderBottom: '1px solid ',
                  borderColor: 'secondary.underLine',
                  marginTop: '1rem',
                }}
              >
                <Typography variant='h3' sx={{}}>
                  Item Price
                </Typography>
              </Grid>
            </Grid>
            <Grid item container xs={12}>
              <Grid item container xs={12} sx={{ margin: '1rem 0 3rem 0' }}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <Typography variant='h4'>Name</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant='h4'>Price list</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant='h4'>From quantity</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant='h4'>Price</Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {product.size.length !== 0 ? (
                        product.size.map((size) => {
                          return (
                            <React.Fragment key={`${size.id}`}>
                              {size.price.map((price) => {
                                return (
                                  <TableRow key={`${size.id}-${price.id}`}>
                                    <TableCell>
                                      {size.sizeTitle}
                                    </TableCell>
                                    <TableCell>
                                      <Typography variant='body1'>
                                        {priceList.find(
                                          (x) => x.id === price.priceListId
                                        ).name}
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography variant='body1'>
                                        {price.startQuantity}
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography variant='body1'>
                                        {price.price}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </React.Fragment>
                          );
                        })
                      ) : (
                        <Typography variant='body1'>
                          this item does not have any size based prices
                        </Typography>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Grid>
        </Grid >
      )}
    </>
  );
};

export default View;
