import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box, Button, Typography, Grid } from '@mui/material';
import EditTableView from '../../../../../../../utilComponents/EditTableView';
import { postItemRuleAction } from '../../../../../../../../reducer_action/product';
import { toast } from 'react-toastify';

const SizeQuantityOption = ({ ruleType }) => {
  const dispatch = useDispatch();

  const params = useParams();
  const evaluationTypes = useSelector((state) => {
    return state.productUtil.enums.find((x) => x.name === 'logical_operator').value.split('|');
  });

  const [ruleTitle, setRuleTitle] = useState('');
  const [ruleDescription, setRuleDescription] = useState('');

  const [evalType, setEvalType] = useState(evaluationTypes[0]);
  const [startQuantity, setStartQuantity] = useState(0);
  const [endQuantity, setEndQuantity] = useState(0);
  const [allQuantity, setAllQuantity] = useState(true);

  const [sizeIds, setSizeIds] = useState([]);
  const [optionIds, setOptionIds] = useState([]);
  const [hideOptionIds, setHideOptionIds] = useState([]);

  const productOption = useSelector((state) => {
    return state.product.find((x) => x.id === +params.item).options;
  });

  const itemSize = useSelector((state) => {
    return state.product.find((x) => x.id === +params.item).size;
  });

  const selectSizeValues = itemSize.map((x) => {
    const mappedSize = {
      name: x.sizeTitle,
      id: x.id,
    };
    return mappedSize;
  });

  const optionSelectValues = productOption
    .map((option) => {
      const selectValuesOptions = option.values.map((value) => {
        return {
          name: `${option.name}-${value.optionValueName}`,
          id: value.id,
        };
      });
      return selectValuesOptions;
    })
    .flat();

  const handleSubmit = () => {
    const rule = { itemId: +params.item, ruleTitle, ruleDescription, sizeIds, optionIds, hideOptionIds, evalType, startQuantity, endQuantity, allQuantity, ruleType, };
    dispatch(postItemRuleAction(rule));
  };

  return (
    <Grid container item xs={12}>
      <Grid item xs={12}>
        <Typography variant='h4'>SizeQuantityOption</Typography>
      </Grid>
      <Grid item xs={12}>
        <EditTableView
          title='rule title'
          value={ruleTitle}
          onChange={(e) => setRuleTitle(e.target.value)}
          type='text'
        />
        <EditTableView
          title='rule description'
          value={ruleDescription}
          onChange={(e) => setRuleDescription(e.target.value)}
          type='text'
        />
        <EditTableView
          title='size'
          type='multiSelect'
          value={sizeIds}
          selectValues={selectSizeValues}
          onChange={(e) => setSizeIds(e.target.value)}
        />
        <EditTableView
          title='rule applies for all quantity'
          type='switch'
          value={allQuantity}
          onChange={(e) => setAllQuantity(e.target.checked)}
        />

        {!allQuantity ? (
          <>
            <EditTableView
              title='evaluation options'
              type='select'
              selectValues={evaluationTypes}
              value={evalType}
              onChange={(e) => setEvalType(e.target.value)}
            />

            <EditTableView
              title='start quantity'
              type='text'
              value={startQuantity}
              onChange={(e) => {
                setStartQuantity(e.target.value);
              }}
            />

            {evalType === 'BETWEEN' && (
              <EditTableView
                title='end quantity'
                type='text'
                value={endQuantity}
                onChange={(e) => {
                  setEndQuantity(e.target.value);
                }}
              />
            )}
          </>
        ) : null}
        <EditTableView
          title='option'
          type='multiSelect'
          selectValues={optionSelectValues}
          value={optionIds}
          onChange={(e) => setOptionIds(e.target.value)}
        />
        <EditTableView
          title='hide option'
          type='multiSelect'
          selectValues={optionSelectValues}
          value={hideOptionIds}
          onChange={(e) => setHideOptionIds(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '1rem',
          }}
        >
          <Button variant='contained' onClick={handleSubmit}>
            save
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default SizeQuantityOption;
