import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Box, TableRow, TableCell, Typography } from '@mui/material'
import { DeleteOutline } from '@mui/icons-material'
import { removeProductFromGodownClientCartAction } from '../../../../../../reducer_action/godownClientCart'

const CartItemRow = ({ item, index, client }) => {
  const dispatch = useDispatch()
  const product = useSelector(state => state.product)
  const option = useSelector(state => state.option)
  const user = useSelector(state => state.user)

  const findProductNameFromId = (itemId) => {
    const name = product.find((product) => product.id === itemId).name;
    return name;
  };

  const findProductSizeName = (itemId, sizeId) => {
    if (!itemId || !sizeId) return
    const currentProduct = product.find((x) => x.id === itemId);

    // some products do not have a size and operate entirely on options
    if (!currentProduct.size.length > 0) return null
    const sizeName = currentProduct?.size?.find((x) => x.id === sizeId).sizeTitle;
    return sizeName;
  };

  const findOptionNameFromId = (optionId) => {
    const name = option.find((option) => option.id === optionId).name;
    return name;
  };

  const findOptionValueFromId = (optionId, optionValueId) => {
    const currentOption = option.find((x) => x.id === optionId);
    const valueName = currentOption.values.find(
      (v) => v.id === optionValueId
    ).optionValueName;
    return valueName;
  };

  const deleteCartItem = () => {
    const userData = {
      godownUsername: user.username,
      godownEmail: user.email,
      clientUsername: client.username,
      clientEmail: client.email,
    }

    // dispatch(removeProductFromGodownClientCartAction(userData, item))
    dispatch(removeProductFromGodownClientCartAction(userData, index))
  }

  return (
    <TableRow key={`${item.itemId}-${Math.random()}-${index}`}>
      <TableCell>
        <Typography>
          {findProductNameFromId(item.itemId)}
        </Typography>
      </TableCell>
      <TableCell>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem', }} >
          <Box sx={{ display: 'flex', justifyContent: 'center', }} >
            <Typography>Size:</Typography>
            <Typography>
              {findProductSizeName(item.itemId, item.itemSizeId) || 'none'}
            </Typography>
          </Box>
          {item.options.map((op, index) => {
            return (
              <Box
                key={`${op.optionId}-${Math.random()}-${index}`}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Typography>
                  {findOptionNameFromId(op.optionId)}:{' '}
                </Typography>
                <Typography>
                  {findOptionValueFromId(
                    op.optionId,
                    op.optionValueId
                  )}
                </Typography>
              </Box>
            );
          })}
        </Box>{' '}
      </TableCell>
      <TableCell>
        <Box>
          <Typography align='center'>{item.quantity}</Typography>
        </Box>
      </TableCell>
      <TableCell>
        <Box sx={{ display: 'flex', justifyContent: 'center', }} >
          {item.mediaUrl === 'no media' ?
            (<Typography>{item.mediaUrl}</Typography>)
            :
            <Typography align='center'>
              <a href={item.mediaUrl} style={{ textDecoration: 'none' }} download>download media</a>
            </Typography>
          }
        </Box>
      </TableCell>
      <TableCell>
        <Box sx={{ display: 'flex', justifyContent: 'center', }} onClick={deleteCartItem} >
          <DeleteOutline />
        </Box>
      </TableCell>
    </TableRow>
  )
}

export default CartItemRow