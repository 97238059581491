import React, { useEffect, useState, useRef } from 'react'
import ProductOptionsHeader from './ProductOptionsHeader'
import ProductOptionsBody from './ProductOptionsBody'

const OptionsSidebar = () => {
  const headerRef = useRef()
  const [headerHeight, setHeaderHeight] = useState(0);
  const [optionSearch, setOptionSearch] = useState('')

  useEffect(() => {
    setHeaderHeight(headerRef.current.offsetHeight)
    console.log(headerRef.current.offsetHeight)
  }, [])

  return (
    <>
      <ProductOptionsHeader headerRef={headerRef} optionSearch={optionSearch} setOptionSearch={setOptionSearch} />
      <ProductOptionsBody headerHeight={headerHeight} optionSearch={optionSearch} />
    </>
  )
}

export default OptionsSidebar