import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { deleteItemRuleAction } from '../../../../../../../../reducer_action/product';

const OptionCard = ({ rule, optionValueName, hideOptionValueName }) => {
  const dispatch = useDispatch()
  const params = useParams()
  const handleRuleDelete = (ruleId) => {
    console.log('delete rule', ruleId);
    dispatch(deleteItemRuleAction(+params.item, ruleId, rule.ruleType))
  }

  return (

    <React.Fragment key={rule.id}>
      <Box
        sx={{
          display: 'flex',
          padding: '0.5rem ',
          borderWidth: '1px',
          borderColor: 'secondary.underLine',
          borderStyle: 'solid',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '1rem',
        }}
      >
        <Box>
          <Typography variant='h4'>Rule type</Typography>
          <Typography>{rule.ruleType}</Typography>
        </Box>
        <Box sx={{}}>
          <Typography variant='h4'>Title</Typography>
          <Typography variant='body1'>{rule.title}</Typography>
        </Box>
        <Box>
          <Typography variant='h4'>Description</Typography>
          <Typography variant='body1'>{rule.description}</Typography>
        </Box>
        <Box>
          <Typography variant='h4'>Options:</Typography>
          {optionValueName.map((optionString, index) => {
            return (
              <Box key={optionString} sx={{ paddingLeft: '1rem' }}>
                <Typography variant='body1'>
                  {optionString ? `${index + 1}.` : null} {optionString}
                </Typography>
              </Box>
            );
          })}
        </Box>
        <Box>
          <Typography variant='h4'>Hide options:</Typography>
          {hideOptionValueName.map((optionString, index) => {
            return (
              <Box key={optionString} sx={{ paddingLeft: '1rem' }}>
                <Typography key={optionString} variant='body1'>
                  {optionString ? `${index + 1}.` : null} {optionString}
                </Typography>
              </Box>
            );
          })}
        </Box>
        <Box>
          <Button variant='contained' onClick={() => handleRuleDelete(rule.id)}> delete </Button>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default OptionCard;
