import { RequestPageRounded } from '@mui/icons-material';
import axios from 'axios';
import { toast } from 'react-toastify';
import userService from './user';

// const baseUrl = 'http://localhost:8081';
const baseUrl = process.env.REACT_APP_PROXY_BACKEND
// const baseUrl = process.env.PROXY

const getToken = async () => {
  const session = await userService.getSession()
  const token = session && session.idToken
  return token.jwtToken
}

export const getPresignedUrl = async (fileName, bucket) => {
  try {
    const token = await getToken()
    if (!token) return

    const response = await axios({
      method: 'get',
      url: `${baseUrl}/util/getImagePostUrl/?fileName=${fileName}&bucket=${bucket}`,
      headers: { Authorization: `${token}` },
    });

    return response.data;
  } catch (error) {
    toast.error('there was an error in getting the upload URL')
  }
};

export const uploadToAwsS3 = async (url, file, setLoadingProgress) => {
  try {

    const response = await new Promise((resolve, reject) => {
      let request = new XMLHttpRequest();
      request.open('PUT', url, true);

      request.upload.addEventListener('progress', function (e) {
        let done = e.position || e.loaded;
        let total = e.totalSize || e.total;
        const percentage = Math.floor(Math.floor(done / total * 1000) / 10)
        console.log('xhr progress: ' + percentage)
        if (setLoadingProgress) {
          console.log('loading progress: ' + percentage)
          setLoadingProgress(percentage)
        }
      })

      request.onload = function (e) {
        // console.log('xhr upload complete', request, request.response, 'ei', e);
        const resolveObject = {
          ok: true,
          status: request.status,
          url: request.responseURL,
        }
        resolve(resolveObject)
      }
      request.onerror = function (e) {
        const rejectObject = {
          ok: false,
          status: request.status,
        }
        reject(rejectObject)
      }

      request.send(file)
    })

    return response
  } catch (error) {
    toast.error('there was an error in uploading the file(s)')
  }
};

    // * 'the fetch solution to uploading the file // const result = await fetch(url, { //   method: 'PUT', //   headers: { 'Content-Type': 'multipart/form-data' }, //   body: file, // }); // return result;