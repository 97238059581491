import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Typography, Grid, Button, Box, Select, MenuItem } from '@mui/material';

import Option from './optionRuleForms/Option';
import OptionSizeHide from './optionRuleForms/OptionSizeHide';
import SizeOption from './optionRuleForms/SizeOption';
import SizeQuantityForm from './optionRuleForms/SizeQuantityForm';
import SizeQuantityOption from './optionRuleForms/SizeQuantityOption';

import SizeQuantityCard from './RuleCards/SizeQuantityCard';
import SizeOptionCard from './RuleCards/SizeOptionCard';
import OptionCard from './RuleCards/OptionCard';
import SizeQuantityOptionCard from './RuleCards/SizeQuantityOptionCard';
import OptionSizeHideCard from './RuleCards/OptionSizeHideCard';

import { underscoreSplitter } from '../../../../../../../utilFunctions';

const OptionRules = () => {
  const params = useParams();
  const ruleTypes = useSelector((state) => state.productUtil.enums.find((x) => x.name === 'option_rule_types')).value.split('|');

  const [tab, setTab] = useState(ruleTypes[0]);
  const [isAdd, setIsAdd] = useState(false);

  const itemOptions = useSelector((state) => state.product.find((product) => product.id === +params.item)).options;
  const itemSize = useSelector((state) => state.product.find((product) => product.id === +params.item)).size;
  const itemRules = useSelector((state) => state.product.find((product) => product.id === +params.item).optionRules);

  const hideForm = (tabValue, element) => {
    return (itemOptions.length > 0 && itemSize.length > 0 && tab === tabValue) ? element : null
  }

  return (
    <Grid container sx={{ width: '100%' }}>
      {itemOptions.length > 0 && itemSize.length > 0 ? (
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Select value={tab} onChange={(e) => setTab(e.target.value)}>
              {ruleTypes.map((ruleType) => {
                return (
                  <MenuItem value={ruleType}>
                    {underscoreSplitter(ruleType)}
                  </MenuItem>
                );
              })}
            </Select>
            <Button onClick={() => setIsAdd(!isAdd)} variant='contained' >
              {isAdd ? 'back' : 'add'}
            </Button>
          </Box>
        </Grid>
      ) : null}

      {itemOptions.length > 0 || itemSize.length > 0 ? (
        <>
          {isAdd ? (
            <>
              <Grid item xs={12}>
                {hideForm('SIZE_QUANTITY', <SizeQuantityForm ruleType={tab} />)}
                {hideForm('SIZE_OPTION', <SizeOption ruleType={tab} />)}
                {hideForm('OPTION', <Option ruleType={tab} />)}
                {hideForm('SIZE_QUANTITY_OPTION', <SizeQuantityOption ruleType={tab} />)}
                {hideForm('OPTION_SIZE_HIDE', <OptionSizeHide ruleType={tab} />)}
              </Grid>
            </>
          ) : (
            <Grid item xs={12}>
              {itemRules.length > 0 ? (
                <>
                  {/* each rule needs its own box */}
                  {itemRules.map((rule) => {
                    const sizeIds = rule.values.map((value) => {
                      return value.itemSizeId;
                    });

                    const sizeNames = itemSize
                      .filter((size) => sizeIds.includes(size.id))
                      .map((size) => size.sizeTitle);

                    const hideOptionIds = rule.values.map((value) => {
                      return value.hideOptionValueId;
                    });

                    const hideSizeIds = rule.values.map((value) => {
                      return value.hideSizeId;
                    });

                    const optionIds = rule.values.map(
                      (value) => value.itemOptionValueId
                    );

                    const hideSizeNames = itemSize
                      .filter((size) => hideSizeIds.includes(size.id))
                      .map((size) => { return size.sizeTitle; });

                    const hideOptionValueName = itemOptions.map((option) => {
                      const valuesNames = option.values
                        .filter((value) => { return hideOptionIds.includes(value.id) })
                        .map((value) => value.optionValueName)
                        .join(', ');

                      if (!valuesNames.length > 0) return null;
                      return `${option.name}: ${valuesNames}`;
                    });

                    const optionValueNames = itemOptions.map((option) => {
                      const valueNames = option.values
                        .filter((value) => {
                          return optionIds.includes(value.id);
                        })
                        .map((value) => {
                          console.log({ value });
                          return value.optionValueName;
                        })
                        .join(', ');
                      if (!valueNames.length > 0) return null;
                      return `${option.name}: ${valueNames}`;
                    });

                    switch (rule.ruleType) {
                      case 'SIZE_QUANTITY':
                        return (
                          <React.Fragment key={rule.id}>
                            <SizeQuantityCard
                              rule={rule}
                              sizeNames={sizeNames}
                              hideOptionValueName={hideOptionValueName}
                            />
                          </React.Fragment>
                        );
                      case 'SIZE_OPTION':
                        return (
                          <React.Fragment key={rule.id}>
                            <SizeOptionCard
                              rule={rule}
                              sizeNames={sizeNames}
                              hideOptionValueName={hideOptionValueName}
                              optionValueName={optionValueNames}
                            />
                          </React.Fragment>
                        );
                      case 'OPTION':
                        return (
                          <React.Fragment key={rule.id}>
                            <OptionCard
                              rule={rule}
                              optionValueName={optionValueNames}
                              hideOptionValueName={hideOptionValueName}
                            />
                          </React.Fragment>
                        );
                      case 'SIZE_QUANTITY_OPTION':
                        return (
                          <React.Fragment key={rule.id}>
                            <SizeQuantityOptionCard
                              rule={rule}
                              sizeNames={sizeNames}
                              optionValueName={optionValueNames}
                              hideOptionValueName={hideOptionValueName}
                            />
                          </React.Fragment>
                        );
                      case 'OPTION_SIZE_HIDE':
                        return (
                          <React.Fragment key={rule.id}>
                            <OptionSizeHideCard
                              rule={rule}
                              optionValueName={optionValueNames}
                              hideSizeNames={hideSizeNames}
                            />
                          </React.Fragment>
                        );
                      default:
                        return null;
                    }
                  })}
                </>
              ) : (
                <Typography>This item does not have any options/size so no rules can be formulated </Typography>
              )}
            </Grid>
          )}
        </>
      ) : (
        <Typography variant='h4' sx={{ marginTop: '2rem' }}>
          This item does not have any size or option values available
        </Typography>
      )}
    </Grid>
  );
};

export default OptionRules;
