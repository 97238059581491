import React, { useState } from 'react'
import { Box, Grid, Typography, Button } from '@mui/material'
import { useNavigate, } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import EditTableView from '../../utilComponents/EditTableView'
import { postUserAddressAction } from '../../../reducer_action/user'

const AddAddress = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [name, setName] = useState('')
  const [address, setAddress] = useState('')
  const [pincode, setPincode] = useState('')
  const [latitude, setLatitude] = useState('')
  const [longitude, setLongitude] = useState('')

  const handlePostAddress = () => {
    dispatch(postUserAddressAction({ name, address, pincode, latitude, longitude }))
    navigate('/asx/user')
  }

  return (
    <Grid container item xs={12} spacing={3} sx={{ padding: '1rem' }}>
      <Grid item xs={12} sx={{ marginTop: '0rem', marginBottom: '0rem' }}>
        <Typography variant="h2" align='left'>Add Address</Typography>
      </Grid>
      <Grid item xs={12}>
        <EditTableView title='name' type='text' value={name} onChange={(e) => setName(e.target.value)} />
        <EditTableView title='address' type='multiline-text' value={address} onChange={(e) => setAddress(e.target.value)} />
        <EditTableView title='pincode' type='text' value={pincode} onChange={(e) => setPincode(e.target.value)} />
        <EditTableView title='latitude' type='text' value={latitude} onChange={(e) => setLatitude(e.target.value)} />
        <EditTableView title='longitude' type='text' value={longitude} onChange={(e) => setLongitude(e.target.value)} />
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '2rem' }}>
          <Button variant='contained' onClick={() => navigate('/asx/user')}>back</Button>
          <Button variant='contained' onClick={handlePostAddress}> save this address</Button>
        </Box>
      </Grid>
    </Grid>

  )
}

export default AddAddress