const awsConfig = {
  Auth: {
    //REQUIRED - Amazon Cognito Identity Pool ID
    region: "ap-south-1", // REQUIRED - Amazon Cognito Region
    userPoolId: "ap-south-1_9rpQhrdi6", //OPTIONAL - Amazon Cognito User Pool ID
    userPoolWebClientId: "6jf20cmt1asb1f6v5eg7p05er", //OPTIONAL - Amazon Cognito Web Client ID
  },
};

export default awsConfig;
