import React from "react";
import { useSelector } from "react-redux";

import { Outlet } from "react-router-dom";
import Sidebar from "../Navigation_Module/Sidebar";
import Grid from "@mui/material/Grid";

const Dash = () => {
  const util = useSelector((state) => state.util);
  return (
    <Grid container>
      <Grid item sx={{ width: util.sideBar ? "60px" : "260px" }}>
        <Sidebar />
      </Grid>
      <Grid item xs>
        <Outlet />
      </Grid>
    </Grid>
  );
};

export default Dash;
