import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Box } from '@mui/material';

import ProductOptionsCard from './ProductOptionsCard';
import { getAllOptionsAction } from '../../../../../reducer_action/options';

const ProductOptionsBody = ({ headerHeight, optionSearch }) => {
  const dispatch = useDispatch();
  const options = useSelector((state) => state.option);

  useEffect(() => {
    dispatch(getAllOptionsAction());
  }, [dispatch]);

  return (
    <Grid container item xs={12}
      sx={{
        overflow: 'auto',
        height: `calc(100vh - ${headerHeight}px)`,
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        padding: '0rem 1rem',
      }}
      alignContent='flex-start'
    >
      {options &&
        options.length > 0 &&
        options.filter(x => x.name.includes(optionSearch.toLowerCase())).map((option) => {
          return (
            <Grid key={option.id} item container sx={{ marginTop: '0.75rem' }}>
              <ProductOptionsCard key={option.id} option={option} />
            </Grid>
          );
        })
      }
    </Grid>
  );
};

export default ProductOptionsBody;
