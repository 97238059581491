import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setUtilAction } from '../../reducer_action/util';

import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Collapse from '@mui/material/Collapse';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const ListItems = ({ toggle, text, routes, icon }) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // console.log({ routes });
  return (
    <>
      <ListItemButton
        key={text}
        sx={{
          minHeight: 48,
          justifyContent: 'center',
        }}
        onClick={(e) => {
          navigate(routes[0].url);
          dispatch(
            setUtilAction({
              mainSideBar: false,
              actionQuery: null,
            })
          );
          !toggle && setOpen(!open);
          e.detail === 2 && dispatch(setUtilAction({ sideBar: false }));
          e.detail === 2 && setOpen(true);
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            justifyContent: 'center',
            paddingRight: toggle ? '0rem' : '1rem',
          }}
        >
          {icon}
        </ListItemIcon>
        {!toggle ? (
          <ListItemText
            primary={
              <Typography variant="h5" sx={{ color: 'white' }}>
                {text}
              </Typography>
            }
          />
        ) : null}
        {!toggle ? (
          <ArrowForwardIosIcon
            sx={{
              width: '1rem',
              transform: open ? 'rotate(90deg)' : 'rotate(0deg)',
              transitionProperty: 'all',
              transitionDuration: '0.5s',
            }}
          />
        ) : null}
      </ListItemButton>
      {!toggle ? (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div">
            {routes.map((route) => {
              return (
                <ListItemButton
                  sx={{ backgroundColor: 'primary' }}
                  key={route.url}
                  onClick={() => {
                    navigate(route.url);
                    dispatch(
                      setUtilAction({
                        mainSideBar: false,
                        actionQuery: null,
                      })
                    );
                  }}
                >
                  <ListItemText
                    align="left"
                    sx={{ paddingLeft: '2.5rem' }}
                    primary={
                      <Typography variant="body2" sx={{ color: 'white' }}>
                        {route.name}
                      </Typography>
                    }
                  />
                </ListItemButton>
              );
            })}
          </List>
        </Collapse>
      ) : null}
    </>
  );
};
export default ListItems;
