import React from 'react';
import { Outlet } from 'react-router-dom';

import SignIn from './SignIn.js';
import SignUp from './SignUp.js';

import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Slide from '@mui/material/Slide';
import CenterBox from '../utilComponents/CenterBox';

import fullLogo from '../../data/imgs/fullLogo.png';

const Authentication = () => {
  const [tab, setTab] = React.useState('signIn');
  const containerRef = React.useRef(null);

  return (
    <CenterBox>
      <Grid
        ref={containerRef}
        container
        sx={{
          width: '30rem',
          borderRadius: '20px',
          border: '1px solid #051f3030',
          boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.35)',
          backgroundColor: '#ffffff',
          padding: '2rem 0rem',
          overflow: 'hidden',
        }}
        justifyContent='center'
        alignItems='flex-start'
        rowSpacing={2}
      >
        <Grid item xs={12}>
          <img
            src={fullLogo}
            style={{ width: '12rem', height: '2.5rem', margin: '0rem auto 0', display: 'block', }}
            alt='papesaur-logo'
          />
        </Grid>

        <Grid item xs={12}>
          <Tabs variant='fullWidth' value={tab} textColor='primary' indicatorColor='primary'
            onChange={(e, newValue) => setTab(newValue)}
          >
            <Tab value='signIn' label='Sign In' />
            <Tab value='signUp' label='Sign up' />
          </Tabs>
        </Grid>

        {tab === 'signIn' && (
          <Slide direction='left' in={tab === 'signIn'} container={containerRef.current} >
            <Grid item xs={12}>
              <SignIn />
            </Grid>
          </Slide>
        )}

        {tab === 'signUp' && (
          <Slide direction='right' in={tab === 'signUp'} container={containerRef.current} >
            <Grid item xs={12}>
              <SignUp setTab={setTab} />
            </Grid>
          </Slide>
        )}
      </Grid>
    </CenterBox>
  );
};

export default Authentication;
