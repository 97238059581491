import React from "react";
import { Grid, Typography, Tabs, Tab } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setUtilAction } from "../../../../reducer_action/util";

const Header = () => {
  const pages = ["view", "transactions", "Assign-Bill", "add-credit-payment"];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { actionQuery } = useSelector((state) => state.util);

  return (
    <Grid container>
      <Grid item xs={12} sx={{ padding: "1rem 1rem" }}>
        <Typography variant="h4" align="left">
          Customer Name
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ padding: "0" }}>
        <Tabs value={actionQuery}>
          {pages.map((page) => {
            return (
              <Tab
                key={page}
                value={page}
                label={page}
                onClick={() => {
                  dispatch(setUtilAction({ actionQuery: page }));
                  navigate(
                    "/asx/creditCustomers/nameOfCustomer?action=" + page
                  );
                }}
              />
            );
          })}
        </Tabs>
      </Grid>
    </Grid>
  );
};

export default Header;
