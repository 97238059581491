import React from 'react';
import { Grid, Box, Typography, Button } from '@mui/material';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setUtilAction } from '../../../../../reducer_action/util';
// import Add from "../../../mainbar/mainBody/Products_Module/categories/categoryPages/Edit-Add";
import Add from '../Mainbar/categoryPages/Add';

const AddCategory = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <>
      <Grid container item xs={12} sx={{ padding: '1rem 1rem 3rem 1rem' }}>
        <Grid item xs={12} sx={{}}>
          <Typography variant='h4' sx={{ textTransform: 'capitalize', display: 'block', }} >
            Add a products
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: '1rem' }}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              onClick={() => {
                navigate('/godown/productCategories');
                dispatch(setUtilAction({ actionQuery: 'view' }));
              }}
              color='primary'
              variant='contained'
            >
              close
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: '2rem' }}>
          <Add />
        </Grid>
      </Grid>
    </>
  );
};

export default AddCategory;
