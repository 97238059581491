import React, { useState, useEffect } from 'react'
import { Modal, LinearProgress, Box, Typography, Select, MenuItem, TextField, Button } from '@mui/material'
import CenterBox from '../../../../../utilComponents/CenterBox'
import { useDispatch, useSelector } from 'react-redux'
import FileMultipleInput from '../../../../../utilComponents/FileMultipleInput'
import { getPresignedUrl, uploadToAwsS3 } from '../../../../../../service/s3'
import { useParams } from 'react-router-dom'
import { addProductToGodownClientCartAction } from '../../../../../../reducer_action/godownClientCart'
import { groupBy } from 'lodash'
import { toast } from 'react-toastify'

const CreateOrderOrder = ({ productId, client }) => {
  const { clientId } = useParams()
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);

  // it pulls whatever is in state => likely switches up between clients
  const godownClientCart = useSelector((state) => state.godownClientCart);

  const currentItem = useSelector((state) => state.product.find((item) => item.id === productId));
  const trackingUnits = useSelector(state => state.productUtil.trackingUnit)
  const orderUnits = useSelector(state => state.productUtil.orderUnit)

  // component util
  const [loading, setLoading] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(true)
  const [openModal, setOpenModal] = useState(false)

  const initOrderDetails = () => {
    const optionInitValues = currentItem?.options.map((option) => {
      return {
        optionId: option.id,
        optionValueId: option.values.length > 0 ? option.values[0].id : [],
      };
    });

    return {
      itemSizeId: currentItem.size.length > 0 ? currentItem?.size[0].id : null,
      options: optionInitValues,
    };
  };

  // * this page does not have any images
  // const [allImages, setAllImages] = useState(allItemImage());
  // const [currentImage, setCurrentImage] = useState(allImages[0] || {});

  const [orderDetails, setOrderDetails] = useState(initOrderDetails());
  const [quantity, setQuantity] = useState(1);

  const [file, setFile] = useState();
  const [fileName, setFileName] = useState('');

  const currentGuideline = (sizeId) => {
    console.log(sizeId)
    if (!sizeId) {
      return currentItem.orderGuideline.find(guideline => guideline.itemId === currentItem.id);
    } else {
      return currentItem.orderGuideline.find((guideline) => guideline.itemSizeId === orderDetails.itemSizeId)
    }
  }

  const currentOrderUnit = (orderUnitId) => {
    return orderUnits.find(orderUnit => orderUnit.id === orderUnitId)
  }

  const currentTrackingUnit = (trackingUnitId) => {
    return trackingUnits.find(trackingUnit => trackingUnit.id === trackingUnitId)
  }

  useEffect(() => {
    setOrderDetails(initOrderDetails());
    // setAllImages(allItemImage());
    // setCurrentImage(allItemImage().length > 0 ? allItemImage()[0] : {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentItem]);



  const stateReset = () => {
    setQuantity(1)
    setLoading(false)
    setFile()
    setFileName()
  }

  const handleAddToCart = async () => {
    try {

      setLoading(true)
      // check if the item is already in the cart
      if (godownClientCart.map(x => x.itemSizeId).includes(orderDetails.itemSizeId)) {
        // find all items with the same itemSizeId
        const currentCartItem = godownClientCart.filter(x => x.itemSizeId === orderDetails.itemSizeId)
        // check if they have options
        if (orderDetails.options.length > 0) {
          // convert option values into a string for all itemSizes
          const mappedCurrentCartOptions = currentCartItem.map(item => {
            return item.options.map(x => `${x.optionId}-${x.optionValueId}`)
          }).flat()
          const mappedOrderDetailsOptions = orderDetails.options.map(x => `${x.optionId}-${x.optionValueId}`)

          // check if the options are the same 
          for (const orderDetailOption of mappedOrderDetailsOptions) {
            if (mappedCurrentCartOptions.includes(orderDetailOption)) {
              setLoading(false)
              return alert('item already in cart, please update option')
            }
          }
        } else {
          alert('item already in cart')
          setLoading(false)
          return
        }
      }

      // adding files
      let mediaUrl = 'no media'
      if (file && fileName) {
        setOpenModal(true)
        // upload the file
        const uploadUrl = await getPresignedUrl(fileName);
        const resultOfUpload = await uploadToAwsS3(uploadUrl, file, setLoadingProgress);

        if (resultOfUpload.ok) {
          mediaUrl = resultOfUpload.url.split('?')[0];
        } else {
          toast.error('error uploading file');
          setOpenModal(false)
          stateReset()
          return
        }
        setOpenModal(false)
        setFile(null)
        setFileName(null)
      }

      const orderUnitId = currentGuideline(orderDetails.itemSizeId)?.orderUnitId;
      const notes = ''
      const cartItem = { itemId: currentItem.id, ...orderDetails, itemOrderUnitId: orderUnitId, quantity, mediaUrl, notes };
      const userData = {
        godownUsername: user.username,
        godownEmail: user.email,
        clientUsername: clientId,
        clientEmail: client.email,
      }
      // console.log(cartItem, userData)
      dispatch(addProductToGodownClientCartAction(userData, cartItem));

      stateReset()
      toast.success(`${currentItem.name} added to cart`)
    } catch (error) {
      setOpenModal(false)
      stateReset()
      toast.error('error adding to cart')
    }
  };

  return (
    <Box sx={{ padding: '0rem 2rem' }}>
      <Typography variant='h3' align='center'
        sx={{ marginBottom: '2rem', textTransform: 'capitalize', borderBottom: '3px solid black', paddingBottom: '0.5rem' }}>
        {currentItem.name}
      </Typography>
      {currentItem.size.filter(s => s.status).length > 0 && (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', }} >
          <Typography variant='h4'>Choose a size</Typography>
          <Select
            value={orderDetails.itemSizeId}
            onChange={(e) => {
              setOrderDetails({
                ...orderDetails,
                itemSizeId: e.target.value,
              });
            }}
          >
            {currentItem?.size.length > 0 &&
              currentItem?.size.map((size) => {
                if (!size.status) return null

                const optionRulesGrouped = groupBy(currentItem?.optionRules, 'ruleType')
                const optionOptionSizeHideRules = optionRulesGrouped?.OPTION_SIZE_HIDE
                const optionSizeHideRuleValues = []
                optionOptionSizeHideRules?.forEach(rule => { rule.values.forEach(ruleValue => { optionSizeHideRuleValues.push(ruleValue) }) })

                const isItOptionSizeHideDisabled = optionSizeHideRuleValues?.find(ruleValue => {
                  return orderDetails?.options && orderDetails.options.find(selection => selection.optionValueId === ruleValue.itemOptionValueId) && ruleValue.hideSizeId === size.id
                })

                return (
                  <MenuItem
                    disabled={typeof isItOptionSizeHideDisabled === 'object'}
                    key={size.id} value={size.id}
                  >
                    {size.sizeTitle}
                  </MenuItem>
                );
              })
            }
          </Select>
        </Box>
      )}

      <Box>
        {currentItem?.options.length > 0 &&
          currentItem.options.map((op, index) => {
            // todo this is the option rules will be added here
            const setDefault = () => {
              const value = orderDetails?.options.find((x) => {
                const result = x.optionId === op.id;
                return result;
              });
              return !value ? null : +value?.optionValueId;
            };

            return (
              <Box
                key={`${op.id}-${op.optionTitle}-${index}`}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginTop: '2rem',
                }}
              >
                <Typography variant='h4'>
                  {op.name.toUpperCase()}
                </Typography>
                <Select
                  value={setDefault()}
                  onChange={(e) => {
                    const optionValue = {
                      optionId: op.id,
                      optionValueId: e.target.value,
                    };

                    const newOrderDetailsOptions = () => {
                      if (orderDetails?.options.length <= 0) {
                        return [optionValue];
                      } else if (
                        orderDetails?.options
                          .map((x) => x.optionId)
                          .includes(op.id)
                      ) {
                        //replace option value with current option value
                        return orderDetails?.options.map((x) => {
                          return x.optionId === op.id ? optionValue : x;
                        });
                      } else {
                        return [...orderDetails?.options, optionValue];
                      }
                    };
                    console.log({ optionValue, h: newOrderDetailsOptions() });
                    setOrderDetails({
                      ...orderDetails,
                      options: newOrderDetailsOptions(),
                    });
                  }}
                >
                  {op.values.length > 0 &&
                    op.values.map((value) => {

                      const optionRulesGrouped = groupBy(currentItem.optionRules, 'ruleType')

                      // size_quantity rule implementation
                      const sizeQuantityRules = optionRulesGrouped?.SIZE_QUANTITY
                      const ruleValues = []
                      sizeQuantityRules?.forEach(rule => { rule.values.forEach(ruleValue => { ruleValues.push(ruleValue) }) })
                      const isItSizeQuantityDisabled = ruleValues?.find(ruleValue => {
                        if (ruleValue.allQuantity) {
                          return ruleValue.itemSizeId === orderDetails.itemSizeId && ruleValue.hideOptionValueId === value.id
                        } else if (ruleValue.evalType === 'EQUAL_TO') {
                          return ruleValue.itemSizeId === orderDetails.itemSizeId && ruleValue.hideOptionValueId === value.id && orderDetails.quantity === ruleValue.startQuantity
                        } else if (ruleValue.evalType === 'GREATER_THAN_OR_EQUAL_TO') {
                          return ruleValue.itemSizeId === orderDetails.itemSizeId && ruleValue.hideOptionValueId === value.id && orderDetails.quantity > ruleValue.startQuantity
                        } else if (ruleValue.evalType === 'LESS_THAN_OR_EQUAL_TO') {
                          return ruleValue.itemSizeId === orderDetails.itemSizeId && ruleValue.hideOptionValueId === value.id && orderDetails.quantity < ruleValue.startQuantity
                        } else if (ruleValue.evalType === 'NOT_EQUAL_TO') {
                          return ruleValue.itemSizeId === orderDetails.itemSizeId && ruleValue.hideOptionValueId === value.id && orderDetails.quantity !== ruleValue.startQuantity
                        } else if (ruleValue.evalType === 'BETWEEN') {
                          return ruleValue.itemSizeId === orderDetails.itemSizeId && ruleValue.hideOptionValueId === value.id && orderDetails.quantity >= ruleValue.startQuantity && orderDetails.quantity <= ruleValue.endQuantity
                        }
                        return false
                      })

                      // size_option rule implementation
                      const sizeOptionRules = optionRulesGrouped?.SIZE_OPTION
                      const sizeOptionRuleValues = []
                      sizeOptionRules?.forEach(rule => { rule.values.forEach(ruleValue => { sizeOptionRuleValues.push(ruleValue) }) })
                      const isItSizeOptionDisabled = sizeOptionRuleValues?.find(ruleValue => {
                        return ruleValue.itemSizeId === orderDetails.itemSizeId &&
                          ruleValue.itemOptionValueId === orderDetails.options.find(x => x.optionValueId === ruleValue.itemOptionValueId)?.optionValueId &&
                          ruleValue.hideOptionValueId === value.id
                      })

                      // option rule implementation
                      const optionOptionRules = optionRulesGrouped?.OPTION
                      const optionRuleValues = []
                      optionOptionRules?.forEach(rule => { rule.values.forEach(ruleValue => { optionRuleValues.push(ruleValue) }) })
                      const isItOptionDisabled = optionRuleValues?.find(ruleValue => orderDetails?.options && orderDetails.options.find(selection => selection.optionValueId === ruleValue.itemOptionValueId) && ruleValue.hideOptionValueId === value.id)


                      // size_quantity_option rule implementation
                      const sizeQuantityOptionRules = optionRulesGrouped?.SIZE_QUANTITY_OPTION
                      const sizeQuantityOptionRuleValues = []
                      sizeQuantityOptionRules?.forEach(rule => { rule.values.forEach(ruleValue => { sizeQuantityOptionRuleValues.push(ruleValue) }) })
                      console.log({ sizeQuantityOptionRuleValues, orderDetails })

                      const isItSizeQuantityOptionDisabled = sizeQuantityOptionRuleValues?.find(ruleValue => {
                        if (ruleValue.allQuantity) {
                          return ruleValue.itemSizeId === orderDetails.itemSizeId &&
                            ruleValue.itemOptionValueId === orderDetails.options.find(x => x.optionValueId === ruleValue.itemOptionValueId)?.optionValueId &&
                            ruleValue.hideOptionValueId === value.id
                        } else if (ruleValue.evalType === 'EQUAL_TO') {
                          return ruleValue.itemSizeId === orderDetails.itemSizeId &&
                            ruleValue.itemOptionValueId === orderDetails.options.find(x => x.optionValueId === ruleValue.itemOptionValueId)?.optionValueId &&
                            ruleValue.hideOptionValueId === value.id &&
                            orderDetails.quantity === ruleValue.startQuantity
                        } else if (ruleValue === 'GREATER_THAN_OR_EQUAL_TO') {
                          return ruleValue.itemSizeId === orderDetails.itemSizeId &&
                            ruleValue.itemOptionValueId === orderDetails.options.find(x => x.optionValueId === ruleValue.itemOptionValueId)?.optionValueId &&
                            ruleValue.hideOptionValueId === value.id &&
                            orderDetails.quantity >= ruleValue.startQuantity
                        } else if (ruleValue === 'LESS_THAN_OR_EQUAL_TO') {
                          return ruleValue.itemSizeId === orderDetails.itemSizeId &&
                            ruleValue.itemOptionValueId === orderDetails.options.find(x => x.optionValueId === ruleValue.itemOptionValueId)?.optionValueId &&
                            ruleValue.hideOptionValueId === value.id &&
                            orderDetails.quantity <= ruleValue.startQuantity
                        } else if (ruleValue === 'NOT_EQUAL_TO') {
                          return ruleValue.itemSizeId === orderDetails.itemSizeId &&
                            ruleValue.itemOptionValueId === orderDetails.options.find(x => x.optionValueId === ruleValue.itemOptionValueId)?.optionValueId &&
                            ruleValue.hideOptionValueId === value.id &&
                            orderDetails.quantity !== ruleValue.startQuantity
                        } else if (ruleValue === 'BETWEEN') {
                          return ruleValue.itemSizeId === orderDetails.itemSizeId &&
                            ruleValue.itemOptionValueId === orderDetails.options.find(x => x.optionValueId === ruleValue.itemOptionValueId)?.optionValueId &&
                            ruleValue.hideOptionValueId === value.id &&
                            orderDetails.quantity >= ruleValue.startQuantity &&
                            orderDetails.quantity <= ruleValue.endQuantity
                        }
                        return false
                      })


                      return (
                        <MenuItem
                          disabled={
                            typeof isItSizeQuantityDisabled === 'object' ||
                            typeof isItSizeOptionDisabled === 'object' ||
                            typeof isItOptionDisabled === 'object' ||
                            typeof isItSizeQuantityOptionDisabled === 'object'
                          }
                          key={value.id}
                          value={value.id}
                        >
                          {value.optionValueName}
                        </MenuItem>
                      );
                    })}
                </Select>
              </Box>
            );
          })}
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '2rem',
        }}
      >
        <Typography variant='h4'>Quantity</Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <TextField
            value={quantity}
            onChange={(e) => {
              setQuantity(+e.target.value);
              setOrderDetails({
                ...orderDetails,
                quantity: +e.target.value,
              });
            }}
            align='center'
            sx={{ width: '100px' }}
          />
        </Box>
      </Box>
      {
        currentItem.uploadCenter && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: '2rem',
            }}
          >
            <Typography variant='h4'>Upload File</Typography>
            <FileMultipleInput
              file={file}
              setFile={setFile}
              setFileName={setFileName}
              fileName={fileName}
            />
          </Box>
        )
      }

      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: '2rem'
      }}>
        <Typography variant='h4' >Order Info</Typography>
        {orderDetails && (() => {
          const orderUnitId = currentGuideline(orderDetails.itemSizeId)?.orderUnitId
          const currentOrUnit = currentOrderUnit(orderUnitId)
          const currentTrUnit = currentTrackingUnit(currentOrUnit?.trackingUnitId)
          // console.log(orderUnitId, currentOrUnit, currentTrUnit)
          return (
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography align='right' variant='body1'>
                {currentOrUnit?.trackingUnitQuantity} {currentTrUnit?.name} per quantity
              </Typography>
              <Typography align='right'>Total {currentTrUnit?.name}(s): {quantity * currentOrUnit?.trackingUnitQuantity} </Typography>
            </Box>
          )
        })()}
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%',

          marginTop: '4rem',
        }}
      >
        <Button disabled={loading} onClick={handleAddToCart} variant='contained'>
          add to cart
        </Button>
      </Box>
      <Modal open={openModal} onClose={() => console.log('modal close')}>
        <>
          <CenterBox>
            <Box sx={{ display: ' flex', justifyContent: 'flex-end' }}>
              <Button variant='contained' onClick={() => setOpenModal(false)}>back</Button>
            </Box>
            <Typography variant='h2' align='center' sx={{ marginBottom: '1rem' }}>Uploading File</Typography>
            <Box sx={{ display: 'flex', align: 'center', columnGap: '1rem' }}>
              <Typography sx={{ paddingTop: '0.5rem' }}>{loadingProgress}%</Typography>
              <Box sx={{ padding: '1rem 1rem 1rem 0.5rem', width: '50vw', }}>
                <LinearProgress color='primary' />
              </Box>
            </Box>
          </CenterBox>
        </>
      </Modal>
    </Box >
  )
}

export default CreateOrderOrder