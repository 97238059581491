import React, { useState, useRef } from 'react'
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Box, Typography, Button } from '@mui/material'
import { useSelector } from 'react-redux'
import { useNavigate, } from 'react-router-dom'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { getPresignedUrl, uploadToAwsS3 } from '../../../../../../../service/s3'
import { updateShipment } from '../../../../../../../service/shipments'
import EditIcon from '@mui/icons-material/Edit';
import UndoIcon from '@mui/icons-material/Undo';
import { toast } from 'react-toastify'


const View = ({ shipment, setShipment }) => {
  const product = useSelector(state => state.product)
  const option = useSelector(state => state.option)
  const orderUnit = useSelector(state => state.productUtil.orderUnit)
  const trackingUnit = useSelector(state => state.productUtil.trackingUnit)
  // const location = useLocation()
  const navigate = useNavigate()

  const [file, setFile] = useState(null)
  const [fileName, setFileName] = useState(null)

  // upload, download or reupload logic
  const [reupload, setReupload] = useState(true)

  const findProductNameFromId = (itemId) => {
    const name = product.find((product) => product.id === itemId)?.name;
    return name;
  };

  const findProductSizeName = (itemId, sizeId) => {
    const currentProduct = product.find((x) => x.id === itemId);
    const sizeName = currentProduct?.size.find((x) => x?.id === sizeId)?.sizeTitle;
    return sizeName;
  };

  const findOptionNameFromId = (optionId) => {
    const name = option.find((option) => option?.id === optionId)?.name;
    return name;
  };

  const findOptionValueFromId = (optionId, optionValueId) => {
    const currentOption = option.find((x) => x.id === optionId);
    const valueName = currentOption?.values.find(
      (v) => v.id === optionValueId
    ).optionValueName;
    return valueName;
  };

  const handleFileUpload = async () => {
    // console.log(shipment,)
    try {
      const uploadUrl = await getPresignedUrl(fileName);
      const resultOfUpload = await uploadToAwsS3(uploadUrl, file);
      if (resultOfUpload.ok) {
        const mediaUrl = resultOfUpload.url.split('?')[0];
        const newShipment = await updateShipment({ shipmentId: shipment.id, mediaUrl, status: 'COMPLETED' })
        console.log({ newShipment, mediaUrl })
        setShipment(newShipment)
      } else {
        toast.error('there was an error in uploading the file')
      }
      setReupload(!reupload)
      return
    } catch (error) {
      // console.log(error)
      toast.error(`error uploading the file`)
      setReupload(!reupload)
    }
  }

  const handleGenerateDC = () => {
    console.log('generate dc')
    // window.open(`/godown/print/shipment/${shipment.id}`,)
    navigate(`/godown/print/shipment/${shipment.id}`)
    return
  }

  const uploadFileJSX = () => {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}>
        <label>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              padding: '1rem',
              border: '3px solid #B6C7D2',
              borderRadius: '10px',
              background: '#B6C7D233',
            }}
          >
            <input
              type='file'
              style={{ display: 'none' }}
              onChange={async (e) => {
                // console.log(e.target.files);
                // console.log(e.target.files[0].name, file);
                setFileName(e.target.files[0].name);
                setFile(e.target.files[0]);
              }}
            />
            <CloudUploadIcon
              sx={{
                // width: '100px',
                fill: 'primary.main',
                display: 'block',
                marginRight: '1rem',
              }}
            />
            <Typography align='center' variant='body1'>
              {fileName ? fileName : 'Upload file'}
            </Typography>{' '}
          </Box>
        </label>
        <Button variant='contained' onClick={handleFileUpload}>Upload</Button>
      </Box>
    )

  }

  return (
    <>
      {shipment ? (
        <Box sx={{ padding: '1rem', width: '100%' }}>
          {!shipment.mediaUrl ? (
            <> {uploadFileJSX()} </>
          ) : (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              {reupload ? (
                <Box sx={{ padding: '1rem', display: 'flex', alignItems: 'center' }}>
                  <Button variant='contained'>
                    <a download href={shipment.mediaUrl} style={{ textDecoration: 'none', color: 'white' }}>Download DC</a>
                  </Button>
                  <Box sx={{ border: '0.5px solid black', borderRadius: '10px', padding: '0.3rem', marginLeft: '2rem' }}>
                    <EditIcon sx={{}} onClick={() => setReupload(!reupload)} />
                  </Box>
                </Box>
              ) : (
                <>
                  {uploadFileJSX()}
                  <Box sx={{ border: '0.5px solid black', borderRadius: '10px', padding: '0.3rem', marginLeft: '2rem' }}>
                    <UndoIcon sx={{}} onClick={() => setReupload(!reupload)} />
                  </Box>
                </>
              )}
            </Box>
          )}
          <TableContainer >
            <Table sx={{}}>
              <TableHead>
                <TableRow>
                  <TableCell> <Typography variant='h4'>Item</Typography> </TableCell>
                  <TableCell> <Typography variant='h4'>Item Info</Typography> </TableCell>
                  <TableCell> <Typography variant='h4'>specification</Typography> </TableCell>
                  <TableCell> <Typography variant='h4'>Media / Notes</Typography> </TableCell>
                  {/* <TableCell> <Typography variant='h4'>notes</Typography> </TableCell> */}
                  <TableCell> <Typography variant='h4'>status</Typography> </TableCell>
                  {/* <TableCell> <Typography variant='h4'>action</Typography> </TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {shipment && shipment.shipmentItems && shipment?.shipmentItems.length > 0 && shipment?.shipmentItems.map((item, index) => {
                  return <TableRow key={`${item.id}-${index}-${Math.random()}`}>
                    <TableCell>
                      {findProductNameFromId(item.itemId)}
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                          }}
                        >
                          <Typography>Size:</Typography>
                          <Typography>
                            {findProductSizeName(item.itemId, item.itemSizeId)}
                          </Typography>
                        </Box>
                        {item.options.length > 0 && item.options.map((op, index) => {
                          return (
                            <Box
                              key={`${op.optionId}-${Math.random()}-${index}`}
                              sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                              }}
                            >
                              <Typography>
                                {findOptionNameFromId(op.itemOptionId)}:{' '}
                              </Typography>
                              <Typography>
                                {findOptionValueFromId(
                                  op.itemOptionId,
                                  op.itemOptionValueId
                                )}
                              </Typography>
                            </Box>
                          );
                        })}
                      </Box>{' '}
                    </TableCell>
                    <TableCell>
                      <Box>
                        <Typography>quantity: {item.quantity}</Typography>
                        <Typography>price: {item.price || 'n/a'}</Typography>
                        <Typography>weight: {item.weight || 'n/a'}</Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          display: 'flex',
                          // justifyContent: 'center',
                        }}
                      >
                        {item.mediaUrl === 'no media'
                          ? (<Typography>{item.mediaUrl}</Typography>)
                          :
                          <Typography align='center'>
                            <a href={item.mediaUrl} style={{ textDecoration: 'none' }} download>download media</a>
                          </Typography>
                        }
                      </Box>
                      {/* </TableCell> */}
                      {/* <TableCell> */}
                      <Typography>
                        {item.notes ? item.notes : 'no notes'}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {item.status}
                      </Typography>
                      <Typography>
                        {orderUnit.find(x => item.itemOrderUnitId === x.id).name}
                      </Typography>
                      <Typography>
                        {orderUnit.find(x => item.itemOrderUnitId === x.id).trackingUnitQuantity * item.quantity}{' '}
                        {trackingUnit.find(y => y.id === orderUnit.find(x => item.itemOrderUnitId === x.id).trackingUnitId).name}s
                      </Typography>
                    </TableCell>
                    <TableCell>
                    </TableCell>
                  </TableRow>
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{ marginTop: '1rem' }}>
            <Button variant='contained' onClick={handleGenerateDC}>Generate DC</Button>
          </Box>
        </Box >
      ) : (
        <Box sx={{ width: '100%', padding: '1rem' }}>
          <Typography variant='h4' align='center' sx={{ textTransform: 'uppercase' }} >
            select an shipment to view
          </Typography>
        </Box>
      )}
      <Box sx={{ display: 'none' }}>
        {/* <DC shipment={shipment} shipmentDCRef={shipmentDCRef} /> */}
      </Box>
    </>
  )
}

export default View