import React, { useState, useRef, useEffect } from 'react'
import { Grid, Box, TextField } from '@mui/material'
import { useSelector } from 'react-redux'
import CreateOrderCard from './CreateOrderCard'
import CreateOrderOrder from './CreateOrderOrder'
// shipment cart 


const CreateOrder = ({ headerHeight, client }) => {
  const textFieldHeightRef = useRef()

  // billing and pre-press item can not be ordered
  const products = useSelector(state => state.product.filter(product => !(product.categoryId === 1 || product.categoryId === 2)))

  const [productId, setProductId] = useState(products[0].id)
  const [textFieldHeight, setTextFieldHeight] = useState(0)

  const [itemName, setItemName] = useState('')

  useEffect(() => {
    setTextFieldHeight(textFieldHeightRef?.current?.offsetHeight)
  }, [])


  return (
    <Grid container sx={{ height: '100%' }}>
      <Grid container item xs={4} sx={{ borderRight: '1px solid', borderColor: 'secondary.underLine', paddingRight: '0rem', }}>
        <Box ref={textFieldHeightRef} sx={{ padding: '0rem 1rem 1rem 0', width: '100%' }}>
          <TextField fullWidth value={itemName} onChange={(e) => setItemName(e.target.value)} />
        </Box>
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              overflow: 'scroll',
              // the 1 rem is the padding from the previous box
              height: `calc(100vh - ${headerHeight}px - 1rem - ${textFieldHeight}px)`,
              scrollbarWidth: 'none',
              '&::-webkit-scrollbar': { display: 'none', },
              padding: '0 1rem 0 0',
            }}
          >
            {products
              .sort(product => product.id)
              .filter(product => product.name.toLowerCase().includes(itemName.toLowerCase()))
              .map((product) => {
                return <CreateOrderCard key={product.id} product={product} setProductId={setProductId} />
              })}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={8} sx={{ borderLeft: '1px solid', borderColor: 'secondary.underLine' }}>
        <Box sx={{ width: '100%' }}>
          <CreateOrderOrder productId={productId} client={client} />
        </Box>
      </Grid>
    </Grid>
  )
}

export default CreateOrder