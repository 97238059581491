import { Auth } from "aws-amplify";
import axios from 'axios'
import { toast } from "react-toastify";

const baseUrl = process.env.REACT_APP_PROXY_BACKEND
// * aws error message are in the .message format. so those error are handled in the component or habdled with a toast error. Our backend sends a error.response.data object. So we handle those errors here

const getToken = async () => {
  const session = await userService.getSession()
  const token = session && session.idToken
  return token.jwtToken
}

const getSession = async () => {
  try {
    const session = await Auth.currentSession();
    return session;
  } catch (error) {
    toast.error(`there was an error getting the current session, ${error?.response.data}`)
  }
};

const signUp = async (email, password) => {
  try {
    const user = await Auth.signUp({ username: email, password, });
    return user;
  } catch (error) {
    // exception to the rule of handling error here: this error is handled in the component
    throw error
  }
};

const confirmSignUp = async (email, code, password) => {
  try {
    const result = await Auth.confirmSignUp(email, code);
    // console.log(result)
    if (result === 'SUCCESS') {
      const user = await Auth.signIn(email, password);
      const token = await getToken()
      const username = user.username

      await axios({
        method: 'post',
        url: `${baseUrl}/user/${username}?email=${email}`,
        headers: { Authorization: `${token}` },
      })

      await Auth.signOut();
      toast.success('sign up confirmed, please sign in')
    } else {
      throw new Error(`Error: confirm sign up`);
    }
  } catch (error) {
    // exception to the rule of handling error here: this error is handled in the component
    throw error
  }
};

const signIn = async (email, password) => {
  try {
    const user = await Auth.signIn(email, password);
    return user;
  } catch (error) {
    toast.error(`error during signIn: ${error.message}`);
  }
};



const verifyCurrentUserPhoneAttribute = async (attributeName) => {
  try {
    const result = await Auth.verifyCurrentUserAttribute('phone_number')
    return result
  } catch (error) {
    toast.error(`there was an error in verifying your phone number : ${error.message}`)
  }
}

const submitPhoneVerificationCode = async (code, data) => {
  try {
    const token = await getToken()
    if (!token) return

    const result = await Auth.verifyCurrentUserAttributeSubmit('phone_number', code)
    const username = data.username
    if (result === 'SUCCESS') {
      await axios({
        method: 'put',
        url: `${baseUrl}/user/${username}`,
        headers: { Authorization: `${token}` },
        data: { phone: data.phoneNumber }
      })
    }
    return result
  } catch (error) {
    error.message ?
      toast.error(`there was an error in confirming your phone number, ${error.message}`) :
      toast.error(`there was an error in confirming your phone number, ${error?.response.data}`)
  }
}



const getAllUsers = async (data) => {
  try {
    const token = await getToken()
    if (!token) return
    const result = await axios({
      method: 'get',
      url: `${baseUrl}/user?clientEmail=${data.clientEmail}&cognitoGroup=${data.cognitoGroup}&limit=${data.limit}&currentPage=${data.currentPage}&orderByDesc=${data.orderByDesc}`,
      headers: { Authorization: `${token}` },
    })
    return result.data
  } catch (error) {
    toast.error(`there was an error in getting all users, ${error?.response.data}`)
  }
}

const getOneUser = async (username) => {
  try {
    const token = await getToken()
    if (!token) return

    const result = await axios({
      method: 'get',
      url: `${baseUrl}/user/${username}`,
      headers: { Authorization: `${token}` }
    })

    return result.data
  } catch (error) {
    toast.error(`there was an error in getting all users, ${error?.response.data}`)
  }
}

const findUsersAddress = async (usernames) => {
  try {
    const token = await getToken()
    if (!token) return
    const result = await axios({
      // axios does not allow a req body for a get request
      method: 'post',
      url: `${baseUrl}/user/getUsersAddress`,
      headers: { Authorization: `${token}`, },
      data: { usernames }
    })
    return result.data
  } catch (error) {
    // toast.error('there was an error in getting a users addresses')
    toast.error(`there was an error in getting all user addresses, ${error?.response.data}`)
  }
}

const postUserAddress = async (username, data) => {
  try {
    const token = await getToken()
    if (!token) return

    const result = await axios({
      method: 'post',
      url: `${baseUrl}/user/address`,
      headers: { Authorization: `${token}` },
      data: { username, data }
    })

    return result.data
  } catch (error) {
    toast.error(`there was an error posting the demand order, ${error?.response.data}`)
  }
}

const editUserAddress = async (username, data) => {
  try {
    const token = await getToken()
    if (!token) return

    const result = await axios({
      method: 'put',
      url: `${baseUrl}/user/address/${data.addressId}`,
      headers: { Authorization: `${token}` },
      data: { username, data }
    })

    return result.data
  } catch (error) {
    toast.error(`there was an error in editing all user addresses, ${error?.response.data}`)
  }
}

const inactivateUserAddress = async (username, data) => {
  try {
    const token = await getToken()
    if (!token) return

    const result = await axios({
      method: 'delete',
      url: `${baseUrl}/user/address/${data.addressId}`,
      headers: { Authorization: `${token}` },
      data: { username, active: data.active }
    })

    return result.data
  } catch (error) {
    toast.error(`there was an error in deleting all user addresses, ${error?.response.data}`)
  }
}

const updateUser = async (username, data) => {
  try {
    const token = await getToken()
    if (!token) return

    const result = await axios({
      method: 'put',
      url: `${baseUrl}/user/${username}`,
      headers: { Authorization: `${token}` },
      data: data
    })

    await Auth.currentSession() // refreshes the session and updates any attribute changes
    return result.data
  } catch (error) {
    error.message ?
      toast.error(`there was an error in updating the user, ${error.message}`) :
      toast.error(`there was an error in updating the user addresses, ${error?.response.data}`)
  }
}

const resetUserPassword = async (data) => {
  try {
    const result = await axios({
      method: 'put',
      url: `${baseUrl}/user/resetUserPassword`,
      data: data
    })

    return result.data
  } catch (error) {
    toast.error('there was an error in sending you a new password')
  }
}

const userService = {
  updateUser,
  inactivateUserAddress,
  resetUserPassword,
  editUserAddress,
  signUp,
  confirmSignUp,
  signIn,
  getSession,
  verifyCurrentUserPhoneAttribute,
  submitPhoneVerificationCode,
  getOneUser,
  postUserAddress,
  getAllUsers,
  findUsersAddress
  // postUserPhoneNumber,
};

export default userService;

// const postUserPhoneNumber = async (phoneNumber) => {
//   try {
//     const token = await getToken()
//     if (!token) return
//     const user = await Auth.currentAuthenticatedUser();

//     const postPhoneNumber = await axios({
//       method: 'post',
//       url: `${baseUrl}/user/updateAwsPhoneNumber`,
//       headers: { Authorization: `${token}` },
//       data: { phoneNumber: phoneNumber, username: user.username }
//     })
//     return postPhoneNumber
//   } catch (error) {
//     toast.error(`there was an error posting the demand order, ${error?.response.data}`)
//   }
// }