import React, { useState } from 'react';
import EditTableView from '../../../../../../../utilComponents/EditTableView';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { postItemRuleAction } from '../../../../../../../../reducer_action/product';
import { toast } from 'react-toastify';

const SizeQuantityForm = ({ ruleType }) => {
  // data input for the form
  const dispatch = useDispatch();

  const evaluationTypes = useSelector((state) =>
    state.productUtil.enums
      .find((x) => x.name === 'logical_operator')
      .value.split('|')
  );

  const { item } = useParams();

  const itemSize = useSelector((state) => state.product.find((x) => x.id === +item).size);

  const productOptions = useSelector((state) => state.product.find((x) => x.id === +item).options);

  const itemId = useSelector((state) => state.product.find((x) => x.id === +item).id);

  const selectValues = productOptions
    .map((option) => {
      const selectValuesOptions = option.values.map((value) => {
        return {
          name: `${option.name}-${value.optionValueName}`,
          id: value.id,
        };
      });
      return selectValuesOptions;
    }).flat();

  const [ruleTitle, setRuleTitle] = useState('');
  const [ruleDescription, setRuleDescription] = useState('');
  // const [sizeId, setSizeId] = useState(itemSize[0]?.id || null);
  const [sizeId, setSizeId] = useState([]);
  const [hideOptionValueId, setHideOptionValueId] = useState([]);

  const [evalType, setEvalType] = useState(evaluationTypes[0]);
  const [startQuantity, setStartQuantity] = useState(0);
  const [endQuantity, setEndQuantity] = useState(0);
  const [allQuantity, setAllQuantity] = useState(true);

  const handlePostRule = () => {
    const rule = {
      itemId, ruleTitle, ruleDescription, sizeId, hideOptionValueId, evalType,
      startQuantity: +startQuantity, endQuantity: +endQuantity,
      allQuantity, ruleType
    };

    dispatch(postItemRuleAction(rule));
  };

  return (
    <>
      {sizeId && hideOptionValueId ? (
        <Grid container item xs={12} sx={{ paddingLeft: '1rem' }}>
          <Grid item xs={12}>
            <Typography variant='h4'>Size Quantity Rule form</Typography>
          </Grid>
          <Grid item xs={12}>
            <EditTableView
              type='text'
              title='rule name'
              value={ruleTitle}
              onChange={(e) => setRuleTitle(e.target.value)}
            />
            <EditTableView
              type='text'
              title='rule description'
              value={ruleDescription}
              onChange={(e) => setRuleDescription(e.target.value)}
            />
            <EditTableView
              title='select sizes'
              type='multiSelect'
              value={sizeId}
              // onChange={(e) => setSizeId([...new Set([...sizeId, e.target.value])])}
              onChange={(e) => setSizeId(e.target.value)}
              // onChange={(e) => console.log(e.target.value)}
              selectValues={itemSize.map((x) => {
                const mappedSize = {
                  name: x.sizeTitle,
                  id: x.id,
                };
                return mappedSize;
              })}
            />
            <EditTableView
              title='rule applies for all quantity'
              type='switch'
              value={allQuantity}
              onChange={(e) => setAllQuantity(e.target.checked)}
            />
            {!allQuantity ? (
              <>
                <EditTableView
                  title='evaluation options'
                  type='select'
                  selectValues={evaluationTypes}
                  value={evalType}
                  onChange={(e) => setEvalType(e.target.value)}
                />

                <EditTableView
                  title='start quantity'
                  type='text'
                  value={startQuantity}
                  onChange={(e) => {
                    setStartQuantity(e.target.value);
                  }}
                />

                {evalType === 'BETWEEN' && (
                  <EditTableView
                    title='end quantity'
                    type='text'
                    value={endQuantity}
                    onChange={(e) => {
                      setEndQuantity(e.target.value);
                    }}
                  />
                )}
              </>
            ) : null}
          </Grid>
          <Grid item xs={12}>
            <EditTableView
              type='multiSelect'
              selectValues={selectValues}
              value={hideOptionValueId}
              title='hide option value'
              // onChange={(e) => setHideOptionValueId([...new Set([...hideOptionValueId, e.target.value])])}
              onChange={(e) => setHideOptionValueId(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sx={{ margin: '2rem 0rem 0 0 ' }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                variant='contained'
                onClick={() => {
                  console.log(
                    ruleTitle,
                    ruleDescription,
                    sizeId,
                    hideOptionValueId,
                    evalType,
                    startQuantity,
                    endQuantity,
                    allQuantity
                  );
                  handlePostRule();
                }}
              >
                save
              </Button>
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Typography variant='h4'>
          Either size or option values are not present for this item for this
          item
        </Typography>
      )}
    </>
  );
};

export default SizeQuantityForm;
