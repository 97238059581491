import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography'

// layout module
import Dash from '../components/Layout_Module/Dash';
import MainSideBar from '../components/Layout_Module/MainSideBar';
import MainBar from '../components/Layout_Module/MainBar';

// auth
import Auth from './Auth';
// import Authentication from '../components/Authentication_Module/Authentication';

// product module
import ProductsMainBar from '../components/Products_Module/Products/asx/Mainbar/Products';
import ProductsSidebar from '../components/Products_Module/Products/asx/Sidebar/ProductsSidebar';


//demand order section section
import Cart from '../components/Demand_Order_Module/cart/asx/Cart';
import ClientOrderSidebar from '../components/Demand_Order_Module/orders/asx/Sidebar/ClientOrderSidebar';
import ClientOrderMainbar from '../components/Demand_Order_Module/orders/asx/Mainbar/ClientOrderMainbar';
import AsxShipmentSidebar from '../components/Demand_Order_Module/shipments/asx/Sidebar/AsxShipmentSidebar';
import AsxShipmentMainbar from '../components/Demand_Order_Module/shipments/asx/Mainbar/AsxShipmentMainbar';


// billing section
import Billing from '../components/Billing_Module/Billing/Mainbar/Billing';
import Expense from '../components/Billing_Module/Expense/Mainbar/Expense';
import AddAdvancePayment from '../components/Billing_Module/Advance_Payment/Mainbar/AddAdvancePayment';
import AdvancePayment from '../components/Billing_Module/Advance_Payment/Mainbar/AdvancePayment';
import AddBankDeposit from '../components/Billing_Module/Bank_Deposit/AddBankDeposit';
import SalaryAdvance from '../components/Billing_Module/Salary_Advance/Mainbar/AddSalaryAdvance';
import ListSalaryAdvance from '../components/Billing_Module/Salary_Advance/Mainbar/ListSalaryAdvance';
import CreditCustomerSidebarHeader from '../components/Billing_Module/Credit_Customers/Sidebar/Header';
import CreditCustomerSidebarBody from '../components/Billing_Module/Credit_Customers/Sidebar/Body';
import AddCustomer from '../components/Billing_Module/Credit_Customers/Sidebar/AddCustomer';
import CreditCustomerMainbarHeader from '../components/Billing_Module/Credit_Customers/Mainbar/Header';
import CreditCustomerMainbarBody from '../components/Billing_Module/Credit_Customers/Mainbar/Body';

// user section
import User from '../components/User_Module/asx/User';
import AddAddress from '../components/User_Module/asx/AddAddress';
import EditAddress from '../components/User_Module/asx/EditAddress';
import VerifyPhoneAtt from '../components/User_Module/asx/VerifyPhoneAtt';
import ChangePassword from '../components/User_Module/asx/ChangePassword';

// prnpro module
// import Prnpro from '../components/Printpro_Module/asx/Prnpro';
import AsxPrnproSidebar from '../components/Printpro_Module/asx/Sidebar/AsxPrnproSidebar';
import AsxPrnproMainbar from '../components/Printpro_Module/asx/Mainbar/AsxPrnproMainbar';


const Asx = () => {
  const action = useSelector((state) => state.util.actionQuery);
  return (
    <Routes>
      <Route path='asx'
        element={
          <Auth>
            <Dash />
          </Auth>
        }
      >
        <Route index element={
          <Auth>
            <Typography variant='h2' align='center' sx={{ marginTop: '3rem' }}>ASX Modules</Typography>
          </Auth>
        } />

        <Route path='/asx/prnpro'
          element={
            <Auth>
              <MainSideBar>
                <AsxPrnproSidebar />
              </MainSideBar>
            </Auth>
          }
        >
          <Route path='/asx/prnpro/:prnproBillId'
            element={
              <Auth>
                <MainBar>
                  <AsxPrnproMainbar />
                </MainBar>
              </Auth>
            }
          />
        </Route>

        <Route path='/asx/products'
          element={
            <Auth>
              <MainSideBar>
                <ProductsSidebar />
              </MainSideBar>
            </Auth>
          }
        >
          <Route path='/asx/products/:item'
            element={
              <Auth>
                <MainBar>
                  <ProductsMainBar />
                </MainBar>
              </Auth>
            }
          />
        </Route>
        {/* asx billing module.  */}
        <Route path='/asx/billing'
          element={
            <Auth>
              <MainBar>
                <Billing />
              </MainBar>
            </Auth>
          }
        ></Route>
        <Route path='/asx/payments' element={
          <Auth>
            <h1>payments</h1>
          </Auth>
        }>

        </Route>
        <Route path='/asx/creditCustomers'
          element={
            <Auth>
              <MainSideBar>
                {action === 'add' ? (
                  <AddCustomer />
                ) : (
                  <>
                    <CreditCustomerSidebarHeader />
                    <CreditCustomerSidebarBody />
                  </>
                )}
              </MainSideBar>
            </Auth>
          }
        >
          <Route path='/asx/creditCustomers/:customer'
            element={
              <Auth>
                <MainBar>
                  <CreditCustomerMainbarHeader />
                  <CreditCustomerMainbarBody />
                </MainBar>
              </Auth>
            }
          />
        </Route>
        <Route path='/asx/bankDeposits' element={<Auth><AddBankDeposit /></Auth>}></Route>
        <Route path='/asx/expenses'
          element={
            <Auth>
              <MainBar>
                <Expense />
              </MainBar>
            </Auth>
          }
        />
        <Route
          path='/asx/salaryAdvance'
          element={
            <Auth>
              <MainBar>
                {action === 'add' ? <SalaryAdvance /> : <ListSalaryAdvance />}
              </MainBar>
            </Auth>
          }
        ></Route>
        <Route
          path='/asx/advancePayments'
          element={
            <Auth>
              <MainBar>
                {action === 'add' ? <AddAdvancePayment /> : <AdvancePayment />}
              </MainBar>
            </Auth>
          }
        ></Route>
        <Route path='/asx/currentInventory'
          element={<Auth><h1> closing stock form - post item quantity = with size and option specifications </h1></Auth>}
        >
        </Route>
        <Route path='/asx/inventoryAdjustment'
          element={
            <Auth>
              <h1>
                inventory adjustment and maintenance of current inventory levels
                to accurately represent present stock levels - this needs to show
                todo: add a form that will subtract certain items from the current inventory levels of a shop
                shipped from other branches
              </h1>
            </Auth>
          }
        ></Route>


        {/* inventory section for the  */}
        <Route path='/asx/cart'
          element={<Auth><Cart /></Auth>}
        />
        <Route path='/asx/orders'
          element={
            <Auth>
              <MainSideBar>
                <ClientOrderSidebar />
              </MainSideBar>
            </Auth>
          }
        >
          <Route path='/asx/orders/:orderId'
            element={<Auth><MainBar> <ClientOrderMainbar /> </MainBar></Auth>}
          />
        </Route>
        {/*  shipments subsection */}
        <Route path='/asx/shipments'
          element={
            <Auth>
              <MainSideBar>
                <AsxShipmentSidebar />
              </MainSideBar>
            </Auth>
          }
        >
          <Route path='/asx/shipments/:shipmentId' element={
            <Auth>
              <MainBar>
                <AsxShipmentMainbar />
              </MainBar>
            </Auth>
          } />
        </Route>
        {/* <Route path='/asx/shipmentReturns' element={<Auth><h1>stock report</h1></Auth>} ></Route> */}


        {/* user section */}
        <Route path='/asx/user' element={<Auth><User /></Auth>} />
        <Route path='/asx/user/add-address' element={<Auth><AddAddress /></Auth>} />
        <Route path='/asx/user/edit-address/:addressId' element={<Auth><EditAddress /></Auth>} />
        <Route path='/asx/user/verifyPhone' element={<Auth><VerifyPhoneAtt /></Auth>} />
        <Route path='/asx/user/changePassword' element={<Auth><ChangePassword /></Auth>} />
      </Route>
    </Routes>
  );
};

export default Asx;
