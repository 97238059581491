import React, { useState, useEffect, useRef } from 'react'
import { Pagination, Select, MenuItem, Box, Grid, Typography, TextField } from '@mui/material'
import { useSelector } from 'react-redux'
// import { getGodownClientDemandOrder } from '../../../../../../service/demandOrder'
import userService from '../../../../../../service/user'
import { getAllDemandOrder } from '../../../../../../service/demandOrder'
import GodownClientOrderCard from './GodownClientOrderCard'
import OrdersFooter from './OrdersFooter'

const Orders = ({ client, headerHeight }) => {
  const footerHeightRef = useRef()
  const [limit, setLimit] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [status, setStatus] = useState('ALL')
  const [itemName, setItemName] = useState('')
  // const [tempItemName, setTempItemName] = useState('')
  const [orderByDesc, setOrderByDesc] = useState(true)
  // todo delete the cognitoGroup -- unnecessary as we are searching by clients
  const [cognitoGroup, setCognitoGroup] = useState('ALL')
  const [clientDemandOrders, setClientDemandOrders] = useState({})
  const [footerHeight, setFooterHeight] = useState(0)

  const totalPages = clientDemandOrders.count ? Math.ceil(clientDemandOrders?.count / limit) : 0
  console.log('totalPages', totalPages)

  useEffect(() => {
    (async () => {
      const data = { status, currentPage, limit, username: client.username, itemName, orderByDesc, cognitoGroup }
      console.log({ data })
      const clientDO = await getAllDemandOrder(data)
      console.log(clientDO, 'clientOrders', data)
      setClientDemandOrders(clientDO)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, limit, itemName, status, orderByDesc, client.username])


  useEffect(() => {

    const footerHeight = footerHeightRef.current && footerHeightRef.current.offsetHeight
    console.log({ footerHeight, headerHeight })
    setFooterHeight(footerHeight)
  }, [])

  return (
    <Box sx={{
      display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignContent: 'flex-start', width: '100%',
      // height: `100vh -${headerHeight}px`
    }} >
      <Box sx={{
        height: `calc(100vh - ${+footerHeight + +headerHeight}px)`,
        overflow: 'scroll',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      }}>
        {clientDemandOrders && clientDemandOrders.orders && clientDemandOrders.orders.length > 0 ?
          <>
            {clientDemandOrders.orders.map(order => { return <GodownClientOrderCard order={order} /> })}
          </>
          :
          <Typography variant='h4'>There are no orders under the current criteria</Typography>
        }
      </Box>
      <Box ref={footerHeightRef} sx={{ padding: '0.5rem 0' }}>
        <OrdersFooter orderByDesc={orderByDesc} setOrderByDesc={setOrderByDesc} clientDemandOrders={clientDemandOrders} setCurrentPage={setCurrentPage} status={status} setStatus={setStatus} limit={limit} setLimit={setLimit} setItemName={setItemName} />
      </Box>
    </Box >
  )
}

export default Orders