import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import EditTableView from '../../../../../utilComponents/EditTableView';
import { Grid, Box, Button } from '@mui/material';
import { updateItemOptionTableAction, postItemOptionTableAction } from '../../../../../../reducer_action/options';

const Edit = ({ type }) => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // console.log(type, 'type');

  const option = useSelector((state) => {
    const currentOption = state.option.find((op) => op.id === +params.option);
    // console.log({ currentOption })
    return currentOption
  });

  const renderTypesSelectValues = useSelector((state) =>
    state.productUtil.enums.find((x) => x.name === 'render_type')
  ).value.split('|');

  const priceRangeLookupSelectValues = useSelector((state) =>
    state.productUtil.enums.find((x) => x.name === 'range_lookup')
  ).value.split('|');

  const weightRangeLookupSelectValues = useSelector((state) =>
    state.productUtil.enums.find((x) => x.name === 'range_lookup')
  ).value.split('|');

  const unitSelectValues = useSelector((state) =>
    state.productUtil.enums.find((x) => x.name === 'pricing_unit')
  ).value.split('|');

  const handleUpdateOption = () => {
    if (type === 'put') {
      dispatch(updateItemOptionTableAction(params.option, { name, renderType, priceRangeLookup, priceUnit, weightRangeLookup, applyLinearFormula, required, status }));
      navigate('?action=view')
    } else if (type === 'post') {
      dispatch(postItemOptionTableAction({ name, renderType, priceRangeLookup, priceUnit, weightRangeLookup, applyLinearFormula, required, }));
      navigate('/godown/productOptions')
    }
  };

  const [name, setName] = useState(option?.name || '');
  const [renderType, setRenderType] = useState(option?.renderType || 'undefined');
  const [priceRangeLookup, setPriceRangeLookup] = useState(option?.priceRangeLookup || 'undefined');

  const [priceUnit, setPriceUnit] = useState(option?.priceUnit || 'undefined');
  const [weightRangeLookup, setWeightRangeLookup] = useState(type === 'put' ? option.weightRangeLookup : 'undefined');

  const [applyLinearFormula, setApplyLinearFormula] = useState(option?.applyLinearFormula || false);
  const [required, setRequired] = useState(option?.required || false);
  const [status, setStatus] = useState(type === 'put' ? option.status : true);

  return (
    <Grid container sx={{ width: '100%' }}>
      {/* render type, price range lookup, weight range lookup, apply linear formula */}
      <Grid container item xs={12}>
        <Grid container item xs={12} alignItems='center'>
          <EditTableView
            title='name'
            value={name}
            onChange={(e) => setName(e.target.value)}
            compareValue={option?.name}
          />
          <EditTableView
            title='render type'
            value={renderType}
            selectValues={renderTypesSelectValues}
            onChange={(e) => setRenderType(e.target.value)}
            type='select'
            compareValue={option?.renderType}
          />
          <EditTableView
            title='price range lookup'
            value={priceRangeLookup}
            onChange={(e) => setPriceRangeLookup(e.target.value)}
            type='select'
            selectValues={priceRangeLookupSelectValues}
            compareValue={option?.priceRangeLookup}
          />
          <EditTableView
            title='price unit lookup'
            value={priceUnit}
            onChange={(e) => setPriceUnit(e.target.value)}
            type='select'
            selectValues={unitSelectValues}
            compareValue={option?.priceUnit}
          />
          <EditTableView
            title='weight range lookup'
            value={weightRangeLookup}
            onChange={(e) => setWeightRangeLookup(e.target.value)}
            type='select'
            selectValues={weightRangeLookupSelectValues}
            compareValue={option?.weightRangeLookup}
          />
          <EditTableView
            title='apply linear formula'
            value={applyLinearFormula}
            onChange={(e) => setApplyLinearFormula(e.target.checked)}
            type='switch'
            compareValue={option?.applyLinearFormula}
          />
          <EditTableView
            title='status'
            value={status}
            onChange={(e) => setStatus(e.target.checked)}
            type='switch'
            compareValue={option?.status}
          />
          <EditTableView
            title='required'
            value={required}
            onChange={(e) => setRequired(e.target.checked)}
            type='switch'
            compareValue={option?.required}
          />
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '1rem',
            }}
          >
            <Button variant='contained' onClick={handleUpdateOption}>
              save
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Edit;
