import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Grid from '@mui/material/Grid';

const MainSideBar = ({ children }) => {
  const util = useSelector((state) => state.util);
  return (
    <Grid container>
      <Grid
        item
        xs={util.mainSideBar ? 3 : 12}
        sx={{
          height: '100vh',
          // minWidth: '276px',
          backgroundColor: 'background.sidebar',
          transitionProperty: 'all',
          transitionDuration: '0.2s',
        }}
      >
        {children}
      </Grid>
      {util.mainSideBar && (
        <Grid item xs={9} sx={{ backgroundColor: 'background.default' }}>
          <Outlet />
        </Grid>
      )}
    </Grid>
  );
};

export default MainSideBar;
