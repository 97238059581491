import { configureStore } from '@reduxjs/toolkit';

// item/product/user section
import userReducer from './reducer_action/user';
import productReducer from './reducer_action/product';
import itemUtilReducer from './reducer_action/productUtil';
import optionsReducer from './reducer_action/options';
import utilReducer from './reducer_action/util';

// cart section
import cartReducer from './reducer_action/cart';
import shipmentCartReducer from './reducer_action/shipCart';
import godownClientCartReducer from './reducer_action/godownClientCart';


const reducer = {
  user: userReducer,
  product: productReducer,
  productUtil: itemUtilReducer,
  option: optionsReducer,
  cart: cartReducer,
  shipmentCart: shipmentCartReducer,
  godownClientCart: godownClientCartReducer,
  util: utilReducer,
}

const store = configureStore({
  reducer,
  devTools: process.env.NODE_ENV !== 'production',
})


export default store;
