import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

// import { setUtilAction } from "../../../../../reducer_action/util";
import { Grid, Button, Typography, TextField } from '@mui/material';
import { setUtilAction } from '../../../../../reducer_action/util';

const ProductOptionsHeader = ({ headerRef, optionSearch, setOptionSearch }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();


  // // useEffect(() => {
  //   // change the main sideBar if they press back
  //   // if (location.pathname === '/admin/productOptions') {
  //   //   dispatch(setUtilAction({ mainSideBar: false }));
  //   // }
  // }, [location.pathname, dispatch]);

  return (
    <Grid ref={headerRef} container sx={{ width: '100%', padding: '1rem 1rem 0rem 1rem', }} >
      <Grid item xs={12}>
        <TextField fullWidth value={optionSearch} onChange={(e) => setOptionSearch(e.target.value)} />
      </Grid>
      <Grid item xs={12} sx={{ marginTop: '1rem' }}>
        <Button
          onClick={() => {
            navigate('/godown/productOptions?action=add');
            dispatch(setUtilAction({ mainSideBar: false }));
          }}
          variant='contained'
        >
          add New
        </Button>
      </Grid>
    </Grid>
  );
};

export default ProductOptionsHeader;
