import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Grid, Box, Typography, } from '@mui/material'
import Tabs from '../../../../../utilComponents/Tabs'


const GodownShipmentHeader = ({ headerRef, shipment }) => {
  const navigate = useNavigate();

  const routes = ['view'].map(tab => {
    return {
      name: tab,
      onClick: () => {
        navigate(`?action=${tab}`)
      }
    }
  })

  return (
    <>
      <Grid ref={headerRef} container item xs={12} sx={{ padding: '1rem 1rem 1rem 1.5rem' }} >

        <Grid item xs={12} sx={{ marginBottom: '0.75rem' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h3">
              {shipment.shippedTo.email && shipment.shippedTo.email.split('@')[0].toUpperCase()}
            </Typography>
            <Box>
              <Typography variant="h5">Shipment Id: {shipment.id && shipment?.id}</Typography>
              <Typography align='right' variant="h6">{shipment.shipDate && shipment?.shipDate.slice(0, 10)}</Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} >
          <Tabs tabs={routes} />
        </Grid>
      </Grid>
    </>
  )

}

export default GodownShipmentHeader