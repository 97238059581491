import React, { } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';

function Spinner({ loading, children, height }) {
  return (< > {loading ? <CircularProgress /> : children} </>)
}

Spinner.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool
};

export default Spinner;