import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getGodownClientCartAction } from '../../../../../../reducer_action/godownClientCart'
import { Switch, Grid, Typography, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Button, Box, RadioGroup, FormControlLabel, Radio } from '@mui/material'
import CartItemRow from './CartItemRow'
import { postDemandOrder } from '../../../../../../service/demandOrder'
import { deleteGodownClientCartAction } from '../../../../../../reducer_action/godownClientCart'

const Cart = ({ headerHeight, client }) => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)

  const cart = useSelector((state) => state.godownClientCart)
  const addresses = client.addresses

  const [addressId, setAddressId] = useState(addresses[0].id)
  const [returnOrder, setReturnOrder] = useState(false)

  useEffect(() => {
    const userData = {
      godownUsername: user.username,
      godownEmail: user.email,
      clientUsername: client.username,
      clientEmail: client.email
    }
    dispatch(getGodownClientCartAction(userData))
  }, [dispatch, user, client])


  const handlePlaceOrder = async () => {
    const clientData = { username: client.username, email: client.email }
    const fulfillerUsername = '09ab14b5-7a2c-4eb7-99e9-4cac1bfd2c66'
    // const notes = 'this is from clientOrders section'
    await postDemandOrder(cart, addressId, clientData, returnOrder, fulfillerUsername)

    const userData = { godownUsername: user.username, godownEmail: user.email, clientUsername: client.username, clientEmail: client.email }
    dispatch(deleteGodownClientCartAction(userData))
  }

  return (
    <Grid container item xs={12} sx={{}}>
      <Grid item xs={12}>
        {cart.length > 0 ?
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell> <Typography align='center' variant='h4'> Item </Typography> </TableCell>
                  <TableCell> <Typography align='center' variant='h4'> Specifications </Typography> </TableCell>
                  <TableCell> <Typography align='center' variant='h4'> Quantity </Typography> </TableCell>
                  <TableCell> <Typography align='center' variant='h4'> Media </Typography> </TableCell>
                  <TableCell> <Typography align='center' variant='h4'> Action </Typography> </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cart.map((item, index) => {
                  return <CartItemRow item={item} index={index} client={client} key={`${item.itemId}-${Math.random()}-${index}`} />;
                })}
              </TableBody>
            </Table>
          </TableContainer>
          :
          <Box sx={{ marginTop: '2rem' }}>
            <Typography variant='h4'>There are no items in the cart</Typography>
          </Box>
        }
      </Grid>
      {cart.length > 0 && (<>
        <Grid item xs={12} sx={{ marginTop: '1rem' }}>
          <Typography variant='h4'>Select Address</Typography>
          <RadioGroup value={addressId}
            onChange={(e) => setAddressId(e.target.value)}
          >
            {addresses.length > 0 ? addresses.map((address, index) => {
              return <FormControlLabel value={address.id} control={<Radio />} label={`${address.name}:  ${address.address}`} />
            }) : <Typography>No address</Typography>}
          </RadioGroup>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '2rem', }} >
            <Switch value={returnOrder} onChange={(e) => setReturnOrder(e.target.checked)} />
            <Button onClick={handlePlaceOrder} variant='contained'> {returnOrder ? 'Return Order' : 'Place order'} </Button>
          </Box>
        </Grid>
      </>
      )}
    </Grid>
  )
}

export default Cart 