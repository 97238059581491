import React, { useState } from 'react';

import { Auth } from 'aws-amplify'
import userService from '../../service/user';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getUserAction } from '../../reducer_action/user';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
// import Spinner from '../utilComponents/Spinner'
import { toast } from 'react-toastify';
// import { imageListClasses } from '@mui/material';

const SignIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false)
  // stages of authentication process
  const [stage, setStage] = useState('signIn');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  // new-password required section
  const [NewPasswordRequired, setNewPasswordRequired] = useState('');
  const [newPasswordRequiredUser, setNewPasswordRequiredUser] = useState();


  // forgot password section
  const [forgotPasswordUserEmail, setForgotPasswordUserEmail] = useState('')
  const [forgotPasswordCode, setForgotPasswordCode] = useState('')
  const [forgotPasswordPassword, setForgotPasswordPassword] = useState('')
  const [confirmForgotPasswordPassword, setConfirmForgotPasswordPassword] = useState('')


  const handleSignIn = async () => {
    try {
      // auth 
      setLoading(true)
      const user = await Auth.signIn(email, password);
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        toast.success('a new password is required')
        setStage('newPasswordRequired')
        setNewPasswordRequiredUser(user)
        return
      }
      const currentUser = await Auth.currentSession();
      setEmail('');
      setPassword('');
      const group = currentUser.idToken.payload['cognito:groups'][0]
      navigate(`/${group === 'asx_branch' ? 'asx' : group}`);
      dispatch(getUserAction())
      setLoading(false)
    } catch (error) {
      toast.error('Error: sign in, ' + error.message);
      if (error.message === 'Temporary password has expired and must be reset by an administrator.') {
        try {
          await userService.resetUserPassword({ email: email })
          toast.success('Your password has been reset and a new password has been sent to your email')
        } catch (error) {
          toast.error('error in resetting password')
        }
      }

      if (error.message === 'Password reset required for the user') {
        toast.success('check your email for an OTP to proceed')
        setStage('forgotPasswordSubmit')
        setForgotPasswordUserEmail(email)
      }
      setLoading(false)
    }
  };

  const handleNewPasswordRequired = async () => {
    try {
      const user = await Auth.completeNewPassword(newPasswordRequiredUser, password);

      if (!user) return
      dispatch(getUserAction())

      const group = user.idToken.payload['cognito:groups'][0]
      navigate(`/${group === 'asx_branch' ? 'asx' : group}`);
      return
    } catch (error) {
      console.log(error)
    }
  }


  const sendForgotPasswordOTP = async () => {
    try {
      const { CodeDeliveryDetails } = await Auth.forgotPassword(forgotPasswordUserEmail)

      if (CodeDeliveryDetails.AttributeName === 'email' && CodeDeliveryDetails.DeliveryMedium === 'EMAIL') {
        toast.success('OTP sent')
      }

      setStage('forgotPasswordSubmit')
    } catch (error) {
      console.log(error)
      toast.error('error in sending OTP. Please try again')
    }
  }

  const handleForgotPasswordSubmit = async () => {
    try {
      if (!confirmForgotPasswordPassword === forgotPasswordPassword) return
      const result = await Auth.forgotPasswordSubmit(forgotPasswordUserEmail, forgotPasswordCode, forgotPasswordPassword)
      if (result === 'SUCCESS') {
        setStage('signIn')
        toast.success('password reset successful.please sign in')
      } else {
        throw Error('forgot password error')
      }
    } catch (error) {
      toast.error('error in resetting password')
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === '13' || event.which === 13) {
      handleSignIn();
    }
  };

  return (
    <Grid container justifyContent='center' alignItems='flex-start' rowSpacing={2} sx={{ padding: '1rem' }} >
      {stage === 'signIn' &&
        <>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant='outlined'
              label='email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              variant='outlined'
              label='password'
              value={password}
              type='password'
              onChange={(e) => setPassword(e.target.value)}
              onKeyPress={handleKeyPress}
            />
          </Grid>

          <Grid item>
            <Button disabled={loading} color='primary' variant='contained' onClick={handleSignIn} sx={{ paddingTop: 'o.4rem' }}>
              sign in
            </Button>
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '0rem' }}>
              <Button disabled={loading} onClick={() => setStage('forgotPassword')}> forgot password</Button>
            </Box>
          </Grid>
        </>
      }

      {stage === 'newPasswordRequired' && (
        <>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant='outlined'
              label='new password'
              value={NewPasswordRequired}
              type='password'
              onChange={(e) => setNewPasswordRequired(e.target.value)}
              onKeyPress={handleKeyPress}
            />
          </Grid>
          <Grid item>
            <Button color='primary' variant='contained' onClick={handleNewPasswordRequired}>
              submit new password
            </Button>
          </Grid>

        </>
      )}

      {stage === 'forgotPassword' && (
        <>
          <Grid item xs={12}>
            <TextField fullWidth variant='outlined' label='email' type='email'
              value={forgotPasswordUserEmail}
              onChange={(e) => setForgotPasswordUserEmail(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
              <Button color='primary' variant='contained' onClick={sendForgotPasswordOTP}> send OTP to email </Button>
            </Box>
          </Grid>
        </>
      )
      }

      {stage === 'forgotPasswordSubmit' && (
        <>
          <Grid item container xs={12}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant='outlined'
                label='code '
                // type='password'
                value={forgotPasswordCode}
                onChange={(e) => setForgotPasswordCode(e.target.value)}
                sx={{ marginTop: '1rem' }}
              />
              <TextField
                sx={{ marginTop: '1rem' }}
                fullWidth
                variant='outlined'
                label='new password'
                type='password'
                value={forgotPasswordPassword}
                onChange={(e) => setForgotPasswordPassword(e.target.value)}
              />
              <TextField
                sx={{ marginTop: '1rem' }}
                fullWidth
                variant='outlined'
                label='confirm new password'
                type='password'
                value={confirmForgotPasswordPassword}
                onChange={(e) => setConfirmForgotPasswordPassword(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
                <Button color='primary' variant='contained' onClick={handleForgotPasswordSubmit}> submit new password </Button>
              </Box>
            </Grid>
          </Grid>
        </>
      )}
    </Grid >
  );
};

export default SignIn;
